<template>
  <b-row>
    <b-col>
      <b-row>
        <b-modal title="Generate Keys" hide-footer ref="keyGenerate">
          <b-row>
            <b-col>
              <p>Select End Points To Access</p>
            </b-col>
          </b-row>
        </b-modal>
      </b-row>
      <b-row class="py-3">
        <b-col cols="3">
          <b-form-input
            class="text-gray text-sm noFocus ml-1"
            style="margin-top: 1px; padding:6px 5px;"
            v-model="search"
            type="text"
            placeholder="Search by Name"
            required
            v-on:keyup.enter="searchAccounts">
          </b-form-input>
        </b-col>
        <b-col cols="9"></b-col>
      </b-row>
      <div class="mx-auto">
        <b-progress value="100" v-if="loading"  variant="#007ac5" height="0.2rem" animated></b-progress>
      </div>
      <b-row>
        <b-col cols="12">
          <table class="min-w-full leading-normal">
            <thead>
            <tr class="border-b border-t border-grey">
              <th class="t-header">Name</th>
              <th class="t-header">Email</th>
              <th class="t-header">Phone</th>
              <th class="t-header">Nationality</th>
              <th class="t-header">Date Created</th>
              <th class="t-header">Status</th>
              <th class="t-header">Action</th>
            </tr>
            </thead>

            <tbody>
            <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(account, idx) in accounts" :key="idx">
              <td class="t-body font-bold text-uppercase">
                <span >{{account.givenName}}</span>
                <span class="mx-2">{{account.surName}}</span>
                <span class="mx-2" v-if="account.middleName !== 'null'">{{account.middleName}}</span>
              </td>
              <td class="t-body">
                <span >{{account.idNumber}}</span><br/>
                <small class="text-green">({{cleanUnderscoreString(account.idType)}})</small>
              </td>
              <td class="t-body">
                <span>
                  <i class="fa fa-check-circle text-green mx-1" v-if="account.emailVerified"></i>
                  <i class="fa fa-times text-orange mx-1" v-else></i>
                  {{account.email}}
                </span>
              </td>
              <td class="t-body">
                <span>
                  <i class="fa fa-check-circle text-green mx-1" v-if="account.phoneVerified"></i>
                  <i class="fa fa-times text-orange mx-1" v-else></i>
                  {{account.phoneNumber}}
                </span>
              </td>
              <td class="t-body">
                <span>{{account.nationality}}</span>
              </td>
              <td class="t-body"><span>{{formatDate(account.createdAt)}}</span></td>
              <td class="t-body font-bold">
                <span class="text-green" v-if="account.enabled">Active</span>
                <span class="text-red" v-else>Inactive</span>
              </td>
              <td class="t-body text-center">
                <b-row>
                  <b-col>
                    <button class="bg-blue text-white rounded-sm px-2 py-1">Generate Keys</button>
                  </b-col>
                  <b-col>
                    <button class="bg-blue text-white rounded-sm px-2 py-1">View <i class="fa fa-eye mx-1"></i></button>
                  </b-col>
                </b-row>
              </td>
            </tr>
            <tr>
              <td v-if="accounts.length === 0" colspan="12" class="bg-inertgrey text-center py-1">
                <span class="text-sm">No results found</span>
              </td>
            </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
      <b-row class="mx-1" v-if="pagination.totalPages > 1">
        <b-col cols="1">
          <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
        </b-col>
        <b-col cols="1">
          <b-row>
            <b-col cols="12">
              <p class="paginate-details text-center">
                <b-badge class="bg-blue text-white">
                  {{(currentPage + 1)}}/{{pagination.totalPages}}
                </b-badge>
              </p>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="1" >
          <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">Next</button>
        </b-col>
        <b-col class="9"></b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "MDAIntegrators",
  data(){
    return{
      loading:false,
      currentPage:0,
      limit:10,
      accounts:[],
      pagination:{
        totalElements: 0,
        totalPages:0
      },
      rows:{
        odd:"border-t border-grey hover:bg-header_grey",
        even:"border-t border-grey bg-header_grey"
      },
      search:"",
      status:null,
      type:null
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    ...mapActions(["fetchUsers", "generateAccessKeys", "fetchAccessKeys", "fetchAccessLevels"]),
    fetch() {
      this.loading = true
      let filters = {"accountType": "MDA"}

      if (this.status) {
        filters.accountStatus = this.status
      }

      this.fetchUsers(this.objectToQueryParams(filters))
    },
    next() {
      if (this.currentPage < this.pagination.totalPages) {
        this.currentPage++
        this.fetch()
      }
    },
    searchAccounts() {

    },
    save() {

    },
    sendActivationLink(account){
      console.log(account)
    },
    openKeyGenerationForm(){
      this.$refs.keyGenerate.show()
    },
    previous(){
      if(this.currentPage > 1){
        this.currentPage--
        this.fetch()
      }else{
        this.warningToast("Observation", "No more data to show")
      }
    },
  },
  computed:{
    ...mapGetters(["getOfficerAccounts", "getGeneratedAccessKey", "getAccessKeyError", "getAccessKeys", "getAccessLevels"])
  },
  watch:{
    getOfficerAccounts(data){
      this.loading = false
      this.accounts = data
    },
    getGeneratedAccessKey(data){
      this.loading = false
      alert(JSON.stringify(data))
    },
    getAccessKeyError(data){
      this.loading = false
      this.errorToast("Error", data.message)
    }
  }
};
</script>

<style scoped>
.paginate-details {
  @apply px-3 py-2 bg-white my-2 text-sm text-gray;
}

.paginate-btn {
  @apply px-3 py-2 bg-white border border-white my-2 text-sm text-blue w-full;
}

.paginate-btn:hover {
  @apply border border-blue text-blue rounded-sm;
}


.inert-btn {
  @apply px-3 py-2 bg-white my-2 text-sm text-grey w-full;
}
</style>