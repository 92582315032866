<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "AccountSummary",

    data() {
        return {
            accountBalance: 0,
            logs: [],
            showIndex: null,
            account: {}
        }
    },
    mounted() {
        this.account = JSON.parse(sessionStorage.getItem("account"))
        this.fetchAccountSummary(this.account.id)
        this.fetchApiLogs(this.account.id)
    },

    computed: {
        ...mapGetters(["getAccountSummary", "getApiLogs"])
    },

    methods: {
        ...mapActions(["fetchAccountSummary", "fetchApiLogs"]),
        showApiDetails(idx) {
            this.showIndex = idx
        },
        hideApiDetails() {
            this.showIndex = null
        },
    },
    watch: {
        getAccountSummary(data) {
            this.accountBalance = data.data.balance
        },
        getApiLogs(data) {
            this.logs = data.data.content
        }
    },
}
</script>

<template>
    <div>
        <b-row>
            <b-col cols="11" class="mx-auto">
                <h3 class="text-lg"> {{ account.name }} Account Summary and Account Credit Usage"</h3>

                <b-row>
                    <b-col class="bg-header_grey rounded px-2 py-2">
                        <p>
                            <label class="text-lg float-end">
                                <label class="text-sm">
                                    Account Balance : <span
                                        class="text-blue text-lg">UGX {{ accountBalance.toLocaleString() }}</span>
                                </label>
                            </label>


                        </p>
                    </b-col>
                </b-row>
                <b-row>
                    <label class="text-lg">Recent api calls </label>
                    <b-col class="bg-header_grey rounded px-2 py-2">
                        <table class="border-1 rounded" width="100%">
                            <thead class="bg-gray_title">
                            <tr>
                                <td>#</td>
                                <td>
                                    <label>Entity</label>
                                </td>
                                <td>
                                    <label>Endpoint</label>
                                </td>
                                <td>
                                    Response
                                </td>
                                <td>
                                    Feedback
                                </td>

                            </tr>
                            </thead>

                            <tbody>

                            <tr class="border-1 border-gray" v-for="(log, idx) in logs" :key="idx">
                                <td>
                                    <label>{{ idx + 1 }}</label>
                                </td>
                                <td>
                                    <label class="text-blue text-lg">{{ JSON.parse(log.apiRequest).brn }}</label>
                                </td>
                                <td>
                                    <label>{{ log.endPoint }}</label>
                                </td>
                                <td>

                                    <span v-if="showIndex===idx"><pre>{{ JSON.parse(log.apiResponse).data }}</pre>
                                    <b-badge style="cursor: pointer" @click="hideApiDetails()" class="bg-blue mx-2"
                                             v-if="log.responseGiven"> <i class="fa fa-eye bg-blue"></i> Hide</b-badge>
                                        </span>
                                    <b-badge style="cursor: pointer" v-else @click="showApiDetails(idx)"
                                             class="bg-green mx-2">
                                        <i class="fa fa-eye bg-green"></i> Show
                                    </b-badge>
                                </td>
                                <td>
                                    <b-badge class="bg-green mx-2" v-if="log.responseGiven">Served</b-badge>
                                    <b-badge class="bg-red mx-2" v-else>Failed</b-badge>

                                </td>
                            </tr>
                            </tbody>

                        </table>

                    </b-col>
                </b-row>

            </b-col>
        </b-row>


    </div>
</template>

<style scoped>

</style>