<template>
  <b-row>
    <b-col>
      <b-row>
        <b-modal ref="details" size="xl" title="Details of Process" hide-footer>
          <b-row>
            <b-col>

            </b-col>
          </b-row>
        </b-modal>
      </b-row>
      <b-row>
        <b-col cols="2">
          <b-form-group>
            <b-form-select
                v-model="service"
                class="form-select noFocus text-gray mx-2 my-2 text-sm">
              <b-select-option :value="null">--Filter by Service--</b-select-option>
              <b-select-option value="BUSINESS_NAME">Business Name Registration</b-select-option>
              <b-select-option value="COMPANY">Company Incorporation</b-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-input
              class="text-gray text-sm noFocus mx-2 my-2"
              style="padding:6px 5px;"
              v-model="brn"
              type="text"
              placeholder="Enter BRN"
              required>
          </b-form-input>
        </b-col>
        <b-col cols="2" >
          <b-form-input class="form-text text-sm mt-2" type="date" v-model="startDate"></b-form-input>
        </b-col>
        <b-col cols="2">
          <b-form-input class="form-text text-sm mt-2" type="date" v-model="endDate"></b-form-input>
        </b-col>
        <b-col cols="2">
          <button class="w-full bg-blue px-2 py-1 rounded-sm text-white" @click="push">
            Push <b-spinner v-if="pushing" small></b-spinner>
          </button>
        </b-col>
      </b-row>
      <div class="mx-auto">
        <b-progress value="100" v-if="loading" variant="#007ac5" height="0.2rem" animated></b-progress>
      </div>
      <b-row class="">
        <b-col class="">
          <table class="min-w-full leading-normal">
            <thead>
            <tr class="border-b border-t border-grey">
              <th class="t-header">#</th>
              <th class="t-header">BRN</th>
              <th class="t-header">NAME</th>
              <th class="t-header">ENTITY TYPE</th>
              <th class="t-header">SUB-ENTITY TYPE</th>
              <th class="t-header text-center">STATUS</th>
            </tr>
            </thead>
            <tbody>
            <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(entity, idx) in responsePayload" :key="idx">
              <td class="t-body">{{idx + 1}}</td>
              <td class="t-body font-bold">{{entity.brn}}</td>
              <td class="t-body">{{entity.name}}</td>
              <td class="t-body">{{ cleanEntityType(entity.type) }}</td>
              <td class="t-body">{{ cleanEntityType(entity.subType) }}</td>
              <td class="t-body text-center">
                <p class="text-xs px-2 py-1 text-capitalize text-center" style="background: #f1f1f1">
                    <span :class="(entity.status === 'SENT') ? 'text-green' :
                                  (entity.status === 'EXPIRED') ? 'text-red' :
                                  (entity.status === 'PENDING') ? 'text-gray' :
                                  (entity.status === 'PAUSED')? 'text-orange': ''">
                       {{ entity.status.toLowerCase() }}
                    </span>
                </p>
              </td>
            </tr>
            <tr>
              <td v-if="responsePayload.length === 0" colspan="12" class="bg-inertgrey text-center py-1">
                <span class="text-sm">No results found</span>
              </td>
            </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "ReservationService",
  data(){
    return{
      loading:false,
      pushing:false,
      service:null,
      responsePayload: [],
      rows: {
        odd: "border-t border-grey hover:bg-header_grey",
        even: "border-t border-grey bg-header_grey"
      },
      brn: null,
      startDate:null,
      endDate:null,
    }
  },
  methods:{
    ...mapActions([
      "incorporationPushToNameCheck",
      "registrationPushToNameCheck"
    ]),
    cleanEntityType(name){
      let n = name.replaceAll("_", " ").replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    push(){
      if(this.service){
        this.pushing = true
        this.loading = true

        if(this.service === "BUSINESS_NAME"){
          let data = {
            brn: this.brn,
            startDate: this.startDate !== null ? moment(this.startDate).startOf('day').toISOString() : null,
            endDate: this.endDate !== null ? moment(this.endDate).startOf('day').toISOString() : null,
          }
          this.registrationPushToNameCheck(data)
        }

        if(this.service === "COMPANY"){
          let data = {
            brn: this.brn,
            startDate: this.startDate !== null ? moment(this.startDate).startOf('day').format("YYYY-MM-DD HH:mm:ss") : null,
            endDate: this.endDate !== null ? moment(this.endDate).startOf('day').format("YYYY-MM-DD HH:mm:ss") : null,
          }
          this.incorporationPushToNameCheck(data)
        }
      } else {
        this.errorToast("Error", "Select a service first.")
      }
    }
  },computed:{
    ...mapGetters([
      "getIncorporationNameCheck",
      "getIncorporationNameCheckError",
      "getRegistrationNameCheck",
      "getRegistrationNameCheckError",
    ]),
  },
  watch: {
    getIncorporationNameCheck(response) {
      this.loading = false
      this.pushing = false
      this.responsePayload = response.data
      console.log(Object.keys(this.responsePayload[0]))
    },
    getRegistrationNameCheck(response) {
      this.loading = false
      this.pushing = false
      this.responsePayload = response.data
      console.log(Object.keys(this.responsePayload[0]))
    },
    getIncorporationNameCheckError(response){
      this.loading = false
      this.pushing = false
      this.errorToast("Error", response.data.message)
    },
    getRegistrationNameCheckError(response){
      this.loading = false
      this.pushing = false
      this.errorToast("Error", response.data.message)
    }
  },
};
</script>

<style scoped>

</style>