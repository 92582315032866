<template>
    <div>
        <b-row class="my-1">
            <b-col>
                Reason Name
                <b-form-input v-model="form.reason"
                              placeholder="Winding up reason" required>
                </b-form-input>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="8"></b-col>
            <b-col cols="4" class="float-right">
                <button @click="addBody" class="w-full bg-blue text-white px-2 py-1 rounded-sm">
                    Save
                    <b-spinner v-if="spinner" small></b-spinner>
                </button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "NewReason",
    data() {
        return {
            form: {
                reason: null,
            },
            spinner: false,
        }
    },
    methods: {
        ...mapActions([
            "createReason", "fetchReasons"
        ]),
        addBody() {
            this.spinner = true
            this.createReason(this.form)
        }
    },
    computed: {
        ...mapGetters([
            "getReasons",
            "getReason",
            "getInsolvencyError"
        ])
    },
    watch: {
        getReason() {
            this.spinner = false
            this.fetchReasons()
            this.successToast("Success", "Request processed successfully");
        },
        getInsolvencyError(error) {
            this.spinner = false
            this.errorToast("Error", "An error occurred while creating new reason " + error.errors[0].message);
        }
    }

}
</script>

<style scoped>

</style>