<template>
    <b-row>
        <b-col cols="12">
            <b-row>
                <b-col cols="12" class="mx-3 mt-3">
                    <b-row>
                        <span class="title">Documents Costs Configurations</span>
                    </b-row>
                </b-col>
            </b-row>
            <b-row>
                <b-modal ref="newService" hide-footer title="New Document Cost ">
                    <b-col cols="11" class="mx-auto">
                        <b-form-input class="my-2" type="text" v-model="form.documentNumber"
                                      placeholder="Document number"></b-form-input>

                        <b-form-input class="my-2" type="text" v-model="form.documentName"
                                      placeholder="Document name"></b-form-input>

                        <b-form-input class="my-2" type="number" v-model="form.amount"
                                      placeholder="Document Cost"></b-form-input>


                        <b-row>
                            <b-col cols="8"></b-col>
                            <b-col cols="4">
                                <button class="w-full bg-blue text-white px-2 py-1 rounded-sm" @click="save">
                                    <i class="fa fa-save mx-1"></i> Save
                                    <b-spinner v-if="spinner" small></b-spinner>
                                </button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-modal>
                <b-modal ref="editService" hide-footer title="Edit Service">
                                    <b-col cols="11" class="mx-auto">
                                        <b-form-input class="my-2" type="text" v-model="editForm.documentName"
                                                      placeholder="Document Name"></b-form-input>

                                        <b-form-input class="my-2" type="text" v-model="editForm.documentNumber"
                                                      placeholder="Document Number"></b-form-input>

                                        <b-form-input class="my-2" type="number" v-model="editForm.amount"
                                                      placeholder="Service Cost"></b-form-input>


                                        <b-row>
                                            <b-col cols="6"></b-col>
                                            <b-col cols="6">
                                                <button class="w-full bg-blue text-white px-2 py-1 rounded-sm"
                                                        @click="updateServiceCost">
                                                    <i class="fa fa-pencil mx-1"></i> Save Changes
                                                    <b-spinner v-if="spinner" small></b-spinner>
                                                </button>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                </b-modal>
            </b-row>
            <b-row class="py-3">
                <b-col cols="2">
                    <b-form-input
                            class="text-gray text-sm noFocus ml-1"
                            style="margin-top: 1px; padding:6px 5px;"
                            v-model="search"
                            type="text"
                            placeholder="Search by Name"
                            required
                    >
                    </b-form-input>
                </b-col>
                <b-col cols="8"></b-col>
                <b-col cols="2">
                    <button class="w-full bg-blue text-white py-1 rounded-sm" @click="openNewServiceForm">
                        <i class="fa fa-plus mx-1"></i> Add Service
                    </button>
                </b-col>
            </b-row>
            <div class="mx-auto">
                <b-progress value="100" v-if="loading" variant="#007ac5" height="0.2rem" animated></b-progress>
            </div>
            <b-row>
                <b-col>
                    <table class="min-w-full leading-normal">
                        <thead>
                        <tr class="border-b border-t border-grey">
                            <th class="t-header">#</th>
                            <th class="t-header">Document Number</th>
                            <th class="t-header">Document Name</th>
                            <th class="t-header">Fee Category</th>
                            <th class="t-header">Cost</th>
                            <th class="t-header text-center">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(service,idx) in serviceCosts"
                            :key="service.id">
                            <td class="t-body ">{{ idx + 1 }}</td>
                            <td class="t-body text-capitalize font-bold">
                                {{ service.documentNumber }}
                            </td>
                            <td class="t-body  text-capitalize">{{ service.documentName }}</td>
                            <td class="t-body  text-capitalize">CERTIFICATION</td>
                            <td class="t-body  text-capitalize">{{ service.amount }}</td>
                            <td class="t-body  text-capitalize text-center">
                                <button class="bg-blue mx-2 px-2 py-1 rounded-sm text-white" @click="view(service)">
                                    Edit
                                </button>
                                <button class="bg-red px-2 py-1 rounded-sm text-white"
                                        @click="deleteService(service.id)">
                                    Delete
                                </button>

                            </td>
                        </tr>
                        <tr>
                            <td v-if="serviceCosts.length === 0" colspan="12" class="bg-inertgrey text-center py-1">
                                <span class="text-sm">No results found</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "DocumentCost",
    data() {
        return {
            registerOccupation: false,
            loading: false,
            new: false,
            serviceCosts: [],
            search: null,
            spinner: false,
            rows: {
                odd: "border-t border-grey hover:bg-header_grey",
                even: "border-t border-grey bg-header_grey"
            },
            form: {
                documentNumber: null,
                documentName: null,
                amount: null
            },
            editForm: {
                documentNumber: null,
                documentName: null,
                amount: null
            }

        };
    },
    mounted() {
        this.loading = true;
        this.fetchDocumentCosts();

    },
    methods: {
        ...mapActions(["fetchDocumentCosts", "createDocumentCost", "editDocumentCost"]),
        save() {
            this.spinner = true;
            this.createDocumentCost(this.form);
        },
        edit() {
            this.spinner = true;
            this.editServiceCost(this.form);
        },
        view(data) {
            this.$refs.editService.show()
            this.editForm = data;
        },
        deleteService(serviceCostId) {
            this.loading = true
            this.deleteServiceCost(serviceCostId);
        },
        updateServiceCost() {
            this.loading = true
            /* let payload = {
                 "serviceChargeId": this.editForm.id,
                 "serviceName": this.editForm.serviceName,
                 "serviceCode": this.editForm.serviceCode,
                 "serviceCost": this.editForm.serviceCost,
                 "taxHeadId": this.editForm.taxHeadId
             }*/
            this.editDocumentCost(this.editForm);
        },
        openNewServiceForm() {
            this.$refs.newService.show();
        },
        closeServiceForm() {
            this.$refs.newService.hide();
        },
        closeEditServiceForm() {
            this.$refs.editService.hide();
        }
    },
    computed: {
        ...mapGetters(["getDocumentCost", "getDocumentCosts", "getEditServiceCost",
            "getError",])
    },
    watch: {
        getDocumentCosts(serviceCosts) {

            this.loading = false;
            this.spinner = false;
            this.serviceCosts = serviceCosts.data;
            this.form = {
                documentNumber: null,
                documentName: null,
                amount: null
            };
        },
        getDocumentCost() {

            this.successToast("Success", "Document cost created successfully");
            this.loading = false;
            this.spinner = false;
            this.form = {
                documentNumber: null,
                documentName: null,
                amount: null
            };
            this.fetchDocumentCosts();
            this.closeServiceForm();
        },
        getEditServiceCost() {
            this.loading = false;
            this.spinner = false;
            this.editForm = {
                documentNumber: null,
                documentName: null,
                amount: null
            };
            this.fetchDocumentCosts()
            this.closeEditServiceForm()
            this.successToast("Updated", "Service  cost updated");
        },
        getError() {
            this.loading = false;
            this.spinner = false;
            this.form = {
                documentNumber: null,
                documentName: null,
                amount: null
            };
            this.errorToast("Error", "Document cost  creation failed");
        },
    },


};
</script>

<style scoped>

</style>