<template>
  <div >
    <b-row>
      <div :style="'width: '+dimensions.side+'%'" class="side-menu min-h-screen">
        <b-row>
          <SideMenu :full="isShowing"/>
        </b-row>
      </div>
      <div :style="'width: '+dimensions.content+'%'" class="right-content">
        <b-row>
          <b-col cols="12" class="topBar">
            <HomeTopBar v-on:toggle="toggle"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <router-view></router-view>
          </b-col>
        </b-row>
      </div>
    </b-row>
  </div>
</template>

<script>
import SideMenu from "@/views/home/SideMenu";
import HomeTopBar from "@/views/home/HomeTopBar";
export default {
  name: "Home",
  components: {HomeTopBar, SideMenu},
  data(){
    return{
      dimensions:{
        side:17,
        content:83
      },
      isShowing:true
    }
  },
  methods:{
    toggle(state){
      if(state){
        this.isShowing = true
        this.dimensions.side = 17
        this.dimensions.content = 83
      }else{
        this.isShowing = false
        this.dimensions.side = 4
        this.dimensions.content = 96
      }
    },
  },
}
</script>

<style scoped>

.topBar {
  position: fixed;
  top: 0;
  left: 0;
  color: white;
  z-index:40
}

.side-menu{
  position: fixed;
  /*height: auto;*/
  height:calc(100% - 85px);
  left: 0;
  overflow-y: auto;
  top: 0;
  background: #f0f0f0;
  padding-top: 20px;
}

.right-content{
  overflow-y: scroll;
  position: fixed;
  background: #dedede;
  right: 0;
  top: 50px;
  height: calc(100% - 60px);
}


</style>