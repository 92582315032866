<template>
  <b-col>
    <b-row>
      <b-col cols="12" class="mx-auto mt-4">
        <table class="w-full leading-normal text-gray"
               style="border: solid 1px #dedede;">
          <thead class="bg-gray-pale" >
          <tr>
            <th colspan="12" class="py-2">
              <span class="px-2" v-if="type !== 'private-company-limited-by-guarantee-without-shares'">Members</span>
              <span class="px-2" v-else>Subscribers</span>
            </th>
          </tr>
          </thead>
          <tbody>
           <LegalPersonsDetails class="p-1" :persons="getCompanySubscribers.data.content"/>
          </tbody>
        </table>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import LegalPersonsDetails from "@/views/home/services/registry/business/files/tabs/company_data/LegalPersonsDetails";
import {mapGetters} from "vuex";
export default {
  name: "CompanySubscribers",
  components: {LegalPersonsDetails},
  data(){
    return{
      type:null
    }
  },
  mounted(){
    this.type = this.$userProfile.businessSubType
  },
  computed:{
    ...mapGetters(["getCompanySubscribers"])
  }
}
</script>

<style scoped>

</style>