<template>
  <b-col>
    <b-row>
      <b-col cols="12" class="mx-auto mt-4">
        <table class="w-full leading-normal text-gray"
               style="border: solid 1px #dedede;">
          <thead class="bg-gray-pale" >
          <tr>
            <th colspan="12" class="py-2">
              <span class="px-2">Secretary</span>
            </th>
          </tr>
          </thead>
          <tbody>
             <LegalPersonsDetails v-if="getCompanySecretary != null" class="p-1" :persons="[getCompanySecretary.data]"/>
          </tbody>
        </table>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import {mapGetters} from "vuex";
import LegalPersonsDetails from "@/views/home/services/registry/business/files/tabs/company_data/LegalPersonsDetails";

export default {
  name: "CompanySecretary",
  components: {LegalPersonsDetails},
  computed:{
    ...mapGetters(["getCompanyDirectors", "getCompanySecretary"])
  }
}
</script>

<style scoped>

</style>