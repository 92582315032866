import {api} from "@/app/services/api.service";

const state = {
    departments: null,
    department: null,
    error: null,
}

const getters = {
    getDepartments: state => state.departments,
    getDepartment: state => state.department,
    getError: state => state.error,
}

const actions = {
    async fetchDepartments({commit}) {
      await  api().get('auth/api/departments')
            .then(response => {
                commit('setDepartments', response)
            })
            .catch(error => {
                commit('setError', error)
            })
    },
    async createDepartment({commit}, data) {

       await api().post('auth/api/departments/', data)
            .then(response => {
                commit('setDepartment', response)
            })
            .catch(error => {
                commit('setError', error)
            })
    },

}

const mutations = {
    setDepartments: (state, payload) => {
        state.departments = payload.data;
    },
    setDepartment: (state, payload) => {
        state.department = payload.data;
    },
    setError: (state, error) => {
        state.error = error.response.data;
    },

}


export default {
    state,
    getters,
    actions,
    mutations

}
