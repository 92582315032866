<template>
  <b-row class="shadow-sm bg-header_grey">
    <b-col cols="4" class="my-3">
      <div class="flex">
        <i @click="toggleMenu" :class="(menu_switch) ? 'fa fa-bars menu-active' : 'fa fa-bars menu'"></i>
          <router-link class="w-11/12 ml-3" :to="{name:'user_profile'}">
          <img class="logo" :src="require('../../../src/assets/banner.png')" />
        </router-link>
      </div>
    </b-col>
    <b-col cols="6"></b-col>
    <b-col cols="2">
      <b-row >
        <b-dropdown class="noFocus" size="lg" variant="link" toggle-class="text-decoration-none" no-caret id="dropdown-1">
          <template #button-content>
            <div class="flex">
              <b-avatar variant="info" src="https://picsum.photos/200/300?random=1"></b-avatar>
              <b-row class="ml-1 mt-1">
                <b-col cols="12">
                  <div style="text-align: left;">
                    <p class="text-gray text-xs">
                      <b class="break-words">{{userProfile.givenName}} {{userProfile.surName}}</b><br/>
                      <small class="break-words">{{userProfile.email}}</small>
                    </p>
                  </div>
                  <b-row>
                    <div class="text-gray text-xs"></div>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </template>

          <b-dropdown-item @click="profile">
            <div class="account-menu-spacing">Manage Account</div>
          </b-dropdown-item>
          <b-dropdown-item @click="settings">
            <div class="account-menu-spacing">Settings</div>
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item @click="logout">
            <div class="account-menu-spacing">Logout</div>
          </b-dropdown-item>
        </b-dropdown>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import jwtService from "@/app/services/jwt.service";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "HomeTopBar",
  data(){
    return{
      menu_switch:true,
      userProfile:{},
    }
  },
  mounted() {
    this.userProfile = jwtService.getUser()
  },
  methods:{
    ...mapActions(["doLogoutAccount"]),
    toggleMenu(){
      this.menu_switch = !this.menu_switch
      this.$emit('toggle', this.menu_switch)
    },
    logout(){
      this.doLogoutAccount()
    },
    profile(){
      let r = this.$router.resolve({
        name: "profile",
      });
      window.location.assign(r.href);
    },
    settings(){
      alert("Coming soon")
    }
  },
  computed:{
    ...mapGetters(["getUserLogout"])
  },
  watch:{
    getUserLogout(){
      sessionStorage.clear()
      let r = this.$router.resolve({
        name: "login",
      });
      window.location.assign(r.href);
    },
    getUserLogoutSuccess(){
      sessionStorage.clear()
      let r = this.$router.resolve({
        name: "login",
      });
      window.location.assign(r.href);
    },
  }
}
</script>

<style scoped>

.menu{
  @apply text-gray mt-1;
  font-size: 20px;
  cursor: pointer;
}

.menu:hover{
  @apply text-blue;
  font-size: 19px;
}

.menu-active{
  @apply text-blue mt-1;
  font-size: 20px;
  cursor: pointer;
}

.logo{
  width: 85px;
  margin-top: 2px;
}

.account-menu-spacing{
  width: 200px
}
</style>