<template>
    <b-row>
        <b-col class="text-center">
            <img class="mx-auto border-2 border-gray rounded px-2 py-1" v-if="signatureFile"
                 :src="baseUrl+'/auth/api/users/downloadFile/attachment/'+signatureFile"/>
            <p v-else class="bg-red mx-auto rounded-sm p-3 text-white">
                User has no signature yet
            </p>
        </b-col>
    </b-row>
</template>

<script>
import server from "@/app/env/server.json"

export default {
    name: "StaffSignature",
    props: {
        userId: String,
        signatureFile: String
    },
    data() {
        return {
            baseUrl: null,
            errorMessage: null,
        }
    },
    mounted() {
        this.baseUrl = server.IP
    },
}
</script>

<style scoped>

</style>