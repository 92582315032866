<template>
    <b-col cols="11" class="mx-auto">
        <b-row>
            <b-col>
                <!-- table to show details of user -->

                <table>
                    <thead>
                    <tr>
                        <th colspan="2">User Details</th>
                    </tr>
                    </thead>
                    <tr>
                        <td>Name</td>
                        <td>{{ user.name }}</td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td>{{ user.email }}</td>
                    </tr>
                    <tr>
                        <td>Phone</td>
                        <td>{{ user.phoneNumber }}</td>
                    </tr>
                </table>

            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12">
                <div class="flex h-full" style="cursor: pointer;">
                    <div :class="[(currentRoute === 'sandbox') ? 'menu-item-active' : 'menu-item']"
                         @click="select('sandbox')">
                        <div class="flex mt-6">
                            <div style="font-size: 14px" class="mt-1">Sandbox Environment</div>
                        </div>
                    </div>
                    <div :class="[(currentRoute === 'production') ? 'menu-item-active' : 'menu-item']"
                         @click="select('production')">
                        <div class="flex mt-6">
                            <div style="font-size: 14px" class="mt-1">Production Environment</div>
                        </div>
                    </div>

                </div>
            </b-col>
        </b-row>


        <b-row>
            <b-col v-if="currentRoute==='sandbox'">
                <!--                <b-row>
                                    <b-col>
                                        <label>Select API Mode</label>
                                        <table>
                                            <thead>
                                            <tr>
                                                <th colspan="2">User Details</th>
                                            </tr>
                                            </thead>
                                            <tr>
                                                <td>API KEY</td>
                                                <td>*******</td>
                                            </tr>
                                            <tr>
                                                <td>Secret Key</td>
                                                <td>*******</td>
                                            </tr>
                                        </table>

                                    </b-col>
                                </b-row>-->

                <b-row>
                    <b-col cols="8">


                        <b-col v-for="(endpoint, idx) in endpoints" :key="idx">
                            <div class="text-lowercase hover:text-blue cursor-pointer flex">
                                <b-form-checkbox
                                        v-model="accessLevelsForm.endPoints"
                                        :id="idx+'l'"
                                        color="#24a4f6"
                                        :name="cleanUnderscoreString(endpoint.endPoint)"
                                        :value="endpoint.id">
                                            <span class="ml-2 cursor-pointer">{{
                                                    cleanUnderscoreString(endpoint.endPoint)
                                                }}</span>
                                </b-form-checkbox>

                            </div>
                        </b-col>


                    </b-col>
                    <b-col cols="4">

                        <label> ACTIVE ENDPOINTS </label>
                        <div v-for="(endpoint, idx) in sandboxEndpoints" :key="idx"
                             class="text-lowercase hover:text-blue cursor-pointer flex">
                            <div>
                                <i class="fa fa-check text-green"></i>
                                <span class="ml-2 cursor-pointer">{{
                                        cleanUnderscoreString(endpoint.serviceName)
                                    }}</span>
                            </div>
                        </div>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="10"></b-col>
                    <b-col cols="2">
                        <button class="w-full bg-blue text-white px-2 py-1 rounded-sm"
                                @click="saveAccessAreasGranted">
                            <i class="fa fa-save mx-1"></i> Save
                            <b-spinner v-if="loading" small/>
                        </button>
                    </b-col>
                </b-row>

            </b-col>


            <b-col v-if="currentRoute==='production'">


                <b-row>
                    <b-col cols="8">
                        <label>Select Endpoints to grant this user </label>

                        <b-col cols="12" v-for="(endpoint, idx) in endpoints" :key="idx">
                            <div class="text-lowercase hover:text-blue cursor-pointer flex">
                                <b-form-checkbox
                                        v-model="accessLevelsForm.endPoints"
                                        :id="idx+'l'"
                                        color="#24a4f6"
                                        :name="cleanUnderscoreString(endpoint.endPoint)"
                                        :value="endpoint.id">
                                            <span class="ml-2 cursor-pointer">{{
                                                    cleanUnderscoreString(endpoint.endPoint)
                                                }}</span>
                                </b-form-checkbox>

                            </div>
                        </b-col>

                    </b-col>

                    <b-col cols="4">

                        <label> ACTIVE ENDPOINTS </label>
                        <div v-for="(endpoint, idx) in productionEndpoints" :key="idx"
                             class="text-lowercase hover:text-blue cursor-pointer flex">
                            <div>
                                <i class="fa fa-check text-green"></i>
                                <span class="ml-2 cursor-pointer">{{
                                        cleanUnderscoreString(endpoint.serviceName)
                                    }}</span>
                            </div>
                        </div>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="8"></b-col>
                    <b-col cols="2">
                        <button v-if="readyToGoLive" class="w-full bg-red-light text-white px-2 py-1 rounded-sm text-xs"
                                @click="goLive">
                            <i class="fa fa-arrow-up mx-1"></i> Go Live !
                            <b-spinner v-if="goLiveLoader" small/>
                        </button>
                    </b-col>
                    <b-col cols="2">
                        <button class="w-full bg-blue text-white px-2 py-1 rounded-sm"
                                @click="saveAccessAreasGranted">
                            <i class="fa fa-save mx-1"></i> Save
                            <b-spinner v-if="loading" small/>
                        </button>
                    </b-col>
                </b-row>

            </b-col>

        </b-row>
    </b-col>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "ApiMode",
    props: {
        user: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            currentRoute: "sandbox",
            loading: false,
            goLiveLoader: false,
            endpoints: [],
            accessLevelsForm: {
                userId: null,
                endPoints: [],
                apiMode: "SANDBOX"
            },
            sandbox: {
                privateKey: null,
                secretKey: null
            },
            production: {
                privateKey: null,
                secretKey: null
            },
            readyToGoLive: false,
            sandboxEndpoints: [],
            productionEndpoints: []
        }
    },

    mounted() {
        this.formData = new FormData();
        this.fetchAllEndpoints();

    },
    methods: {
        ...mapActions(["fetchUserProductionEndpoints", "fetchUserSandboxEndpoints",
            "generateAccessKeys", "fetchAllEndpoints", "doAddAccessAreas", "doRevokeAccess", "doGoLive"]),
        select(route) {
            this.currentRoute = route
            if (route === "sandbox") {
                this.fetchUserSandboxEndpoints(this.user.id)
                this.accessLevelsForm.apiMode = "SANDBOX"
            } else {
                this.fetchUserProductionEndpoints(this.user.id)
                this.accessLevelsForm.apiMode = "LIVE"
            }
        },
        saveAccessAreasGranted() {
            this.accessLevelsForm.userId = this.user.id;
            this.doAddAccessAreas(this.accessLevelsForm)

        },
        goLive() {
            this.goLiveLoader = true;
            this.doGoLive({userId: this.user.id, apiMode: "LIVE"})
        }
    },
    computed: {
        ...mapGetters(["getEndpoints", "getGoLive", "getProductionEndpoints", "getSandboxEndpoints"]),
    },
    watch: {
        getEndpoints(data) {
            this.endpoints = data.data;
            this.select("sandbox")
        },
        getGoLive() {
            this.goLiveLoader = false;
            this.successToast("Success", "User has been granted access to production environment")
        },
        getProductionEndpoints(data) {

            if (data.data.apiKey === null || data.data.secretKey === "") {
                this.readyToGoLive = true;
            }
            this.production.privateKey = data.data.apiKey;
            this.production.secretKey = data.data.secretKey;
            this.productionEndpoints = data.data.endPoints;
        },
        getSandboxEndpoints(data) {

            this.sandbox.privateKey = data.data.apiKey;
            this.sandbox.secretKey = data.data.secretKey;
            this.sandboxEndpoints = data.data.endPoints;
            // this.endpoints = data.data;
        }
    }
}
</script>
