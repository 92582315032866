<template>
  <div class="bg-white rounded-sm text-gray mx-2 my-3">
    <b-row>
      <b-col>
        <b-row>
          <b-col cols="12">
            <div class="flex h-full" style="cursor: pointer;">
              <div :class="[(currentRoute === 'roles') ? 'menu-item-active' : 'menu-item']" @click="select('roles')">
                <div class="flex mt-6">
                  <div style="font-size: 14px" class="mt-1">Roles</div>
                </div>
              </div>
              <div :class="[(currentRoute === 'permissions') ? 'menu-item-active' : 'menu-item']" @click="select('permissions')">
                <div class="flex mt-6">
                  <div style="font-size: 14px" class="mt-1">Permission Groups</div>
                </div>
              </div>
              <div :class="[(currentRoute === 'accounts') ? 'menu-item-active' : 'menu-item']" @click="select('accounts')">
                <div class="flex mt-6">
                  <div style="font-size: 14px" class="mt-1">User-Role Management</div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <AccountPermissionGroups v-if="currentRoute === 'permissions'"/>
            <AccountRoles v-if="currentRoute === 'roles'"/>
            <PermissionAccounts v-if="currentRoute === 'accounts'"/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import AccountPermissionGroups from "@/views/home/services/accounts/permissions/AccountPermissionGroups";
import AccountRoles from "@/views/home/services/accounts/permissions/AccountRoles";
import PermissionAccounts from "@/views/home/services/accounts/permissions/PermissionAccounts";
export default {
  name: "PermissionsManagement",
  components: { PermissionAccounts, AccountRoles, AccountPermissionGroups },
  data(){
    return{
      currentRoute: "roles",
    }
  },
  mounted() {

  },
  methods:{
    select(route){
      this.currentRoute = route
    },
  }



};
</script>

<style scoped>

</style>