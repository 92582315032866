import { api } from "@/app/services/api.service";

const state = {
  transactions: null,
  transactions_error: null,
  retryTransactionSuccess: null,
  retryTransactionError: null,
  prnReleaseSuccess:null,
  prnReleaseError:null,
};

const getters = {
  getTransactions: (state) => state.transactions,
  getTransactionsListError: (state) => state.transactions_error,
  getTransactionSuccess: (state) => state.retryTransactionSuccess,
  getTransactionError: (state) => state.retryTransactionError,
  getPRNReleaseSuccess: (state) => state.prnReleaseSuccess,
  getPRNReleaseError: (state) => state.prnReleaseError
};

const actions = {
  async fetchTransactions({ commit }, data) {
    let path = "/billing/transaction/list?page=" + data.page + "&perPage=" + data.pageSize;
    if (data.trackingNumber) {
      path += "&trackingNumber=" + data.trackingNumber;
    }
    if (data.prn) {
      path += "&prn=" + data.prn;
    }
    if (data.status) {
      path += "&status=" + data.status;
    }
    if (data.prnStatus) {
      path += "&prnStatus=" + data.prnStatus;
    }
    if (data.paymentMethod) {
      path += "&paymentMethod=" + data.paymentMethod;
    }
    await api()
      .get(path)
      .then((response) => {
        commit("setTransactionList", response.data);
      })
      .catch((error) => {
        commit("setTransactionError", error);
      });
  },
  async retryTransactionVerification({ commit }, data) {
    await api()
      .post("/billing/transaction/query", data)
      .then((response) => {
        commit("setTransactionQuerySuccess", response);
      })
      .catch((error) => {
        commit("setTransactionQueryError", error);
      });
  },
  async releasePRNTransaction({ commit }, data) {
    await api()
      .post("/billing/transaction/prn-release", data)
      .then((response) => {
        commit("setPRNReleaseSuccess", response);
      })
      .catch((error) => {
        commit("setPRNReleaseError", error);
      });
  },
};

const mutations = {
  setTransactionList: (state, response) => {
    state.transactions = response.data;
  },
  setTransactionError: (state, error) => {
    state.transactions_error = error;
  },
  setTransactionQuerySuccess: (state, response) => {
    state.retryTransactionSuccess = response.data;
  },
  setTransactionQueryError: (state, error) => {
    state.retryTransactionError = error;
  },
  setPRNReleaseSuccess: (state, response) => {
    state.prnReleaseSuccess = response.data;
  },
  setPRNReleaseError: (state, error) => {
    state.prnReleaseError = error;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};