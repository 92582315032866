import {api} from "@/app/services/api.service"


const state = {
    rolesList: null,
    permissionList: null,
    rolesAndPermissionList: [],
    rolesAndPermissionEdit: null,
    rolesAndPermissionUpdate: null,
    revokeRole: null,
    rolesError: null,
    permissionGroups: null,
    permission: null,
    group: null,
    success: null,
    successRole: null,
    error: null,
    roleDetails: null
};

const getters = {
    getRolesList: state => state.rolesList,
    getRoleDetails: state => state.roleDetails,
    getNewRole: state => state.successRole,
    getNewRoleError: state => state.error,
    getPermissionList: state => state.permissionList,
    getPermissionGroupsList: state => state.permissionGroups,
    getRolesAndPermissionEdit: state => state.rolesAndPermissionEdit,
    getRolesAndPermissionUpdate: state => state.rolesAndPermissionUpdate,
    getRolesError: state => state.rolesError,
    getRoleRemovalSuccess: state => state.revokeRole,
    getNewPermission: state => state.permission,
    getNewPermissionGroup: state => state.group,
};

const actions = {
    async saveNewRole({commit}, data) {
        await api()
            .post("auth/api/roles", data)
            .then(response => {
                commit("setNewRole", response);
            })
            .catch(error => {
                commit("setNewRoleError", error);
            });
    }, async addPermission({commit}, data) {
        await api()
            .post("auth/api/permissions", data)
            .then(response => {
                commit("setPermission", response);
            })
            .catch(error => {
                commit("setRolesError", error);
            });
    }, async addPermissionGroup({commit}, data) {
        await api()
            .post("auth/api/permission-group", data)
            .then(response => {
                commit("setPermissionGroup", response);
            })
            .catch(error => {
                commit("setRolesError", error);
            });
    },
    async updateRoleAndPermission({commit}, data) {

        await api()
            .put("auth/api/roles/update", data)
            .then(response => {
                commit("setUpdateRoleAndPermissions", response);
            })
            .catch(error => {
                commit("setRolesError", error);
            });
    },
    async fetchPermissions({commit}) {
        await api()
            .get("auth/api/permissions")
            .then(response => {
                commit("setPermissionList", response);
            })
            .catch(error => {
                commit("setRolesError", error);
            });
    },
    async fetchPermissionGroups({commit}) {
        await api()
            .get("auth/api/permission-group")
            .then(response => {
                commit("setPermissionGroupList", response);
            })
            .catch(error => {
                commit("setPermissionGroupsError", error);
            });
    },
    async fetchRoles({commit}) {
        await api()
            .get("auth/api/roles")
            .then(response => {
                commit("setRolesList", response);
            })
            .catch(error => {
                commit("setRolesError", error);
            });
    },

    async fetchRoleDetails({commit}, roleId) {
        await api()
            .get("auth/api/roles/" + roleId)
            .then(response => {
                commit("setRolesDetails", response);
            })
            .catch(error => {
                commit("setRolesError", error);
            });
    },
    async assignRole({commit}, data) {
        await api()
            .post("auth/api/roles/to-user", data)
            .then(response => {
                commit("setNewRole", response);
            })
            .catch(error => {
                commit("setNewRoleError", error);
            });
    },
    async removeUserRole({commit}, data) {
        await api()
            .post("auth/api/roles/from-user", data)
            .then(response => {
                commit("setRevokeRole", response);
            })
            .catch(error => {
                commit("setRolesError", error);
            });
    },
};

const mutations = {
    setPermissionList: (state, response) => {
        state.permissionList = response.data;
    },
    setPermissionGroupList: (state, response) => {
        state.permissionGroups = response.data;
    },
    setNewRole: (state, response) => {
        state.successRole = response.data;
    },
    setNewRoleError: (state, error) => {
        state.error = error.response.data;
    },
    setRolesList: (state, response) => {
        state.rolesList = response.data;
    },
    setRolesDetails: (state, response) => {
        state.roleDetails = response.data;
    },
    setPermission: (state, response) => {
        state.permission = response.data;
    },
    setPermissionGroup: (state, response) => {
        state.group = response.data;
    },

    setUpdateRoleAndPermissions: (state, response) => {
        state.rolesAndPermissionUpdate = response.data;
    },
    setRevokeRole: (state, response) => {
        state.revokeRole = response.data;
    },
    setRolesError: (state, error) => {
        state.rolesError = error.response.data;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
