import {api} from "@/app/services/api.service";

const state = {
    taxHeads: null,
    taxHead: null,
    error: null,
}

const getters = {
    getTaxHeads: state => state.taxHeads,
    getTaxHead: state => state.taxHead,
    getTaxHeadError: state => state.error,
}

const actions = {
    async fetchTaxHeads({commit}) {
        api().get('configuration/tax-head/all')
            .then(response => {
                commit('setTaxHeads', response)
            })
            .catch(error => {
                commit('setError', error)
            })
    },
    async createTaxHead({commit}, data) {

        api().post('configuration/tax-head/create', data)
            .then(response => {
                commit('setTaxHead', response)
            })
            .catch(error => {
                commit('setTaxHeadError', error)
            })
    },
}

const mutations = {
    setTaxHeads: (state, payload) => {
        state.taxHeads = payload.data;
    },
    setTaxHead: (state, payload) => {
        state.taxHead = payload.data;
    },
    setTaxHeadError: (state, error) => {
        state.error = error.response.data;
    },
}


export default {
    state,
    getters,
    actions,
    mutations

}
