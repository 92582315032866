import {api} from "@/app/services/api.service";

const state = {
    internalRegisterData: null,
    registerError: null,
};

const getters = {
    getRegistrationData: state => state.internalRegisterData,
    getRegisterError: state => state.registerError
};

const actions = {
  async doRegisterUser({commit}, data) {
      await api()
          .post("auth/api/auth/signup/staff", data
          )
          .then(response => {
              commit("setUserRegistrationData", response);
          })
        .catch(error => {
          commit("setRegisterError", error.response);
        });
  }, async doRegisterMda({commit}, data) {
    await api()
        .post("auth/api/auth/signup/mda", data)
        .then(response => {
          commit("setUserRegistrationData", response);
        })
        .catch(error => {
          commit("setRegisterError", error.response);
        });
  }, async doRegisterPartner({commit}, data) {
    await api()
        .post("auth/api/partners", data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        )
        .then(response => {
          commit("setUserRegistrationData", response);
        })
        .catch(error => {
          commit("setRegisterError", error.response);
        });
  },
};

const mutations = {
  setUserRegistrationData: (state, response) => {
      state.internalRegisterData = response.data;
  },
  setRegisterError: (state, error) => {
    state.registerError = error.data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
