<template>
  <b-row>
    <b-col>
      <b-row>
        <b-modal title="Create Access Level" ref="accessLevel" hide-footer>
          <b-row>
            <b-col>
              <b-form-group label="End Point">
                <b-form-input v-model="alForm.endPoint" placeholder="EndPoint" class="form-text"></b-form-input>
              </b-form-group>
              <button class="bg-blue px-2 py-1 rounded-sm text-white my-2 hover:bg-blue-dark" @click="onSubmit">
                <i class="fa fa-save mx-1"></i> Save
              </button>
            </b-col>
          </b-row>
        </b-modal>

        <b-modal title="Edit Access Level" ref="editAccessLevel" hide-footer>
          <b-row>
            <b-col>
              <b-form-group label="End Point">
                <b-form-input v-model="editALForm.endPoint" placeholder="EndPoint" class="form-text"></b-form-input>
              </b-form-group>
              <button class="bg-blue px-2 py-1 rounded-sm text-white my-2 hover:bg-blue-dark" @click="onEdit">
                <i class="fa fa-save mx-1"></i> Save Changes
              </button>
            </b-col>
          </b-row>
        </b-modal>

        <b-modal title="Delete Access Level" ref="deleteAccessLevel" hide-footer>
          <b-row>
            <b-col>
              <p>Are you sure, you want to remove this ACCESS LEVEL from your available levels of integration? All MDA/Partners who have this access level
              will no longer be able to make API calls over this route.
              </p>
              <button class="bg-red px-2 py-1 rounded-sm text-white my-2 hover:bg-red-dark" @click="onDelete">
                <i class="fa fa-check-circle mx-1"></i> Yes Delete
              </button>
            </b-col>
          </b-row>
        </b-modal>
      </b-row>


      <b-row>
        <b-col cols="8" class="mx-auto mt-4">
          <b-row>
            <b-col cols="9"></b-col>
            <b-col cols="3">
              <button class="w-full bg-blue px-2 py-1 rounded-sm text-white"
                      @click="createAccessLevel">
                <i class="fa fa-plus mx-1"></i> Create Access Level
              </button>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="10">
              <p class="h6 text-uppercase">End Point</p>
            </b-col>
            <b-col cols="2" class="text-center">
              <p class="h6 text-uppercase">Action</p>
            </b-col>
          </b-row>
          <b-row v-for="(level, idx) in accessLevels"
                 :key="idx"
                 class="py-1 border-2 border-gray rounded-sm my-1 hover:text-blue hover:border-blue">
            <b-col cols="10">
              <p class="mt-1 cursor-pointer ">{{level.endPoint}}</p>
            </b-col>
            <b-col cols="2">
              <b-row>
                <b-col>
                  <button class="w-full bg-blue px-2 rounded-sm text-white my-1 mx-1 hover:bg-blue-dark" @click="editAccessLevel(level)">
                    <i class="fa fa-pencil mx-1 text-sm"></i>
                  </button>
                </b-col>
                <b-col>
                  <button class="w-full bg-red px-2 rounded-sm text-white my-1 mx-1 hover:bg-red-dark" @click="deleteAccessLevel(level)">
                    <i class="fa fa-times mx-1 text-sm"></i>
                  </button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "IntegrationSettings",
  data(){
    return{
      alForm:{
        endPoint:null
      },
      editALForm:{
        id:null,
        endPoint:null
      },
      deleteALForm:{
        id:null,
      },
      loading:false,
      accessLevels:[
        {
          "id": "0aa412ed-06d9-4923-8a74-736667f45c2c",
          "endPoint": "search/get_reg_status"
        },
        {
          "id": "39a28966-cb38-400e-9517-3670091442cd",
          "endPoint": "legal/get_legal_doc_details"
        },
        {
          "id": "467b6574-00c0-4526-b2d5-c792d7e825c3",
          "endPoint": "insolvency/get_reg_status_insolvency_practitioner"
        },
        {
          "id": "4dd47003-e8a3-45f1-92ce-0c437349574d",
          "endPoint": "entity/get_attachment_list"
        },
        {
          "id": "5fd46950-5ea9-4442-bfaa-d1368a8a76db",
          "endPoint": "entity/get_attachment"
        },
        {
          "id": "6b28eb7f-ca80-48cb-a92c-49c231a5c6bc",
          "endPoint": "entity/get_entity_members"
        },
        {
          "id": "6d825ad5-cd2c-4536-b5da-ce0f43f20350",
          "endPoint": "entity/get_entity_partners"
        },
        {
          "id": "7d59621b-b023-4f93-978d-cc6e75ff8534",
          "endPoint": "entity/get_entity_beneficial_owners"
        },
        {
          "id": "8cdd7e7e-b2ae-4d1b-9347-a86d7b24de4b",
          "endPoint": "entity/get_entity_subscribers"
        },
        {
          "id": "a7e61889-2701-4254-9d2d-34b84ceb6b2d",
          "endPoint": "insolvency/get_insolvency_practitioners"
        },
        {
          "id": "aec4ef80-2a05-4c8b-a8bf-6025bc08df1d",
          "endPoint": "entity/get_entity_basic"
        },
        {
          "id": "b232f197-d82a-4d77-9e65-78c5eebbd1d1",
          "endPoint": "entity/get_entity_secretary"
        },
        {
          "id": "be642515-ed72-48cc-baa1-6453bb4c9d2e",
          "endPoint": "legal/get_legal_reg_status"
        },
        {
          "id": "c2af142c-bad3-4ff1-ae7d-fc85d86fff81",
          "endPoint": "entity/entity_get_entity_list_basic"
        },
        {
          "id": "c91b8906-6e89-4923-aa82-93e9f119a8dc",
          "endPoint": "entity/get_entity_address"
        },
        {
          "id": "cf4ac365-8ae6-498f-85e0-aa75a77b5e63",
          "endPoint": "entity/get_entity_minimium"
        },
        {
          "id": "d70b1953-6b6c-453f-a3a9-bde4392fc274",
          "endPoint": "entity/get_entity_list_minimum"
        },
        {
          "id": "e1ed73d4-01aa-470c-94f8-44cefaa9fc45",
          "endPoint": "entity/get_entity_shareholders"
        },
        {
          "id": "f0a9e8a5-a3bb-4b39-8624-bfcce4223bcd",
          "endPoint": "entity/get_entity_full"
        },
        {
          "id": "f6833d03-ae5f-42cf-901f-21e4a24b11be",
          "endPoint": "entity/get_entity_directors"
        },
        {
          "id": "faf8047c-69f9-488a-9a8d-8f533d0aefaf",
          "endPoint": "search/get_name_similarity"
        }
      ]
    }
  },
  methods:{
      ...mapActions(["doAddEndpoint", "fetchAccessLevels"]),
      createAccessLevel() {
          this.$refs.accessLevel.show()
      },
      editAccessLevel(data) {
          this.editALForm.id = data.id
          this.editALForm.endPoint = data.endPoint
          this.$refs.editAccessLevel.show()
      },
      deleteAccessLevel(data) {
          this.deleteALForm.id = data.id
      this.$refs.deleteAccessLevel.show()
    },
    fetch(){
      this.loading = true
      this.fetchAccessLevels()
    },
    onSubmit(){
      this.loading = true
      this.$refs.accessLevel.hide()


    },
    onEdit(){
      this.loading = true
      this.$refs.editAccessLevel.hide()
    },
    onDelete(){

    }
  },
  computed:{
    ...mapGetters(["getAccessLevels", "getAccessLevelSuccess", "getAccessLevelError"])
  },
  watch:{
    getAccessLevels(data){
      this.loading = false
      this.accessLevels = data.data
    },
    getAccessLevelSuccess(){
      this.loading = false
      this.successToast("Success", "Access Level created")
    },
    getAccessLevelError(data){
      this.loading = false
      this.errorToast("Error", data.message)
    }
  }
};
</script>

<style scoped>

</style>