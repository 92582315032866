import {api} from "@/app/services/api.service";

const state = {
    loginData: null,
    logoutData:null,
    userProfileData: null,
    requestPassword: null,
    newPassword: null,
    setToken: null,
    userLogout: null,
    loginError: null,
    otpData: null,
};

const getters = {
    getLoginData: (state) => state.loginData,
    getUserLogoutSuccess: (state) => state.logoutData,
    getUserProfileData: (state) => state.userProfileData,
    getRequestPassword: (state) => state.requestPassword,
    getNewPassword: (state) => state.newPassword,
    getSetToken: (state) => state.setToken,
    getUserLogout: (state) => state.userLogout,
    getLoginError: (state) => state.loginError,
    getOtpSuccess: (state) => state.otpData,
};

const actions = {
  async doLoginUser({commit}, data) {
    delete api().defaults.headers.common['Authorization']
    await api()
        .post("auth/api/auth/signin/backoffice", data)
        .then((response) => {
          commit("setLoginToken", response);
        })
        .catch((error) => {
          sessionStorage.removeItem("token");
          commit("setLoginErrorMessage", error);
        });
  },
  async getUserProfile({commit}, token) {
    await api()
        .get("profile", {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          commit("setUserProfileData", response);
        })
        .catch((error) => {
          sessionStorage.removeItem("userProfile");
          commit("setLoginErrorMessage", error);
        });
  },
  async requestResetCode({commit}, data) {
    await api()
        .post("request-reset", data)
        .then((response) => {
          commit("setRequestPassword", response);
        })
        .catch((error) => {
          commit("setLoginErrorMessage", error);
        });
  },
  async changePassword({commit}, data) {
    await api()
        .post("set-password", data)
        .then((response) => {
          commit("setPassword", response);
        })
        .catch((error) => {
          sessionStorage.removeItem("token");
          commit("setLoginErrorMessage", error);
        });
  },
  async checkTokenVerification({commit}, data) {
      await api()
          .get("validate-token?token=" + data)
          .then((response) => {
              commit("setCheckToken", response);
          })
          .catch((error) => {
              sessionStorage.removeItem("token");
              commit("setLoginErrorMessage", error);
          });
  },
    // async doLogOutUser({commit}) {
    //     await api().get("/auth/api/auth/logout")
    //         .then((response) => {
    //             commit("setUserLogout", response);
    //         })
    //         .catch((error) => {
    //             commit("setAuthError", error.response);
    //         });
    //
    // },
  async doLogoutUser({commit}, data) {
    await api()
      .post("auth/api/auth/logout/at-login", data)
      .then((response) => {
        commit("setLogoutData", response);
      })
      .catch((error) => {
        commit("setLoginErrorMessage", error);
      });
  },
  async doLogoutAccount({commit}) {
    await api().get("/auth/api/auth/logout")
      .then((response) => {
        commit("setUserLogout", response);
      })
      .catch((error) => {
        commit("setAuthError", error.response);
      });
  },
    async doInvalidateSession({commit}, data) {
        await api().post("/auth/api/auth/logout/at-login", data)
            .then((response) => {
                commit("setUserLogout", response);
            })
            .catch((error) => {
                commit("setAuthError", error.response);
            });

    },

    async doOtpVerification({commit}, data) {
        await api()
            .post("auth/api/auth/validate-login-code", data)
            .then((response) => {
                commit("setOtpData", response);
            })
            .catch((error) => {
                commit("setLoginErrorMessage", error);
            });
    },
};

const mutations = {
  setLoginToken: (state, response) => {
    state.loginData = response.data;
    // sessionStorage.setItem("token", response.data.token);
    // sessionStorage.setItem("expires_in", response.data.expires_in);
    // sessionStorage.setItem("sessionStorage", JSON.stringify(sessionStorage));
    // sessionStorage.removeItem("sessionStorage");
  },
  setLogoutData: (state, response) => {
    state.logoutData = response.data;
  },
  setUserProfileData: (state, response) => {
    let user = response.data;
    sessionStorage.setItem("userProfile", JSON.stringify(user));
    state.userProfileData = user;

    if (user.type === "Client") {
      let abilities = user.active_permissions || [];
      sessionStorage.setItem("abilities", JSON.stringify(abilities));
    }
  },setLogegdInState: (state, response) => {
    let user = response.data;
    sessionStorage.setItem("userProfile", JSON.stringify(user));
    state.userProfileData = user;

    if (user.type === "Client") {
      let abilities = user.active_permissions || [];
      sessionStorage.setItem("abilities", JSON.stringify(abilities));
    }
  },
  setRequestPassword: (state, response) => {
    state.requestPassword = response.data;
  },
  setPassword: (state, response) => {
    state.newPassword = response.data;
  },
    setCheckToken: (state, response) => {
        state.setToken = response.data;
    },
    setUserLogout: (state, response) => {
        state.userLogout = response.data;
    },
    setLoginErrorMessage: (state, error) => {
        state.loginError = error.response.data;
    },

    setOtpData: (state, response) => {
        state.otpData = response.data;
    },
};

export default {
  state,
  getters,
  actions,
  mutations,
};