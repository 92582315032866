<template>
  <v-row class="fill-height" style="background-color: #f7f7f8;">
    <v-col lg="5" class="mx-auto" style="margin-top: 10%;">
      <v-card tile class="text-center">
        <v-card-text style="font-size: 90px; padding-top: 90px;">
          4<span style="color: #00b7ff;">0</span>4
        </v-card-text>

        <v-card-text style="font-size: 20px;" class="mt-3">
          Error - Page Not Found
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text style="font-size: 17px;" class="mt-3">
          Please Check the URL.
        </v-card-text>
        <v-card-text style="font-size: 17px; padding-bottom: 90px;">
          Otherwise,
          <router-link class="text-decoration-none" :to="{ name: 'login' }"
          >click here
          </router-link
          >
          to go to home page.
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Error404"
};
</script>

<style scoped></style>
