import {api} from "@/app/services/api.service";

const state = {
    countries: null,
    country: null,
    countryError: null,
}

const getters = {
    getCountries: state => state.countries,
    getCountry: state => state.country,
    getCountryError: state => state.countryError,
}

const actions = {
    async fetchCountries({commit}) {
        await api().get("configuration/address/country")
            .then((response) => {
                commit("setCountries", response);
            })
            .catch((error) => {
                commit("setCountryError", error.response);
            });
    },
    async createCountry({commit}, data) {

        await api().post('configuration/address/country', data)
            .then(response => {
                commit('setCountry', response)
            })
            .catch(error => {
                commit('setCountryError', error)
            })
    },

    async updateCountry({commit}, data) {

        await api().post('configuration/address/country', data)
            .then(response => {
                commit('setCountry', response)
            })
            .catch(error => {
                commit('setCountryError', error)
            })
    },

}

const mutations = {
    setCountries: (state, payload) => {
        state.countries = payload.data;
    },
    setCountry: (state, payload) => {
        state.country = payload.data;
    },
    setCountryError: (state, error) => {
        state.countryError = error.response.data;
    },

}


export default {
    state,
    getters,
    actions,
    mutations

}
