<template>
    <div class="bg-white rounded-sm text-gray mx-2 my-3">
        <b-row>
            <b-modal ref="addBody" title="Add Academic Qualification" hide-footer>
                <new-academic-qualification></new-academic-qualification>
            </b-modal>
            <b-modal ref="editBody" title="Update Academic Qualification" hide-footer>
                <edit-academic-qualification :draft-data="draft"></edit-academic-qualification>
            </b-modal>
        </b-row>
        <b-row>
            <b-col>
                <b-row>
                    <b-col cols="10"></b-col>
                    <b-col cols="2">
                        <button class="w-full bg-blue text-white px-2 py-1 rounded-sm" @click="openForm"><i
                                class="fa fa-plus"></i>
                            Add Qualification
                        </button>
                    </b-col>
                </b-row>
                <b-row class="my-3">
                    <b-col>
                        <table class="min-w-full leading-normal">
                            <thead>
                            <tr class="border-b border-t border-grey">
                                <th class="t-header">#</th>
                                <th class="t-header" width="50%">Name</th>
                                <th class="t-header text-center">Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(pb,idx) in qualifications"
                                :key="pb.id">
                                <td class="t-body">{{ idx + 1 }}</td>
                                <td class="t-body font-bold text-capitalize">{{ pb.name }}</td>
                                <td class="t-body font-bold">
                                    <b-row>
                                        <b-col>
                                            <button @click="openEditForm(pb)"
                                                    class="w-full bg-green text-white text-xs px-2 py-1 rounded-sm"><i
                                                    class="fa fa-pencil"></i> Edit
                                            </button>
                                        </b-col>
                                    </b-row>
                                    <!--                                        <b-col>-->
                                    <!--                                            <button class="w-full bg-red text-white text-xs px-2 py-1 rounded-sm"><i-->
                                    <!--                                                    class="fa fa-trash"></i> Delete-->
                                    <!--                                            </button>-->
                                    <!--                                        </b-col>-->
                                    <!--                                    </b-row>-->
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import NewAcademicQualification
    from "@/views/home/services/settings/insolvency/academicQualifications/NewAcademicQualification.vue";
import EditAcademicQualification
    from "@/views/home/services/settings/insolvency/academicQualifications/EditAcademicQualification.vue";

export default {
    name: "AcademicQualifications",
    components: {
        EditAcademicQualification,
        NewAcademicQualification,
    },
    data() {
        return {
            registerDepartment: false,
            newDepartment: false,
            qualifications: [],
            rows: {
                odd: "border-t border-grey hover:bg-header_grey",
                even: "border-t border-grey bg-header_grey"
            },
            draft: {}
        };
    },
    mounted() {
        this.fetchAcademicQualifications();
    },

    methods: {
        ...mapActions(["fetchAcademicQualifications"]),
        openForm() {
            this.$refs.addBody.show();
        },
        openEditForm(draftData) {
            this.draft = draftData
            this.$refs.editBody.show();
        }
    }
    ,
    computed: {
        ...mapGetters(["getAcademicQualifications"])
    },

    watch: {
        getAcademicQualifications(data) {
            this.qualifications = data.data
        }
    }

};
</script>

<style scoped>

</style>