<template>
  <b-row>
    <b-col>
      <b-form-group
          class="text-sm my-1"
          id="input-group-1"
          label="Given Name"
          label-for="input-1">
        <b-form-input
            id="companyName"
            class="form-text text-sm noFocus"
            type="text"
            disabled="true"
            :value="member.givenName"
            required>
        </b-form-input>
      </b-form-group>
      <b-form-group
          v-if="member.middleName"
          class="text-sm my-1"
          id="input-group-1"
          label="Middle Name"
          label-for="input-1">
        <b-form-input
            id="companyName"
            class="form-text text-sm noFocus"
            type="text"
            disabled="true"
            :value="member.middleName"
            required>
        </b-form-input>
      </b-form-group>
      <b-form-group
          class="text-sm my-1"
          id="input-group-1"
          label="Surname"
          label-for="input-1">
        <b-form-input
            id="companyName"
            class="form-text text-sm noFocus"
            type="text"
            disabled="true"
            :value="member.surName"
            required>
        </b-form-input>
      </b-form-group>
      <b-form-group
          class="text-sm my-1"
          id="input-group-1"
          label="ID Type"
          label-for="input-1">
        <b-form-input
            id="companyName"
            class="form-text text-sm noFocus text-capitalize"
            type="text"
            disabled="true"
            :value="member.idType.toLowerCase()"
            required>
        </b-form-input>
      </b-form-group>
      <b-form-group
          class="text-sm my-1"
          id="input-group-1"
          label="ID Number"
          label-for="input-1">
        <b-form-input
            id="companyName"
            class="form-text text-sm noFocus"
            type="text"
            disabled="true"
            :value="member.idNumber"
            required>
        </b-form-input>
      </b-form-group>
      <b-form-group
          class="text-sm my-1"
          id="input-group-1"
          label="Nationality"
          label-for="input-1">
        <b-form-input
            id="companyName"
            class="form-text text-sm noFocus"
            type="text"
            disabled="true"
            :value="member.nationality"
            required>
        </b-form-input>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
          class="text-sm my-1"
          id="input-group-1"
          label="Email Address"
          label-for="input-1">
        <b-form-input
            id="companyName"
            class="form-text text-sm noFocus"
            type="text"
            disabled="true"
            :value="member.email"
            required>
        </b-form-input>
      </b-form-group>
      <b-form-group
          class="text-sm my-1"
          id="input-group-1"
          label="Phone Number"
          label-for="input-1">
        <b-form-input
            id="companyName"
            class="form-text text-sm noFocus"
            type="text"
            disabled="true"
            :value="member.phoneNumber"
            required>
        </b-form-input>
      </b-form-group>
      <b-form-group
          class="text-sm my-1"
          id="input-group-1"
          label="Gender (at birth)"
          label-for="input-1">
        <b-form-input
            id="companyName"
            class="form-text text-sm noFocus text-capitalize"
            type="text"
            disabled="true"
            :value="member.sex.toLowerCase()"
            required>
        </b-form-input>
      </b-form-group>
      <b-form-group
          class="text-sm my-1"
          id="input-group-1"
          label="Date of Birth"
          label-for="input-1">
        <b-form-input
            id="companyName"
            class="form-text text-sm noFocus"
            type="text"
            disabled="true"
            :value="dateFormat(member.dateOfBirth)"
            required>
        </b-form-input>
      </b-form-group>
      <b-form-group
          class="text-sm my-1"
          id="input-group-1"
          label="Occupation"
          label-for="input-1">
        <b-form-input
            id="companyName"
            class="form-text text-sm noFocus"
            type="text"
            disabled="true"
            :value="member.occupation"
            required>
        </b-form-input>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
          class="text-sm my-1"
          id="input-group-1"
          label="District"
          label-for="input-1">
        <b-form-input
            class="form-text text-sm noFocus"
            type="text"
            disabled="true"
            :value="address.district.name"
            required>
        </b-form-input>
      </b-form-group>
      <b-form-group
          class="text-sm my-1"
          id="input-group-1"
          label="Region"
          label-for="input-1">
        <b-form-input
            class="form-text text-sm noFocus"
            type="text"
            disabled="true"
            :value="address.region.name"
            required>
        </b-form-input>
      </b-form-group>
      <b-form-group
          class="text-sm my-1"
          id="input-group-1"
          label="Sub county"
          label-for="input-1">
        <b-form-input
            class="form-text text-sm noFocus"
            type="text"
            disabled="true"
            :value="address.subcounty.name"
            required>
        </b-form-input>
      </b-form-group>
      <b-form-group
          class="text-sm my-1"
          id="input-group-1"
          label="Parish"
          label-for="input-1">
        <b-form-input
            class="form-text text-sm noFocus"
            type="text"
            disabled="true"
            :value="address.parish.name"
            required>
        </b-form-input>
      </b-form-group>
      <b-form-group
          class="text-sm my-1"
          id="input-group-1"
          label="Village"
          label-for="input-1">
        <b-form-input
            class="form-text text-sm noFocus"
            type="text"
            disabled="true"
            :value="address.village.name"
            required>
        </b-form-input>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
          class="text-sm my-1"
          id="input-group-1"
          label="Postal Address"
          label-for="input-1">
        <b-form-input
            id="companyName"
            class="form-text text-sm noFocus"
            type="text"
            disabled="true"
            :value="member.postalAddress"
            required>
        </b-form-input>
      </b-form-group>
      <b-form-group
          class="text-sm my-1"
          id="input-group-1"
          label="Street"
          label-for="input-1">
        <b-form-input
            id="companyName"
            class="form-text text-sm noFocus"
            type="text"
            disabled="true"
            :value="member.street"
            required>
        </b-form-input>
      </b-form-group>
      <b-form-group
          class="text-sm my-1"
          id="input-group-1"
          label="Plot"
          label-for="input-1">
        <b-form-input
            id="companyName"
            class="form-text text-sm noFocus"
            type="text"
            disabled="true"
            :value="member.plot"
            required>
        </b-form-input>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import moment from "moment";
import {api} from "@/app/services/api.service";

export default {
  name: "MemberDetails",
  props:{
    member:Object
  },
  data(){
    return{
      address:null
    }
  },
  mounted() {
    this.fetchAddressByVillageId(this.member.villageId)
  },
  methods:{
    dateFormat(date){
      return moment(date).format("DD/MM/YYYY")
    },
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    async fetchAddressByVillageId(id){
      api().get("/configuration/address/full-path?villageId="+id).
      then(response => {
        this.address = response.data.data
      }).catch(error => {
        this.errorToast("Error", error.data)
      });
    },
  },
}
</script>

<style scoped>

</style>