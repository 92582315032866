import {api} from "@/app/services/api.service"

const state = {
    error: null,
    access_level_success: null,
    access_level_error: null,
    go_live: null,
    access_key_error: null,
    access_levels: null,
    access_keys: null,
    endpoints: null,
    sandbox_endpoints: null,
    production_endpoints: null,
    endpoint_error: null,
    revoke_access: null,
    accountSummary: null,
    logs: null,


};

const getters = {
    getAccessLevels: (state) => state.access_levels,
    getAccessKeys: (state) => state.access_keys,
    getAccessLevelSuccess: (state) => state.access_level_success,
    getGeneratedAccessKey: (state) => state.access_key_success,
    getAccessLevelError: (state) => state.access_level_error,
    getAccessKeyError: (state) => state.access_key_error,
    getEndpoints: (state) => state.endpoints,
    getSandboxEndpoints: (state) => state.sandbox_endpoints,
    getProductionEndpoints: (state) => state.production_endpoints,
    getRevokeAccess: (state) => state.revoke_access,
    getGoLive: (state) => state.go_live,
    getAccountSummary: (state) => state.accountSummary,
    getApiLogs: (state) => state.logs,
};

const actions = {
    async fetchAccessLevels({commit}) {
        api().get("/api/third-party/level/all")
            .then(response => {
                commit("setAccessLevels", response);
            })
            .catch(error => {
                commit("setAccessLevelError", error);
            });
    },
    async doAddAccessAreas({commit}, data) {
        api().post("/third-party-api/access-list/create", data)
            .then(response => {
                commit("setAccessLevelSuccess", response);
            })
            .catch(error => {
                commit("setAccessLevelError", error);
            });
    },
    async generateAccessKeys({commit}, data) {
        api().post("/third-party-api/access/setup", data)
            .then(response => {
                commit("setGenerateKeySuccess", response);
            })
            .catch(error => {
                commit("setGenerateKeyError", error);
            });
    }, async doGoLive({commit}, data) {
        api().post("/third-party-api/access/go-live", data)
            .then(response => {
                commit("setGoLiveSuccess", response);
            })
            .catch(error => {
                commit("setGenerateKeyError", error);
            });
    }, async doRevokeAccess({commit}, data) {
        api().post("/third-party-api/access/revoke", data)
            .then(response => {
                commit("setRevokeAccessSuccess", response);
            })
            .catch(error => {
                commit("setGenerateKeyError", error);
            });
    },
    async fetchAccessKeys({commit}, data) {
        api().get("/api/third-party/access-key/by-account/" + data.id)
            .then(response => {
                commit("setAccessKeys", response);
            })
            .catch(error => {
                commit("setAccessKeyError", error);
            });
    },

    async fetchAllEndpoints({commit}) {
        api().get("/third-party-api/endpoint/all")
            .then(response => {
                commit("setEndpointFetchSuccess", response);
            })
            .catch(error => {
                commit("setEndpointFetchError", error);
            });
    }, async fetchApiLogs({commit}, userId) {
        api().get("/third-party-access/request-logs?organisationId=" + userId)
            .then(response => {
                commit("setFetchLogs", response);
            })
            .catch(error => {
                commit("setEndpointFetchError", error);
            });
    },

    async fetchUserProductionEndpoints({commit}, userId) {
        api().get("/third-party-api/access/live/" + userId)
            .then(response => {
                commit("setProductionEndpointFetchSuccess", response);
            })
            .catch(error => {
                commit("setEndpointFetchError", error);
            });
    },
    async fetchUserSandboxEndpoints({commit}, userId) {
        api().get("/third-party-api/access/sandbox/" + userId)
            .then(response => {
                commit("setSandboxEndpointFetchSuccess", response);
            })
            .catch(error => {
                commit("setEndpointFetchError", error);
            });
    },
    async fetchAccountSummary({commit}, userId) {
        api().get("/billing/transaction/partner-account/" + userId)
            .then(response => {
                commit("setAccountSummary", response);
            })
            .catch(error => {
                commit("setEndpointFetchError", error);
            });
    },

};

const mutations = {
    setAccessLevels: (state, response) => {
        state.access_levels = response.data;
    },
    setAccessLevelSuccess: (state, response) => {
        state.access_level_success = response.data;
    },
    setAccessLevelError: (state, error) => {
        state.access_level_error = error;
    },
    setAccessKeys: (state, response) => {
        state.access_keys = response.data;
    },
    setGenerateKeySuccess: (state, error) => {
        state.access_key_success = error;
    },
    setGoLiveSuccess: (state, error) => {
        state.go_live = error;
    },
    setRevokeAccessSuccess: (state, error) => {
        state.revoke_access = error;
    },
    setAccessKeyError: (state, error) => {
        state.access_key_error = error.response.data;
    },
    setEndpointFetchSuccess: (state, response) => {
        state.endpoints = response.data;
    },
    setSandboxEndpointFetchSuccess: (state, response) => {
        state.sandbox_endpoints = response.data;
    },
    setProductionEndpointFetchSuccess: (state, response) => {
        state.production_endpoints = response.data;
    },

    setEndpointFetchError: (state, error) => {
        state.endpoint_error = error;
    },
    setGenerateKeyError: (state, error) => {
        state.access_key_error = error.response.data;
    },
    setAccountSummary: (state, response) => {
        state.accountSummary = response.data;
    },
    setFetchLogs: (state, response) => {
        state.logs = response.data;
    },

};

export default {
    state,
    getters,
    actions,
    mutations,
};
