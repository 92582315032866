<template>
  <div class="bg-white rounded-sm mx-2 my-3">
    <b-row>
      <b-col cols="12" class="mx-3 mt-3">
        <b-row>
          <b-col>
            <span class="title">Insolvency Practitioner</span>
          </b-col>
          <b-col cols="2" class="mx-auto text-center">
            <button class="bg-blue rounded px-2 py-1 text-white  hover:bg-blue-dark" @click="back">
              <i class="fa fa-arrow-left text-white mr-2"></i>Back
            </button>
          </b-col>
        </b-row>

      </b-col>
    </b-row>
    <b-row class="mx-3 mt-2">
      <b-col cols="12" class="mx-auto bg-header_grey py-3 rounded">
         <span class="text-gray">Practitioner Number / {{practitionerNumber}}</span>
      </b-col>
    </b-row>

    <b-row class="mx-3 py-3">
      <b-overlay rounded="sm" :show="loading">
        <b-col>
          <b-row>
            <b-col cols="12" sm="6" md="4" lg="3" xl="2">
              <img v-if="avatar !== null" :src="avatarUrl" class="rounded-full w-100 border-2 border-blue"/>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="mt-3">
              <span class="font-bold text-blue h4">{{practitionerNumber}}</span><br/>
              <span class="font-bold text-gray h4 my-auto mr-2 text-capitalize">{{practitioner.firstName}} {{practitioner.lastName}}</span>
              <br/>
              <span v-if="profession !== null" class="text-gray text-sm text-capitalize">Profession: {{ profession.professionName }}</span>
              <br/>
              <br/>
              <span class="text-gray text-sm text-capitalize">{{practitioner.phoneNumber}}</span><br/>
              <span class="text-gray text-sm text-lowercase">{{practitioner.email}}</span><br/>
              <span class="text-blue text-xs" >Registration Date: {{dateFormat(practitioner.registrationDate)}}</span>
              <br/>
              <span class="text-gray text-sm" >Practitioner Account Status:
                <span v-if="practitioner.active" class="px-2 py-1 bg-green rounded-sm text-white text-xs my-auto">ACTIVE</span>
                <span v-else class="px-2 py-1 bg-red rounded-sm text-white text-xs my-auto">INACTIVE</span>
              </span>
              <br/><br/>
              <b-row>
                <b-col>
                  <PractitionerDetails
                      :data="practitioner.form1s[0]"
                      :upn="practitionerNumber"
                      :form1s="practitioner.form1s"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-overlay>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import server from "@/app/env/server.json";
import PractitionerDetails from "./PractitionerDetails";

export default {
  name: "PractitionerProfileWrapper",
  components: { PractitionerDetails },
  data(){
    return{
      practitionerNumber:null,
      loading: false,
      practitioner: {
        active:true,
        form1s: [],
      },
      currentRoute:"details",
      dpUrl:""
    }
  },
  mounted(){
    this.practitionerNumber = this.$route.params.id
    this.fetch()
  },
  methods:{
    ...mapActions(["fetchPractitionerDetails"]),
    select(route){
      this.currentRoute = route
    },
    fetch(){
      this.loading = true
      this.fetchPractitionerDetails(this.practitionerNumber)
    },
    cleanString(name){
      let n = name.replaceAll("_", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    back() {
      window.history.back()
    }
  },
  computed:{
    ...mapGetters(["getPractitionerDetails"]),
    profession() {
      if (this.practitioner.form1s.length === 0) {
        return null;
      }

      let professions = [];

      this.practitioner.form1s.forEach(form1=>{
        form1.professions.forEach(profession=>{
          professions.push(profession)
        })
      })

      if (professions.length === 0) {
        return null;
      }

      return professions[0];
    },
    avatar() {
      if (this.practitioner.form1s.length === 0) {
        return null;
      }

      let passportPhotos = [];

      this.practitioner.form1s.forEach(form1=>{
        form1.passportPhotos.forEach(photo=>{
          passportPhotos.push(photo)
        })
      })

      if (passportPhotos.length === 0) {
        return null;
      }

      return passportPhotos[0];
    },
    avatarUrl() {
      if (this.avatar === null) {
        return null
      } else {
        return server.IP+'/insolvency/api/practitioner-registration/passport-photo/'+this.avatar.filename
      }
    }
  },
  watch:{
    getPractitionerDetails(data){
      this.loading = false
      this.practitioner = data.data
    }
  }
};
</script>

<style scoped>

</style>