<template>
  <b-row>
    <b-col>
      <b-row class="mx-auto">
        <b-col>
          <form ref="form">
            <b-alert variant="danger" v-if="errorMessage">
              {{ errorMessage }}
            </b-alert>
            <b-form-select
              v-model="form.groupId"
              class="form-select my-2"
              :options="groupData">
            </b-form-select>
            <b-form-input
              class="my-2"
              v-model="form.name"
              placeholder="Permission Name"
              required
              type="text"></b-form-input>
            <b-form-textarea
              data-test="role-description"
              class="my-2"
              v-model="form.description"
              placeholder="Description">
            </b-form-textarea>
            <button class="bg-blue text-white px-2 py-1 rounded-sm hover:bg-blue-dark" type="button" @click="submitPermission">
              Save Permission
              <b-spinner v-if="spinner" small></b-spinner>
            </button>
          </form>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "NewPermission",
  props: {
    roleData: {}
  },
  data() {
    return {
      errorMessage: null,
      checkPermissions: false,
      form: {
        groupId: null,
        name: null,
        description: null,
        enabled: true
      },
      groupData: [],
      spinner: false,
      role_uid: null,
      permissions: [],
      allPermissions: [],
      permissionsToRemove: []
    }
  },
  mounted() {
    this.errorMessage = ""
    this.fetchPermissionGroups()
    this.fetchPermissions()
  },
  methods: {
    ...mapActions(
      [
        "fetchPermissions",
        "fetchPermissionGroups",
        "addPermission"
      ]
    ),
    submitPermission() {
      this.spinner = true
      this.errorMessage = null
      this.addPermission(this.form)
    },
  },
  computed: {
    ...mapGetters(
      [
        "getPermissionGroupsList",
        "getRolesError",
        "getPermissionList",
        "getNewPermission",
      ]
    )
  },
  watch: {
    getPermissionGroupsList(rolesGroup) {
      if (rolesGroup) {
        console.log(rolesGroup)
        this.groupData.push(
          {
            text:"- Select Group -",
            value:null
          }
        )
        for(let i = 0; i < rolesGroup.length; i++){
          console.log("")
          this.groupData.push(
            {
              text:rolesGroup[i].name,
              value:rolesGroup[i].id
            }
          )
        }
      }
    },
    getRolesError(rolesError) {
      if (rolesError) {
        this.spinner = false
        this.errorMessage = rolesError.message
        this.errorToast("Error", rolesError.message)
      }
    },
    getDoAssignRole(assignRoleData) {
      if (assignRoleData) {
        this.successToast("Success", assignRoleData.message)
      }
    },
    getPermissionList(permissionList) {
      this.permissions = permissionList
    },
    getRolesAndPermissionUpdate(roleDataUpdate) {
      if (roleDataUpdate) {
        this.successToast("Success", "Role updated successfully")
        this.form.permissions = []
        this.permissionsToRemove = []
        this.form.name = null
        this.form.description = null
        this.role_uid = null
        this.$emit("finished", true)
        this.fetchRoles()
        this.fetchPermissionGroups()
        this.fetchPermissions()
      }
    },
    getNewPermission() {
      this.successToast()
      this.groupData = []
      this.spinner = false
      this.role_uid = null
      this.permissions = []
      this.allPermissions = []
      this.permissionsToRemove = []
      this.form = {
        groupId: null,
        name: null,
        description: null,
        enabled: true
      }
      this.fetchPermissionGroups()
      this.fetchPermissions()
    }
  }
}
</script>

<style scoped>

</style>
