<template>
  <b-col cols="8" class="mx-auto">
    <b-row>
      <b-modal id="modal-confirm" hide-footer ref="confirm" title="Another Session">
        <b-row>
          <b-col>
            <p class="text-sm">
              <span class="h6">You are already signed in</span>.<br/>
              We have noticed that you are currently signed in to another computer.
              For security purposes we highly discourage having two different sessions running.
            </p>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="6">
            <button class="px-3 py-2 bg-green rounded-sm text-white w-full hover:bg-green-dark" @click="yesConfirm">
              <i class="fa fa-check-circle text-white mr-1"></i>Logout Old Session</button>
          </b-col>
          <b-col cols="6">
            <button class="px-3 py-2 bg-red rounded-sm text-white w-full" @click="cancelConfirm ">
              <i class="fa fa-times text-white mr-2"></i>Cancel</button>
          </b-col>
        </b-row>
        <b-row class="mt-1">

        </b-row>
      </b-modal>
    </b-row>
    <b-row>
      <b-col>
        <div class="w-full shadow-md px-3 py-5 border text-center" style="background-color: #ffffff;">
          <b-row>
            <b-col>
              <span class="h2 text-blue">OBRS Setup Portal</span>
            </b-col>
          </b-row>
          <b-row class="mt-3" v-if="isOnLogin">
            <b-col>
              <p class="max-w-2xl text-gray-500 lg:mx-auto" style="color: #909090;">
                Sign in to your account
              </p>
              <form @submit.prevent="onLoginSubmit">
                <div class="rounded-md shadow-sm -space-y-px">
                  <div style="color: #909090;"
                       class="flex flex-wrap border border-grey items-stretch w-full relative  bg-white items-center rounded mb-0 ">
                    <div class="flex  justify-center w-10 h-9">
                      <span
                          class="flex items-center leading-normal bg-white px-2 border-0 rounded-r-none text-gray-600">
                        <i class="fas fa-user"></i>
                      </span>
                    </div>
                    <input
                        v-model="form.usernameOrEmail"
                        type="text"
                        :class="loginTextInput"
                        placeholder="Username"/>
                  </div>
                  <div style="color: #909090;"
                       class="flex flex-wrap border border-grey items-stretch w-full relative  bg-white items-center rounded mb-1 ">
                    <div class="flex justify-center w-10 h-9">
                      <span
                          class="flex items-center leading-normal bg-white px-2 border-0 rounded-r-none text-gray-600">
                        <i class="fas fa-key"></i>
                      </span>
                    </div>
                    <input
                        v-model="form.password"
                        :type="show1 ? 'text' : 'password'"
                        required="required"
                        :class="loginTextInput"
                        placeholder="Password"/>
                    <div class="flex  justify-center w-10 h-9">
                      <span
                          class="flex items-center leading-normal bg-white px-2 border-0 rounded-r-none text-gray-600">
                        <i :class="show1 ? 'fas fa-eye' : 'fas fa-eye-slash'" @click="show1 = !show1"
                           style="cursor: pointer"></i>
                      </span>
                    </div>

                  </div>


                </div>
                <div class="flex items-center justify-between mt-3">
                  <div class="flex items-center">
                    <label class="inline-flex items-center">
                      <input type="checkbox" class="form-checkbox"/>
                      <span class="font-medium ml-2" style="color: #909090;">Remember me</span>
                    </label>
                  </div>
                  <div class="text-sm">
                    <router-link :to="{name:'reset-password'}" class="font-medium text-blue hover:text-blue-dark">
                      Forgot your password?
                    </router-link>
                  </div>
                </div>
                <div>
                  <button
                      class="noFocus w-full mt-3 justify-center p-3 border border-transparent text-sm font-medium rounded-md text-white bg-blue hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dark"
                      type="submit">
                    Sign in
                    <b-spinner v-if="showSpinner" small class="ml-2"></b-spinner>
                  </button>
                </div>
              </form>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col>
              <p class="mt-1 max-w-2xl text-gray-500 lg:mx-auto" style="color: #909090;">
                Verify that it is you signing in
              </p>
              <form class="mt-3">
                <Otp ref="otp-input" :input-number="otpSize" class="mx-auto" v-on:otpData="getOtpCode"/>
                <div>
                  <button
                      class="noFocus w-full mt-3 justify-center p-3 border border-transparent text-sm font-medium rounded-md text-white bg-blue hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dark"
                      type="button" @click="onOtpSubmit" v-if="this.errorMessage == null">
                    Verify Code
                    <b-spinner v-if="showSpinner" small></b-spinner>
                  </button>

                  <button
                    class="noFocus mx-auto mt-3 justify-center p-3 border-2 border-red text-sm font-medium text-red rounded hover:bg-red hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red"
                    type="button" @click="onResendOtp" v-if="this.errorMessage != null">
                    Resend Code
                    <b-spinner v-if="showSpinner" small></b-spinner>
                  </button>
                </div>
              </form>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import jwtService from "@/app/services/jwt.service";
import Otp from "@/views/auth/Login/otp/Otp";

export default {
  name: "Login",
  components: {Otp},
  data() {
    return {
      isOnLogin:true,
      show1: false,
      showSpinner:false,
      otpSize:4,
      form: {
        usernameOrEmail: null,
        password: null,
      },
      loginObject:null,
      hasError: false,
      errorMessage: null,
      otp:null,
      loading: false,
      loginTextInput: "flex-shrink flex-grow flex-auto leading-normal h-6 flex-1 self-center relative  font-roboto sm:text-sm outline-none",
    }
  },
  mounted() {
      if (sessionStorage.getItem("access_token")) {
          let r = this.$router.resolve({
              name: "user_profile",
          });
          window.location.assign(r.href);
    }
  },
  methods: {
    ...mapActions(["doLoginUser", "doOtpVerification", "doLogoutUser"]),
    onLoginSubmit() {
      this.errorMessage = null
      this.showSpinner = true
      this.doLoginUser(this.form)
    },
    onOtpSubmit() {
      this.errorMessage = null
      this.showSpinner = true
      this.doOtpVerification(this.otp)
    },
    onResendOtp() {
      //this.showSpinner = true
      this.otp = null
      this.$refs["otp-input"].flush()
      //this.doOtpVerification(this.otp)
    },
    yesConfirm(){
      this.showSpinner = true
      this.doLogoutUser(this.form)
      this.$refs.confirm.hide()
    },
    cancelConfirm(){
      this.$refs.confirm.hide()
    },
    getOtpCode(code) {
      this.showSpinner = true
      let payload = {
        token: this.getLoginData.data,
        otp: code
      }
      this.doOtpVerification(payload)
    }
  },

  computed: {
    ...mapGetters(['getLoginData','getLoginError', 'getOtpSuccess', 'getUserLogoutSuccess'])
  },
  watch: {
    getLoginData(data){
      this.successToast("Success", data.message)
      this.showSpinner = false
      this.isOnLogin = false
    },
    getOtpSuccess(loginObject){
      if(loginObject){
        jwtService.saveToken(loginObject.accessToken)
        jwtService.saveUser(loginObject.user)
        this.successToast("Welcome", loginObject.user.givenName)
        let permissions = []

        loginObject.user.roles.forEach(role => {
          role.permissions.forEach(permission => {
            permissions.push({"action":permission.permission,"resource":permission.group})
          })
        })
        sessionStorage.setItem('abilities',JSON.stringify(permissions))
        this.showSpinner = false
        let r = this.$router.resolve({
          name: "Home",
        });
        window.location.assign(r.href);
      }
    },
    getUserLogoutSuccess(){
      this.showSpinner = true
      this.successToast("Logout", "Logged out old session")
      this.doLoginUser(this.form)
    },
    getLoginError(data){
      this.showSpinner = false
      if(data.status === 406){
        this.$refs.confirm.show();
      }else{
        this.errorToast("Error", data.message)
        this.errorMessage = data.message
      }
    }
  }
}
</script>

<style scoped>
.border {
  border: solid 1px #eeeeee;
  border-radius: 3px;
}
</style>