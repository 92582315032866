<template>
    <div class="bg-white rounded-sm text-gray mx-2 my-3 px-2">
        <b-row>
            <b-col>
                <router-view></router-view>
            </b-col>
        </b-row>
    </div>

</template>

<script>
export default {
    name: "BillingAndRevenue"
};
</script>

<style scoped>

</style>