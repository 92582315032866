<template>
    <div>
        <b-row class="my-1">
            <b-col>
                Name
                <b-form-input v-model="form.name"
                              placeholder="eg Bachelors " required>
                </b-form-input>
            </b-col>
        </b-row>

        <b-row class="my-1">
            <b-col>
                Professional Body
                <b-form-select
                        class="form-select"
                        v-model="form.professionalBodyId"
                >
                    <b-form-select-option v-for="(pb, index) in professional_bodies" :key="index" :value="pb.id">
                        {{ pb.name }}
                    </b-form-select-option>
                </b-form-select>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="8"></b-col>
            <b-col cols="4" class="float-right">
                <button @click="addBody" class="w-full bg-blue text-white px-2 py-1 rounded-sm">
                    Save
                    <b-spinner v-if="spinner" small></b-spinner>
                </button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "EditProfessionalQualification",
    data() {
        return {
            form: {
                name: null,
                professionalBodyId: null
            },
            spinner: false,
            professional_bodies: []
        }
    },

    props: {
        DraftData: {
            type: Object,
            required: true
        }
    },


    mounted() {
        this.fetchProfessionalBodies()
        let newVal = this.DraftData
        this.form.name = newVal.name
        this.form.professionalBodyId = newVal.professionalBodyId
        this.form.professionalQualificationId = newVal.id
    },

    methods: {
        ...mapActions([
            "updateProfessionalQualification", "fetchProfessionalQualifications", "fetchProfessionalBodies"
        ]),
        addBody() {
            this.spinner = true
            this.updateProfessionalQualification(this.form)
        }
    },
    computed: {
        ...mapGetters([
            "getProfessionalQualifications",
            "getProfessionalQualification",
            "getInsolvencyError",
            "getProfessionalBodies"
        ])
    },
    watch: {
        getProfessionalQualification() {
            this.spinner = false
            this.fetchProfessionalQualifications()
            this.successToast("Success", "Academic Qualification updated successfully");
        },
        getInsolvencyError() {
            this.spinner = false
            this.errorToast("Error", "An error occurred while creating department");
        },
        getProfessionalBodies(data) {
            this.professional_bodies = data.data
        }
    }

}
</script>

<style scoped>

</style>