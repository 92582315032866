<template>
    <div>
        <b-row class="my-3">
            <b-col>
                <b-form-input v-model="form.name"
                              placeholder="Country Name" required>
                </b-form-input>
            </b-col>
        </b-row>
        <b-row class="my-3">
            <b-col>
                <b-form-input v-model="form.code"
                              placeholder="Country code eg . UG, US" required>
                </b-form-input>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="8"></b-col>
            <b-col cols="4" class="float-right">
                <button @click="newCountry" class="w-full bg-blue text-white px-2 py-1 rounded-sm">
                    Save
                    <b-spinner v-if="spinner" small></b-spinner>
                </button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "NewCountry",
    data() {
        return {
            form: {
                name: null,
                code: null
            },
            spinner: false,
        }
    },
    methods: {
        ...mapActions([
            "createCountry", "fetchCountries"
        ]),
        newCountry() {
            this.spinner = true;
            this.createCountry(this.form);
        }
    },
    computed: {
        ...mapGetters([
            "getCountry",
            "getCountries",
            "getCountryError"
        ])
    },
    watch: {
        getCountry() {
            this.spinner = false
            this.successToast("Success", "Country created successfully");
            this.fetchCountries();
        },
        getCountryError() {
            this.spinner = false
            this.errorToast("Error", " creation failed");
        }
    }

}
</script>

<style scoped>

</style>