<template>

  <div style="background: #f0f2f5;" class="flex min-h-screen items-center">
    <div class="w-full">
      <b-row>
        <b-col cols="6" class="mx-auto">
          <router-view></router-view>
        </b-col>
      </b-row>
    </div>
  </div>

</template>

<script>
export default {
  name: "Auth"
}
</script>

<style scoped>

</style>