import {api} from "@/app/services/api.service";

const state = {
    professional_bodies: null,
    reasons: null,
    academic_qualifications: null,
    professional_qualifications: null,
    professional_body: null,
    reason: null,
    academic_qualification: null,
    professional_qualification: null,
    insolvency_error: null,
}

const getters = {
    getProfessionalBodies: state => state.professional_bodies,
    getReasons: state => state.reasons,
    getAcademicQualifications: state => state.academic_qualifications,
    getProfessionalQualifications: state => state.professional_qualifications,
    getInsolvencyError: state => state.insolvency_error,
    getProfessionalBody: state => state.professional_body,
    getReason: state => state.reason,
    getAcademicQualification: state => state.academic_qualification,
    getProfessionalQualification: state => state.professional_qualification,

}

const actions = {
    async fetchProfessionalBodies({commit}) {
        await api().get('configuration/professional-body/all')
            .then(response => {
                commit('setProfessionalBodies', response)
            })
            .catch(error => {
                commit('setInsolvencyError', error)
            })
    },
    async fetchReasons({commit}) {
        await api().get('configuration/insolvency-reasons')
            .then(response => {
                commit('setReasons', response)
            })
            .catch(error => {
                commit('setInsolvencyError', error)
            })
    },
    async fetchAcademicQualifications({commit}) {
        await api().get('configuration/academic-qualification/all')
            .then(response => {
                commit('setAcademicQualifications', response)
            })
            .catch(error => {
                commit('setInsolvencyError', error)
            })
    },
    async fetchProfessionalQualifications({commit}) {
        await api().get('configuration/professional-qualification/all')
            .then(response => {
                commit('setProfessionalQualifications', response)
            })
            .catch(error => {
                commit('setInsolvencyError', error)
            })
    },
    async createProfessionalBody({commit}, data) {
        await api().post('configuration/professional-body/create', data)
            .then(response => {
                commit('setProfessionalBody', response)
            })
            .catch(error => {
                commit('setInsolvencyError', error)
            })
    }, async createReason({commit}, data) {
        await api().post('configuration/insolvency-reasons', data)
            .then(response => {
                commit('setReason', response)
            })
            .catch(error => {
                commit('setInsolvencyError', error)
            })
    },
    async updateProfessionalBody({commit}, data) {
        await api().post('configuration/professional-body/update', data)
            .then(response => {
                commit('setProfessionalBody', response)
            })
            .catch(error => {
                commit('setInsolvencyError', error)
            })
    },
    async createAcademicQualification({commit}, data) {
        await api().post('configuration/academic-qualification/create', data)
            .then(response => {
                commit('setAcademicQualification', response)
            })
            .catch(error => {
                commit('setInsolvencyError', error)
            })
    },
    async updateAcademicQualification({commit}, data) {
        await api().post('configuration/academic-qualification/update', data)
            .then(response => {
                commit('setAcademicQualification', response)
            })
            .catch(error => {
                commit('setInsolvencyError', error)
            })
    },
    async createProfessionalQualification({commit}, data) {
        await api().post('configuration/professional-qualification/create', data)
            .then(response => {
                commit('setProfessionalQualification', response)
            })
            .catch(error => {
                commit('setInsolvencyError', error)
            })
    },
    async updateProfessionalQualification({commit}, data) {
        await api().post('configuration/professional-qualification/update', data)
            .then(response => {
                commit('setProfessionalQualification', response)
            })
            .catch(error => {
                commit('setInsolvencyError', error)
            })
    },
}

const mutations = {
    setProfessionalBodies: (state, payload) => {
        state.professional_bodies = payload.data;
    },
    setReasons: (state, payload) => {
        state.reasons = payload.data;
    },
    setAcademicQualifications: (state, payload) => {
        state.academic_qualifications = payload.data;
    },
    setProfessionalQualifications: (state, payload) => {
        state.professional_qualifications = payload.data;
    },
    setInsolvencyError: (state, error) => {
        state.insolvency_error = error.response.data;
    },
    setProfessionalBody: (state, payload) => {
        state.professional_body = payload.data;
    },
    setReason: (state, payload) => {
        state.reason = payload.data;
    },
    setAcademicQualification: (state, payload) => {
        state.academic_qualification = payload.data;
    },
    setProfessionalQualification: (state, payload) => {
        state.professional_qualification = payload.data;
    },

}


export default {
    state,
    getters,
    actions,
    mutations

}
