<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col cols="12" class="mt-3">
          <b-row>
            <span class="title">Third Party API Integrators</span>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-row>
            <b-col cols="12">
              <div class="flex h-full" style="cursor: pointer;">
                <div :class="[(currentRoute === 'mdas') ? 'menu-item-active' : 'menu-item']"
                     @click="select('mdas')">
                  <div class="flex mt-6">
                    <div style="font-size: 14px" class="mt-1">MDA Integrators</div>
                  </div>
                </div>
                <div :class="[(currentRoute === 'partners') ? 'menu-item-active' : 'menu-item']"
                     @click="select('partners')">
                  <div class="flex mt-6">
                    <div style="font-size: 14px" class="mt-1">Partner Integrators</div>
                  </div>
                </div>
                <div :class="[(currentRoute === 'settings') ? 'menu-item-active' : 'menu-item']"
                     @click="select('settings')">
                  <div class="flex mt-6">
                    <div style="font-size: 14px" class="mt-1">Settings</div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <MDAIntegrators v-if="currentRoute === 'mdas'"/>
              <PartnerIntegrators v-if="currentRoute === 'partners'"/>
              <IntegrationSettings v-if="currentRoute === 'settings'"/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import MDAIntegrators from "@/views/home/services/integrations/api/MDAIntegrators.vue";
import PartnerIntegrators from "@/views/home/services/integrations/api/PartnerIntegrators.vue";
import IntegrationSettings from "@/views/home/services/integrations/api/IntegrationSettings.vue";

export default {
  name: "APIIntegrators",
  components: { IntegrationSettings, PartnerIntegrators, MDAIntegrators },
  data() {
    return {
      currentRoute: "mdas"
    };
  },
  methods: {
    select(route) {
      this.currentRoute = route;
    }
  }
};
</script>

<style scoped>

</style>