<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col cols="4" class="mx-auto my-5">
          <b-form-input
              class="text-gray text-sm noFocus mx-2 my-2"
              style="padding:6px 5px;"
              v-model="form.brn"
              type="text"
              placeholder="BRN"
              required>
          </b-form-input>
          <b-form-textarea
              class="text-gray text-sm noFocus mx-2 my-2"
              style="padding:6px 5px;"
              v-model="form.rationale"
              type="text"
              placeholder="Rationale"
              required>
          </b-form-textarea>

          <b-form-group>
            <b-form-select
                v-model="form.target"
                class="form-select noFocus text-gray mx-2 my-2 text-sm">
              <b-select-option :value="null">--Target Status--</b-select-option>
              <b-select-option value="FORM_18">Form 18</b-select-option>
              <b-select-option value="FORM_20">Form 20</b-select-option>
              <b-select-option value="FORM_24">Form 24</b-select-option>
              <b-select-option value="FORM_25">Form 25</b-select-option>
              <b-select-option value="FORM_26">Form 26</b-select-option>
            </b-form-select>
          </b-form-group>

          <p class="px-2 text-sm mt-2">Is Compliant?</p>
          <b-form-group>
            <b-form-select
                v-model="form.isCompliant"
                class="form-select noFocus text-gray mx-2 my-2 text-sm">
              <b-select-option :value="null">--Select Compliance--</b-select-option>
              <b-select-option :value="true">TRUE</b-select-option>
              <b-select-option :value="false">FALSE</b-select-option>
            </b-form-select>
          </b-form-group>

          <button class="bg-blue px-2 py-1 my-4 rounded-sm text-white mx-1 w-full"
                  @click="onSubmit">
            Submit <b-spinner small v-if="loading"></b-spinner>
          </button>

        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "FormComplianceManager",
  data() {
    return {
      loading: false,
      form: {
        brn: null,
        rationale: null,
        isCompliant: false,
        target: null
      },
      rows: {
        odd: "border-t border-grey hover:bg-header_grey",
        even: "border-t border-grey bg-header_grey"
      },
    }
  },
  methods: {
    ...mapActions(["updateFormCompliance"]),
    onSubmit() {
      this.loading = true
      this.updateFormCompliance(this.form)
    },
    resetForm(){
      this.form = {
        brn:null,
        rationale: null,
        isCompliant: false,
        target: null
      }
    }
  },
  computed: {
    ...mapGetters(["getFormComplianceStatusChange", "getFormComplianceStatusError"])
  },
  watch: {
    selectedYear: {
      handler(year) {
        this.form.years.push(year)
      },
      deep: true
    },
    getFormComplianceStatusChange(){
      this.loading = false
      this.successToast("Success", "Company annual returns status updated for form selected")
      this.resetForm()
    },
    getAnnualReturnsStatusError(data){
      this.loading = false
      this.errorToast("Error", JSON.stringify(data.message))
    }
  }
};
</script>

<style scoped>

.paginate-details {
  @apply px-3 py-2 bg-white my-2 text-sm text-gray;
}

.paginate-btn {
  @apply px-3 py-2 bg-white border border-white my-2 text-sm text-blue w-full;
}

.paginate-btn:hover {
  @apply border border-blue text-blue rounded-sm;
}

.inert-btn {
  @apply px-3 py-2 bg-white my-2 text-sm text-grey w-full;
}

.waiting {
  @apply flex text-gray;
}

.normal {
  @apply flex text-blue;
}

.negative {
  @apply flex text-red-light;
}

.stat-count {
  display: inline;
  width: 15%;
}
</style>