<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col cols="4" class="mx-auto my-5">
          <b-form-input
              class="text-gray text-sm noFocus mx-2 my-2"
              style="padding:6px 5px;"
              v-model="form.brn"
              type="text"
              placeholder="BRN"
              required>
          </b-form-input>
          <b-form-textarea
              class="text-gray text-sm noFocus mx-2 my-2"
              style="padding:6px 5px;"
              v-model="form.rationale"
              type="text"
              placeholder="Rationale"
              required>
          </b-form-textarea>
          <b-form-group>
            <b-form-select
                v-model="selectedYear"
                class="form-select noFocus text-gray mx-2 my-2 text-sm"
                :options="years()">
            </b-form-select>
          </b-form-group>

          <p v-if="form.years.length > 0" class="bg-header_grey rounded-sm p-3">
              <span v-for="(y, idx) in form.years" :key="idx" class="px-4 py-1 mx-1 border border-blue rounded-sm">
                {{ y }} <i class="fa fa-times hover:text-blue-dark" @click="removeYear(idx)"></i>
              </span>
          </p>

          <b-form-group>
            <b-form-select
                v-model="form.targetStatus"
                class="form-select noFocus text-gray mx-2 my-2 text-sm">
              <b-select-option :value="null">--Target Status--</b-select-option>
              <b-select-option value="FILED">Filed</b-select-option>
              <b-select-option value="NOT_FILED">Not Filed</b-select-option>
              <b-select-option value="OVERDUE">Overdue</b-select-option>
            </b-form-select>
          </b-form-group>

          <button class="bg-blue px-2 py-1 my-4 rounded-sm text-white mx-1 w-full"
                  @click="onSubmit">
            Submit <b-spinner small v-if="loading"></b-spinner>
          </button>

        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {years} from "@/views/home/commons/years";

export default {
  name: "AnnualReturnsService",
  data() {
    return {
      loading: false,
      selectedYear: null,
      form: {
        brn: null,
        rationale: null,
        years: [],
        targetStatus: null
      },
      rows: {
        odd: "border-t border-grey hover:bg-header_grey",
        even: "border-t border-grey bg-header_grey"
      },
    }
  },
  methods: {
    ...mapActions(["updateAnnualReturnStatus"]),
    years() {
      return years
    },
    onSubmit() {
      this.loading = true
      this.updateAnnualReturnStatus(this.form)
    },
    removeYear(index) {
      this.form.years.splice(index, 1)
    },
    resetForm(){
      this.form = {
        brn:null,
        rationale: null,
        years: [],
        targetStatus: null
      }
    }
  },
  computed: {
    ...mapGetters(["getAnnualReturnsStatusChange", "getAnnualReturnsStatusError"])
  },
  watch: {
    selectedYear: {
      handler(year) {
        this.form.years.push(year)
      },
      deep: true
    },
    getAnnualReturnsStatusChange(){
      this.loading = false
      this.successToast("Success", "Company annual returns status updated for years selected")
      this.resetForm()
    },
    getAnnualReturnsStatusError(data){
      this.loading = false
      this.errorToast("Error", JSON.stringify(data.message))
    }
  }
};
</script>

<style scoped>

.paginate-details {
  @apply px-3 py-2 bg-white my-2 text-sm text-gray;
}

.paginate-btn {
  @apply px-3 py-2 bg-white border border-white my-2 text-sm text-blue w-full;
}

.paginate-btn:hover {
  @apply border border-blue text-blue rounded-sm;
}

.inert-btn {
  @apply px-3 py-2 bg-white my-2 text-sm text-grey w-full;
}

.waiting {
  @apply flex text-gray;
}

.normal {
  @apply flex text-blue;
}

.negative {
  @apply flex text-red-light;
}

.stat-count {
  display: inline;
  width: 15%;
}
</style>