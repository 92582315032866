<template>
  <b-row>
    <b-col>
      <b-row class="p-3 mt-4">
        <b-col cols="2">
          <p @click="select('library')" :class="[(currentRoute === 'library') ? 'border-b border-r border-t border-blue text-sm cursor-pointer font-bold py-2 text-blue' : 'text-sm cursor-pointer py-2 hover:text-blue']">Word Library</p>
          <p @click="select('types')" :class="[(currentRoute === 'types') ? 'border-b border-r border-t border-blue text-sm cursor-pointer font-bold py-2 text-blue' : 'text-sm cursor-pointer py-2 hover:text-blue']">Dictionary Types</p>
        </b-col>
        <b-col cols="10">
          <b-row>
            <b-col>
              <WordLibrary v-if="currentRoute === 'library'" />
              <DictionaryTypes v-if="currentRoute === 'types'"/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import DictionaryTypes from "@/views/home/services/configurations/dictionary/DictionariyTypes";
import WordLibrary from "@/views/home/services/configurations/dictionary/WordLibrary";
export default {
  name: "Dictionaries",
  components: { WordLibrary, DictionaryTypes },
  data() {
    return {
      currentRoute: "library"
    };
  },
  methods: {
    select(route) {
      this.currentRoute = route;
    }
  }
};
</script>

<style scoped>

</style>