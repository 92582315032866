<template>
    <b-row>
        <b-col>
            <b-row>
                <b-modal size="lg" title="Add Account" ref="addAccountForm" hide-footer>
                    <b-col cols="11" class="mx-auto">
                        <b-row>
                            <b-col>
                                <Label>Company details</Label>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-input v-model="form.name" class="my-2" type="text"
                                              placeholder="Organisation Name"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-input v-model="form.email" class="my-2" type="text"
                                              placeholder="Official Email Address"></b-form-input>
                            </b-col>
                            <b-col>
                                <b-form-input v-model="form.phone" class="my-2" type="text"
                                              placeholder="Official Phone Number"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <Label>Contact Person</Label>
                            </b-col>
                        </b-row>

                        <b-row>
<!--                            <b-col>
                                <b-form-input v-model="form.contactNin" class="my-2" type="text"
                                              placeholder="NIN"></b-form-input>
                                <b-form-input class="my-2" v-model="form.contactEmail" type="text"
                                              placeholder="Email Address"></b-form-input>
                                <label class="text-sm">Access Expiry Date</label>
                                <b-form-input v-model="form.expiresOn" class="mb-2" type="date"></b-form-input>
                            </b-col>-->
                            <b-col>
                                <b-form-input v-model="form.contactName" class="my-2" type="text"
                                              placeholder="Contact Person Name"></b-form-input>
                                <b-form-input v-model="form.contactPhone" class="my-2" type="text"
                                              placeholder="Phone Number"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row class="my-2">
                            <b-col class="mx-auto border border-gray rounded">
                                <label class="text-sm">Authorizing Letter</label>
                                <b-form-file v-model="form.letterAttachment" class="mb-2" type="file"></b-form-file>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="10"></b-col>
                            <b-col cols="2">
                                <button class="w-full bg-blue text-white px-2 py-1 rounded-sm" @click="save">
                                    <i class="fa fa-save mx-1"></i> Save
                                    <b-spinner v-if="loading"></b-spinner>
                                </button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-modal>


                <b-modal size="lg" title="Edit Account" ref="editAccountForm" hide-footer>
                    <b-col cols="11" class="mx-auto">
                        <b-row>
                            <b-col>
                                <b-form-input class="my-2" :disabled="true" type="text" v-model="editForm.name"
                                              placeholder="Name"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-input class="my-2" type="text" v-model="editForm.email"
                                              placeholder="Email Address"></b-form-input>
                            </b-col>
                            <b-col>
                                <b-form-input class="my-2" type="text" v-model="editForm.phoneNumber"
                                              placeholder="Phone Number"></b-form-input>
                            </b-col>
                        </b-row>


                        <b-row>
                            <b-col cols="10"></b-col>
                            <b-col cols="2">
                                <button class="w-full bg-blue text-white px-2 py-1 rounded-md"
                                        @click="updateUserDetails">
                                    <i class="fa fa-save mx-1"></i> Save changes
                                </button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-modal>

            </b-row>
            <b-modal title="Account Roles" ref="accountRoles" hide-footer>
                <b-row>
                    <b-col class="bg-header_grey rounded px-2 py-2">
                        <p v-for="(list, index) in userRoles" :key="index" class="text-sm">
                            <label v-if="list.name !=='ROLE_USER'">
                                -
                                {{ list.name.replaceAll("_", " ") }}
                                <i class="fa fa-times-circle text-red mx-1" style="cursor: pointer"
                                   title="Click to remove  role from this user "
                                   @click="removeRole(list.roleId, selectedAccountId)"></i>
                            </label>
                        </p>
                    </b-col>
                </b-row>
            </b-modal>

            <b-modal :title="account.name +' Account Credit Usage'" ref="accountSummary" size="xl" hide-footer>


                <b-row>
                    <b-col class="bg-header_grey rounded px-2 py-2">
                        <p>
                            <label class="text-lg float-end">
                                <label class="text-sm">
                                    Account Balance : <span
                                        class="text-blue text-lg">UGX {{ accountBalance.toLocaleString() }}</span>
                                </label>
                            </label>


                        </p>
                    </b-col>
                </b-row>
                <b-row>
                    <label class="text-lg">Recent api calls </label>
                    <b-col class="bg-header_grey rounded px-2 py-2">
                        <table class="border-1 rounded" width="100%">
                            <thead class="bg-gray_title">
                            <tr>
                                <td>#</td>
                                <td>
                                    <label>Entity</label>
                                </td>
                                <td>
                                    <label>Endpoint</label>
                                </td>
                                <td>
                                    Response
                                </td>
                                <td>
                                    Feedback
                                </td>

                            </tr>
                            </thead>

                            <tbody>

                            <tr class="border-1 border-gray" v-for="(log, idx) in logs" :key="idx">
                                <td>
                                    <label>{{ idx + 1 }}</label>
                                </td>
                                <td>
                                    <label class="text-blue text-lg">{{ JSON.parse(log.apiRequest).brn }}</label>
                                </td>
                                <td>
                                    <label>{{ log.endPoint }}</label>
                                </td>
                                <td>

                                    <span v-if="showIndex===idx"><pre>{{ JSON.parse(log.apiResponse).data }}</pre>
                                    <b-badge style="cursor: pointer" @click="hideApiDetails()" class="bg-blue mx-2"
                                             v-if="log.responseGiven"> <i class="fa fa-eye bg-blue"></i> Hide</b-badge>
                                        </span>
                                    <b-badge style="cursor: pointer" v-else @click="showApiDetails(idx)"
                                             class="bg-green mx-2"><i class="fa fa-eye bg-green"></i> Show
                                    </b-badge>
                                </td>
                                <td>
                                    <b-badge class="bg-green mx-2" v-if="log.responseGiven">Served</b-badge>
                                    <b-badge class="bg-red mx-2" v-else>Failed</b-badge>

                                </td>
                            </tr>
                            </tbody>

                        </table>

                    </b-col>
                </b-row>
            </b-modal>
            <b-row>
                <b-modal title="Assign Roles" ref="assignRolesForm" hide-footer>
                    <b-col cols="11" class="mx-auto">
                        <b-row>
                            <b-col>
                                <form>
                                    <b-form-select v-model="assignForm.userId" class="form-select my-2"
                                                   :options="usersList"></b-form-select>
                                    <b-form-select v-model="assignForm.roleId" class="form-select my-2"
                                                   :options="rolesDataList"></b-form-select>
                                    <button
                                            class="bg-blue text-white px-2 py-1 rounded-sm hover:bg-blue-dark"
                                            type="button" @click="assignNewRole">
                                        Assign Role
                                        <b-spinner v-if="spinner" small></b-spinner>
                                    </button>
                                </form>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-modal>
            </b-row>
            <b-row>
                <b-modal size="lg" title="Grant access areas" ref="apiMode" hide-footer>
                    <b-row>
                        <b-col>
                            <ApiMode :user="selectedUser"></ApiMode>
                        </b-col>
                    </b-row>
                </b-modal>

            </b-row>

            <b-row>
                <b-modal size="lg" title="Grant access areas" ref="grantAccessAreas" hide-footer>
                    <b-col cols="11" class="mx-auto">


                        <b-row>
                            <b-col>
                                <label>Select Endpoints to grant this user </label>

                                <b-col cols="12" v-for="(endpoint, idx) in endpoints" :key="idx">
                                    <div class="text-lowercase hover:text-blue cursor-pointer flex">
                                        <b-form-checkbox
                                                v-model="accessLevelsForm.endPoints"
                                                :id="idx+'l'"
                                                color="#24a4f6"
                                                :name="cleanUnderscoreString(endpoint.endPoint)"
                                                :value="endpoint.id">
                                            <span class="ml-2 cursor-pointer">{{
                                                    cleanUnderscoreString(endpoint.endPoint)
                                                }}</span>
                                        </b-form-checkbox>

                                    </div>
                                </b-col>

                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="10"></b-col>
                            <b-col cols="2">
                                <button class="w-full bg-blue text-white px-2 py-1 rounded-sm"
                                        @click="saveAccessAreasGranted">
                                    <i class="fa fa-save mx-1"></i> Save
                                    <b-spinner v-if="loading" small/>
                                </button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-modal>

                <b-modal size="lg" title="Edit Account" ref="editAccountForm" hide-footer>
                    <b-col cols="11" class="mx-auto">
                        <b-row>
                            <b-col>
                                <b-form-input class="my-2" :disabled="true" type="text" v-model="editForm.name"
                                              placeholder="Name"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-input class="my-2" type="text" v-model="editForm.email"
                                              placeholder="Email Address"></b-form-input>
                            </b-col>
                            <b-col>
                                <b-form-input class="my-2" type="text" v-model="editForm.phoneNumber"
                                              placeholder="Phone Number"></b-form-input>
                            </b-col>
                        </b-row>


                        <b-row>
                            <b-col cols="10"></b-col>
                            <b-col cols="2">
                                <button class="w-full bg-blue text-white px-2 py-1 rounded-md"
                                        @click="updateUserDetails">
                                    <i class="fa fa-save mx-1"></i> Save changes
                                </button>
                            </b-col>
                        </b-row>


                        <b-row>
                            <!--                            <b-col>
                                                            <b-form-input v-model="form.contactNin" class="my-2" type="text"
                                                                          placeholder="NIN"></b-form-input>
                                                            <b-form-input class="my-2" v-model="form.contactEmail" type="text"
                                                                          placeholder="Email Address"></b-form-input>
                                                            <label class="text-sm">Access Expiry Date</label>
                                                            <b-form-input v-model="form.expiresOn" class="mb-2" type="date"></b-form-input>
                                                        </b-col>-->
                            <b-col>
                                <label class="uppercase">Update contact details </label>

                                <b-form-input v-model="contactDetails.contactName" class="my-2" type="text"
                                              placeholder="Contact Person Name"></b-form-input>
                                <b-form-input v-model="contactDetails.contactPhone" class="my-2" type="text"
                                              placeholder="Phone Number"></b-form-input>
                                <b-form-input class="my-2" type="text" v-model="contactDetails.email"
                                              placeholder="Email Address"></b-form-input>
                                >
                            </b-col>
                        </b-row>


                        <b-row>
                            <b-col cols="10"></b-col>
                            <b-col cols="2">
                                <button class="w-full bg-blue text-white px-2 py-1 rounded-md"
                                        @click="updateContacts">
                                    <i class="fa fa-save mx-1"></i> Update contacts
                                </button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-modal>

            </b-row>

            <b-row class="py-3">
                <b-col cols="2">
                    <b-form-input
                            class="text-gray text-sm noFocus ml-1"
                            style="margin-top: 1px; padding:6px 5px;"
                            v-model="name"
                            type="text"
                            placeholder="Search by Name"
                            required
                            v-on:keyup.enter="fetch(1)">
                    </b-form-input>
                </b-col>

                <b-col cols="2">
                    <b-form-input
                            class="text-gray text-sm noFocus ml-1"
                            style="margin-top: 1px; padding:6px 5px;"
                            v-model="email"
                            type="text"
                            placeholder="Search by Email"
                            required
                            v-on:keyup.enter="fetch(1)">
                    </b-form-input>
                </b-col>

                <b-col cols="2">
                    <b-form-input
                            class="text-gray text-sm noFocus ml-1"
                            style="margin-top: 1px; padding:6px 5px;"
                            v-model="phone"
                            type="text"
                            placeholder="Search by phone"
                            required
                            v-on:keyup.enter="fetch(1)">
                    </b-form-input>
                </b-col>

                <b-col cols="2">
                    <b-form-select
                            class="form-select text-sm noFocus text-gray"
                            v-model="status"
                            @change="fetch(1)"
                            v-on:keyup.enter="fetch">
                        <b-select-option :value="null">--Filter by Status--</b-select-option>
                        <b-select-option :value="null">All</b-select-option>
                        <b-select-option value="ACTIVE">Active</b-select-option>
                        <b-select-option value="INACTIVE">Inactive</b-select-option>
                    </b-form-select>
                </b-col>


                <b-col cols="2">
                    <button class="w-full bg-blue text-white px-2 py-1 rounded-sm" @click="openForm">
                        <i class="fa fa-plus mx-1"></i> Add Account
                    </button>
                </b-col>
                <b-col cols="2">
                    <button class="w-full bg-blue text-white px-2 py-1 rounded-sm" @click="openAssignRolesForm">
                        <i class="fa fa-pencil mx-1"></i> Assign Roles
                    </button>
                </b-col>

            </b-row>

            <div class="mx-auto">
                <b-progress value="100" v-if="loading" variant="#007ac5" height="0.2rem" animated></b-progress>
            </div>
            <b-row>
                <b-col cols="12">
                    <table class="min-w-full leading-normal">
                        <thead>
                        <tr class="border-b border-t border-grey">
                            <th class="t-header">Name</th>
                            <th class="t-header">Official contacts</th>
                            <th class="t-header">Roles</th>
                            <th class="t-header">Contact Person</th>
                            <th class="t-header">Date Created</th>
                            <th class="t-header">Status</th>
                            <th class="t-header">API</th>
                            <th class="t-header">Action</th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(account, idx) in accounts" :key="idx">
                            <td class="t-body font-bold text-uppercase">
                                <span
                                ><a href="#" @click="showAccountSummary(account,idx)">{{ account.name }}</a> <b-spinner
                                        v-if="indexLoader===idx"></b-spinner> </span>

                            </td>
                            <td class="t-body">
                                <i class="fa fa-check-circle text-green mx-1" v-if="account.emailVerified"></i>
                                <i class="fa fa-times text-orange mx-1" v-else></i>
                                {{ account.email }}

                                <span>
                  <i class="fa fa-check-circle text-green mx-1" v-if="account.phoneVerified"></i>
                  <i class="fa fa-times text-orange mx-1" v-else></i>
                  {{ account.phoneNumber }}
                </span>

                            </td>

                            <td class="t-body">

                                <span v-if="account.userRoles.length === 2">
                                <span v-for="(list, index) in account.userRoles" :key="index" class="text-xs">
                                  <label v-if="list.name !=='ROLE_USER'">
                                    {{ list.name.replaceAll("_", " ") }}
                                    <i class="fa fa-times-circle text-red mx-1" style="cursor: pointer"
                                       title="Click to remove  role from this user "
                                       @click="removeRole(list.roleId, account.id)"></i>
                                  </label>
                               </span>
                              </span>
                                <span v-else @click="showUserRoles(account.userRoles, account.id)"
                                      class="text-xs italic text-blue cursor-pointer hover:underline">
                                {{ account.userRoles.length - 1 }} ROLES
                              </span>

                            </td>

                            <td class="t-body">
                                <p v-if="account.contactPersons.length > 0">{{ account.contactPersons[0].name }}</p>
                                <p v-if="account.contactPersons.length > 0">{{ account.contactPersons[0].phone }}</p>
                            </td>

                            <td class="t-body">
                                <span>{{ formatDate(account.createdAt) }}</span>
                            </td>


                            <td class="t-body">
                                <span class="text-green" v-if="account.enabled">Active</span>
                                <span class="text-red" v-else>Inactive</span>
                            </td>


                            <td class="t-body font-bold">
                                 <span class="text-green" v-if="!account.apiAccess">
                                    <button
                                            v-if="canViewMenuItem('can_update_account_status')"
                                            @click="grantApiAccess(account.id)"
                                            class="bg-blue rounded-sm px-2 py-1 text-sm text-white">
                                        <i class="fa fa-check-circle mx-1"></i> Grant Access</button>
                                </span>

                                <div v-else>

                                    <!--                                    <i class="fa fa-check-circle text-green mx-1"></i>
                                                                        <button @click="openAccessAreasForm(account.id)"
                                                                                class="bg-blue rounded-sm px-2 py-1 text-xs text-white">
                                                                            <i class="fa fa-pencil mx-1"></i> Manage
                                                                        </button>-->

                                    <button
                                            v-if="canViewMenuItem('can_update_account_status')"
                                            @click="openAPiModeForm(account)"
                                            class="bg-blue rounded-sm px-2 py-1 text-xs text-white mx-2">
                                        <i class="fa fa-pencil mx-1"></i> API Mode
                                    </button>


                                    <button
                                            v-if="canViewMenuItem('can_revoke_role')"
                                            @click="revokeAccess(account.id)"
                                            class="bg-red rounded-sm px-2 py-1 text-xs text-white">
                                        <i class="fa fa-times mx-2"></i> Revoke
                                    </button>
                                </div>
                            </td>
                            <td class="t-body text-center">
                                <b-row>
                                    <b-col>


                                        <i class="fa fa-times mr-1 text-red"
                                           v-if="account.enabled && canViewMenuItem('can_update_account_status')"
                                           style="cursor: pointer"
                                           @click="changeStatus(account.id,'INACTIVE')"
                                           title="Click to disable account"/>
                                        <i class="fa fa-check mr-1 text-green"
                                           style="cursor: pointer"
                                           @click="changeStatus(account.id,'ACTIVE')"
                                           title="Click to activate account"
                                           v-else-if="canViewMenuItem('can_update_account_status')"/>
                                        <i
                                                v-if="canViewMenuItem('can_update_account_status')"
                                                class="fa fa-pen-square mr-1 text-gray"
                                           style="cursor: pointer"
                                           title="Click to edit profile"
                                           @click="openEditForm(account)"/>

                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <i v-if="canViewMenuItem('can_resend_account_emails')"
                                           class="fa fa-reply mr-1 text-blue" @click="resendActivation(account.id)"/>
                                    </b-col>
                                </b-row>
                            </td>
                        </tr>
                        <tr>
                            <td v-if="accounts.length === 0" colspan="12" class="bg-inertgrey text-center py-1">
                                <span class="text-sm">No results found</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </b-col>
            </b-row>
            <b-row class="mx-1" v-if="pagination.totalPages > 1">
                <b-col cols="1">
                    <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
                </b-col>
                <b-col cols="1">
                    <b-row>
                        <b-col cols="12">
                            <p class="paginate-details text-center">
                                <b-badge class="bg-blue text-white">
                                    {{ (currentPage + 1) }}/{{ pagination.totalPages }}
                                </b-badge>
                            </p>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="1">
                    <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">
                        Next
                    </button>
                </b-col>
                <b-col class="9"></b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ApiMode from "@/views/home/services/accounts/thirdParty/ApiMode.vue";

export default {
    name: "PrivateOrganisationAccounts",
    components: {ApiMode},
    data() {
        return {
            loading: false,
            currentPage: 1,
            limit: 10,
            accounts: [],
            pagination: {
                totalElements: 0,
                totalPages: 0
            },
            rows: {
                odd: "border-t border-grey hover:bg-header_grey",
                even: "border-t border-grey bg-header_grey"
            },
            search: "",
            status: null,
            name: null,
            email: null,
            phone: null,
            type: null,
            formData: null,
            form: {
                partnerType: "PRIVATE_ORGANIZATION",
                name: null,
                email: null,
                phone: null,
                contactName: null,
                contactEmail: null,
                contactPhone: null,
                contactNin: null,
                letterAttachment: null,
                expiresOn: null,

            },
            editForm: {
                userId: null,
                name: null,
                email: null,
                phoneNumber: null,
            },
            contactDetails: {
                partnerId: null,
                contactName: null,
                contactEmail: null,
                contactPhone: null
            },
            endpoints: [],
            accessLevelsForm: {
                userId: null,
                endPoints: [],
                apiMode: "SANDBOX"
            },
            selectedUser: null,
            assignForm: {
                userId: null,
                roleId: null,
            },
            usersList: [{text: "Select User", id: null}],
            rolesDataList: [{text: "Select Role", id: null, disabled: true}],
            spinner: false,
            userRoles: [],
            indexLoader: "",
            account: {},
            accountBalance: 0,
            logs: [],
            showIndex: null,
        }
    },
    mounted() {
        this.formData = new FormData();
        this.fetch(1)
        this.fetchRoles()
    },
    methods: {
        ...mapActions(["fetchUsers", "fetchPartners", "doResendActivationLink", "doRegisterPartner", "changeAccountStatus", "updateStaffContacts",
            "generateAccessKeys", "fetchAllEndpoints", "doAddAccessAreas", "doRevokeAccess", "fetchRoles", "assignRole",
            "removeUserRole", "updateAccountContactDetails",
        ]),
        fetch(startPage = null) {
            this.loading = true
            let filters = {"accountType": "PRIVATE_ORGANIZATION"}

            if (this.status) {
                filters.accountStatus = this.status
            }
            if (this.name) {
                filters.name = this.name;
            }

            if (this.email) {
                filters.email = this.email;
            }

            if (this.phone) {
                filters.phone = this.phone;
            }


            filters.page = startPage ? startPage : this.currentPage
            filters.perPage = this.limit


            this.fetchUsers(this.objectToQueryParams(filters))
            this.fetchAllEndpoints();
        },
        next() {
            if (this.currentPage < this.pagination.totalPages) {
                this.currentPage++
                this.fetch()
            }
        },
        previous() {
            if (this.currentPage > 1) {
                this.currentPage--
                this.fetch()
            } else {
                this.warningToast("Observation", "No more data to show")
            }
        },
        save() {
            this.loading = true
            this.registerInternalUser()
        },
        resendActivation(userId) {
            this.doResendActivationLink({userId})

        },
        openForm() {
            this.$refs.addAccountForm.show()
        },
        closeForm() {
            this.$refs.addAccountForm.hide()
        },
        openEditForm(account) {

            this.editForm = {
                userId: account.id,
                name: account.name,
                email: account.email,
                phoneNumber: account.phoneNumber,

            };

            this.contactDetails = {
                partnerId: account.contactPersons[0].contactId,
                contactName: account.contactPersons[0].name,
                contactEmail: account.contactPersons[0].email,
                contactPhone: account.contactPersons[0].phone
            }
            this.$refs.editAccountForm.show();
        },
        updateUserDetails() {
            let data = {
                "email": this.editForm.email,
                "phone": this.editForm.phoneNumber,
                "userId": this.editForm.userId
            }

            this.updateStaffContacts(data)


        },
        updateContacts() {

            this.updateAccountContactDetails(this.contactDetails)


        },
        registerInternalUser() {
            this.registerSpinner = true;

            const formData = new FormData();
            formData.set("email", this.form.email);
            formData.set("phone", this.form.phone);
            formData.set("name", this.form.name);
            formData.set("contactName", this.form.contactName);
            formData.set("contactEmail", this.form.contactEmail);
            formData.set("contactNin", this.form.contactNin);
            formData.set("contactPhone", this.form.contactPhone);
            formData.set("partnerType", this.form.partnerType);
            formData.set("expiresOn", this.form.expiresOn);

            if (this.form.letterAttachment) {
                formData.set("letterAttachment", this.form.letterAttachment);
            }

            this.doRegisterPartner(formData)

        },
        async changeStatus(userId, status) {
            this.showConfirmDialog().then(value => {
                if (value) {
                    this.changeAccountStatus({userId, status})
                }
            })

        },
        async grantApiAccess(userId) {
            this.loading = true
            this.accessLevelsForm.userId = userId;
            let payload = {
                "userId": userId,
            }
            await this.generateAccessKeys(payload)
// sleep for 2 seconds
            await new Promise(r => setTimeout(r, 1000));

            this.fetch()
            this.loading = false

        },
        saveAccessAreasGranted() {
            this.doAddAccessAreas(this.accessLevelsForm)

        },
        openAccessAreasForm(userId = null) {
            if (userId) {
                this.accessLevelsForm.userId = userId
            }
            this.$refs.grantAccessAreas.show()
        },
        openAPiModeForm(user) {
            this.selectedUser = user
            this.$refs.apiMode.show()
        },
        closeAccessAreasForm() {
            this.$refs.grantAccessAreas.hide()
        },
        revokeAccess(userId) {
            this.loading = true
            let payload = {
                userId
            }
            this.doRevokeAccess(payload)
        },
        openAssignRolesForm() {
            this.$refs.assignRolesForm.show();
        },
        assignNewRole() {
            this.spinner = true
            this.errorMessage = null
            let data = {
                userId: this.assignForm.userId,
                roleIds: [this.assignForm.roleId]
            }
            this.assignRole(data)
        },
        filterRolesListData(dataList) {
            let dataObj = [{text: "Select Role", id: null, disabled: true}];
            let dataJson = {};
            for (let i = 0; i < dataList.length; i++) {
                dataJson = {
                    text: dataList[i].name,
                    value: dataList[i].roleId
                };
                dataObj.push(dataJson);
            }
            return dataObj;
        },
        filterUserList(dataList) {
            let dataObj = [{text: "Select User", id: null}];
            let dataJson = {};
            for (let i = 0; i < dataList.length; i++) {
                let userData = dataList[i]
                dataJson = {
                    text: userData.name,
                    value: userData.id
                };
                dataObj.push(dataJson);
            }
            return dataObj;
        },
        showUserRoles(roles, id) {
            this.userRoles = roles;
            this.selectedAccountId = id;
            this.$refs.accountRoles.show();
        },
        async removeRole(roleId, userId) {
            this.showConfirmDialog().then(value => {
                if (value) {
                    let payload = {
                        roleIds: [
                            roleId
                        ],
                        userId: userId
                    };
                    this.removeUserRole(payload);
                }

            });


        },
        showAccountSummary(account) {
            //store the account object in sessionstorage an route to the account summary page
            sessionStorage.setItem("account", JSON.stringify(account));
            let r = this.$router.resolve({
                name: "account-summary",
            });
            window.location.assign(r.href)
        },
        showApiDetails(index) {
            this.showIndex = index
        },
        hideApiDetails() {
            this.showIndex = null
        },
        canViewMenuItem(permission) {
            return this.$can(permission, "general") === true;
        }
    },
    computed: {
        ...mapGetters(["getOfficerAccounts", "getRegistrationData", "getRegisterError", "getReactivationSuccess", "getUserError", "getReactivationError",
            "getGeneratedAccessKey", "getEndpoints", "getAccessLevelSuccess", "getAccessKeyError",
            "getAccessLevelError", "getRevokeAccess", "getNewRole", "getRolesList", "getRoleRemovalSuccess", "getAccountSummary", "getApiLogs"
        ])
    },
    watch: {
        getOfficerAccounts(data) {
            this.loading = false
            this.accounts = data
            this.usersList = this.filterUserList(data)
        },
        getRegistrationData(response) {

            this.fetch();
            this.closeForm();
            this.successToast("Success", response.message)
            this.loading = false

        },

        getRegisterError(error) {
            this.loading = false
            this.errorToast(error.message)
        },

        getUserError(error) {
            this.loading = false
            this.errorToast(error.message)
        },

        getReactivationSuccess(data) {
            this.successToast("Success", data.message);
            this.loading = true;
            this.fetch();
            this.$refs.addAccountForm.hide()
            this.$refs.editAccountForm.hide();

        },
        getReactivationError(error) {
            if (error) {
                this.errorToast(error.message, error.message);
            }
        },
        getGeneratedAccessKey() {
            this.loading = false;
            this.successToast("Success", "Request was processed successfully");
            this.openAccessAreasForm()
            this.fetch();
        },
        getEndpoints(data) {
            this.endpoints = data.data;
        },
        getAccessLevelSuccess() {
            this.loading = false;
            this.successToast("Success", "Access level granted successfully");
            this.fetch();
            this.closeAccessAreasForm();
        },
        getAccessLevelError(error) {
            this.loading = false;
            this.errorToast(error.message);
        },
        getAccessKeyError(error) {
            this.loading = false;
            this.errorToast(error.message);
        },
        getRevokeAccess() {
            this.loading = false;
            this.successToast("Success", "Access revoked successfully");
            this.fetch();
        },
        getNewRole() {
            this.successToast("Success", "Role Assigned Successfully")
            this.spinner = false
            this.form.userId = null
            this.$refs.assignRolesForm.hide()
            this.$emit("finished", true)
            this.fetch()
        },
        getRolesList(rolesList) {
            if (rolesList) {
                this.rolesDataList = this.filterRolesListData(rolesList)
            }
        },
        getRoleRemovalSuccess() {
            this.successToast();
            this.loading = true;
            this.fetch();
            this.$refs.accountRoles.hide();
        },
        getAccountSummary(data) {
            this.indexLoader = false
            this.$refs.accountSummary.show()
            this.accountBalance = data.data.accountBalance
        },
        getApiLogs(data) {
            this.logs = data.data.content
        }

    }
};
</script>

<style scoped>
.paginate-details {
    @apply px-3 py-2 bg-white my-2 text-sm text-gray;
}

.paginate-btn {
    @apply px-3 py-2 bg-white border border-white my-2 text-sm text-blue w-full;
}

.paginate-btn:hover {
    @apply border border-blue text-blue rounded-sm;
}


.inert-btn {
    @apply px-3 py-2 bg-white my-2 text-sm text-grey w-full;
}
</style>