<template>
  <div class="bg-white rounded-sm text-gray mx-2 my-3">
    <b-row>
      <b-modal ref="addDepartment" title="Add Department" hide-footer>
        <new-department />
      </b-modal>
    </b-row>
    <b-row>
      <b-col>
        <b-row>
          <b-col cols="10"></b-col>
          <b-col cols="2">
            <button class="w-full bg-blue text-white px-2 py-1 rounded-sm" @click="openForm"><i class="fa fa-plus"></i>
              Add Department
            </button>
          </b-col>
        </b-row>
        <b-row class="my-3">
          <b-col>
            <table class="min-w-full leading-normal">
              <thead>
              <tr class="border-b border-t border-grey">
                <th class="t-header">#</th>
                <th class="t-header" width="85%">Name</th>
                <th class="t-header text-center">Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(department,idx) in departments"
                  :key="department.id">
                <td class="t-body">{{ idx + 1 }}</td>
                <td class="t-body font-bold text-capitalize">{{ department.departmentName }}</td>
                <td class="t-body font-bold">
                  <b-row>
                    <b-col>
                      <button class="w-full bg-green text-white text-xs px-2 py-1 rounded-sm"><i class="fa fa-pencil"></i> Edit</button>
                    </b-col>
                    <b-col>
                      <button class="w-full bg-red text-white text-xs px-2 py-1 rounded-sm"><i class="fa fa-trash"></i> Delete</button>
                    </b-col>
                  </b-row>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import NewDepartment from "@/views/home/services/settings/departments/NewDepartment";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Departments",
  components: {
    NewDepartment
  },
  data() {
    return {
      registerDepartment: false,
      newDepartment: false,
      departments: [
        {
          id: 1,
          name: "Department 1"
        }
      ],
      rows: {
        odd: "border-t border-grey hover:bg-header_grey",
        even: "border-t border-grey bg-header_grey"
      }
    };
  },
  mounted() {
    this.fetchDepartments();
  },

  methods: {
    ...mapActions(["fetchDepartments", "createDepartment"]),
    openForm() {
      this.$refs.addDepartment.show();
    }
  }
  ,
  computed: {
    ...mapGetters(["getDepartments"])
  },

  watch: {
    getDepartments(departments) {
      this.departments = departments;
    }
  }

};
</script>

<style scoped>

</style>