<template>
  <b-col>
    <b-row>
      <b-col cols="4" class="mt-4">
        <table class="w-full leading-normal text-blue-dark" style="border: solid 1px #dedede;">
          <thead class="bg-gray-pale">
          <tr class="bg-gray-pale">
            <th class="px-2 py-1">#</th>
            <th class="px-2 py-1">Name</th>
          </tr>
          </thead>
          <tbody>
          <tr style="border: solid 1px #dedede;" v-for="(document, idx) in documents"
              class="cursor-pointer hover:bg-gray-pale hover:font-bold"
              @click="view(document)"
              :key="idx">
            <td class="px-2 py-1">
                 <span class="text-gray text-xs">
                   {{(idx + 1)}}
                 </span>
            </td>
            <td class="px-2 py-1">
                 <span class="text-gray text-xs text-capitalize">
                   {{cleanEntityType(document.name.toLowerCase())}}
                 </span>
            </td>
          </tr>
          <tr v-if="documents.length === 0">
            <td colspan="12" class="bg-inertgrey text-center py-1">
              <span class="text-sm">No results found</span>
            </td>
          </tr>
          </tbody>
        </table>
        <b-row v-if="documents.length < 2">
          <b-col>
            <p class="alert alert-success">
              <b>Note:</b> Documents are still being migrated to EDMS be patient they will all reflect here within the next 15mins.
            </p>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="8" class="mt-4">
        <b-row>
          <div class="mx-auto">
            <b-progress value="100" v-if="loading"  variant="#007ac5" height="0.2rem" animated></b-progress>
          </div>
          <b-col>
            <EDMSDocument class="w-full border border-gray" ref="edmsFile" :file="getEDMSFile"/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import EDMSDocument from "@/views/home/services/registry/business/document/EDMSDocument";
import {mapActions, mapGetters} from "vuex";
export default {
  name: "BuildingSocietyDocuments",
  components: {EDMSDocument},
  props:{
    documents:[]
  },
  data(){
    return{
      loading: false,
      currentPage:1,
      limit:10,
      pagination:{
        totalElements: 0,
        totalPages:0
      },
      search:""
    }
  },
  mounted() {
    //this.view(this.documents[0])
  },
  methods:{
    ...mapActions(["fetchSocietyEDMSFile"]),
    cleanEntityType(name){
      let n = name.replaceAll("_", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    view(document){
      console.log(JSON.stringify(document))
      let data = {
        documentHandle: document.base64String,
        overlay: ""
      }
      this.loading = true
      this.fetchSocietyEDMSFile(data)
      this.$refs.edmsFile.show();
    },
    next(){
      if(this.currentPage < this.pagination.totalPages){
        this.currentPage++
        this.fetch()
      }
    },
    previous(){
      if(this.currentPage > 1){
        this.currentPage--
        this.fetch()
      }else{
        this.warningToast("Observation", "No more data to show")
      }
    },
  },
  computed:{
    ...mapGetters(["getEDMSFile", "getNetworkStatus", "getRegistryError"])
  },
  watch:{
    getEDMSFile(){
      this.loading = false
    },
    getRegistryError(){
      this.loadingDocument = false
      this.errorToast("Error", "Failed to get document")
    },
    getNetworkStatus(){
      this.loading = false
      this.errorToast("Document Error", "Document loading times out")
    },
  }
}
</script>

<style scoped>
.button {
  @apply  text-center rounded-sm bg-blue text-xs text-white border border-blue px-2 py-1;
  font-size: 14px;
}

.button:hover {
  @apply bg-blue-dark;
}

.paginate-details{
  @apply px-3 py-2 bg-white my-2 text-sm text-gray;
}

.paginate-btn{
  @apply px-3 py-2 bg-white border border-white my-2 text-sm text-blue w-full;
}

.paginate-btn:hover{
  @apply border border-blue text-blue rounded-sm;
}

.inert-btn{
  @apply px-3 py-2 bg-white my-2 text-sm text-grey w-full;
}
</style>