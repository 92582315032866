import Vue from "vue";
import Vuex from "vuex";
import accounts from "@/views/home/services/accounts/vuex-store/accounts";
import login_store from "@/views/home/services/accounts/vuex-store/login_store";
import register_store from "@/views/home/services/accounts/vuex-store/register_store";
import reservations from "@/views/home/services/configurations/vuex-store/reservations";
import name_search from "@/views/home/services/configurations/vuex-store/name_search";
import isic from "@/views/home/services/configurations/nature_of_business/vuex-store/isic";
import roles_store from "@/views/home/services/accounts/vuex-store/roles_store";
import department_store from "@/views/home/services/settings/departments/vuex-store/department_store";
import occupation_store from "@/views/home/services/settings/ocupations/vuex-store/occupation_store";
import service_cost_store from "@/views/home/services/billing/vuex-store/service_cost_store";
import dictionary from "@/views/home/services/configurations/dictionary/vuex-store/dictionary";
import tax_head_store from "@/views/home/services/billing/tax-heads/vuex-store/tax_head_store";
import rejectionQueryMessages
  from "@/views/home/services/configurations/reservations/responses/vuex-store/rejectionQueryMessages";
import business_file from "@/views/home/services/registry/vuex-store/business_file";
import practitioners_registry from "@/views/home/services/registry/vuex-store/practitioners_registry";
import legal_documents_registry from "@/views/home/services/registry/vuex-store/legal_documents_registry";
import business_registry from "@/views/home/services/registry/vuex-store/business_registry";
import settings from "@/views/home/services/settings/vuex-store/settings";
import countries_store from "@/views/home/services/settings/countries/vuex-store/countries_store";
import user_manuals_store from "@/views/home/services/settings/manuals/vuex-store/user_manuals_store";
import internal_processes from "@/views/home/services/internal_processing/vuex-store/internal_processes";
import prn_management from "@/views/home/services/billing/vuex-store/prn_management";
import entity_documents from "@/views/home/services/registry/vuex-store/entity_documents";
import transactions from "@/views/home/services/accounts/vuex-store/transactions";
import integrations from "@/views/home/services/integrations/vuex-store/integrations";
import insolvency_settings from "@/views/home/services/settings/insolvency/vuex-store/insolvency_settings";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    accounts,
    login_store,
    register_store,
    name_search,
    reservations,
    isic,
    roles_store,
    department_store,
    occupation_store,
    service_cost_store,
    dictionary,
    tax_head_store,
    rejectionQueryMessages,
    business_file,
    business_registry,
    legal_documents_registry,
    practitioners_registry,
    settings,
    countries_store,
    user_manuals_store,
    internal_processes,
    prn_management,
    entity_documents,
    transactions,
    integrations,
    insolvency_settings
  },
  state: {
    network_status: null
  },
  getters: {
    getNetworkStatus: (state) => state.network_status
  },
  mutations: {
    resetNetworkStatus: (state) => {
      state.network_status = null;
    }
  },
});

