<template>
  <div class="bg-white rounded-sm text-gray mx-2 my-3">
    <b-row>
      <b-col>
        <b-row>
          <b-col cols="12" class="mx-3 mt-3">
            <b-row>
              <span class="title">PRN Management</span>
            </b-row>
          </b-col>
        </b-row>

        <b-row>
            <b-modal size="lg" ref="removePRN" title="Remove Used PRNs" hide-footer>
            <b-row>
              <b-col class="mx-auto mb-2">
                <div class="flex h-full" style="cursor: pointer;">
                    <div :class="[(option === 'form') ? 'menu-item-active' : 'menu-item']">
                    <div class="flex mt-6">
                        <div style="font-size: 14px" class="mt-1">Enter PRN</div>
                    </div>
                  </div>

                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="8" class="mx-auto">
                <form v-if="option === 'form'">
                    <b-form-input
                            :max="13"
                            v-model="prn_to_remove"
                            placeholder="Enter PRN here"
                      rows="6">
                    </b-form-input>
                    <p class="italic text-xs">Number of characters: {{ prn_to_remove.length }}</p>
                    <button class="bg-red text-white px-3 py-1 rounded-sm mt-2 float-end" @click="removeUsedPrn"
                            type="button">
                        Remove
                        <b-spinner v-if="spinner" small></b-spinner>
                  </button>
                </form>
              </b-col>
            </b-row>
          </b-modal>
        </b-row>

          <b-row>
              <b-modal size="lg" ref="addPRN" title="Add Used PRNs" hide-footer>
                  <b-row>
                      <b-col class="mx-auto mb-2">
                          <div class="flex h-full" style="cursor: pointer;">
                              <div :class="[(option === 'form') ? 'menu-item-active' : 'menu-item']"
                                   @click="select('form')">
                                  <div class="flex mt-6">
                                      <div style="font-size: 14px" class="mt-1">Enter PRNs</div>
                                  </div>
                              </div>
                              <div :class="[(option === 'upload') ? 'menu-item-active' : 'menu-item']"
                                   @click="select('upload')">
                                  <div class="flex mt-6">
                                      <div style="font-size: 14px" class="mt-1">Upload File</div>
                                  </div>
                              </div>
                          </div>
                      </b-col>
                  </b-row>
                  <b-row>
                      <b-col cols="8" class="mx-auto">
                          <form v-if="option === 'form'">
                              <b-form-textarea
                                      v-model="prns"
                                      placeholder="Add PRN(s) here, with comma separation if more than 1"
                                      v-on:keydown="updateSize"
                                      v-on:keyup="updateSize"
                                      rows="6">
                              </b-form-textarea>
                              <p class="italic text-xs">Number of PRNs: {{ prnSize }}</p>
                              <button class="bg-blue text-white px-3 py-1 rounded-sm mt-2 float-end" @click="save"
                                      type="button">
                                  Save
                                  <b-spinner v-if="spinner" small></b-spinner>
                              </button>
                          </form>
                          <form @submit.prevent="saveUpload" v-if="option === 'upload'">
                              <b-input-group>
                                  <b-form-file
                                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                          label-size="sm"
                                          type="file"
                                          placeholder="Please provide a copy of you id..."
                                          drop-placeholder="Drop file here..."
                                          v-model="file"
                                          class="form-control noFocus removeOnClickBorder rounded-0 mb-2"
                                          plain></b-form-file>
                              </b-input-group>
                              <button class="bg-blue text-white px-3 py-1 rounded-sm mt-2 float-end" type="submit">
                                  Upload
                                  <b-spinner v-if="spinner" small></b-spinner>
                              </button>
                          </form>
                      </b-col>
                  </b-row>
              </b-modal>
          </b-row>
        <b-row>
          <b-col>
            <b-row>
              <b-col cols="4">
                <b-form-input
                    class="text-gray text-sm noFocus px-4 mr-2 my-2"
                    style="padding:6px 5px;"
                    v-model="search"
                    type="text"
                    placeholder="Search by PRN."
                    required
                    @change="searchByPrn">
                </b-form-input>
              </b-col>
                <b-col cols="4">
              </b-col>
              <b-col cols="2">
                  <button class="w-full bg-blue text-white my-2 px-2 py-1 rounded-sm hover:bg-green"
                        @click="openForm">
                  <i class="fa fa-plus"></i> Add PRN(s)
                </button>
              </b-col>
                <b-col cols="2">
                    <button v-if="canReleasePrn()"
                            class="w-full bg-blue text-white my-2 px-2 py-1 rounded-sm hover:bg-red"
                            @click="openRemoveForm">
                        <i class="fa fa-times"></i> Remove PRN
                    </button>
              </b-col>
            </b-row>
            <div class="mx-auto mt-2">
              <b-progress value="100" v-if="loading" variant="#007ac5" height="0.2rem" animated></b-progress>
            </div>
            <b-row>
              <b-col>
                <table class="min-w-full leading-normal">
                  <thead>
                  <tr class="border-b border-t border-grey">
                    <th class="t-header">#</th>
                    <th class="t-header" width="85%">PRN</th>
                    <th class="t-header text-center">Date</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(item, idx) in prnSubmissions.usedPrnResponses"
                      :key="idx">
                    <td class="t-body">{{ getPrnNumber(idx).toLocaleString() }}</td>
                    <td class="t-body font-bold text-capitalize">{{ item.prn }}</td>
                    <td class="t-body font-bold">
                      <span>{{item.createdAt}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td v-if="prnSubmissions.length === 0" colspan="12" class="bg-inertgrey text-center py-1">
                      <span class="text-sm">No results found</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
            <b-row class="mx-1" v-if="pagination.totalPages > 1">
              <b-col cols="1">
                <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
              </b-col>
              <b-col cols="1">
                <b-row>
                  <b-col cols="12">
                    <p class="paginate-details text-center">
                      <b-badge class="bg-blue text-white">
                        {{ currentPage.toLocaleString() }}/{{ pagination.totalPages.toLocaleString() }}
                      </b-badge>
                    </p>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="1" >
                <button @click="next" :class="(currentPage.toLocaleString() < pagination.totalPages.toLocaleString()) ? 'paginate-btn' :'inert-btn'">Next</button>
              </b-col>
              <b-col class="9"></b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "PRNManager",
  data(){
    return{
      search:null,
      prns:null,
      file:null,
      option:"form",
      prnSize:0,
      prnSubmissions:[],
      form:{
        prnList:[]
      },
      spinner: false,
      loading:false,
      currentPage:1,
      perPage: 15,
      pagination:{
        totalElements: 0,
        totalPages:0
      },
      rows: {
        odd: "border-t border-grey hover:bg-header_grey",
        even: "border-t border-grey bg-header_grey"
      },
        prn_to_remove: ""
    }
  },
  mounted() {
    this.fetch()
  },
  methods:{
      ...mapActions(["fetchUsedPRNs", "filterByPrn", "addPRNs", "uploadPRNs", "removePRN"]),
    select(route){
      this.option = route
    },
    fetch(){
      this.loading = true
      let data = {
        page:this.currentPage,
        perPage:this.perPage,
      }
      this.fetchUsedPRNs(data)
    },
    searchByPrn(){
      this.loading = true
      let data = {
        prn: this.search,
        page: this.currentPage,
        perPage: this.perPage,
      }
      this.filterByPrn(data)
    },
    next(){
      if(this.currentPage < this.pagination.totalPages){
        this.currentPage++
        this.fetch()
      }
    },
    previous(){
      if(this.currentPage > 1){
        this.currentPage--
        this.fetch()
      }else{
        this.warningToast("Observation", "No more data to show")
      }
    },
    openForm() {
      this.$refs.addPRN.show();
    }, openRemoveForm() {
          this.$refs.removePRN.show();
    },
    save(){
      let data = this.prns.split(",")
      this.prnSize = data.length
      this.form.prnList = data
      this.spinner = true
      this.addPRNs(this.form)
    },
      removeUsedPrn() {
          this.spinner = true
          this.showConfirmDialog().then(value => {
              if (value) {
                  this.removePRN(this.prn_to_remove)
              }
          });

      },
    saveUpload(){
      let formData = new FormData;
      formData.set("file", this.file);
      this.spinner = true
      this.uploadPRNs(formData);
    },
    updateSize(){
      let data = this.prns.split(",")
      this.prnSize = data.length
    },
    getPrnNumber(index){
      return index+1 + ((this.perPage*this.currentPage) - this.perPage)
    },
      canReleasePrn() {
          return this.$can('can_release_prn', 'general');
      }
  },
  computed:{
      ...mapGetters(["getUsedPRNs", "getPRNsAdded", "getPRNError", "getPRNRemoved"]),
  },
  watch:{
    getUsedPRNs(data){
      this.loading = false
      this.prnSubmissions = data
      this.pagination = {
        totalPages:data.totalPages,
        totalElements:data.totalElements
      }
    },
    getPRNsAdded(){
      this.spinner = false
      this.successToast("Success", "PRN(s) added successfully")
      this.fetchUsedPRNs()
      this.$refs.addPRN.hide()
      this.prns = null
      this.prnSize = 0
    },
    getPRNError(data){
      this.loading = false
        this.errorToast("Error", data.message)
    },
      getPRNRemoved() {
          this.spinner = false
          this.successToast("Success", "PRN removed successfully")
          this.$refs.removePRN.hide()
          this.prn_to_remove = ""
      }
  }
};
</script>

<style scoped>
.paginate-details {
  @apply px-3 py-2 bg-white my-2 text-sm text-gray;
}

.paginate-btn {
  @apply px-3 py-2 bg-white border border-white my-2 text-sm text-blue w-full;
}

.paginate-btn:hover {
  @apply border border-blue text-blue rounded-sm;
}


.inert-btn {
  @apply px-3 py-2 bg-white my-2 text-sm text-grey w-full;
}
</style>