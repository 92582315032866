<template>
  <div class="overflow-hidden bg-gray p-2">
    <b-row>
      <b-col cols="12">
        <iframe class="pdf" :src="'data:application/pdf;base64,'+file.Base64FileStream">
        </iframe>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  name: "EDMSLegalDocument",
  props:{
    file:Object
  }
}
</script>

<style scoped>
.pdf{
  width: 100%;
  height: 1000px;
  overflow-x: auto;
  border:1px solid #b1b1b1;
}
</style>