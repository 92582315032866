<template>
    <div class="bg-white rounded-sm text-gray mx-2 my-3 px-2">
      <b-row>
        <b-col cols="12" class="mt-3">
          <b-row>
            <span class="title">External Integrations</span>
          </b-row>
        </b-col>
      </b-row>
        <b-row>
            <b-col class="mx-2">
              <URACredentials/>
              <NIRACredentials/>
              <EDMSCredentials/>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import URACredentials from "@/views/home/services/integrations/credentials/URACredentials.vue";
import EDMSCredentials from "@/views/home/services/integrations/credentials/EDMSCredentials.vue";
import NIRACredentials from "@/views/home/services/integrations/credentials/NIRACredentials.vue";
export default {
    name: "SystemCredentials",
  components: { NIRACredentials, EDMSCredentials, URACredentials }
};
</script>

<style scoped>

</style>