<template>
  <b-row>
    <b-col>
      <b-row v-if="document.documentType === 'AGREEMENT' || document.documentType === 'CONSTITUTION' || document.documentType === 'MINUTES' || document.documentType === 'OTHERS' || document.documentType === 'RESOLUTION_AS_A_DOCUMENT'">
        <b-col cols="12">
          <b-row>
            <b-col>
              <span class="h5">Parties</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <LegalPersonsPartner class="p-1" :individuals="document.individualParties" :corporates="document.entityParties"/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row v-if=" document.documentType === 'GUARANTEE'">
        <b-col cols="12">
          <b-row>
            <b-col>
              <span class="h5">Parties</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <LegalPersonsPartner class="p-1" :individuals="document.individualParties" :corporates="document.entityParties"/>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <span class="h5">Beneficiary</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <LegalPersonsPartner class="p-1" :individuals="getArray(document.individualBeneficiary)" :corporates="getArray(document.entityBeneficiary)"/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row v-if="document.documentType === 'AFFIDAVIT' || document.documentType === 'STATUTORY_DECLARATION'" class="mt-4">
        <b-col cols="12">
          <b-row>
            <b-col>
              <span class="h5">Deponent's KYC</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <LegalPerson v-if="document.entityDeclarantDeponent != null" :person="document.entityDeclarantDeponent" entity-type="CORPORATE"/>
              <LegalPerson v-if="document.individualDeclarantDeponent != null" :person="document.individualDeclarantDeponent" entity-type="INDIVIDUAL"/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row v-if="document.documentType === 'FACILITY_LETTER'">
        <b-col cols="12">
          <b-row>
            <b-col>
              <b-row>
                <b-col cols="4">
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Amount Secured"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus text-capitalize"
                      type="text"
                      disabled="true"
                      :value="document.amountSecured.toLocaleString()"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="8">
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Collateral Security Description"
                    label-for="input-1">
                    <b-form-textarea
                      id="companyName"
                      class="form-text text-sm noFocus text-capitalize"
                      type="text"
                      disabled="true"
                      :value="document.collateral"
                      required>
                    </b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <span class="h5">Borrowers</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <LegalPersonsPartner class="p-1" :individuals="document.individualBorrowers" :corporates="document.entityBorrowers"/>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col>
              <span class="h5">Lenders</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <LegalPersonsPartner class="p-1" :individuals="document.individualLenders" :corporates="document.entityLenders"/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row v-if="document.documentType === 'DEED_OF_ASSIGNMENT' || document.documentType === 'DEED_OF_INTER_VIVOS' || document.documentType ===  'DEED_OF_VARIATION' || document.documentType === 'DEED_OF_GIFT' || document.documentType === 'DEED_OF_INDEMNITY' || document.documentType === 'DEED_OF_TRUST'">
        <b-col cols="12">
          <b-row>
            <b-col>
              <span class="h5">Assigners</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <LegalPersonsPartner class="p-1" :individuals="document.individualAssigners" :corporates="document.entityAssigners"/>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col>
              <span class="h5">Assignees</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <LegalPersonsPartner class="p-1" :individuals="document.individualAssignees" :corporates="document.entityAssignees"/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row v-if="document.documentType === 'DEED_POLL'">
        <b-col>
          <b-row>
            <b-col cols="4">
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="Old Name of Declarant"
                label-for="input-1">
                <b-form-input
                  id="companyName"
                  class="form-text text-sm noFocus text-capitalize"
                  type="text"
                  disabled="true"
                  :value="document.oldName"
                  required>
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="New Name of Declarant"
                label-for="input-1">
                <b-form-input
                  id="companyName"
                  class="form-text text-sm noFocus text-capitalize"
                  type="text"
                  disabled="true"
                  :value="document.newName"
                  required>
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <span class="h5">Address of Declarant</span>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="District"
                label-for="input-1">
                <b-form-input
                  class="form-text text-sm noFocus"
                  type="text"
                  disabled="true"
                  :value="document.physicalAddress.district.name"
                  required>
                </b-form-input>
              </b-form-group>
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="Region"
                label-for="input-1">
                <b-form-input
                  class="form-text text-sm noFocus"
                  type="text"
                  disabled="true"
                  :value="document.physicalAddress.region.name"
                  required>
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="Sub county"
                label-for="input-1">
                <b-form-input
                  class="form-text text-sm noFocus"
                  type="text"
                  disabled="true"
                  :value="document.physicalAddress.subcounty.name"
                  required>
                </b-form-input>
              </b-form-group>
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="Parish"
                label-for="input-1">
                <b-form-input
                  class="form-text text-sm noFocus"
                  type="text"
                  disabled="true"
                  :value="document.physicalAddress.parish.name"
                  required>
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="Village"
                label-for="input-1">
                <b-form-input
                  class="form-text text-sm noFocus"
                  type="text"
                  disabled="true"
                  :value="document.physicalAddress.village.name"
                  required>
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row v-if="document.documentType === 'MORTGAGE_DEED'">
        <b-col cols="12">
          <b-row>
            <b-col>
              <b-row>
                <b-col cols="4">
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Amount"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus text-capitalize"
                      type="text"
                      disabled="true"
                      :value="document.amountSecured.toLocaleString()"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Collateral Security Description"
                    label-for="input-1">
                    <b-form-textarea
                      id="companyName"
                      class="form-text text-sm noFocus text-capitalize"
                      type="text"
                      disabled="true"
                      :value="document.collateral"
                      required>
                    </b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <span class="h5">Mortgagors</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <LegalPersonsPartner class="p-1" :individuals="document.individualMortgagors" :corporates="document.entityMortgagors"/>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col>
              <span class="h5">Mortgagees</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <LegalPersonsPartner class="p-1" :individuals="document.individualMortgagees" :corporates="document.entityMortgagees"/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row v-if="document.documentType === 'POWER_OF_ATTORNEY'" class="mt-4">
        <b-col cols="12">
          <b-row>
            <b-col>
              <span class="h5">Donor KYC</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <LegalPerson v-if="document.entityDonor != null" :person="document.entityDonor" entity-type="CORPORATE"/>
              <LegalPerson v-if="document.individualDonor != null" :person="document.individualDonor" entity-type="INDIVIDUAL"/>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col>
              <span class="h5">Donee KYC</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <LegalPerson v-if="document.entityDonee != null" :person="document.entityDonee" entity-type="CORPORATE"/>
              <LegalPerson v-if="document.individualDonee != null" :person="document.individualDonee" entity-type="INDIVIDUAL"/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row v-if="document.documentType === 'SPOUSAL_CONSENT'">
        <b-col cols="12">

          <b-row class="mt-2">
            <b-col>
              <span class="h5">Consenting Person</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <LegalPerson v-if="document.entityConsentingPerson != null" :person="document.entityConsentingPerson" entity-type="CORPORATE"/>
              <LegalPerson v-if="document.individualConsentingPerson != null" :person="document.individualConsentingPerson" entity-type="INDIVIDUAL"/>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col>
              <span class="h5">Mortgagee</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <LegalPerson v-if="document.entityMortgagee != null" :person="document.entityMortgagee" entity-type="CORPORATE"/>
              <LegalPerson v-if="document.individualMortgagee != null" :person="document.individualMortgagee" entity-type="INDIVIDUAL"/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row v-if="document.documentType === 'DEBENTURE'">
        <b-col cols="12">
          <b-row class="mt-2">
            <b-col>
              <span class="h5">Assigners</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <LegalPersonsPartner class="p-1" :individuals="document.individualAssigners" :corporates="document.entityAssigners"/>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col>
              <span class="h5">Assignees</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <LegalPersonsPartner class="p-1" :individuals="document.individualAssignees" :corporates="document.entityAssignees"/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row v-if="document.documentType === 'CHATTEL'">
        <b-col cols="12">
          <b-row class="mt-2">
            <b-col>
              <span class="h5">Assigners</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <LegalPersonsPartner class="p-1" :individuals="document.individualMortgagors" :corporates="document.entityMortgagors"/>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <span class="h5">Assignees</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <LegalPersonsPartner class="p-1" :individuals="document.individualMortgagees" :corporates="document.entityMortgagees"/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row v-if="document.documentType === 'DEMAND_PROMISSORY_NOTE'" >
        <b-col cols="12">
          <b-row>
            <b-col cols="4">
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="Amount"
                label-for="input-1">
                <b-form-input
                  id="companyName"
                  class="form-text text-sm noFocus text-capitalize"
                  type="text"
                  disabled="true"
                  :value="0"
                  required>
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="Realization Date"
                label-for="input-1">
                <b-form-input
                  id="companyName"
                  class="form-text text-sm noFocus text-capitalize"
                  type="text"
                  disabled="true"
                  :value="new Date()"
                  required>
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col>
              <span class="h5">Borrower KYC</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <LegalPerson v-if="document.entityBorrower != null" :person="document.entityBorrower" entity-type="CORPORATE"/>
              <LegalPerson v-if="document.individualBorrower != null" :person="document.individualBorrower" entity-type="INDIVIDUAL"/>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col>
              <span class="h5">Creditor KYC</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <LegalPerson v-if="document.entityCreditor != null" :person="document.entityCreditor" entity-type="CORPORATE"/>
              <LegalPerson v-if="document.individualCreditor != null" :person="document.individualCreditor" entity-type="INDIVIDUAL"/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row v-if="document.documentType === 'INDEPENDENT_ADVISE_FORM'" class="mt-4">
        <b-col cols="12">
          <b-row>
            <b-col>
              <span class="h5">Advisor KYC</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <LegalPerson v-if="document.entityAdviser != null" :person="document.entityAdviser" entity-type="CORPORATE"/>
              <LegalPerson v-if="document.individualAdviser != null" :person="document.individualAdviser" entity-type="INDIVIDUAL"/>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col>
              <span class="h5">Advisee KYC</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <LegalPerson v-if="document.entityAdvise != null" :person="document.entityAdvise" entity-type="CORPORATE"/>
              <LegalPerson v-if="document.individualAdvise != null" :person="document.individualAdvise" entity-type="INDIVIDUAL"/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row v-if="document.documentType === 'REPORT_OF_DIRECTORS'">
        <b-col cols="12">
          <b-row>
            <b-col>
              <b-row>
                <b-col cols="4">
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Amount"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus text-capitalize"
                      type="text"
                      disabled="true"
                      :value="document.amountSecured.toLocaleString()"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Collateral Security Description"
                    label-for="input-1">
                    <b-form-textarea
                      id="companyName"
                      class="form-text text-sm noFocus text-capitalize"
                      type="text"
                      disabled="true"
                      :value="document.collateral"
                      required>
                    </b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <span class="h5">Mortgagors</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <LegalPersonsPartner class="p-1" :individuals="document.individualMortgagors" :corporates="document.entityMortgagors"/>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col>
              <span class="h5">Mortgagees</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <LegalPersonsPartner class="p-1" :individuals="document.individualMortgagees" :corporates="document.entityMortgagees"/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row v-if="document.documentType === 'WILL'">
        <b-col cols="12">
          <b-row>
            <b-col>
              <b-row>
                <b-col cols="4">
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Amount"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus text-capitalize"
                      type="text"
                      disabled="true"
                      :value="document.amountSecured.toLocaleString()"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Collateral Security Description"
                    label-for="input-1">
                    <b-form-textarea
                      id="companyName"
                      class="form-text text-sm noFocus text-capitalize"
                      type="text"
                      disabled="true"
                      :value="document.collateral"
                      required>
                    </b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <span class="h5">Mortgagors</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <LegalPersonsPartner class="p-1" :individuals="document.individualMortgagors" :corporates="document.entityMortgagors"/>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col>
              <span class="h5">Mortgagees</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <LegalPersonsPartner class="p-1" :individuals="document.individualMortgagees" :corporates="document.entityMortgagees"/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import LegalPersonsPartner from "@/views/home/services/registry/legal-documents/LegalPersonsPartner";
import LegalPerson from "@/views/home/services/registry/legal-documents/LegalPerson";
export default {
  name: "LegalDocumentDetails",
  components: { LegalPerson, LegalPersonsPartner },
  props:{
    document:Object
  },
  methods:{
    getArray(data){
      if(data){
        return [data]
      }
      return []
    }
  }

};
</script>

<style scoped>

</style>