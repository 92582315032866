import { render, staticRenderFns } from "./PractitionerFilingMessages.vue?vue&type=template&id=fa45d25e&scoped=true&"
import script from "./PractitionerFilingMessages.vue?vue&type=script&lang=js&"
export * from "./PractitionerFilingMessages.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa45d25e",
  null
  
)

export default component.exports