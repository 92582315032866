import {api} from "@/app/services/api.service";

const state = {
    messageError: null,
    searchResults: [],
    nameFileUpload: null,
};

const getters = {
    getSearchResults: (state) => state.searchResults,
    getNameFileUpload: (state) => state.nameFileUpload,
    getMessageError: (state) => state.messageError,
};

const actions = {
    async uploadBusinessNames({commit}, data) {
        api().post('/namesearch/upload-names-file', data)
            .then(response => {
                commit('setSearchResults', response)
            })
            .catch(error => {
                commit('setSearchError', error)
            })
    },
    async doSearch({commit}, filters) {
        await api().get("/namesearch/search"+filters)
            // await api().get("/namesearch/direct-search"+filters)
            .then((response) => {
                commit("setSearchResults", response);
            })
            .catch((error) => {
                commit("setSearchError", error.response);
            });
    },
    async doGetFilters({commit},) {
        await api().get("/namesearch/filters")
            .then((response) => {
                commit("setFilters", response);
            })
            .catch((error) => {
                commit("setFiltersError", error.response);
            });
    },

};

const mutations = {
    setSearchResults: (state, response) => {
        state.searchResults = response.data;
    },
    setSearchError: (state, response) => {
        state.messageError = response.data;
    },setFilters: (state, response) => {
        state.filters = response.data;
    },
    setFiltersError: (state, response) => {
        state.message = response.data.message;
    },

};

export default {
    state,
    getters,
    actions,
    mutations,
};