<template>
  <div class="bg-white rounded-sm text-gray mx-2 my-3 px-2">
    <b-row>
      <b-col cols="12" class="mt-3">
        <b-row>
          <span class="title">Internal Processes</span>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <div class="flex h-full" style="cursor: pointer;">
          <div :class="[(currentRoute === 'registration') ? 'menu-item-active' : 'menu-item']" @click="select('registration')">
            <div class="flex mt-6">
              <div style="font-size: 14px">Registration Service</div>
            </div>
          </div>
          <div :class="[(currentRoute === 'incorporation') ? 'menu-item-active' : 'menu-item']" @click="select('incorporation')">
            <div class="flex mt-6">
              <div style="font-size: 14px">Incorporation Service</div>
            </div>
          </div>
          <div :class="[(currentRoute === 'reservation') ? 'menu-item-active' : 'menu-item']" @click="select('reservation')">
            <div class="flex mt-6">
              <div style="font-size: 14px">Reservation Service</div>
            </div>
          </div>
          <div :class="[(currentRoute === 'legal-documents') ? 'menu-item-active' : 'menu-item']" @click="select('legal-documents')">
            <div class="flex mt-6">
              <div style="font-size: 14px">Legal Documents Service</div>
            </div>
          </div>
          <div :class="[(currentRoute === 'annual-returns') ? 'menu-item-active' : 'menu-item']" @click="select('annual-returns')">
            <div class="flex mt-6">
              <div style="font-size: 14px">Annual Returns</div>
            </div>
          </div>
          <div :class="[(currentRoute === 'form-compliance') ? 'menu-item-active' : 'menu-item']" @click="select('form-compliance')">
            <div class="flex mt-6">
              <div style="font-size: 14px">Forms Compliance</div>
            </div>
          </div>
            <div :class="[(currentRoute === 'data-correction') ? 'menu-item-active' : 'menu-item']"
                 @click="select('data-correction')">
                <div class="flex mt-6">
                    <div style="font-size: 14px">Data Correction</div>
                </div>
            </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <RegistrationService v-if="currentRoute === 'registration'"/>
        <IncorporationService v-if="currentRoute === 'incorporation'"/>
        <ReservationService v-if="currentRoute === 'reservation'"/>
        <LegalDocumentService v-if="currentRoute === 'legal-documents'"/>
        <AnnualReturnsService v-if="currentRoute === 'annual-returns'"/>
        <FormComplianceManager v-if="currentRoute === 'form-compliance'"/>
          <DataCorrectionService v-if="currentRoute === 'data-correction'"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RegistrationService from "@/views/home/services/internal_processing/tabs/RegistrationService";
import IncorporationService from "@/views/home/services/internal_processing/tabs/IncorporationService";
import ReservationService from "@/views/home/services/internal_processing/tabs/ReservationService";
import LegalDocumentService from "@/views/home/services/internal_processing/tabs/LegalDocumentService.vue";
import AnnualReturnsService from "@/views/home/services/internal_processing/tabs/AnnualReturnsService.vue";
import FormComplianceManager from "@/views/home/services/internal_processing/tabs/FormComplianceManager.vue";
import DataCorrectionService from "@/views/home/services/internal_processing/tabs/DataCorrectionService.vue";

export default {
  name: "InternalProcesses",
  components: {
      DataCorrectionService,
    FormComplianceManager,
    AnnualReturnsService, LegalDocumentService, ReservationService, IncorporationService, RegistrationService },
  data(){
    return{
      currentRoute: "registration",
    }
  },
  methods:{
    select(route){
      this.currentRoute = route
    },
  },
};
</script>

<style scoped>

</style>