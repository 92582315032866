<template>
  <div>
      <b-row class="my-3">
          <b-col>
              <b-form-input v-model="form.departmentName"
                            placeholder="Department Name" required>
              </b-form-input>
          </b-col>
      </b-row>
      <b-row>
        <b-col cols="8"></b-col>
        <b-col cols="4" class="float-right">
          <button @click="addDepartment" class="w-full bg-blue text-white px-2 py-1 rounded-sm">
            Save
            <b-spinner  v-if="spinner" small></b-spinner>
          </button>
        </b-col>
      </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "NewDepartment",
  data() {
    return {
      form: {
        departmentName: null
      },
      spinner: false,
    }
  },
  methods: {
    ...mapActions([
      "createDepartment", "fetchDepartments"
    ]),
    addDepartment() {
      this.spinner = true;
      this.createDepartment(this.form);
    }
  },
  computed: {
    ...mapGetters([
      "getDepartments",
      "getDepartment"
    ])
  },
  watch: {
    getDepartment() {
      this.spinner = false
      this.successToast("Success", "Department created successfully");
      this.fetchDepartments();
    },
    getError() {
      this.spinner = false
      this.errorToast("Error", "Department creation failed");
    }
  }

}
</script>

<style scoped>

</style>