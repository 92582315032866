<template>
  <b-row>
    <b-col>
      <Isic/>
    </b-col>
  </b-row>
</template>

<script>
import Isic from "@/views/home/services/configurations/nature_of_business/Isic";
export default {
  name: "NatureOfBusiness",
  components: { Isic },
};
</script>

<style scoped>

</style>