<template>
    <div class="bg-white rounded-sm text-gray mx-2 my-3">
        <b-row>
            <b-col>
                <b-row>
                    <b-modal ref="addManual" title="Create Manual" hide-footer>
                        <form>
                            <b-form-input class="my-2"
                                          v-model="form.name"
                                          placeholder="Module/Section Name">
                            </b-form-input>
                            <b-form-input class="my-2"
                                          v-model="form.position"
                                          placeholder="Position"
                                          type="number">
                            </b-form-input>
                            <label class="mt-2">Section Attachment</label>
                            <b-form-file class="mb-2"
                                         v-model="form.file">
                            </b-form-file>
                            <button
                                    class="bg-blue text-white px-3 py-1 rounded-sm mt-3 float-end"
                                    @click="save"
                                    type="button">

                                Save
                                <b-spinner small v-if="loading"></b-spinner>
                            </button>
                        </form>
                    </b-modal>
                </b-row>
                <b-row>
                    <b-col>
                        <b-row>
                            <b-col cols="10"></b-col>
                            <b-col cols="2">
                                <button class="w-full bg-blue text-white px-2 py-1 rounded-sm"
                                        @click="openForm">
                                    <i class="fa fa-plus"></i> Add Manual
                                </button>
                            </b-col>
                        </b-row>
                        <b-row class="my-3">
                            <b-col>

                                <table class="min-w-full leading-normal">
                                    <thead>
                                    <tr class="border-b border-t border-grey">
                                        <th class="t-header">Section</th>
                                        <th class="t-header">Position</th>
                                        <th class="t-header">File</th>
                                        <th class="t-header text-center">Date</th>
                                        <th class="t-header text-center">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(item, idx) in manuals"
                                        :key="idx">
                                        <td class="t-body font-bold text-capitalize">{{ item.sectionName }}</td>
                                        <td class="t-body font-bold">{{ item.position }}</td>
                                        <td class="t-body font-bold">
                                            <label class="text-blue text-sm"

                                                   title="Click to view document"
                                                   style="cursor: pointer">
                                                <b-icon-eye></b-icon-eye>
                                                <a target="_blank"
                                                   :href="baseUrl+'/configuration/user-manual/'+item.filename">
                                                    View
                                                </a>

                                            </label>
                                        </td>
                                        <td class="t-body font-bold text-center">
                                            <span>{{ item.createdAt }}</span>
                                        </td>

                                        <td class="t-body font-bold text-center">
                                            <button class="text-white text-sm bg-red px-2 py2"
                                                    @click="deleteUserManual(item.id)">
                                                <i class="fa fa-times"></i>
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </b-col>
                        </b-row>


                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import server from "@/app/env/server.json"

export default {
    name: "UserManuals",
    data() {
        return {
            manuals: [],
            spinner: false,
            form: {
                name: null,
                position: null,
                file: null
            },
            file: null,
            rows: {
                odd: "border-t border-grey hover:bg-header_grey",
                even: "border-t border-grey bg-header_grey"
            },
            loading: false,
            baseUrl: null,
            limit: 7,
            pagination: {
                totalElements: 0,
                totalPages: 0
            },

        }
    },
    mounted() {
        this.fetchManuals();
        this.baseUrl = server.IP

    },
    methods: {
        ...mapActions(["createManual", "fetchManuals", "deleteManual"]),


        save() {
            this.loading = true
            let formData = new FormData()

            formData.set("position", this.form.position);
            formData.set("sectionName", this.form.name);
            formData.set("file", this.form.file);
            // this.warningToast("New Feature", "Under construction")
            this.createManual(formData)
        },
        openForm() {
            this.$refs.addManual.show();
        },
        closeForm() {
            this.$refs.addManual.hide();
        },
        deleteUserManual(id) {
            this.showConfirmDialog().then(value => {
                if (value) {
                    this.deleteManual(id);
                }
            })

        }
    },
    computed: {
        ...mapGetters(["getManualCreated", "getManuals", "getManualDeleted", "getManualsError"])
    },
    watch: {
        getManuals(data) {
            this.manuals = data.data
        },
        getManualDeleted() {
            this.loading = false
            this.successToast("Success", "Manual deleted successfully")
            this.fetchManuals();
        },
        getManualCreated() {
            this.loading = false
            this.closeForm()
            this.successToast("Success", "Manual created successfully")
            this.fetchManuals()
        },
        getManualsError(error) {
            this.loading = false
            this.errorToast("Error", error.message)
        }
    }
};
</script>

<style scoped>

</style>