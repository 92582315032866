<template>
  <div class="overflow-hidden bg-gray p-2">
    <b-row>
      <b-col cols="12" v-if="file">
        <iframe class="pdf" :src="'data:application/pdf;base64,'+file.Base64FileStream">
        </iframe>
      </b-col>
      <b-col cols="12" v-else class="bg-gray text-center h-screen">
        <p class="text-white h3 mt-5">Select a document to view it</p>
      </b-col>
    </b-row>
  </div>
</template>
<script>

export default {
  name: "EDMSDocument",
  props:{
    file:Object
  }
}
</script>

<style scoped>
.pdf{
  width: 100%;
  height: 1000px;
  overflow-x: auto;
  border:1px solid #b1b1b1;
}
</style>