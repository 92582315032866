<template>
  <v-row class="fill-height" style="background-color: #f7f7f8;">
    <v-col lg="5" class="mx-auto" style="margin-top: 10%;">
      <v-card tile class="text-center">
        <v-card-text style="font-size: 90px; padding-top: 90px;">
          4<span style="color: #00b7ff;">0</span>3
        </v-card-text>

        <v-card-text style="font-size: 20px;" class="mt-3">
          Forbidden - that's all we know
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text style="font-size: 17px;" class="mt-3">
          For us to be sure you have the correct permissions.
        </v-card-text>
        <v-card-text style="font-size: 17px; padding-bottom: 90px;">
          Kindly
          <a @click="doLogout" class="text-decoration-none"
          >Click here to login again</a>

        </v-card-text>
        <v-card-text style="font-size: 17px;">
          <a class="text-decoration-none" @click="goToHome"
          >Or Go to home page</a>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: "Error404",
  mounted() {

  },

  methods: {
    async doLogout() {
      await sessionStorage.clear();
      sessionStorage.setItem("clearSessionStorage", JSON.stringify(sessionStorage));
      sessionStorage.removeItem("clearSessionStorage");
      let r = this.$router.resolve({
        name: "login",
      });
      window.location.assign(r.href)
    },
    goToHome() {
      let r = this.$router.resolve({
        name: "user_dashboard",
      });
      window.location.assign(r.href)
    }
  }
};
</script>

<style scoped></style>
