<template>
  <b-row>
    <b-col cols="12">
      <b-row class="pt-1">
        <b-col cols="12">
          <div class="flex h-full" style="cursor: pointer;">
            <div :class="[(currentRoute == 'members') ? 'menu-item-active' : 'menu-item']" @click="select('members')">
              <div class="flex mt-6">
                <div style="font-size: 14px">Members</div>
                <b-badge class="bg-blue mb-1 mx-1 ">
                  {{society.members.length}}
                </b-badge>
              </div>
            </div>
            <div :class="[(currentRoute == 'details') ? 'menu-item-active' : 'menu-item']" @click="select('details')">
              <div class="flex mt-6">
                <div style="font-size: 14px">Address</div>
              </div>
            </div>
            <div :class="[(currentRoute == 'documents') ? 'menu-item-active' : 'menu-item']" @click="select('documents')">
              <div class="flex mt-6">
                <div style="font-size: 14px">Documents</div>
                <b-badge class="bg-blue mx-1 mb-1">
                  {{society.attachments.length}}
                </b-badge>
              </div>
            </div>
            <div :class="[(currentRoute === 'filing-history') ? 'menu-item-active' : 'menu-item']" @click="select('filing-history')">
              <div class="flex mt-6">
                <div style="font-size: 14px">Filing History</div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-row>
            <BuildingSocietyMembers :members="society.members" v-if="currentRoute === 'members'"/>
            <BuildingSocietyDetails :file="society" v-if="currentRoute === 'details'"/>
            <BuildingSocietyDocuments :documents="society.attachments" v-if="currentRoute === 'documents'"/>
            <FilingHistory :brn="society.brn" v-if="currentRoute == 'filing-history'"/>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import BuildingSocietyMembers
  from "@/views/home/services/registry/business/files/tabs/building_society_data/BuildingSocietyMembers";
import BuildingSocietyDetails
  from "@/views/home/services/registry/business/files/tabs/building_society_data/BuildingSocietyDetails";
import BuildingSocietyDocuments
  from "@/views/home/services/registry/business/files/tabs/building_society_data/BuildingSocietyDocuments";
import FilingHistory from "@/views/home/services/registry/business/files/FilingHistory";
export default {
  name: "BuildingSocietyFile",
  components: {FilingHistory, BuildingSocietyDocuments, BuildingSocietyDetails, BuildingSocietyMembers},
  props:{
    society:Object
  },
  data(){
    return{
      currentRoute:"members",
    }
  },
  methods:{
    select(route){
      this.currentRoute = route
    },
  }
}
</script>

<style scoped>

</style>