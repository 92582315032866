<template>
  <b-row>
    <b-col>
      <b-row>
        <b-modal ref="addGroup" title="Create Group" hide-footer>
          <NewGroup/>
        </b-modal>
        <b-modal ref="addPermission" title="Create Permission" hide-footer>
          <NewPermission/>
        </b-modal>
      </b-row>
      <b-row>
        <b-col>
          <b-row class="my-2">
            <b-col cols="8"></b-col>
            <b-col cols="2">
              <button class="w-full bg-blue text-white px-2 py-1 rounded-sm" @click="openFormPermission">
                <i class="fa fa-plus"></i>
                Add Permission
              </button>
            </b-col>
            <b-col cols="2">
              <button class="w-full bg-blue text-white px-2 py-1 rounded-sm" @click="openFormGroup">
                <i class="fa fa-plus"></i>
                Add Group
              </button>
            </b-col>
          </b-row>
          <div class="mx-auto">
            <b-progress value="100" v-if="loading"  variant="#007ac5" height="0.2rem" animated></b-progress>
          </div>
          <b-row>
            <b-col cols="12">
              <table class="min-w-full leading-normal">
                <thead>
                <tr class="border-b border-t border-grey">
                  <th class="t-header">Name</th>
                  <th class="t-header">Permissions</th>
                  <th class="t-header">Action</th>
                </tr>
                </thead>

                <tbody>
                <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(group, idx) in groups" :key="idx">
                  <td class="t-body text-lowercase">
                    <span class="font-monospace text-xs">{{cleanUnderscoreString(group.name.replace('-', ' '))}}</span>
                  </td>
                  <td class="t-body">
                    <p v-for="(permission, idx) in group.permissions" :key="idx">{{permission}}, </p>
                  </td>
                  <td class="t-body text-center">
                    <b-row>
                      <b-col>
                        <i class="fa fa-edit mr-1 text-blue hover:text-blue-dark" style="font-size: 16px;"/>
                      </b-col>
                    </b-row>
                  </td>
                </tr>
                <tr>
                  <td v-if="groups.length === 0" colspan="12" class="bg-inertgrey text-center py-1">
                    <span class="text-sm">No results found</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NewGroup from "@/views/home/services/accounts/permissions/components/NewGroup";
import NewPermission from "@/views/home/services/accounts/permissions/components/NewPermission";

export default {
  name: "AccountPermissionGroups",
  components: { NewPermission, NewGroup },
  data(){
    return{
      loading:false,
      currentPage:0,
      limit:10,
      groups:[],
      pagination:{
        totalElements: 0,
        totalPages:0
      },
      rows:{
        odd:"border-t border-grey hover:bg-header_grey",
        even:"border-t border-grey bg-header_grey"
      },
      search:"",
      status:null,
      type:null
    }
  },
  mounted() {
    this.loading = true
    this.fetchPermissionGroups()
  },
  methods:{
    ...mapActions(["fetchRoles", "fetchRoleDetails", "fetchPermissions",
      "fetchPermissionGroups", "saveNewRole", "addPermission", "addPermissionGroup"]),
    openFormPermission(){
      this.$refs.addPermission.show()
    },
    openFormGroup(){
      this.$refs.addGroup.show()
    }
  },
  computed:{
    ...mapGetters(["getRoleDetails", "getPermissionList",
      "getRolesError", "getPermissionGroupsList",
      "getNewPermissionGroup", "getNewRole", "getNewRoleError"])
  },
  watch:{
    getPermissionGroupsList(data){
      this.loading = false
      this.groups = data
    },
    getNewPermissionGroup(){
      this.$refs.addGroup.hide()
    },
    getNewPermission() {
      this.$refs.addPermission.hide()
    }
  }
};
</script>

<style scoped>

</style>