<template>
  <b-col>
    <b-row>
      <b-overlay :show="loading" rounded="sm">
        <b-col cols="12" class="mx-auto mt-1">
          <b-row class="mx-1 py-1">
            <b-col>
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="Country"
                label-for="input-1">
                <b-form-input
                  id="companyName"
                  class="form-text text-sm noFocus"
                  type="text"
                  disabled="true"
                  :value="getCompanyAddress.data.village.country.name"
                  required>
                </b-form-input>
              </b-form-group>
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="Region"
                label-for="input-1">
                <b-form-input
                  id="companyName"
                  class="form-text text-sm noFocus"
                  type="text"
                  disabled="true"
                  :value="getCompanyAddress.data.village.region.name"
                  required>
                </b-form-input>
              </b-form-group>
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="District"
                label-for="input-1">
                <b-form-input
                  id="companyName"
                  class="form-text text-sm noFocus text-capitalize"
                  type="text"
                  disabled="true"
                  :value="getCompanyAddress.data.village.district.name.toLowerCase()"
                  required>
                </b-form-input>
              </b-form-group>
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="Sub County"
                label-for="input-1">
                <b-form-input
                  id="companyName"
                  class="form-text text-sm noFocus text-capitalize"
                  type="text"
                  disabled="true"
                  :value="getCompanyAddress.data.village.subcounty.name"
                  required>
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="Parish"
                label-for="input-1">
                <b-form-input
                  id="companyName"
                  class="form-text text-sm noFocus"
                  type="text"
                  disabled="true"
                  :value="getCompanyAddress.data.village.parish.name"
                  required>
                </b-form-input>
              </b-form-group>
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="Village"
                label-for="input-1">
                <b-form-input
                  id="companyName"
                  class="form-text text-sm noFocus text-capitalize"
                  type="text"
                  disabled="true"
                  :value="getCompanyAddress.data.village.village.name"
                  required>
                </b-form-input>
              </b-form-group>
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="Plot"
                label-for="input-1">
                <b-form-input
                  id="companyName"
                  class="form-text text-sm noFocus"
                  type="text"
                  disabled="true"
                  :value="getCompanyAddress.data.address.plot"
                  required>
                </b-form-input>
              </b-form-group>
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="Street"
                label-for="input-1">
                <b-form-input
                  id="companyName"
                  class="form-text text-sm noFocus text-capitalize"
                  type="text"
                  disabled="true"
                  :value="getCompanyAddress.data.address.street"
                  required>
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="Postal Address"
                label-for="input-1">
                <b-form-input
                  id="companyName"
                  class="form-text text-sm noFocus text-capitalize"
                  type="text"
                  disabled="true"
                  :value="getCompanyAddress.data.address.postalAddress"
                  required>
                </b-form-input>
              </b-form-group>
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="Description"
                label-for="input-1">
                <b-form-textarea
                  id="companyName"
                  class="form-text text-sm noFocus text-capitalize"
                  type="text"
                  rows="4"
                  disabled="true"
                  :value="getCompanyAddress.data.address.description"
                  required>
                </b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-overlay>
    </b-row>
  </b-col>
</template>

<script>
import {mapGetters, mapActions} from "vuex";

export default {
  name: "ForeignCompanyAddress",
  props:{
    brn:String
  },
  data(){
    return{
      loading:true
    }
  },
  mounted(){
    this.fetchCompanyAddress(this.brn)
  },
  methods:{
    ...mapActions(["fetchCompanyAddress", "fetchFilingCompliance"])
  },
  computed:{
    ...mapGetters(["getCompanyAddress", "getFilingCompliance"])
  },
  watch:{
    getCompanyAddress(){
      this.loading = false
    },
    getFilingCompliance(data){
      this.lockDown = this.determineLockDown(data.data)
    }
  }
}
</script>

<style scoped>

</style>