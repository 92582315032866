<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col cols="12" class="mt-3">
          <b-row>
            <span>URA API Credentials Setup</span>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6" class="py-2">
          <b-row>
            <b-col>

              <b-row>
                <b-col cols="10">
                  <b-form-input class="my-2" type="password" placeholder="Access Key" v-model="form.key"></b-form-input>
                </b-col>
                <b-col cols="2">
                  <button class="w-full bg-white border-2 border-blue text-blue px-2 py-1 rounded-sm my-2" @click="save">
                    <i class="fa fa-save"></i>
                    <b-spinner v-if="spinner" small></b-spinner>
                  </button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="6" ></b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "URACredentials",
  data() {
    return {
      spinner: false,
      form: {
        key: null
      }
    };
  },
  methods: {
    save() {

    }
  }
};
</script>

<style scoped>

</style>