// import axios from "axios";
import {api} from "@/app/services/api.service"

const state = {
    error: null,
    success: null,
    dictionaries: [],
    reservationsExpiryTimes: [],

};

const getters = {
    getDictionaryAdd: (state) => state.success,
    getDictionaryAddError: (state) => state.error,
    getDictionaryFetch: (state) => state.dictionaries,
    getReservationExpiryTimes: (state) => state.reservationsExpiryTimes,
    getAddStatus: (state) => state.success,
    getAddError: (state) => state.error,
};

const actions = {

    async fetchDictionary({commit}) {
        api().get("namesearch/dictionary")
            .then(response => {
                commit("setDictionaryFetch", response);
            })
            .catch(error => {
                commit("setDictionaryError", error);
            });
    },

    async addDictionary({commit}, data) {
        api().post("namesearch/dictionary", data)
            .then(response => {
                commit("setDictionary", response);
            })
            .catch(error => {
                commit("setDictionaryError", error);
            });
    },
    async addReservationExpiryTime({commit}, data) {
        await api().post("reservation/settings/expiry", data)
            .then(response => {
                commit("setReservationExpiryAdd", response);
            })
            .catch(error => {
                commit("setReservationExpiryAddError", error);
            });
    }
    ,
    async fetchReservationExpiryTimes({commit}) {
        await api().get("reservation/settings/expiry")
            .then(response => {
                commit("setReservationExpiryTimes", response);
            })
            .catch(error => {
                commit("setReservationExpiryAddError", error);
            });
    },
    async addReservationNotificationTime({commit}, data) {
        await api().post("reservation/settings/notifications", data)
            .then(response => {
                commit("setReservationExpiryAdd", response);
            })
            .catch(error => {
                commit("setReservationExpiryAddError", error);
            });
    }
};

const mutations = {
    setDictionary: (state, response) => {
        state.success = response.data;
    },
    setDictionaryError: (state, error) => {
        state.error = error.response.data;
    }, setDictionaryFetch: (state, response) => {
        state.dictionaries = response.data;
    },
    setDictionaryFetchError: (state, error) => {
        state.error = error.response.data;
    },
    setReservationExpiryTimes: (state, response) => {
        state.reservationsExpiryTimes = response.data;
    },
    setReservationExpiryAdd: (state, response) => {
        state.success = response.data;
    },
    setReservationExpiryAddError: (state, error) => {
        state.error = error.response.data;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
