<template>
  <b-row>
    <b-col>
      <b-row>
        <b-modal ref="transactionDetails" title="Transaction Details" hide-footer size="lg">
          <b-row v-if="selectedTransaction">
            <b-col>
              <b-row>
                <b-col >
                  <p>Payment Ref Number: {{selectedTransaction.prn}}</p>
                  <p>Tracking Number: {{selectedTransaction.trackingNumber}}</p>
                  <p>Invoice Number: {{selectedTransaction.invoiceNo}}</p>
                  <p>Transaction Ref: {{selectedTransaction.transactionRef}}</p>
                  <p>Service Name: {{cleanEntityType(selectedTransaction.transactionType)}}</p>
                  <p>Transaction Date: {{dateFormat(selectedTransaction.createdDate)}}</p>
                </b-col>
                <b-col >
                  <p>Total Amount Paid: {{selectedTransaction.grandTotal.toLocaleString()}}</p>
                  <p>Sub Total: {{selectedTransaction.subTotal.toLocaleString()}}</p>
                  <p>Total Tax: {{selectedTransaction.totalTax.toLocaleString()}}</p>
                  <p>Payment Method: {{cleanUnderscoreString(selectedTransaction.paymentMethod)}}
                    (<span v-if="selectedTransaction.paymentMethod === 'MOBILE_MONEY'" class="text-blue text-xs">{{selectedTransaction.msisdn}}</span>)
                  </p>
                  <p>Transaction Status: <b :class="statusStyling(selectedTransaction.status)">{{selectedTransaction.status}}</b></p>
                  <p>PRN Status: <b :class="statusStyling(selectedTransaction.prnStatus)">{{selectedTransaction.prnStatus}}</b></p>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col cols="8"></b-col>
                <b-col cols="4">
                  <button class="w-full bg-red px-2 py-1 rounded-sm text-white mx-1" @click="retryVerification(selectedTransaction, -1)">
                    <i class="fa fa-redo mx-1"></i> Retry Transaction <b-spinner v-if="retrying" small></b-spinner>
                  </button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-modal>
      </b-row>
      <b-row>
        <b-col cols="12" class="mx-3 my-3">
          <b-row>
            <span class="title">Transactions</span>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="my-2">
        <b-col cols="2">
          <b-form-input
            class="text-gray text-sm noFocus ml-1"
            style="margin-top: 1px; padding:6px 5px;"
            v-model="trackingNumber"
            type="text"
            placeholder="Search by Tracking No"
            required
            v-on:keyup.enter="fetch()">
          </b-form-input>
        </b-col>
        <b-col cols="2">
          <b-form-input
            class="text-gray text-sm noFocus ml-1"
            style="margin-top: 1px; padding:6px 5px;"
            v-model="prn"
            type="text"
            placeholder="Search by PRN"
            required
            v-on:keyup.enter="fetch()">
          </b-form-input>
        </b-col>

        <b-col cols="2">
          <b-form-select
            class="form-select text-sm noFocus text-gray"
            v-model="status"
            @change="fetch()"
            v-on:keyup.enter="fetch">
            <b-select-option :value="null">--Filter by Status--</b-select-option>
            <b-select-option value="PENDING">Pending</b-select-option>
            <b-select-option value="COMPLETED">Completed</b-select-option>
            <b-select-option value="CANCELLED">Canceled</b-select-option>
            <b-select-option value="FAILED">Failed</b-select-option>
          </b-form-select>
        </b-col>
        <b-col cols="2">
          <b-form-select
            class="form-select text-sm noFocus text-gray"
            v-model="prnStatus"
            @change="fetch()"
            v-on:keyup.enter="fetch">
            <b-select-option :value="null">--Filter by PRN Status--</b-select-option>
            <b-select-option value="PENDING">Pending</b-select-option>
            <b-select-option value="CREDITED">Credited</b-select-option>
            <b-select-option value="FAILED">Failed</b-select-option>
          </b-form-select>
        </b-col>
        <b-col cols="2">
          <b-form-select
            class="form-select text-sm noFocus text-gray"
            v-model="paymentMethod"
            @change="fetch()"
            v-on:keyup.enter="fetch">
            <b-select-option :value="null">--Filter by Payment--</b-select-option>=
            <b-select-option value="MOBILE_MONEY">Mobile Money</b-select-option>
            <b-select-option value="ALREADY_PAID">Already Paid</b-select-option>
            <b-select-option value="COUNTER">Counter</b-select-option>
          </b-form-select>
        </b-col>
      </b-row>
      <div class="mx-auto">
        <b-progress value="100" v-if="loading" variant="#007ac5" height="0.2rem" animated></b-progress>
      </div>
      <b-row>
        <b-col>
          <table class="min-w-full leading-normal">
            <thead>
            <tr class="border-b border-t border-grey">
              <th class="t-header">#</th>
              <th class="t-header">PRN</th>
              <th class="t-header">Tracking No.</th>
              <th class="t-header">Service</th>
              <th class="t-header">Payment Method</th>
              <th class="t-header">Total</th>
              <th class="t-header text-center">PRN Status</th>
              <th class="t-header text-center">Status</th>
              <th class="t-header text-center">Date</th>
              <th class="t-header text-center">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(transaction, idx) in transactions" :key="idx">
              <td class="t-body ">{{(currentPage - 1) * limit + idx + 1}}</td>
              <td class="t-body text-capitalize font-bold">
                <p>{{transaction.prn}}</p>

                  <button v-if="canReleasePrn()" class="bg-white text-red px-1 text-sm"
                          @click="releasePrnPrompt(transaction, idx)">
                  <i class="fa fa-times mx-1"></i> release <b-spinner v-if="releasing && selectedIdx === idx" small></b-spinner>
                </button>
              </td>
              <td class="t-body text-capitalize font-bold text-blue">
                {{transaction.trackingNumber}}
              </td>
              <td class="t-body text-capitalize">
                {{cleanEntityType(transaction.transactionType)}}
              </td>
              <td class="t-body text-capitalize">
                {{cleanUnderscoreString(transaction.paymentMethod.toLowerCase())}}
              </td>
              <td class="t-body text-capitalize">
                <span>{{transaction.grandTotal.toLocaleString()}}</span>
              </td>
              <td class="t-body text-capitalize text-center">
                <span v-if="transaction.prnStatus" :class="statusStyling(transaction.prnStatus)">{{transaction.prnStatus.toLowerCase()}}</span>
              </td>
              <td class="t-body text-capitalize text-center">
                <span v-if="transaction.status" :class="statusStyling(transaction.status)">{{transaction.status.toLowerCase()}}</span>
              </td>
              <td class="t-body text-capitalize text-center">
                {{dateFormat(transaction.createdDate)}}
              </td>
              <td class="t-body text-center">
                <button class="bg-blue px-2 py-1 rounded-sm text-white mx-1" @click="view(transaction)">
                  View <i class="fa fa-eye mx-1"></i>
                </button>
                <button v-if="transaction.prnStatus !== 'CREDITED'"
                        class="bg-red px-2 py-1 rounded-sm text-white mx-1" @click="retryVerification(transaction, idx)">
                  <i class="fa fa-redo mx-1"></i> Retry <b-spinner v-if="retrying && (idx === selectedIdx)" small></b-spinner>
                </button>
              </td>
            </tr>
            <tr>
              <td v-if="transactions.length === 0" colspan="12" class="bg-inertgrey text-center py-1">
                <span class="text-sm">No results found</span>
              </td>
            </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
      <b-row class="mx-1" v-if="pagination.totalPages > 1">
        <b-col cols="1">
          <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
        </b-col>
        <b-col cols="1">
          <b-row>
            <b-col cols="12">
              <p class="paginate-details text-center">
                <b-badge class="bg-blue text-white">
                  {{ (currentPage) }}/{{ pagination.totalPages }}
                </b-badge>
              </p>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="1">
          <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">
            Next
          </button>
        </b-col>
        <b-col cols="6"></b-col>
        <b-col cols="1">
          <b-form-select v-model="limit" class="w-full form-select border-0 paginate-details" v-on:change="fetch">
            <b-select-option value="12">12</b-select-option>
            <b-select-option value="20">20</b-select-option>
            <b-select-option value="50">50</b-select-option>
            <b-select-option value="100">100</b-select-option>
          </b-form-select>
        </b-col>
        <b-col cols="2">
          <b-row>
            <b-col class="mx-auto">
              <p class="paginate-details text-center text-xs font-bold">
                <span v-if="currentPage === 1">1</span>
                <span v-else>{{((currentPage - 1) * limit) + 1}}</span> - {{currentPage * limit}} of {{pagination.totalElements}}
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Transactions",
  data(){
    return{
      loading:false,
      retrying:false,
      releasing:false,
      prn: null,
      trackingNumber:null,
      paymentMethod:null,
      prnStatus:null,
      status:null,
      spinner: false,
      rows: {
        odd: "border-t border-grey hover:bg-header_grey",
        even: "border-t border-grey bg-header_grey"
      },
      currentPage: 1,
      limit: 12,
      transactions:[],
      pagination: {
        totalElements: 0,
        totalPages: 0
      },
      selectedTransaction:null,
      selectedIdx:null
    }
  },
  mounted(){
    this.fetch()
  },
  methods:{
    ...mapActions(["fetchTransactions", "retryTransactionVerification", "releasePRNTransaction"]),
    fetch(){
      let filters = {
        prn: this.prn,
        prnStatus: this.prnStatus,
        status: this.status,
        paymentMethod: this.paymentMethod,
        trackingNumber: this.trackingNumber,
        page:this.currentPage,
        pageSize:this.limit
      }
      this.loading = true
      this.fetchTransactions(filters)
    },
    view(data){
      this.selectedTransaction = data
      this.$refs.transactionDetails.show()
    },
    retryVerification(data, idx){
      console.log(data)
      this.selectedIdx = idx
      let payload = {
        userId: JSON.parse(sessionStorage.getItem("current_user")).userId,
        prn: data.prn
      }
      this.retrying = true
      this.retryTransactionVerification(payload)
    },
    releasePrnPrompt(data, idx){
        this.showConfirmDialog().then(value => {
            if (value) {
                this.selectedIdx = idx
                let payload = {
                    userId: JSON.parse(sessionStorage.getItem("current_user")).userId,
                    prn: data.prn
                }
                this.releasing = true
                this.releasePRNTransaction(payload)
            }
        })

    },
    next() {
      if (this.currentPage < this.pagination.totalPages) {
        this.currentPage++;
        this.fetch();
      }
    },
    previous() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetch();
      } else {
        this.warningToast("Observation", "No more data to show");
      }
    },
    statusStyling(status){
      if(status === "CREDITED" || status === "COMPLETED"){
        return "text-green"
      }

      if(status === "FAILED"){
        return "text-red"
      }

      if(status === "PENDING"){
        return "text-gray"
      }

      if(status === "CANCELED"){
        return "text-orange"
      }
    },
      canReleasePrn() {
          return this.$can('can_release_prn', 'general');
      }
  },
  computed:{
    ...mapGetters([
      "getTransactions", "getPRNReleaseSuccess",
      "getTransactionsListError", "getPRNReleaseError",
      "getTransactionSuccess",
        "getTransactionError"]),

  },
  watch:{
    getTransactions(data){
      this.loading = false
      this.retrying = false
      this.transactions = data.content
      this.pagination = {
        totalPages: data.totalPages,
        totalElements: data.totalElements
      }
    },
    getTransactionsListError(data){
      this.loading = false
      this.retrying = false
      this.errorToast("Error", data.message)
    },
    getTransactionSuccess(data){
      this.loading = false
      this.retrying = false
      this.successToast("Success", data.message)
    },
    getTransactionError(data){
      this.loading = false
      this.retrying = false
      this.errorToast("Error", data.message)
    },
    getPRNReleaseSuccess(data){
      this.releasing = false
      this.successToast("Success", data.message)
      this.fetch()
    },
    getPRNReleaseError(data){
      this.releasing = false
      this.errorToast("Error", data.message)
    }
  }
};
</script>

<style scoped>
.paginate-details {
  @apply px-3 py-2 bg-white my-2 text-sm text-gray;
}

.paginate-btn {
  @apply px-3 py-2 bg-white border border-white my-2 text-sm text-blue w-full;
}

.paginate-btn:hover {
  @apply border border-blue text-blue rounded-sm;
}


.inert-btn {
  @apply px-3 py-2 bg-white my-2 text-sm text-grey w-full;
}
</style>