<template>
    <div class="bg-white rounded-sm text-gray mx-2 my-3">
        <b-row>
            <b-col cols="12">
                <b-row>
                    <b-col cols="12">
                        <div class="flex h-full" style="cursor: pointer;">
                            <div :class="[(currentRoute === 'client') ? 'menu-item-active' : 'menu-item']"
                                 @click="select('client')">
                                <div class="flex mt-6">
                                    <div style="font-size: 14px" class="mt-1">Client Accounts</div>
                                    <b-badge class="m-1 bg-blue" v-if="getIndividualAccountsCount.data">
                                        {{ getIndividualAccountsCount.data.toLocaleString() }}
                                    </b-badge>
                                </div>
                            </div>
                            <div :class="[(currentRoute === 'business') ? 'menu-item-active' : 'menu-item']"
                                 @click="select('business')">
                                <div class="flex mt-6">
                                    <div style="font-size: 14px" class="mt-1">Business Accounts</div>
                                    <b-badge class="m-1 bg-blue" v-if="getBusinessAccountsCount.data">
                                        {{ getBusinessAccountsCount.data.toLocaleString() }}
                                    </b-badge>
                                </div>
                            </div>
                            <div :class="[(currentRoute === 'backoffice') ? 'menu-item-active' : 'menu-item']"
                                 @click="select('backoffice')">
                                <div class="flex mt-6">
                                    <div style="font-size: 14px" class="mt-1">Backoffice Accounts</div>
                                    <b-badge class="m-1 bg-blue" v-if="getStaffAccountsCount.data">
                                        {{ getStaffAccountsCount.data.toLocaleString() }}
                                    </b-badge>
                                </div>
                            </div>
                            <div :class="[(currentRoute === 'mda') ? 'menu-item-active' : 'menu-item']"
                                 @click="select('mda')">
                                <div class="flex mt-6">
                                    <div style="font-size: 14px" class="mt-1">MDA Accounts</div>
                                    <b-badge class="m-1 bg-blue" v-if="getMdaAccountsCount.data">
                                        {{ getMdaAccountsCount.data.toLocaleString() }}
                                    </b-badge>
                                </div>
                            </div>
                            <div :class="[(currentRoute === 'private-organisations') ? 'menu-item-active' : 'menu-item']"
                                 @click="select('private-organisations')">
                                <div class="flex mt-6">
                                    <div style="font-size: 14px" class="mt-1">Private Organisations</div>
                                    <b-badge class="m-1 bg-blue" v-if="getPrivateOrganisationsAccountsCount.data">
                                        {{ getPrivateOrganisationsAccountsCount.data.toLocaleString() }}
                                    </b-badge>

                                </div>
                            </div>

                            <div :class="[(currentRoute === 'official-receiver') ? 'menu-item-active' : 'menu-item']"
                                 @click="select('official-receiver')">
                                <div class="flex mt-6">
                                    <div style="font-size: 14px" class="mt-1">Official Receivers</div>
                                    <b-badge class="m-1 bg-blue" v-if="getPrivateOrganisationsAccountsCount.data">
                                        {{ getPrivateOrganisationsAccountsCount.data.toLocaleString() }}
                                    </b-badge>

                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <ClientAccounts v-if="currentRoute === 'client'"/>
                        <BackofficeAccounts v-if="currentRoute === 'backoffice'"/>
                        <BusinessAccounts v-if="currentRoute === 'business'"/>
                        <PartnerAccounts v-if="currentRoute === 'partner'"/>
                        <MDAAccounts v-if="currentRoute === 'mda'"/>
                        <PrivateOrganisationAccounts v-if="currentRoute === 'private-organisations'"/>
                        <OfficialReceiverAccounts
                                v-if="currentRoute === 'official-receiver'"></OfficialReceiverAccounts>

                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import ClientAccounts from "@/views/home/services/accounts/breakdown/ClientAccounts";
import BackofficeAccounts from "@/views/home/services/accounts/breakdown/BackofficeAccounts";
import BusinessAccounts from "@/views/home/services/accounts/breakdown/BusinessAccounts";
import PartnerAccounts from "@/views/home/services/accounts/breakdown/PartnerAccounts";
import MDAAccounts from "@/views/home/services/accounts/breakdown/MDAAccounts";
import {mapActions, mapGetters} from "vuex";
import PrivateOrganisationAccounts from "@/views/home/services/accounts/breakdown/PrivateOrganisationAccounts.vue";
import OfficialReceiverAccounts from "@/views/home/services/accounts/breakdown/OfficialReceiverAccounts.vue";

export default {
    name: "AccountBreakdown",
    components: {
        OfficialReceiverAccounts,
        PrivateOrganisationAccounts,
        MDAAccounts,
        PartnerAccounts,
        BusinessAccounts,
        BackofficeAccounts,
        ClientAccounts
    },
    data() {
        return {
            currentRoute: "client",
        }
    },
    mounted() {
        this.fetchTotalAccountMetricsByType("INDIVIDUAL")
        this.fetchTotalAccountMetricsByType("BACKOFFICE")
        this.fetchTotalAccountMetricsByType("BUSINESS")
        this.fetchTotalAccountMetricsByType("PARTNER")
        this.fetchTotalAccountMetricsByType("MDA")
        this.fetchTotalAccountMetricsByType("PRIVATE_ORGANIZATION")
    },
    methods: {
        ...mapActions(["fetchTotalAccountMetricsByType"]),
        select(route) {
            this.currentRoute = route
        },
    },
    computed: {
        ...mapGetters(["getBusinessAccountsCount",
            "getIndividualAccountsCount",
            "getMdaAccountsCount", "getStaffAccountsCount",
            "getPrivateOrganisationsAccountsCount",
            "getPartnerAccountsCount"])
    }
};
</script>

<style scoped>

</style>