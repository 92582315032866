 <template>
  <b-row>
    <b-col>
      <b-row class="mx-1 my-2">
        <b-col cols="8" class="mx-auto px-4 border border-gray rounded-md">
          <b-row v-for="(message, idx) in messages" :key="idx">
            <b-col>
              <b-row v-if="message.user.accountType === 'INDIVIDUAL'">
                <b-col cols="8" class="py-2 rounded-md bg-header_grey my-2 text-gray">
                  <b-row>
                    <b-col>
                      <span class="text-sm font-bold text-capitalize">{{message.name}}</span>
                      <span class="bg-green rounded text-white text-capitalize px-1 ml-1"
                            style="font-size: 8px; padding-bottom: 2px; padding-top: 2px;" v-if="message.user">
                      {{message.user.accountType.toLowerCase()}}
                   </span><br/>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <span class="text-sm">{{message.description}}</span>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <span style="font-size:9px" class="text-blue italic">{{dateFormat(message.dateTime)}}</span>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="4"></b-col>
              </b-row>
              <b-row v-else>
                <b-col cols="4"></b-col>
                <b-col cols="8" class="py-2 rounded-md bg-green-pale my-2 text-gray">
                  <b-row>
                    <b-col>
                      <span class="text-sm font-bold">{{message.name}}</span>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <span class="text-sm">{{message.description}}</span>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <span style="font-size:9px" class="text-blue italic">{{dateFormat(message.dateTime)}}</span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>

          </b-row>
          <b-row v-if="messages.length == 0">
            <b-col cols="11" class="mx-auto rounded-md bg-header_grey my-2 text-gray">
              <b-row>
                <b-col>
                  <span class="text-xs font-bold">No messages yet</span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "PractitionerFilingMessages",
  data(){
    return{
      messages:[]
    }
  },
  mounted(){
    console.log(this.getPractitionerTask)
    let id = this.getPractitionerTask.processId
    //this.fetchContinuousFilingStatuses(id)
    this.fetchPractitionerTaskMessages(id)
  },
  methods:{
    ...mapActions(["fetchPractitionerTaskMessages"])

  },
  computed:{
    ...mapGetters(["getPractitionerTask", "getPractitionerMessages", "getPractitionerMessageError"])
  },
  watch:{
    getPractitionerMessages(data){
      for(let i = 0; i<data.length; i++){
        let message = data[i]
        if(message.description !== "" && message.description != null){
          this.messages.push(message)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>