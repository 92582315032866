<template>
  <div class="bg-white rounded-sm mx-2 my-3">
    <b-row>
      <b-col cols="12" class="mx-3 mt-3">
        <b-row>
          <span class="title">Insolvency Practitioners Registry</span>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <InsolvencyPractitionersRegistry/>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {mapGetters} from "vuex"
import InsolvencyPractitionersRegistry
  from "@/views/home/services/registry/practitioners/InsolvencyPractitionersRegistry";

export default {
  name: "DocumentRegistry",
  components: { InsolvencyPractitionersRegistry},
  data(){
    return{
      currentPath:this.$route.fullPath,
      items:[]
    }
  },
  mounted() {
    let paths = this.$route.path.split('/')
    let basePath = "/home/registry/"
    for(let i = 3; i<paths.length; i++){
      basePath += paths[i]
      let path = {
        text:paths[i].toLowerCase().replaceAll("-", " "),
        href:basePath
      }
      this.items.push(path)
    }
  },
  computed:{
    ...mapGetters(["getPractitionerRegistryError"])
  },

}
</script>

<style scoped>
@import "../../../../assets/styles/menu.css";

</style>