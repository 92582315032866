<template>
  <b-row>
    <b-col cols="12">
      <b-row class="pt-1">
        <b-col cols="12">
          <div class="flex h-full" style="cursor: pointer;">
            <div :class="[(currentRoute === 'subscribers') ? 'menu-item-active' : 'menu-item']" @click="select('subscribers')">
              <div class="flex mt-6">
                <div style="font-size: 14px" v-if="company.subType !== 'private-company-limited-by-guarantee-without-shares'">Subscribers</div>
                <div style="font-size: 14px" v-else>Members</div>
                <b-badge class="bg-blue mb-1 mx-1 ">
                  {{subscribersTotal}}
                </b-badge>
              </div>
            </div>
            <div :class="[(currentRoute === 'directors') ? 'menu-item-active' : 'menu-item']" @click="select('directors')">
              <div class="flex mt-6">
                <div style="font-size: 14px">Directors</div>
                <b-badge class="bg-blue mx-1 mb-1">
                  {{(directorsTotal)}}
                </b-badge>
              </div>
            </div>
            <div :class="[(currentRoute === 'secretary') ? 'menu-item-active' : 'menu-item']" @click="select('secretary')">
              <div class="flex mt-6">
                <div style="font-size: 14px">Secretary</div>
                <b-badge class="bg-blue mx-1 mb-1">
                  {{(secretaryTotal)}}
                </b-badge>
              </div>
            </div>
            <div v-if="company.subType !== 'private-company-limited-by-guarantee-without-shares'" :class="[(currentRoute === 'shares') ? 'menu-item-active' : 'menu-item']" @click="select('shares')">
              <div class="flex mt-6">
                <div style="font-size: 14px">Share Information</div>
              </div>
            </div>
            <div :class="[(currentRoute === 'address') ? 'menu-item-active' : 'menu-item']" @click="select('address')">
              <div class="flex mt-6">
                <div style="font-size: 14px">Address</div>
              </div>
            </div>
            <div :class="[(currentRoute === 'documents') ? 'menu-item-active' : 'menu-item']" @click="select('documents')">
              <div class="flex mt-6">
                <div style="font-size: 14px">Documents</div>
                <b-badge class="bg-blue mx-1 mb-1">
                  {{company.documents.length}}
                </b-badge>
              </div>
            </div>
            <div :class="[(currentRoute === 'filing-history') ? 'menu-item-active' : 'menu-item']" @click="select('filing-history')">
              <div class="flex mt-6">
                <div style="font-size: 14px">Filing History</div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-row>
            <CompanySubscribers v-if="currentRoute === 'subscribers'"/>
            <CompanyDirectors v-if="currentRoute === 'directors'"/>
            <CompanySecretary v-if="currentRoute === 'secretary'"/>
            <CompanyShares :brn="company.brn" v-if="currentRoute === 'shares' && company.subType !== 'private-company-limited-by-guarantee-without-shares'"/>
            <CompanyAddress :is-filed="getFilingCompliance.data.formCompliance.complianceForForm18" :brn="company.brn" v-if="currentRoute === 'address'"/>
            <CompanyDocuments :brn="company.brn" :documents="company.documents" v-if="currentRoute === 'documents'"/>
            <FilingHistory :brn="company.brn" v-if="currentRoute === 'filing-history'"/>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import CompanySubscribers from "@/views/home/services/registry/business/files/tabs/company_data/CompanySubscribers";
import CompanyDirectors from "@/views/home/services/registry/business/files/tabs/company_data/CompanyDirectors";
import CompanyShares from "@/views/home/services/registry/business/files/tabs/company_data/CompanyShares";
import CompanyAddress from "@/views/home/services/registry/business/files/tabs/company_data/CompanyAddress";
import CompanyDocuments from "@/views/home/services/registry/business/files/tabs/company_data/CompanyDocuments";
import FilingHistory from "@/views/home/services/registry/business/files/FilingHistory";
import {mapActions, mapGetters} from "vuex";
import CompanySecretary from "@/views/home/services/registry/business/files/tabs/company_data/CompanySecretary";
export default {
  name: "CompanyFile",
  components: {
    CompanySecretary, FilingHistory, CompanyDocuments, CompanyAddress, CompanyShares, CompanyDirectors, CompanySubscribers
  },
  props:{
    company:Object
  },
  data(){
    return{
      currentRoute:"subscribers",
      subscribersTotal:0,
      directorsTotal:0,
      secretaryTotal:0
    }
  },
  mounted() {
    this.fetchCompanySubscribers(this.company.brn)
    this.fetchCompanyDirectors(this.company.brn)
    this.fetchCompanySecretary(this.company.brn)
    this.fetchFilingCompliance(this.company.brn)
  },
  methods:{
    ...mapActions(["fetchCompanyDirectors", "fetchCompanySecretary", "fetchCompanySubscribers", "fetchFilingCompliance"]),
    select(route){
      this.currentRoute = route
    },
  },
  computed:{
    ...mapGetters(["getCompanySubscribers", "getCompanyDirectors", "getCompanySecretary", "getFilingCompliance"])
  },
  watch:{
    getCompanySubscribers(data){
      this.subscribersTotal = data.data.content.length
    },
    getCompanyDirectors(data){
      this.directorsTotal = data.data.content.length
    },
    getCompanySecretary(data){
      if(data){
        this.secretaryTotal = 1
      }
    }
  }
}
</script>

<style scoped>

</style>