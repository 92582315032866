<template>
    <div class="bg-white rounded-sm text-gray mx-2 my-3">
        <b-row>
            <b-col cols="12" class="mx-3 mt-3">
                <b-row>
                    <span class="title">Insolvency Settings</span>
                </b-row>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-row>
                    <b-col cols="12">
                        <div class="flex h-full" style="cursor: pointer;">


                            <div :class="[(currentRoute === 'academic') ? 'menu-item-active' : 'menu-item']"
                                 @click="select('academic')">
                                <div class="flex mt-6">
                                    <div style="font-size: 14px" class="mt-1">Academic Qualifications Manuals</div>
                                </div>
                            </div>
                            <div :class="[(currentRoute === 'bodies') ? 'menu-item-active' : 'menu-item']"
                                 @click="select('bodies')">
                                <div class="flex mt-6">
                                    <div style="font-size: 14px" class="mt-1">Professional Bodies</div>
                                </div>
                            </div>

                            <div :class="[(currentRoute === 'professional') ? 'menu-item-active' : 'menu-item']"
                                 @click="select('professional')">
                                <div class="flex mt-6">
                                    <div style="font-size: 14px" class="mt-1">Professional Qualifications</div>
                                </div>
                            </div>

                            <div :class="[(currentRoute === 'reasons') ? 'menu-item-active' : 'menu-item']"
                                 @click="select('reasons')">
                                <div class="flex mt-6">
                                    <div style="font-size: 14px" class="mt-1">Winding Up Reasons</div>
                                </div>
                            </div>

                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <ProfessionalBodies v-if="currentRoute === 'bodies'"/>
                        <AcademicQualifications v-if="currentRoute === 'academic'"/>
                        <ProfessionalQualifications v-if="currentRoute === 'professional'"></ProfessionalQualifications>
                        <WindingUpReasons v-if="currentRoute=== 'reasons'"></WindingUpReasons>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import ProfessionalBodies from "@/views/home/services/settings/insolvency/proffessionalBodies/ProfessionalBodies.vue";
import AcademicQualifications
    from "@/views/home/services/settings/insolvency/academicQualifications/AcademicQualifications.vue";
import ProfessionalQualifications
    from "@/views/home/services/settings/insolvency/professionalQualifications/ProfessionalQualifications.vue";
import WindingUpReasons from "@/views/home/services/settings/insolvency/reasons/WindingUpReasons.vue";

export default {
    name: "InsolvencySettings",
    components: {WindingUpReasons, ProfessionalQualifications, AcademicQualifications, ProfessionalBodies},
    data() {
        return {
            currentRoute: "academic"
        };
    },
    methods: {
        select(route) {
            this.currentRoute = route;
        }
    }
};
</script>

<style scoped>
@import "../../../../assets/styles/menu.css";
</style>