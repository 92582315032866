<template>
    <b-row>
        <b-col>
            <b-row>
                <b-modal ref="addRole" title="Add Role" size="xl" hide-footer>
                    <CreateRole :role-id="role_uid" v-on:finished="closeDialog"/>
                </b-modal>
            </b-row>
            <b-row class="my-2">
                <b-col cols="10"></b-col>
                <b-col cols="2">
                    <button class="w-full bg-blue text-white px-2 py-1 rounded-sm" @click="openForm">
                        <i class="fa fa-plus"></i>
                        Add Role
                    </button>
                </b-col>
            </b-row>
            <div class="mx-auto">
                <b-progress value="100" v-if="loading" variant="#007ac5" height="0.2rem" animated></b-progress>
            </div>
            <b-row>
                <b-col cols="12">
                    <table class="min-w-full leading-normal">
                        <thead>
                        <tr class="border-b border-t border-grey">
                            <th class="t-header">Name</th>
                            <th class="t-header">Description</th>
                            <th class="t-header">Permissions</th>
                            <th class="t-header">Action</th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(role, idx) in roles" :key="idx">
                            <td class="t-body font-bold">
                                {{ role.name }}
                            </td>
                            <td class="t-body">
                                <span>{{ role.description }}</span>
                            </td>
                            <td class="t-body">
                                <label v-for="(permission, idx) in role.permissions" :key="idx" class="mr-1">
                                    {{ permission.permission }},
                                </label>
                            </td>
                            <td class="t-body">
                                <i @click="editRole(role)" class="fa fa-edit text-blue hover:text-blue-dark"
                                   style="font-size: 16px;"></i>
                            </td>
                        </tr>
                        <tr>
                            <td v-if="roles.length === 0" colspan="12" class="bg-inertgrey text-center py-1">
                                <span class="text-sm">No results found</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </b-col>
            </b-row>
            <b-row class="mx-1" v-if="pagination.totalPages > 1">
                <b-col cols="1">
                    <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
                </b-col>
                <b-col cols="1">
                    <b-row>
                        <b-col cols="12">
                            <p class="paginate-details text-center">
                                <b-badge class="bg-blue text-white">
                                    {{ (currentPage + 1) }}/{{ pagination.totalPages }}
                                </b-badge>
                            </p>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="1">
                    <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">
                        Next
                    </button>
                </b-col>
                <b-col class="9"></b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import CreateRole from "@/views/home/services/accounts/permissions/components/CreateRole";

export default {
    name: "AccountRoles",
    components: {CreateRole},
    data() {
        return {
            loading: false,
            currentPage: 0,
            limit: 10,
            roles: [],
            pagination: {
                totalElements: 0,
                totalPages: 0
            },
            rows: {
                odd: "border-t border-grey hover:bg-header_grey",
                even: "border-t border-grey bg-header_grey"
            },
            search: "",
            status: null,
            type: null,
            role_uid: null
        }
    },
    mounted() {
        this.fetch()
    },
    methods: {
        ...mapActions(["fetchRoles", "fetchRoleDetails", "fetchPermissions",
            "fetchPermissionGroups", "saveNewRole", "addPermission", "addPermissionGroup"]),
        fetch() {
            this.loading = true
            this.fetchRoles()
        },
        openForm() {

            this.$refs.addRole.show()
        },

        editRole(item) {
            this.newRoleData = item
            this.fetchRoleDetails(item.roleId)
            this.role_uid = item.role_uid
            this.$refs.addRole.show()

        },
        closeDialog() {
            this.fetch()
            this.$refs.addRole.hide()
        },
        previous() {

        },
        next() {

        }
    },
    computed: {
        ...mapGetters(["getRoleDetails", "getRolesList", "getPermissionList",
            "getRolesError", "getPermissionGroupsList",
            "getNewPermissionGroup", "getNewRole", "getNewRoleError"])
    },
    watch: {
        getRolesList(data) {
            this.loading = false
            this.roles = data
        }
    }
};
</script>

<style scoped>

</style>