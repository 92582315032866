import axios from "axios"
//import jwtService from "./jwt.service"
import server from "@/app/env/server.json"
import jwtService from "@/app/services/jwt.service";


export const api = (ip) => {
    let IP = server.IP
    if (ip != null) {
        IP = ip
    }
    return axios.create({
        baseURL: IP,
        // withCredentials: false,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: 'Bearer ' + jwtService.getToken()
        }
    })
}

export const authApi = () => {
    return axios.create({
        baseURL: server.IP,
        withCredentials: false,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        }
    })
}

export default { authApi, api }
