import {api} from "@/app/services/api.service";

const state = {
    company_documents:null,
    business_name_documents:null,
    building_society_documents:null,
    documents_error:null,
};

const getters = {
    getCompanyDocuments: (state) => state.company_documents,
    getBusinessNameDocuments: (state) => state.business_name_documents,
    getBuildingSocietyDocuments: (state) => state.building_society_documents,
    getDocumentFetcherError: (state) => state.documents_error,

};

const actions = {
    async fetchBusinessNameDocuments({commit}, data) {
        let path = "/business-registration/attachment/"+data.brn+"?page="+data.page+"&per_page="+data.limit
        if(data.search !== ""){
            path += "&search="+data.search
        }
        await api()
            .get(path)
            .then(response => {
                commit("setBusinessNameDocument", response.data);
            })
            .catch(error => {
                commit("setDocumentFetchError", error.response);
            });
    },
    async fetchCompanyDocuments({commit}, data) {
        let path = "/incorporation/companies/continuous-filing/get-registered-documents/"+data.brn+"?page="+data.page+"&pageSize="+data.limit
        if(data.search !== ""){
            path += "&search="+data.search
        }
        await api()
          .get(path)
          .then(response => {
              commit("setCompanyDocument", response.data);
          })
          .catch(error => {
              commit("setDocumentFetchError", error.response);
          });
    },
    async fetchBuildingSocietyDocuments({commit}, data) {
        let path = "/building-society/societies/get-registered-documents/"+data.brn+"?page="+data.page+"&pageSize="+data.limit
        if(data.search !== ""){
            path += "&search="+data.search
        }
        await api()
          .get(path)
          .then(response => {
              commit("setBuildingSocietyDocument", response.data);
          })
          .catch(error => {
              commit("setDocumentFetchError", error.response);
          });
    },
};

const mutations = {
    setCompanyDocument: (state, response) => {
        state.company_documents = response.data;
    },
    setBusinessNameDocument: (state, response) => {
        state.business_name_documents = response;
    },
    setBuildingSocietyDocument: (state, response) => {
        state.building_society_documents = response;
    },
    setDocumentFetchError: (state, response) => {
        state.documents_error = response;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
