<template>
  <b-col>
    <b-row>
      <b-col cols="8" class="mt-2 mx-auto">
        <div class="mx-auto">
          <b-progress value="100" v-if="loading"  variant="#007ac5" height="0.2rem" animated></b-progress>
        </div>
        <b-row>
          <b-col cols="12">
            <EDMSLegalDocument class="w-full border border-gray" ref="edmsFile" :file="getLegalDocumentEDMSFile"/>
          </b-col>
          <b-col cols="12" v-if="noDocs">
            <b-alert variant="success" show>
              <p>
                <b>Notice:</b>
                <span>Document is still migrating to EDMS. Give it another 15 mins to be available for viewing</span>
              </p>
            </b-alert>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import EDMSLegalDocument from "@/views/home/services/registry/legal-documents/document/EDMSLegalDocument";

export default {
  name: "LegalDocumentRegistryAttachment",
  components: { EDMSLegalDocument },
  props:{
    documentHandle:String
  },
  data(){
    return{
      loading: false,
      noDocs:false
    }
  },
  mounted() {
    this.view()
  },
  methods:{
    ...mapActions(["fetchLegalDocumentEDMSFile"]),
    cleanEntityType(name){
      let n = name.replaceAll("_", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    view(){
      if(this.documentHandle){
        let data = {
          documentHandle: this.documentHandle,
          overlay: ""
        }
        this.loading = true
        this.fetchLegalDocumentEDMSFile(data)
        this.$refs.edmsFile.show();
      }else{
        this.noDocs = true
      }

    }
  },
  computed:{
    ...mapGetters(["getLegalDocumentEDMSFile"])
  },
  watch:{
    getLegalDocumentEDMSFile(){
      this.loading = false
    },
  }
}
</script>

<style scoped>
.button {
  @apply text-center rounded-sm bg-blue text-xs text-white border border-blue px-2 py-1;
  font-size: 14px;
}

.button:hover {
  @apply bg-blue-dark;
}
</style>