import {api} from "@/app/services/api.service";

const state = {
  incorporation_name_check:null,
  incorporation_name_check_error:null,
  registration_name_check:null,
  registration_name_check_error:null,
  registration_service_actions:null,
  registration_service_actions_pending:null,
  registration_service_actions_failed:null,
  incorporation_service_actions:null,
  incorporation_service_actions_pending:null,
  incorporation_service_actions_failed:null,
  incorporation_process_stats:null,
  reg_status_change:null,
  doc_status_change:null,
  status_change:null,
  status_change_error:null,
  reg_status_change_error:null,
  doc_status_change_error:null,
  documents_service_actions:null,
  annual_returns_status_change:null,
  annual_returns_status_error:null,
  form_compliance_status_change:null,
  form_compliance_status_error:null,
  success:null,
  error:null,
};

const getters = {
  getIncorporationNameCheck: (state) => state.incorporation_name_check,
  getIncorporationNameCheckError: (state) => state.incorporation_name_check_error,
  getRegistrationNameCheck: (state) => state.registration_name_check,
  getRegistrationNameCheckError: (state) => state.registration_name_check_error,
  getRegistrationProcessStream: (state) => state.registration_service_actions,
  getDocumentsProcessStream: (state) => state.documents_service_actions,
  getAnnualReturnsStatusChange: (state) => state.annual_returns_status_change,
  getAnnualReturnsStatusError: (state) => state.annual_returns_status_error,
  getFormComplianceStatusChange: (state) => state.form_compliance_status_change,
  getFormComplianceStatusError: (state) => state.form_compliance_status_error,
  getIncorporationProcessStats: (state) => state.incorporation_process_stats,
  getRegistrationProcessStreamPending: (state) => state.registration_service_actions_pending,
  getRegistrationProcessStreamFailed: (state) => state.registration_service_actions_failed,
  getIncorporationProcessStream: (state) => state.incorporation_service_actions,
  getIncorporationProcessStreamPending: (state) => state.incorporation_service_actions_pending,
  getIncorporationProcessStreamFailed: (state) => state.incorporation_service_actions_failed,
  getStatusChangeSuccess: (state) => state.status_change,
  getRegistrationStatusChangeSuccess: (state) => state.reg_status_change,
  getRegistrationStatusChangeError: (state) => state.reg_status_change_error,
  getDocumentStatusChangeSuccess: (state) => state.doc_status_change,
  getDocumentStatusChangeError: (state) => state.doc_status_change_error,
  getStatusChangeError: (state) => state.status_change_error,
  getInternalProcessStreamError: (state) => state.error,
};

const actions = {
  async incorporationPushToNameCheck({commit}, data) {
    await api()
        .post("/incorporation/companies/registry/name-search/manual-update", data)
        .then(response => {
          commit("setIncorporationNameCheck", response.data);
        })
        .catch(error => {
          commit("setIncorporationNameCheckError", error.response);
        });
  },
  async registrationPushToNameCheck({commit}, data) {
    await api()
        .post("/business-registration/sync-name-check", data)
        .then(response => {
          commit("setRegistrationNameCheck", response.data);
        })
        .catch(error => {
          commit("setRegistrationNameCheckError", error.response);
        });
  },
  async fetchRegistrationProcessesStream({commit}, data) {
    let path = "/business-registration/sync-items?status="+data.statuses+"&page="+data.page+"&per_page="+data.limit
    if(data.search !== ""){
      path += "&search="+data.search
    }
    if(data.startDate){
      path += "&start_date="+data.startDate
    }
    if(data.endDate){
      path += "&end_date="+data.endDate
    }
    await api()
      .get(path)
      .then(response => {
        commit("setRegistrationServiceStream", response.data);
      })
      .catch(error => {
        commit("setStreamError", error.response);
      });
  },
  async fetchRegistrationProcessesPending({commit}) {
    let path = "/business-registration/sync-items?status=Pending&page=1&per_page=12"
    await api()
      .get(path)
      .then(response => {
        commit("setRegistrationServiceStreamPending", response.data);
      })
      .catch(error => {
        commit("setStreamError", error.response);
      });
  },
  async fetchRegistrationProcessesFailed({commit}) {
    let path = "/business-registration/sync-items?status=Failed&page=1&per_page=12"
    await api()
      .get(path)
      .then(response => {
        commit("setRegistrationServiceStreamFailed", response.data);
      })
      .catch(error => {
        commit("setStreamError", error.response);
      });
  },
  async fetchIncorporationProcessesStream({commit}, data) {
    let path = "/incorporation/companies/get-all-actions?status="+data.statuses+"&page="+data.page+"&pageSize="+data.limit
    if(data.search !== ""){
      path += "&search="+data.search
    }
    if(data.startDate){
      path += "&startDate="+data.startDate
    }
    if(data.endDate){
      path += "&endDate="+data.endDate
    }
    await api()
      .get(path)
      .then(response => {
        commit("setIncorporationServiceStream", response.data);
      })
      .catch(error => {
        commit("setStreamError", error.response);
      });
  },
  async updateAnnualReturnStatus({commit}, data) {
    await api()
        .post("/incorporation/annual-returns/mark-years-as-filed/"+data.brn, data)
        .then(response => {
          commit("setAnnualReturnsStatusUpdated", response.data);
        })
        .catch(error => {
          commit("setAnnualReturnsStatusError", error.response);
        });
  },
  async updateFormCompliance({commit}, data) {
    await api()
        .post("/incorporation/companies/continuous-filing/correct-company-compliance-tag", data)
        .then(response => {
          commit("setFormComplianceStatusUpdated", response.data);
        })
        .catch(error => {
          commit("setFormComplianceStatusError", error.response);
        });
  },
  async fetchIncorporationProcessStatistics({commit}) {
    let path = "/incorporation/companies/get-action_statistics"
    await api()
      .get(path)
      .then(response => {
        commit("setIncorporationProcessStats", response.data);
      })
      .catch(error => {
        commit("setStreamError", error.response);
      });
  },
  async fetchIncorporationProcessesPending({commit}) {
    let path = "/incorporation/companies/get-all-actions?status=Pending&page=1&pageSize=12"
    await api()
      .get(path)
      .then(response => {
        commit("setIncorporationServiceStreamPending", response.data);
      })
      .catch(error => {
        commit("setStreamError", error.response);
      });
  },
  async fetchIncorporationProcessesFailed({commit}) {
    let path = "/incorporation/companies/get-all-actions?status=Expired&page=1&pageSize=12"
    await api()
      .get(path)
      .then(response => {
        commit("setIncorporationServiceStreamFailed", response.data);
      })
      .catch(error => {
        commit("setStreamError", error.response);
      });
  },
  async updateProcessStatus({commit}, data) {
    commit("setStatusChange", null);
    await api()
      .post("/incorporation/companies/update-action-status", data)
      .then(response => {
        commit("setStatusChange", response.data);
      })
      .catch(error => {
        commit("setStatusChangeError", error.response);
      });
  },
  async runProcessNow({commit}, data) {
    await api()
      .post("/incorporation/companies/run-action-immediately", data)
      .then(response => {
        commit("setStatusChange", response.data);
      })
      .catch(error => {
        commit("setStatusChangeError", error.response);
      });
  },
  async retryRegistrationProcess({commit}, data) {
    let path = "/business-registration/attachment/sync-item?brn="+data.brn+"&sync-item="+data.description
    await api()
      .post(path)
      .then(response => {
        commit("setRegistrationStatusChange", response.data);
      })
      .catch(error => {
        commit("setRegistrationStatusChangeError", error.response);
      });
  },
  async retryDocumentProcess({commit}, instrumentNumber) {
    await api()
      .post("/legal-documents/attachments/retry?instrumentNo=" + instrumentNumber)
      .then(response => {
        commit("setDocumentStatusChange", response.data);
      })
      .catch(error => {
        commit("setDocumentStatusChangeError", error.response);
      });
  },
};

const mutations = {
  setIncorporationNameCheck: (state, response) => {
    state.incorporation_name_check = response;
  },
  setIncorporationNameCheckError: (state, response) => {
    state.incorporation_name_check_error = response;
  },
  setRegistrationNameCheck: (state, response) => {
    state.registration_name_check = response;
  },
  setRegistrationNameCheckError: (state, response) => {
    state.registration_name_check_error = response;
  },
  setRegistrationServiceStream: (state, response) => {
    state.registration_service_actions = response;
  },
  setRegistrationServiceStreamPending: (state, response) => {
    state.registration_service_actions_pending = response;
  },
  setRegistrationServiceStreamFailed: (state, response) => {
    state.registration_service_actions_failed = response;
  },
  setIncorporationServiceStream: (state, response) => {
    state.incorporation_service_actions = response;
  },
  setIncorporationProcessStats: (state, response) => {
    state.incorporation_process_stats = response.data;
  },
  setIncorporationServiceStreamPending: (state, response) => {
    state.incorporation_service_actions_pending = response;
  },
  setIncorporationServiceStreamFailed: (state, response) => {
    state.incorporation_service_actions_failed = response;
  },
  setStatusChange: (state, response) => {
    state.status_change = response;
  },
  setRegistrationStatusChange: (state, response) => {
    state.reg_status_change = response;
  },
  setDocumentStatusChange: (state, response) => {
    state.doc_status_change = response;
  },
  setRegistrationStatusChangeError: (state, response) => {
    state.reg_status_change_error = response;
  },
  setDocumentStatusChangeError: (state, response) => {
    state.doc_status_change_error = response;
  },
  setAnnualReturnsStatusUpdated: (state, response) => {
    state.annual_returns_status_change = response.data;
  },
  setFormComplianceStatusUpdated: (state, response) => {
    state.form_compliance_status_change = response.data;
  },
  setFormComplianceStatusError: (state, response) => {
    state.form_compliance_status_error = response.data;
  },
  setAnnualReturnsStatusError: (state, response) => {
    state.annual_returns_status_error = response.data;
  },
  setStatusChangeError: (state, response) => {
    state.status_change_error = response.data;
  },
  setStreamError: (state, response) => {
    state.error = response.data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};