<template>
    <div class="bg-white rounded-sm mx-3 my-3 py-3 overflow-hidden">
        <b-row class="m-2">
            <b-col cols="10">
                <b-row class=" my-2 p-3 bg-white text-gray">
                    <b-col cols="2" class="text-center">
                        <i class="fa fa-user rounded-full bg-header_grey p-5" style="font-size: 65px;"></i>
                    </b-col>
                    <b-col cols="10">
                        <div class="mt-2" v-if="userProfile">
                            <p class="font-bold">{{ userProfile.givenName }} {{ userProfile.surName }}</p>
                            <p class="text-sm cursor-pointer underline text-blue">{{ userProfile.email }}</p>
                            <p class="text-xs">{{ userProfile.phoneNumber }}</p>
                            <p class="text-xs"> ({{ userProfile.idType.replaceAll("_", " ") }}) {{
                                    userProfile.idNumber
                                }}</p>
                            <p class="text-xs">Department: {{ userProfile.department.departmentName }}</p>

                            <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                                <thead>
                                <tr class="bg-gray-pale">
                                    <td colspan="12" class="px-2 py-2 font-bold">User Roles</td>
                                </tr>
                                </thead>
                                <thead>
                                <tr class="bg-white">
                                    <th class="px-2 py-2 font-bold">Roles</th>
                                    <th class="px-2 py-2 font-bold">Permission Count</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr style="border: solid 1px #dedede;" v-for="(role, idx) in userProfile.roles"
                                    :key="idx">
                                    <td class="px-2 py-1">{{ role.name.replaceAll("_", " ") }}</td>
                                    <td class="px-2 py-1">{{ role.permissions.length }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>

export default {
    name: "AnalyticsDashboard",
    data() {
        return {
            userProfile: null
        }
    },
    mounted() {
        this.userProfile = JSON.parse(sessionStorage.getItem("current_user"))
    }
}
</script>

<style scoped>
@import "../../../../src/assets/styles/menu.css";

th, td {
    font-size: 12px;
}
</style>