<template>
    <b-row>
        <b-col>


            <b-row class="py-3">
                <b-col cols="2">
                    <b-form-input
                            class="text-gray text-sm noFocus ml-1"
                            style="margin-top: 1px; padding:6px 5px;"
                            v-model="search"
                            type="text"
                            placeholder="Search by Name"
                            required
                            v-on:keyup.enter="fetch(1)">
                    </b-form-input>
                </b-col>

                <b-col cols="2">
                    <b-form-input
                            class="text-gray text-sm noFocus ml-1"
                            style="margin-top: 1px; padding:6px 5px;"
                            v-model="email"
                            type="text"
                            placeholder="Search by Email"
                            required
                            v-on:keyup.enter="fetch(1)">
                    </b-form-input>
                </b-col>


            </b-row>

            <div class="mx-auto">
                <b-progress value="100" v-if="loading" variant="#007ac5" height="0.2rem" animated></b-progress>
            </div>
            <b-row>
                <b-col cols="12">
                    <table class="min-w-full leading-normal">
                        <thead>
                        <tr class="border-b border-t border-grey">
                            <th class="t-header">Names</th>
                            <th class="t-header">Emails</th>
                            <th class="t-header">Phones</th>
                            <th class="t-header">Date Created</th>
                            <th class="t-header">Status</th>

                            <th class="t-header">Action</th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(account, idx) in accounts" :key="idx">
                            <td class="t-body font-bold text-uppercase">
                                <span
                                >{{ account.individualName }}, {{ account.backOfficeName }} </span>
                            </td>
                            <td class="t-body">

                                {{ account.individualEmail }},{{ account.backOfficeEmail }}

                            </td>
                            <td>


                                {{ account.individualPhone }},{{ account.backOfficePhone }}

                            </td>





                            <td class="t-body">
                                <span>{{ formatDate(account.createdAt) }}</span>
                            </td>


                            <td class="t-body">
                                <span class="text-green" v-if="account.status === 'ACTIVE'"> Active</span>
                                <span class="text-red" v-else>Inactive</span>
                            </td>


                            <td class="t-body font-bold">

                                <div>


                                    <button
                                            v-if="canViewMenuItem('can_revoke_role')"
                                            @click="revokeReceiver(account.id)"
                                            class="bg-red rounded-sm px-2 py-1 text-xs text-white">
                                        <i class="fa fa-times mx-2"></i> Revoke
                                    </button>
                                </div>
                            </td>
                            <td class="t-body text-center">

                            </td>
                        </tr>
                        <tr>
                            <td v-if="accounts.length === 0" colspan="12" class="bg-inertgrey text-center py-1">
                                <span class="text-sm">No results found</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </b-col>
            </b-row>
            <b-row class="mx-1" v-if="pagination.totalPages > 1">
                <b-col cols="1">
                    <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
                </b-col>
                <b-col cols="1">
                    <b-row>
                        <b-col cols="12">
                            <p class="paginate-details text-center">
                                <b-badge class="bg-blue text-white">
                                    {{ (currentPage + 1) }}/{{ pagination.totalPages }}
                                </b-badge>
                            </p>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="1">
                    <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">
                        Next
                    </button>
                </b-col>
                <b-col class="9"></b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {api} from "@/app/services/api.service";

export default {
    name: "OfficialReceiverAccounts",
    components: {},
    data() {
        return {
            loading: false,
            currentPage: 1,
            limit: 10,
            accounts: [],
            pagination: {
                totalElements: 0,
                totalPages: 0
            },
            rows: {
                odd: "border-t border-grey hover:bg-header_grey",
                even: "border-t border-grey bg-header_grey"
            },
            search: "",
            status: null,
            name: null,
            email: null,
            phone: null,
            type: null,
            formData: null,
            usersList: [{text: "Select User", id: null}],
            rolesDataList: [{text: "Select Role", id: null, disabled: true}],
            spinner: false,
            userRoles: [],
            indexLoader: "",
            account: {},
            accountBalance: 0,
            logs: [],
            showIndex: null,
        }
    },
    mounted() {
        this.formData = new FormData();
        this.fetch(1)

    },
    methods: {
        ...mapActions([]),
        fetch(startPage = null) {
            this.loading = true

            api().get("insolvency/official-receivers?", {
                params: {
                    page: startPage ? startPage : this.currentPage,
                    size: this.limit,
                    search: this.name,

                }
            }).then(response => {
                this.loading = false
                this.accounts = response.data.content
                //this.pagination = response.data.pagination
            }).catch(error => {
                this.loading = false
                this.errorToast(error.message)
            })


        },
        next() {
            if (this.currentPage < this.pagination.totalPages) {
                this.currentPage++
                this.fetch()
            }
        },
        previous() {
            if (this.currentPage > 1) {
                this.currentPage--
                this.fetch()
            } else {
                this.warningToast("Observation", "No more data to show")
            }
        },

        async changeStatus(userId, status) {
            this.showConfirmDialog().then(value => {
                if (value) {
                    this.changeAccountStatus({userId, status})
                }
            })

        },

        filterUserList(dataList) {
            let dataObj = [{text: "Select User", id: null}];
            let dataJson = {};
            for (let i = 0; i < dataList.length; i++) {
                let userData = dataList[i]
                dataJson = {
                    text: userData.name,
                    value: userData.id
                };
                dataObj.push(dataJson);
            }
            return dataObj;
        },
        showUserRoles(roles, id) {
            this.userRoles = roles;
            this.selectedAccountId = id;
            this.$refs.accountRoles.show();
        },
        async removeRole(roleId, userId) {
            this.showConfirmDialog().then(value => {
                if (value) {
                    let payload = {
                        roleIds: [
                            roleId
                        ],
                        userId: userId
                    };
                    this.removeUserRole(payload);
                }

            });


        },

        canViewMenuItem(permission) {
            return this.$can(permission, "general") === true;
        },
        revokeReceiver(id) {
            api().post("insolvency/official-receivers/" + id + "/delete").then(() => {
                this.successToast("Success", "Receiver has been revoked")
                this.fetch()
            }).catch(error => {
                this.errorToast(error.message)
            })
        }
    },
    computed: {
        ...mapGetters([""
        ])
    },
    watch: {
        getOfficerAccounts(data) {
            this.loading = false
            this.accounts = data
            this.usersList = this.filterUserList(data)
        },


        getUserError(error) {
            this.loading = false
            this.errorToast(error.message)
        },


    }
};
</script>

<style scoped>
.paginate-details {
    @apply px-3 py-2 bg-white my-2 text-sm text-gray;
}

.paginate-btn {
    @apply px-3 py-2 bg-white border border-white my-2 text-sm text-blue w-full;
}

.paginate-btn:hover {
    @apply border border-blue text-blue rounded-sm;
}


.inert-btn {
    @apply px-3 py-2 bg-white my-2 text-sm text-grey w-full;
}
</style>