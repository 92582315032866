<template>
  <b-col>
    <b-row>
      <b-col cols="12" class="mx-auto mt-4">
        <table class="w-full leading-normal text-blue-dark"
               style="border: solid 1px #dedede;">
          <thead class="bg-blue-pale" >
          <tr>
            <th colspan="12" class="py-2">
              <span class="px-2">Members</span>
            </th>
          </tr>
          </thead>
          <tbody>
            <MemberDetails v-for="(member,idx) in members" :member="member" :key="idx" class="mt-2 mb-4 px-2 py-2 border-b border-gray"/>
          </tbody>
        </table>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import MemberDetails from "@/views/home/services/registry/business/files/tabs/building_society_data/MemberDetails";
export default {
  name: "BuildingSocietyMembers",
  components: {MemberDetails},
  props:{
    members:[]
  }
}
</script>

<style scoped>

</style>