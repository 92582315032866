import {api} from "@/app/services/api.service";

const state = {
    officer_accounts: [],
    user_accounts: [],
    business_accounts: [],
    individual_accounts_count: 0,
    partner_accounts_count: 0,
    mda_accounts_count: 0,
    private_organisations_accounts_count: 0,
    business_accounts_count: 0,
    officer_accounts_count: 0,
    user_details: {},
    user_error: null,
    reactivationSuccess: null,
    reactivationError: null,
    idAvailability: null,
    ninInfo: null,
    ninError: null,
    idAttachment: null,
    idAttachmentError: null,
    signatureUpdate: null,
    signatureUpdateError: null,
    signatureDetails: null,
    signatureDetailsError: null,
    clients: null,
};

const getters = {
    getOfficerAccounts: (state) => state.officer_accounts,
    getUserAccounts: (state) => state.user_accounts,
    getBusinessAccounts: (state) => state.business_accounts,
    getUserDetails: (state) => state.user_details,
    getReactivationSuccess: (state) => state.reactivationSuccess,
    getReactivationError: (state) => state.reactivationError,
    getIndividualAccountsCount: (state) => state.individual_accounts_count,
    getPartnerAccountsCount: (state) => state.partner_accounts_count,
    getMdaAccountsCount: (state) => state.mda_accounts_count,
    getPrivateOrganisationsAccountsCount: (state) => state.private_organisations_accounts_count,
    getBusinessAccountsCount: (state) => state.business_accounts_count,
    getStaffAccountsCount: (state) => state.officer_accounts_count,
    getIdAvailability: (state) => state.idAvailability,
    getNinInfo: state => state.ninInfo,
    getNinError: state => state.ninError,
    getIdAttachment: state => state.idAttachment,
    getIdAttachmentError: state => state.idAttachmentError,
    getSignatureUpdateSuccess: state => state.signatureUpdate,
    getSignatureUpdateError: state => state.signatureUpdateError,
    getSignatureDetails: state => state.signatureDetails,
    getSignatureDetailsError: state => state.signatureDetailsError,
    getUserError: state => state.user_error,
    getClients: (state) => state.clients

}

const actions = {
    async fetchUsers({commit}, queryString) {
        await api()
            .get("auth/api/users?" + queryString)
            .then(response => {
                commit("setOfficerListData", response);
            })
            .catch(error => {
                commit("setUserListError", error.response);
            });
    }, async fetchStaffAccounts({commit}, queryString) {
        await api()
            .get("auth/api/users/staff?" + queryString)
            .then(response => {
                commit("setOfficerListData", response);
            })
            .catch(error => {
                commit("setUserListError", error.response);
            });
    },
    async fetchBusinessAccounts({commit}, queryString) {
        await api()
            .get("auth/api/users/business?" + queryString)
            .then(response => {
                commit("setBusinessListData", response);
            })
            .catch(error => {
                commit("setUserListError", error.response);
            });
    },
    async fetchClientAccounts({commit}, queryString) {
        await api()
            .get("auth/api/users/individual?" + queryString)
            .then(response => {
                commit("setUserListData", response);
            })
            .catch(error => {
                commit("setUserListError", error.response);
            });
    },
    async fetchPartners({commit}, type) {
        await api()
            .get("auth/api/partners?partner-type=" + type)
            .then(response => {
                commit("setUserListData", response);
            })
            .catch(error => {
                commit("setUserListError", error.response);
            });
    },
    async fetchUserDetails({commit}, userId) {
        await api()
            .get("auth/api/users/" + userId)
            .then(response => {
                commit("setUserDetails", response);
            })
            .catch(error => {
                commit("setUserDetailsError", error.response);
            });
    },
    async doResendActivationLink({commit}, data) {
        await api().post("/auth/api/auth/send-reactivation-link", data)
            .then((response) => {
                commit("setReactivationSuccess", response);
            })
            .catch((error) => {
                commit("setReactivationError", error.response);
            });
    },

    async fetchTotalAccountMetricsByType({commit}, type) {
        await api()
            .get("/auth/api/users/user-account-type/" + type)
            .then(response => {
                if (type === "BACKOFFICE") {
                    commit("setOfficerAccountsCount", response);
                } else if (type === "PARTNER") {
                    commit("setPartnerAccountsCount", response);
                } else if (type === "INDIVIDUAL") {
                    commit("setIndividualAccountsCount", response);
                } else if (type === "MDA") {
                    commit("setMdaAccountsCount", response);
                } else if (type === "BUSINESS") {
                    commit("setBusinessAccountsCount", response);
                } else if (type === "PRIVATE_ORGANIZATION") {
                    commit("setPrivateOrganisationsAccountsCount", response);
                }
            })
            .catch(error => {
                commit("setUserListError", error.response);
            });
    },
    async changeAccountStatus({commit}, data) {
        await api().post("/auth/api/users/update-account-status", data)
            .then((response) => {
                commit("setReactivationSuccess", response);
            })
            .catch((error) => {
                commit("setReactivationError", error.response);
            });
    },
    async validateNin({commit}, data) {

        await api("https://nira.ursbonline.go.ug").post('/api/nira', data)
            .then(response => {
                commit('setNinInfo', response)
            })
            .catch(error => {
                commit('setNinError', error)
            })
    },
    async checkIdAvailability({commit}, data) {
        await api().post("/auth/api/users/checkIdNumberAvailability", data)
            .then((response) => {
                commit("setIdCheckSuccess", response);
            })
            .catch((error) => {
                commit("setUserDetailsError", error.response);
            });
    },
    async updateStaffDepartment({commit}, data) {
        await api().post("/auth/api/users/update-staff-department", data)
            .then((response) => {
                commit("setReactivationSuccess", response);
            })
            .catch((error) => {
                commit("setReactivationError", error.response);
            });
    },
    async updateStaffContacts({commit}, data) {
        await api().post("/auth/api/users/update-user", data)
            .then((response) => {
                commit("setReactivationSuccess", response);
            })
            .catch((error) => {
                commit("setReactivationError", error.response);
            });
    },
    async updateAccountContactDetails({commit}, data) {
        await api().post("/auth/api/partner/update-contact-person", data)
            .then((response) => {
                commit("setReactivationSuccess", response);
            })
            .catch((error) => {
                commit("setReactivationError", error.response);
            });
    },
    async fetchIdAttachment({commit}, userId) {
        await api().get("auth/api/users/user-attachment/" + userId).then((response) => {
            commit("setIdAttachment", response)
        }).catch((error) => {
            commit("setIdAttachmentError", error.response)
        })
    },
    async doUpdateStaffSignature({commit}, data) {
        await api().post("/auth/api/users/staff-signature/update", data)
            .then((response) => {
                commit("setSignatureUpdate", response);
            })
            .catch((error) => {
                commit("setSignatureUpdateError", error.response.data);
            });
    },

    async fetchStaffSignature({commit}, data) {
        await api().get("/auth/api/users/staff-signature/" + data.id, data)
            .then((response) => {
                commit("setSignatureDetails", response);
            })
            .catch((error) => {
                commit("setSignatureDetailsError", error.response.data);
            });
    },
    async searchClients({commit}, queryParams) {
        await api()
            .get("auth/api/users/individual?" + queryParams)
            .then(response => {
                commit("setClients", response.data);
            })
            .catch(error => {
                commit("setUserListError", error.response);
            });
    },


};

const mutations = {
    setOfficerListData: (state, response) => {
        state.officer_accounts = response.data;
    },
    setUserListData: (state, response) => {
        state.user_accounts = response.data;
    },
    setBusinessListData: (state, response) => {
        state.business_accounts = response.data;
    },
    setUserListError: (state, error) => {
        state.user_error = error.data;
    }, setUserDetails: (state, response) => {
        state.user_details = response.data;
    }, setUserDetailsError: (state, error) => {
        state.user_error = error.data;
    },

    setReactivationSuccess: (state, response) => {
        state.reactivationSuccess = response.data;
    },
    setReactivationError: (state, error) => {
        state.reactivationError = error.data;
    },

    setOfficerAccountsCount: (state, response) => {
        state.officer_accounts_count = response.data;
    },
    setIndividualAccountsCount: (state, response) => {
        state.individual_accounts_count = response.data;
    },
    setBusinessAccountsCount: (state, response) => {
        state.business_accounts_count = response.data;
    },
    setMdaAccountsCount: (state, response) => {
        state.mda_accounts_count = response.data;
    }, setPrivateOrganisationsAccountsCount: (state, response) => {
        state.private_organisations_accounts_count = response.data;
    },
    setPartnerAccountsCount: (state, response) => {
        state.partner_accounts_count = response.data;
    },
    setNinInfo: (state, payload) => {
        state.ninInfo = payload.data;
    },
    setNinError: (state, error) => {
        state.ninError = error.response.data;
    },
    setIdCheckSuccess: (state, payload) => {
        state.idAvailability = payload.data
    },
    setIdAttachmentError: (state, error) => {
        //alert(JSON.stringify(error))
        state.idAttachmentError = error.data;
    },
    setIdAttachment: (state, payload) => {
        state.idAttachment = payload.data
    },
    setSignatureUpdate: (state, payload) => {
        state.signatureUpdate = payload.data
    },
    setSignatureUpdateError: (state, error) => {
        state.signatureUpdateError = error
    },
    setSignatureDetails: (state, payload) => {
        state.signatureDetails = payload.data
    },
    setSignatureDetailsError: (state, error) => {
        state.signatureDetailsError = error
    },
    setClients: (state, response) => {
        state.clients = response.data;
    }


};

export default {
    state,
    getters,
    actions,
    mutations,
};