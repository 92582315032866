import {api} from "@/app/services/api.service";

const state = {
    manuals: null,
    manual: null,
    manualDelete: null,
    manualsError: null,
}

const getters = {
    getManuals: state => state.manuals,
    getManualCreated: state => state.manual,
    getManualDeleted: state => state.manualDelete,
    getManualsError: state => state.manualsError,
}

const actions = {
    async fetchManuals({commit}) {
        api().get('configuration/user-manual')
            .then(response => {
                commit('setManuals', response)
            })
            .catch(error => {
                commit('setManualError', error)
            })
    },    async deleteManual({commit},manualId) {
        api().get('configuration/user-manual/delete/'+manualId)
            .then(response => {
                commit('setManualDelete', response)
            })
            .catch(error => {
                commit('setManualError', error)
            })
    },

    async createManual({commit}, data) {

        api().post('configuration/user-manual/add', data)
            .then(response => {
                commit('setManual', response)
            })
            .catch(error => {
                commit('setManualError', error)
            })
    },

    /* async fetchManuals({commit}) {
         api().get('configuration/user-manuals')
             .then(response => {
                 commit('setManuals', response)
             })
             .catch(error => {
                 commit('setError', error)
             })
     },/*
     async createManual({commit}, data) {
         api().post('configuration/manual', data)
             .then(response => {
                 commit('setAddedManual', response)
             })
             .catch(error => {
                 commit('setError', error)
             })
     },*/
}

const mutations = {
    setManuals: (state, payload) => {
        state.manuals = payload.data;
    },
    setManualDelete: (state, payload) => {
        state.manualDelete = payload.data;
    },
    setManual: (state, payload) => {
        state.manual = payload.data;
    },
    setManualError: (state, error) => {
        state.manualsError = error.response.data;
    },
}


export default {
    state,
    getters,
    actions,
    mutations

}
