<template>
  <b-col cols="8" class="mx-auto">
    <div class="w-full shadow-md px-3 py-5 border text-center" style="background-color: #ffffff;">
      <span class="h1 text-blue">OBRS</span>
      <p class="mt-4 max-w-2xl text-gray-500 lg:mx-auto" style="color: #909090;">
        Enter your account's email address to request for a password reset.
      </p>
      <p v-if="errorMessage" class="mt-2 text-center text-sm text-gray-600 bg-red-500 bg-opacity-75 p-2 rounded">
        <a href="#" class="font-medium text-white">
          {{errorMessage}}
        </a>
      </p>



      <form @submit.prevent="onResetPasswordSubmit" class="mt-8">

        <div class="rounded-md shadow-sm -space-y-px mb-3">

          <div style="color: #909090;"
               class="flex flex-wrap border border-grey items-stretch w-full relative  bg-white items-center rounded mb-0 ">
            <div class="flex  justify-center w-10 h-9">
              <span
                  class="flex items-center leading-normal bg-white px-2 border-0 rounded-r-none text-gray">
                <i class="fa fa-envelope"></i>
              </span>
            </div>
            <input
                v-model="form.userEmail"
                type="email"
                :class="loginTextInput"
                placeholder="Email Address"
            />
          </div>
        </div>

        <div>
          <button
              class="w-full mt-1 justify-center p-3 border border-transparent text-sm font-medium rounded-md text-white bg-blue hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dark"
              type="submit">
            Reset Password
          </button>
        </div>


        <div class="mt-3 max-w-2xl text-gray-500 lg:mx-auto"
             style="text-align: center; margin-top: 5%; color: #909090;">
          You can go back to
          <router-link
              style="color: #242f7e"
              class="text-decoration-none"
              :to="{ name: 'login' }">
            Login
          </router-link>
        </div>


      </form>

    </div>
  </b-col>
</template>

<script>
export default {
  name: "PasswordReset",
  data() {
    return {
      show1: false,
      errorMessage:null,
      form: {
        userEmail: null
      },
      loginTextInput:"flex-shrink flex-grow flex-auto leading-normal h-6  flex-1 self-center relative  font-roboto sm:text-sm outline-none",
    }
  },
  methods:{
    onResetPasswordSubmit(){
      alert(JSON.stringify(this.form))
    }
  }
}
</script>

<style scoped>
.border {
  border: solid 1px #eeeeee;
  border-radius: 3px;
}
</style>