<template>
    <b-row>
        <b-col>

            <b-row>
                <b-modal ref="details" size="xl" title="Details of Process" hide-footer>
                    <b-row>
                        <b-col>
                            <b-row>
                                <b-col>
                                    <p><b>BRN:</b> {{ process.brn }}</p>
                                    <p><b>Type of Issue:</b> {{ process.type }}</p>
                                    <p><b>Number of Retires:</b> {{ process.retries }}</p>
                                    <p><b>Date Time:</b> {{ dateFormat(process.createdAt) }}</p>
                                    <!--                  <p><b>Endpoint:</b> <i class="border-2 border-green text-green text-sm rounded px-1">{{process.endPoint}}</i></p>-->
                                </b-col>
                            </b-row>
                            <b-row class="mt-4">
                                <b-col>
                                    <b-row>
                                        <b-col>
                                            <p class="font-bold">Request</p>
                                            <pre class="text-green text-xs bg-header_grey border-2 border-green p-2 rounded"
                                                 v-if="process.payload">
                         {{ JSON.parse(process.payload) }}
                      </pre>
                                            <p v-else
                                               class="text-green text-xs bg-header_grey border-2 border-green p-2 rounded"></p>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col>
                                            <p class="font-bold">Response</p>
                                            <p class="text-red text-xs bg-header_grey border-2 border-red p-2 rounded">

                                            </p>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-modal>
            </b-row>
            <!--            <b-row class="m-2">-->
            <!--                <b-col cols="1" class="p-3 mx-2 stat-count shadow bg-white rounded-md">-->
            <!--                    <div class="waiting">-->
            <!--                        <span class="h3 mx-auto"></span>-->
            <!--                    </div>-->
            <!--                    <div class="waiting">-->
            <!--                        <span class="text-sm mx-auto">In Queue</span>-->
            <!--                    </div>-->
            <!--                </b-col>-->
            <!--                <b-col cols="1" class="p-3 mx-2 stat-count shadow bg-white rounded-md">-->
            <!--                    <div class="negative">-->
            <!--                        <span class="h3 mx-auto"></span>-->
            <!--                    </div>-->
            <!--                    <div class="negative">-->
            <!--                        <span class="text-sm mx-auto">Failed</span>-->
            <!--                    </div>-->
            <!--                </b-col>-->
            <!--            </b-row>-->
            <b-row>
                <b-col cols="3">
                    <b-form-input
                            class="text-gray text-sm noFocus mx-2 my-2"
                            style="padding:6px 5px;"
                            v-model="search"
                            type="text"
                            placeholder="Search by BRN."
                            required
                            v-on:keyup.enter="fetch">
                    </b-form-input>
                </b-col>
                <b-col cols="2">
                    <b-form-group>
                        <b-form-select
                                v-model="statuses"
                                v-on:change="fetch"
                                class="form-select noFocus text-gray mx-2 my-2 text-sm">
                            <b-select-option :value="null">--Filter by Status--</b-select-option>
                            <b-select-option value="PENDING">Pending</b-select-option>
                            <b-select-option value="Success">Success</b-select-option>
                            <b-select-option value="Sent">Sent</b-select-option>
                            <b-select-option value="Failed">Failed</b-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col cols="2">
                    <b-form-input class="form-text text-sm mt-2" type="date" v-on:change="fetch"
                                  v-model="startDate"></b-form-input>
                </b-col>
                <b-col cols="2">
                    <b-form-input class="form-text text-sm mt-2" type="date" v-on:change="fetch"
                                  v-model="endDate"></b-form-input>
                </b-col>
                <b-col cols="1">
                    <b-form-select v-model="limit" class="w-full text-xs form-select border-0 paginate-details"
                                   v-on:change="fetch">
                        <b-select-option value="12">12</b-select-option>
                        <b-select-option value="20">20</b-select-option>
                        <b-select-option value="50">50</b-select-option>
                        <b-select-option value="100">100</b-select-option>
                    </b-form-select>
                </b-col>
                <b-col cols="2">
                    <b-row>
                        <b-col class="mx-auto">
                            <p class="paginate-details text-center text-xs font-bold">
                                <span>{{ ((currentPage - 1) * limit) + 1 }}</span> -
                                <span v-if="limit > stream.length ">{{ pagination.totalElements }}</span>
                                <span v-else>{{ currentPage * limit }}</span>
                                of {{ pagination.totalElements }}
                            </p>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <div class="mx-auto">
                <b-progress value="100" v-if="loading" variant="#007ac5" height="0.2rem" animated></b-progress>
            </div>
            <b-row>
                <b-col>
                    <table class="min-w-full leading-normal">
                        <thead>
                        <tr class="border-b border-t border-grey">
                            <th class="t-header">#</th>
                            <th class="t-header">BRN</th>
                            <th class="t-header">Type</th>
                            <th class="t-header">Owner</th>
                            <th class="t-header text-center">Retry Count</th>
                            <th class="t-header text-center">Status</th>
                            <th class="t-header text-center">Date</th>
                            <th class="t-header text-center">Last Ran</th>
                            <th class="t-header text-center">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(action, idx) in stream" :key="idx">
                            <td class="t-body">{{ (currentPage - 1) * limit + idx + 1 }}</td>
                            <td class="t-body font-bold">
                                <p class="cursor-pointer hover:underline" @click="searchTerm(action.brn)">
                                    {{ action.brn }}</p>
                            </td>
                            <td class="t-body italic text-blue">
                                <p class="cursor-pointer hover:underline" @click="searchTerm(action.type)">
                                    {{ action.type }}</p>
                            </td>
                            <td class="t-body">{{ action.brn }}</td>
                            <td class="t-body text-center">{{ action.retries }}</td>
                            <td class="t-body text-center">
                                <p class="text-xs px-2 py-1 text-capitalize text-center" style="background: #f1f1f1">
                    <span :class="(action.status === 'Sent') ? 'text-green' :
                                  (action.status === 'Success') ? 'text-green' :
                                  (action.status === 'Failed') ? 'text-red' :
                                  (action.status === 'PENDING') ? 'text-gray' : ''">
                       {{ action.status.toLowerCase() }}
                    </span>
                                </p>
                            </td>
                            <td class="t-body text-center text-xs">{{ dateFormat(action.createdAt) }}</td>
                            <td class="t-body text-center text-xs">{{ dateFormat(action.updatedAt) }}</td>
                            <td class="t-body text-center text-xs">
                                <button class="bg-red px-2 py-1 rounded-sm text-white mx-1"
                                        v-if="action.status === 'FAILED' || action.status === 'PAUSED' || action.status === 'IN_PROGRESS'"
                                        @click="retry(action, idx)"><i class="fa fa-redo mx-1">
                                </i>Retry
                                    <b-spinner v-if="loadingItem && selectedIdx === idx" small></b-spinner>
                                </button>
                                <button class="bg-blue px-2 py-1 rounded-sm text-white" @click="view(action)"><i
                                        class="fa fa-eye mx-1"></i>View
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td v-if="stream.length === 0" colspan="12" class="bg-inertgrey text-center py-1">
                                <span class="text-sm">No results found</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </b-col>
            </b-row>
            <b-row class="mx-1" v-if="pagination.totalPages > 1">
                <b-col cols="1">
                    <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
                </b-col>
                <b-col cols="1">
                    <b-row>
                        <b-col cols="12">
                            <p class="paginate-details text-center">
                                <b-badge class="bg-blue text-white">
                                    {{ currentPage }}/{{ pagination.totalPages }}
                                </b-badge>
                            </p>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="1">
                    <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">
                        Next
                    </button>
                </b-col>
                <b-col cols="6"></b-col>
                <b-col cols="1">
                    <b-form-select v-model="limit" class="w-full form-select border-0 paginate-details"
                                   v-on:change="fetch">
                        <b-select-option value="12">12</b-select-option>
                        <b-select-option value="20">20</b-select-option>
                        <b-select-option value="50">50</b-select-option>
                        <b-select-option value="100">100</b-select-option>
                    </b-form-select>
                </b-col>
                <b-col cols="2">
                    <b-row>
                        <b-col class="mx-auto">
                            <p class="paginate-details text-center text-xs font-bold">
                                <span v-if="currentPage === 1">1</span>
                                <span v-else>{{ ((currentPage - 1) * limit) + 1 }}</span> -

                                <span v-if="limit > stream.length ">{{ pagination.totalElements }}</span>
                                <span v-else>{{ currentPage * limit }}</span> of {{ pagination.totalElements }}
                            </p>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import moment from "moment";
import {api} from "@/app/services/api.service";

export default {
    name: "DataCorrectionService",
    data() {
        return {
            loading: false,
            loadingItem: false,
            loadingRunItem: false,
            selectedIdx: null,
            currentPage: 1,
            limit: 12,
            pagination: {
                stream: [],
                totalElements: 0,
                totalPages: 0
            },
            statuses: null,
            process: {
                brn: null,
                createdAt: null,
                type: null,
                endPoint: null,
                payload: null,
                response: null
            },
            search: "",
            startDate: null,
            endDate: null,
            rows: {
                odd: "border-t border-grey hover:bg-header_grey",
                even: "border-t border-grey bg-header_grey"
            },
            stream: []
        }
    },
    mounted() {
        this.fetch()
    },
    methods: {
        async fetch() {
            this.loading = true
            let options = this.statuses
            if (this.statuses === null) {
                options = "PENDING,FAILED,PAUSED,IN_PROGRESS,SENT"
            }
            if (this.search !== "") {
                this.currentPage = 1
            }
            let data = {
                search: this.search,
                statuses: options,
                page: this.currentPage,
                startDate: this.startDate,
                endDate: this.endDate,
                limit: this.limit,
            }
            await api().get("/data-correction/reconciliations?status=" + data.statuses + "&page=" + data.page + "&per_page=" + data.limit).then((response) => {
                let data = response.data

                this.loading = false
                this.stream = data.content;
                this.pagination = {
                    totalPages: data.totalPages,
                    totalElements: data.totalElements
                }
            }).catch((error) => {
                this.errorToast("Error", "Process failed to return to queue. Try again later " + error)
            })

        },
        searchTerm(term) {
            this.search = term
            this.fetch()
        },
        view(details) {
            this.process = details
            this.$refs.details.show()
        },
        retry(details, idx) {
            this.selectedIdx = idx
            this.loadingItem = true
            api().post("/data-correction/reconciliations/reconcile/" + details.id).then(() => {
                this.loadingItem = false
                this.successToast("Success", "Process has been returned to queue")
                this.fetch()
            }).catch((error) => {
                this.loadingItem = false
                this.errorToast("Error", "Process failed to return to queue. Try again later " + error)
            })

        },
        dateFormat(date) {
            return moment(date).format("DD-MM-YYYY HH:mm:ss")
        },
        next() {
            if (this.currentPage < this.pagination.totalPages) {
                this.currentPage++
                this.fetch()
            }
        },
        previous() {
            if (this.currentPage > 1) {
                this.currentPage--
                this.fetch()
            } else {
                this.warningToast("Observation", "No more data to show")
            }
        },
    },

};
</script>

<style scoped>
.paginate-details {
    @apply px-3 py-2 bg-white my-2 text-sm text-gray;
}

.paginate-btn {
    @apply px-3 py-2 bg-white border border-white my-2 text-sm text-blue w-full;
}

.paginate-btn:hover {
    @apply border border-blue text-blue rounded-sm;
}

.inert-btn {
    @apply px-3 py-2 bg-white my-2 text-sm text-grey w-full;
}

.waiting {
    @apply flex text-gray;
}

.normal {
    @apply flex text-blue;
}

.negative {
    @apply flex text-red-light;
}

.stat-count {
    display: inline;
    width: 15%;
}
</style>