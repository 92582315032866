import { render, staticRenderFns } from "./NewReason.vue?vue&type=template&id=7dbb64fa&scoped=true&"
import script from "./NewReason.vue?vue&type=script&lang=js&"
export * from "./NewReason.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7dbb64fa",
  null
  
)

export default component.exports