<template>
  <div class="bg-white rounded-sm mx-2 my-3">
    <b-row>
      <b-col cols="12" class="mx-3 mt-3">
        <b-row>
          <b-col>
            <span class="title">Registry File</span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="mx-3 mt-2">
      <b-col cols="12" class="mx-auto bg-header_grey py-3 rounded">
        <span class="cursor-pointer text-blue hover:text-blue-dark hover:underline" @click="back">Back</span> <span class="ml-3 text-gray">Business / {{brn}}</span>
      </b-col>
    </b-row>
    <b-row class="mx-3">
      <b-col cols="12" class="mx-auto my-3 shadow-sm rounded pt-2 pb-4 border border-blue">
      <b-row>
        <b-col>
          <b-row v-if="getEntityFile">
            <b-col cols="12" class="mt-3">
              <span class="font-bold text-blue h5">{{getEntityFile.brn}}</span><br/>
              <span class="font-bold text-gray h3">{{getEntityFile.name}}</span><br/>
              <div class="flex mt-1">
                  <b-badge class="bg-blue text-capitalize">
                    {{cleanEntityType(type)}}
                  </b-badge>
                  <b-badge class="mx-1 bg-green text-capitalize">
                    {{cleanEntityType(getEntityFile.subType)}}
                  </b-badge>
              </div>
              <span class="italic text-gray text-xs font-bold" v-if="getEntityFile.dateOfRegistration">Registration Date: {{dateFormat(getEntityFile.dateOfRegistration)}}</span><br/>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col cols="12" class="my-3 blink_me">
              <b-badge class="bg-gray_loading text-gray_loading my-1 w-2/12">name</b-badge><br/>
              <b-badge class="bg-gray_loading text-gray_loading py-3 w-4/12">name</b-badge>
              <div class="flex mt-1">
                <b-badge class="bg-gray_loading">
                  loading...
                </b-badge>
                <b-badge class="mx-1 bg-gray_loading">
                  loading...
                </b-badge>
              </div>
              <b-badge class="bg-gray_loading my-1 text-gray_loading w-6/12">name</b-badge>
              <br/>
            </b-col>
          </b-row>
          <b-row v-if="getEntityFile">
            <b-col>
              <CompanyFile :company="getEntityFile" v-if="type === 'company' && !getEntityFile.subType.includes('foreign')"/>
              <ForeignCompanyFile :company="getEntityFile" v-if="type ===  'company' && getEntityFile.subType.includes('foreign')"/>
              <BusinessNameFile :business="getEntityFile" v-if="type === 'business'"/>
              <BuildingSocietyFile :society="getEntityFile" v-if="type === 'building-society'"/>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col class="bg-gray_loading py-5 blink_me">

            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment";
import CompanyFile from "@/views/home/services/registry/business/files/CompanyFile";
import BusinessNameFile from "@/views/home/services/registry/business/files/BusinessNameFile";
import BuildingSocietyFile from "@/views/home/services/registry/business/files/BuildingSocietyFile";
import ForeignCompanyFile from "@/views/home/services/registry/business/files/ForeignCompanyFile";

export default {
  name: "EntityFileWrapper",
  components: { ForeignCompanyFile, BuildingSocietyFile, BusinessNameFile, CompanyFile},
  data(){
    return{
      brn:null,
      type:"",
      loading: false
    }
  },
  mounted(){
    this.brn = this.$route.params.id
    this.type = localStorage.getItem("entityType")

    this.loading = true
    if(this.type === "company"){
      this.fetchCompanyByBRN(this.brn)
    }

    if(this.type === "business"){
      this.fetchBusinessNameByBRN(this.brn)
    }

    if(this.type === "building-society"){
      this.fetchBuildingSocietyByBRN(this.brn)
    }
  },
  methods:{
    ...mapActions(["fetchCompanyByBRN", "fetchBusinessNameByBRN", "fetchBuildingSocietyByBRN"]),
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")

      if(name === "business"){
        n += " Name"
      }
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    back() {
      window.history.back()
    }
  },
  computed:{
    ...mapGetters(["getEntityFile"])
  },
  watch:{
    getEntityFile(){
      this.loading = false
    }
  }
}
</script>

<style scoped>
.blink_me {
  animation: blinker 3s linear infinite;
}

@keyframes blinker {
  80% {
    opacity: 0.3;
  }
}
</style>