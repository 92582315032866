const ID_TOKEN_KEY = "access_token";
const CURRENT_USER = 'current_user';

//refresh token
export const getToken = () => {
    return JSON.parse(window.sessionStorage.getItem(ID_TOKEN_KEY));
};

export const saveToken = token => {
    window.sessionStorage.setItem(ID_TOKEN_KEY, JSON.stringify(token));
};

export const destroyToken = () => {
    window.sessionStorage.removeItem(ID_TOKEN_KEY);
};

//user
//also contain client id and refresh expiry
export const getUser = () => {
    return JSON.parse(window.sessionStorage.getItem(CURRENT_USER))
}

export const saveUser = user => {
    window.sessionStorage.setItem(CURRENT_USER, JSON.stringify(user));
}

export const destroyUser = () => {
    window.sessionStorage.removeItem(CURRENT_USER)
}


export default { getToken, saveToken, destroyToken, getUser, saveUser, destroyUser };
