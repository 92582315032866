import {api} from "@/app/services/api.service";

const state = {
    words: [],
    rejectionWordsError: null,
    rejectionWordsSuccess: null
};

const getters = {
    getRejectionQueryWords: (state) => state.words,
    getRejectionQueryTransactionSuccess: (state) => state.rejectionWordsSuccess,
    geRejectionTransactionError: (state) => state.rejectionWordsError
};

const actions = {
    async fetchQueryRejectionWords({commit}) {
        api().get("/configuration/appeal-message/all").then(response => {
            commit('setRejectionWords', response.data);
        }).catch(error => {
            commit("setRejectionWordError", error.response)
        });
    },


    async addRejectionQueryWord({commit}, data) {
        api().post("/configuration/appeal-message/create/", data).then(response => {
            commit('setAddRejectionWordSuccess', response.data);
        }).catch(error => {
            commit("setRejectionWordError", error.response)
        });
    },
    async editRejectionQueryWord({commit}, data) {
        api().post("/configuration/appeal-message/edit", data).then(response => {
            commit('setEditWordSuccess', response.data);
        }).catch(error => {
            commit("setRejectionWordError", error.response)
        });
    },
    async deleteRejectionQueryWord({commit}, data) {
        api().get("/configuration/appeal-message/delete/" + data.id, data).then(response => {
            commit('setDeleteWordSuccess', response.data);
        }).catch(error => {
            commit("setRejectionWordError", error.message)
        });
    },
};

const mutations = {

    setRejectionWords: (state, response) => {
        state.words = response;
    },
    setAddRejectionWordSuccess: (state, response) => {
        state.rejectionWordsSuccess = response;
    },
    setEditWordSuccess: (state, response) => {
        state.rejectionWordsSuccess = response;
    },
    setDeleteWordSuccess: (state, response) => {
        state.rejectionWordsSuccess = response;
    },
    setRejectionWordError: (state, error) => {
        state.rejectionWordsError = error;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
