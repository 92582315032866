<template>
  <b-row>
    <b-col>
      <form ref="form">
        <b-row class="mx-auto">
          <b-col>
            <b-form-input
              class="my-2"
              v-model="form.name"
              placeholder="Group Name"
              required
              type="text">
            </b-form-input>
            <b-form-textarea
              class="my-2"
              v-model="form.description"
              placeholder="Description"
              type="text">
            </b-form-textarea>
            <button class="bg-blue text-white px-2 py-1 rounded-sm hover:bg-blue-dark" type="button" @click="submitGroup">
              Save Group
              <b-spinner v-if="spinner" small></b-spinner>
            </button>
          </b-col>
        </b-row>
      </form>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "NewGroup",
  data() {
    return {
      errorMessage: null,
      checkPermissions: false,
      form: {
        name: null,
        description: null
      },
      groupData: [],
      spinner: false,
      role_uid: null,
      permissionGroups: []
    };
  },
  mounted() {
    this.errorMessage = "";
    this.fetchPermissionGroups();
  },
  methods: {
    ...mapActions(
      [
        "fetchPermissionGroups",
        "addPermissionGroup"
      ]
    ),
    submitGroup() {
      this.spinner = true;
      this.errorMessage = null;

      let data = {
        description: this.form.description,
        name: this.form.name,
        enabled: true
      };
      this.addPermissionGroup(data);
    }
  }
  ,
  computed: {
    ...
      mapGetters(
        [
          "getPermissionGroupsList",
          "getRolesError",
          "getRoleDetails",
          "getPermissionList",
          "getRolesAndPermissionEdit",
          "getNewRole",
          "getNewRoleError",
          "getRolesAndPermissionUpdate",
          "getNewPermissionGroup"
        ]
      )
  }
  ,
  watch: {

    getPermissionGroupsList(rolesGroup) {
      if (rolesGroup) {
        this.groupData = rolesGroup;
      }
    },
    getNewPermissionGroup() {
      this.spinner = false;
      this.successToast("Success", "Group added successfully");
      this.groupData = [];
      this.fetchPermissionGroups();
    },
    getRolesError(rolesError) {
      if (rolesError) {
        this.spinner = false;
        this.errorMessage = rolesError.message;
        this.errorToast("Error", rolesError.message);
      }
    }
  }
};
</script>

<style scoped>

</style>