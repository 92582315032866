<template>
  <b-row>
    <b-col>
      <b-row>
        <b-modal ref="create" title="Add Category" hide-footer>
            <b-row>
              <b-col>
                <form>
                  <b-form-input
                    v-model="form.description"
                    :rules="nameRules"
                    class="my-2"
                    placeholder="Category description"
                    required>
                  </b-form-input>
                  <b-form-input
                    v-model="form.code"
                    :rules="nameRules"
                    class="my-2"
                    placeholder="Code"
                    required />
                  <label class="mt-2 text-sm">ISIC Category</label>
                  <b-form-select
                    v-model="form.parentId"
                    class="form-select mb-2"
                    :options="IsicItems">
                  </b-form-select>
                  <button
                    class="my-2 bg-blue text-white px-2 py-1 rounded-sm"
                    @click="submitIsicForm">
                    <i class="fa fa-save mr-1"></i>Save
                  </button>
                </form>
              </b-col>
            </b-row>
        </b-modal>
      </b-row>
      <b-row class="mx-auto mt-5">
        <b-col cols="4">
          <b-input-group class="border-2 border-gray rounded">
            <b-form-input
              class="border-0 noFocus"
              v-model="search"
              type="text"
              placeholder="Search"
              required>
            </b-form-input>

            <b-input-group-append style="background: #ffffff;">
              <i style="color: #909090;" class="fas fa-search mt-2 ml-1 mr-2"></i>

            </b-input-group-append>

          </b-input-group>
          <small style="color: #909090;">Press enter to search.</small>
        </b-col>
        <b-col cols="1" class="py-1">
          <i style="font-size: 30px" :class="isTable ? 'text-blue fa fa-table mx-1' : 'text-gray_title fa fa-table mx-1'" @click="isTable = !isTable"></i>
          <i style="font-size: 30px" :class="isTable ? 'text-gray_title fa fa-bars mx-1': 'text-blue fa fa-bars mx-1'" @click="isTable = !isTable"></i>
        </b-col>
        <b-col cols="5"></b-col>
        <b-col cols="2">
          <button class="w-full bg-blue text-white px-2 py-1 rounded-sm" @click="openForm">
            <i class="fa fa-plus"></i>
            Add Category
          </button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <isic-table :search="search" v-if="isTable"></isic-table>
          <isic-list v-else></isic-list>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import isicTable from "@/views/home/services/configurations/nature_of_business/IsicTable";
import {mapActions, mapGetters} from "vuex";
import IsicList from "@/views/home/services/configurations/nature_of_business/IsicList";

export default {
  name: "Isic",
  components: { IsicList, isicTable},
  data(){
    return{
      search:null,
      isTable:true,
      isTree:false,
      isicDialog:false,
      valid: true,
      loading: false,
      name: '',
      nameRules: [
        v => !!v || 'Field is required',
        v => (v && v.length <= 100) || 'Name must be less than 100 characters',
      ],
      select: null,
      IsicItems: [
      ],
      checkbox: false,
      form: {
        code: null,
        description: null,
        parentId: null
      }
    }
  },
  computed: {
    ...mapGetters(["getIsicFetch", "getIsicAdd", "getIsicFetchChildren", "getIsicAddError"]),
  },
  methods: {
    ...mapActions(["fetchIsic", "addIsicCategory"]),
    submitIsicForm() {
      this.loading = true
      this.addIsicCategory(this.form)
    },
    openForm(){
      this.$refs.create.show()
    }
  },
  watch: {

    getIsicAdd(){
      this.loading = false
      this.successToast()
      this.fetchIsic()
    },
    getIsicAddError(error) {
      this.loading = false
      this.errorToast("Invalid", error.message)
    },
    getIsicFetch(list) {
      list.forEach(item => {
        this.IsicItems.push({
          text: item.description,
          value: item.id
        })
      })
    },

  }
}
</script>

<style scoped>

</style>