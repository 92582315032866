<template>
    <div class="bg-white rounded-sm text-gray mx-2 my-3">
        <b-row>
            <b-col cols="12" class="mx-3 mt-3">
                <b-row>
                    <span class="title">General Settings</span>
                </b-row>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-row>
                    <b-col cols="12">
                        <div class="flex h-full" style="cursor: pointer;">
                            <div :class="[(currentRoute === 'departments') ? 'menu-item-active' : 'menu-item']"
                                 @click="select('departments')">
                                <div class="flex mt-6">
                                    <div style="font-size: 14px" class="mt-1">Departments</div>
                                </div>
                            </div>
                            <div :class="[(currentRoute === 'occupations') ? 'menu-item-active' : 'menu-item']"
                                 @click="select('occupations')">
                                <div class="flex mt-6">
                                    <div style="font-size: 14px" class="mt-1">Occupations</div>
                                </div>
                            </div>

                            <div :class="[(currentRoute === 'countries') ? 'menu-item-active' : 'menu-item']"
                                 @click="select('countries')">
                                <div class="flex mt-6">
                                    <div style="font-size: 14px" class="mt-1">Countries</div>
                                </div>
                            </div>

                            <div :class="[(currentRoute === 'manuals') ? 'menu-item-active' : 'menu-item']"
                                 @click="select('manuals')">
                                <div class="flex mt-6">
                                    <div style="font-size: 14px" class="mt-1">User Manuals</div>
                                </div>
                            </div>
                            <div :class="[(currentRoute === 'messenger') ? 'menu-item-active' : 'menu-item']"
                                 @click="select('messenger')">
                                <div class="flex mt-6">
                                    <div style="font-size: 14px" class="mt-1">System Messenger</div>
                                </div>
                            </div>

                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <Departments v-if="currentRoute === 'departments'"/>
                        <Occupation v-if="currentRoute === 'occupations'"/>
                        <UserManuals v-if="currentRoute === 'manuals'"/>
                        <Messenger v-if="currentRoute === 'messenger'"/>
                        <Countries v-if="currentRoute==='countries'"></Countries>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import Departments from "@/views/home/services/settings/departments/Departments";
import Occupation from "@/views/home/services/settings/ocupations/Occupation";
import UserManuals from "@/views/home/services/settings/manuals/UserManuals";
import Messenger from "@/views/home/services/settings/messenger/Messenger";
import Countries from "@/views/home/services/settings/countries/Countries";

export default {
    name: "Settings",
    components: {Messenger, UserManuals, Occupation, Departments, Countries},
    data() {
        return {
            currentRoute: "departments"
        };
    },
    methods: {
        select(route) {
            this.currentRoute = route;
        }
    }
};
</script>

<style scoped>
@import "../../../../assets/styles/menu.css";
</style>