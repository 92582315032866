import {api} from "@/app/services/api.service";

const state = {
  used_prns:null,
  prn_added:null,
  prn_removed: null,
  prn_error: null,
  error: null,
}

const getters = {
  getUsedPRNs: state => state.used_prns,
  getPRNsAdded: state => state.prn_added,
  getPRNRemoved: state => state.prn_removed,
  getPRNError: state => state.prn_error,
  getError: state => state.error,
}

const actions = {
  async fetchUsedPRNs({commit}, data) {
    api().get('billing/used-prn?page='+data.page+'&perPage='+data.perPage)
      .then(response => {
        commit('setUsedPRNs', response.data)
      })
      .catch(error => {
        commit('setPRNError', error)
      })
  },
  async filterByPrn({commit}, data) {
    api().get('billing/used-prn/by-prn/'+data.prn+'?page='+data.page+'&perPage='+data.perPage)
      .then(response => {
        commit('setUsedPRNs', response.data)
      })
      .catch(error => {
        commit('setPRNError', error)
      })
  },
  async addPRNs({commit}, data) {
    api().post('billing/used-prn/add', data)
      .then(response => {
        commit('setAddedPRNs', response)
      })
      .catch(error => {
        commit('setPRNError', error)
      })
  },
  async removePRN({commit}, data) {
    api().get('billing/delete-prn/' + data)
        .then(response => {
          commit('setRemovedPRN', response)
        })
        .catch(error => {
          commit('setPRNError', error)
        })
  },
  async uploadPRNs({commit}, data) {
    api().post('billing/upload-used-prn-file', data)
      .then(response => {
        commit('setAddedPRNs', response)
      })
      .catch(error => {
        commit('setPRNError', error)
      })
  },
}

const mutations = {
  setUsedPRNs: (state, payload) => {
    state.used_prns = payload.data;
  },
  setAddedPRNs: (state, payload) => {
    state.prn_added = payload.data;
  }, setRemovedPRN: (state, payload) => {
    state.prn_removed = payload.data;
  },
  setPRNError: (state, error) => {
    state.prn_error = error.response.data;
  },

}


export default {
  state,
  getters,
  actions,
  mutations

}