<template>
  <div class="bg-white rounded-sm text-gray mx-2 my-3">
    <b-row>
      <b-col cols="12" class="mx-3 mt-3">
        <b-row>
          <span class="title">Accounts Management</span>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <router-view></router-view>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "Accounts",
};
</script>

<style scoped>

</style>