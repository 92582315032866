import { render, staticRenderFns } from "./NewCountry.vue?vue&type=template&id=11b348fa&scoped=true&"
import script from "./NewCountry.vue?vue&type=script&lang=js&"
export * from "./NewCountry.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11b348fa",
  null
  
)

export default component.exports