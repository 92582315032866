import { render, staticRenderFns } from "./Countries.vue?vue&type=template&id=38918af5&scoped=true&"
import script from "./Countries.vue?vue&type=script&lang=js&"
export * from "./Countries.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38918af5",
  null
  
)

export default component.exports