import { render, staticRenderFns } from "./PartnerDetails.vue?vue&type=template&id=dcdb9842&scoped=true&"
import script from "./PartnerDetails.vue?vue&type=script&lang=js&"
export * from "./PartnerDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dcdb9842",
  null
  
)

export default component.exports