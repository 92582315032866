<template>
    <div class="bg-white rounded-sm text-gray mx-2 my-3">
        <b-row>
            <b-modal ref="addReason" title="Add Winding up  reason" hide-footer>
                <new-reason/>
            </b-modal>
            <b-modal ref="editReason" title="Update Winding Up reason" hide-footer>
                <edit-reason :draft-data="draft"/>
            </b-modal>
        </b-row>
        <b-row>
            <b-col>
                <b-row>
                    <b-col cols="10"></b-col>
                    <b-col cols="2">
                        <button class="w-full bg-blue text-white px-2 py-1 rounded-sm" @click="openForm"><i
                                class="fa fa-plus"></i>
                            Add Reason
                        </button>
                    </b-col>
                </b-row>
                <b-row class="my-3">
                    <b-col>
                        <table class="min-w-full leading-normal">
                            <thead>
                            <tr class="border-b border-t border-grey">
                                <th class="t-header">#</th>
                                <th class="t-header" width="90%">Name</th>
                                <th class="t-header text-center">Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(pb,idx) in professional_bodies"
                                :key="pb.id">
                                <td class="t-body">{{ idx + 1 }}</td>
                                <td class="t-body font-bold text-capitalize">{{ pb.reason }}</td>
                                <td class="t-body font-bold">
                                    <b-row>
                                        <b-col>
                                            <button @click="openEditForm(pb)"
                                                    class=" bg-green text-white text-xs px-2 py-1 rounded-sm"><i
                                                    class="fa fa-pencil"></i> Edit
                                            </button>
                                        </b-col>
                                        <b-col>
                                            <button class=" bg-red text-white text-xs px-2 py-1 rounded-sm"><i
                                                    class="fa fa-trash"></i> Delete
                                            </button>
                                        </b-col>
                                    </b-row>

                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>


import NewReason from "@/views/home/services/settings/insolvency/reasons/NewReason.vue";
import EditReason from "@/views/home/services/settings/insolvency/reasons/EditReason.vue";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "WindingUpReasons",
    components: {
        EditReason,
        NewReason
    },
    data() {
        return {
            registerDepartment: false,
            newDepartment: false,
            professional_bodies: [],
            rows: {
                odd: "border-t border-grey hover:bg-header_grey",
                even: "border-t border-grey bg-header_grey"
            },
            draft: null
        };
    },
    mounted() {
        this.fetchReasons();
    },

    methods: {
        ...mapActions(["fetchReasons", "createReason"]),
        openForm() {
            this.$refs.addReason.show();
        },
        openEditForm(body) {
            this.draft = body
            this.$refs.editReason.show()
        },

    }
    ,
    computed: {
        ...mapGetters(["getReasons"])
    },

    watch: {
        getReasons(data) {
            this.professional_bodies = data
        }
    }

};
</script>

<style scoped>

</style>