<template>
    <b-row>
        <b-col cols="12">
          <b-row>
            <b-col cols="12" class="mx-3 mt-3">
              <b-row>
                <span class="title">Tax Head Configurations</span>
              </b-row>
            </b-col>
          </b-row>
            <b-row>
                <b-modal size="lg" ref="newTaxHead" hide-footer title="New Tax Head">
                    <b-col cols="11" class="mx-auto">
                        <b-row>
                            <b-col>
                                <b-form-input class="my-2" type="text" v-model="form.taxHeadName"
                                              placeholder="Tax Head Name"></b-form-input>
                                <b-form-input class="my-2" type="text" v-model="form.taxHeadId"
                                              placeholder="Tax Head ID"></b-form-input>
                            </b-col>
                            <b-col>
                                <b-form-input class="my-2" type="text" v-model="form.taxHeadCode"
                                              placeholder="Tax Head"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-textarea class="my-2" type="text" v-model="form.taxHeadDescription"
                                                 placeholder="Tax Head Description"></b-form-textarea>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <label class="text-sm">Base Value</label>
                                <b-form-input class="mb-2" type="number" v-model="form.baseValue"
                                              placeholder="Base Value"></b-form-input>
                                <label class="text-sm">Percentage %</label>
                                <b-form-input class="mb-2" type="text" v-model="form.percentage"
                                              placeholder="Percentage"></b-form-input>
                                <label class="text-sm">Fees Per Unit</label>
                                <b-form-input class="mb-2" type="text" v-model="form.feesPerUnit"
                                              placeholder="Fees Per Unit"></b-form-input>
                                <label class="text-sm">Is Active</label>
                                <b-form-select class="form-select mb-2" v-model="form.isActive"
                                               :options="yesNo"></b-form-select>
                            </b-col>
                            <b-col>
                                <label class="text-sm">Is Available On Portal</label>
                                <b-form-select class="form-select mb-2" v-model="form.isAvailableOnPortal"
                                               :options="yesNo"></b-form-select>
                                <label class="text-sm">Is Description Displayed</label>
                                <b-form-select class="form-select mb-2" v-model="form.isDescriptionDisplayed"
                                               :options="yesNo"></b-form-select>
                                <label class="text-sm">Is Tin Mandatory</label>
                                <b-form-select class="form-select mb-2" v-model="form.isTinMandatory"
                                               :options="yesNo"></b-form-select>
                                <label class="text-sm">Is Reference Type Mandatory</label>
                                <b-form-select class="form-select mb-2" v-model="form.isReferenceTypeMandatory"
                                               :options="yesNo"></b-form-select>
                            </b-col>
                        </b-row>

                        <p>Tax Head Levels</p>
                        <b-row>
                            <b-col>
                                <label class="text-sm">Level 1</label>
                                <b-form-select class="form-select mb-2" v-model="form.level_1"
                                               :options="level1"></b-form-select>
                                <label class="text-sm">Level 3</label>
                                <b-form-select class="form-select mb-2" v-model="form.level_3"
                                               :options="level3"></b-form-select>
                                <label class="text-sm">Level 5</label>
                                <b-form-select class="form-select mb-2" v-model="form.level_5"
                                               :options="level5"></b-form-select>
                            </b-col>
                            <b-col>
                                <label class="text-sm">Level 2</label>
                                <b-form-select class="form-select mb-2" v-model="form.level_2"
                                               :options="level2"></b-form-select>
                                <label class="text-sm">Level 4</label>
                                <b-form-select class="form-select mb-2" v-model="form.level_4"
                                               :options="level4"></b-form-select>
                                <label class="text-sm">Level 6</label>
                                <b-form-select class="form-select mb-2" v-model="form.level_6"
                                               :options="level6"></b-form-select>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="8"></b-col>
                            <b-col cols="4">
                                <button class="w-full bg-blue text-white px-2 py-1 rounded-sm" @click="save">
                                    <i class="fa fa-save mx-1"></i> Save
                                    <b-spinner v-if="spinner" small></b-spinner>
                                </button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-modal>
                <b-modal size="lg" ref="viewTaxHead" hide-footer title="Tax Head">
                    <b-col cols="11" class="mx-auto">
                        <b-row>
                            <b-col>
                                <b-form-input class="my-2" type="text" v-model="selectedTaxHead.taxHeadName"
                                              placeholder="Tax Head Name"></b-form-input>
                                <b-form-input class="my-2" type="text" v-model="selectedTaxHead.taxHeadId"
                                              placeholder="Tax Head ID"></b-form-input>
                            </b-col>
                            <b-col>
                                <b-form-input class="my-2" type="text" v-model="selectedTaxHead.taxHeadCode"
                                              placeholder="Tax Head"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-textarea class="my-2" type="text" v-model="selectedTaxHead.taxHeadDescription"
                                                 placeholder="Tax Head Description"></b-form-textarea>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <label class="text-sm">Base Value</label>
                                <b-form-input class="mb-2" type="number" v-model="selectedTaxHead.baseValue"
                                              placeholder="Base Value"></b-form-input>
                                <label class="text-sm">Percentage %</label>
                                <b-form-input class="mb-2" type="text" v-model="selectedTaxHead.percentage"
                                              placeholder="Percentage"></b-form-input>
                                <label class="text-sm">Fees Per Unit</label>
                                <b-form-input class="mb-2" type="text" v-model="selectedTaxHead.feesPerUnit"
                                              placeholder="Fees Per Unit"></b-form-input>
                                <label class="text-sm">Is Active</label>
                                <b-form-select class="form-select mb-2" v-model="selectedTaxHead.isActive"
                                               :options="yesNo"></b-form-select>
                            </b-col>
                            <b-col>
                                <label class="text-sm">Is Available On Portal</label>
                                <b-form-select class="form-select mb-2" v-model="selectedTaxHead.isAvailableOnPortal"
                                               :options="yesNo"></b-form-select>
                                <label class="text-sm">Is Description Displayed</label>
                                <b-form-select class="form-select mb-2" v-model="selectedTaxHead.isDescriptionDisplayed"
                                               :options="yesNo"></b-form-select>
                                <label class="text-sm">Is Tin Mandatory</label>
                                <b-form-select class="form-select mb-2" v-model="selectedTaxHead.isTinMandatory"
                                               :options="yesNo"></b-form-select>
                                <label class="text-sm">Is Reference Type Mandatory</label>
                                <b-form-select class="form-select mb-2"
                                               v-model="selectedTaxHead.isReferenceTypeMandatory"
                                               :options="yesNo"></b-form-select>
                            </b-col>
                        </b-row>

                        <p>Tax Head Levels</p>
                        <b-row>
                            <b-col>
                                <label class="text-sm">Level 1</label>
                                <b-form-select class="form-select mb-2" v-model="selectedTaxHead.level_1"
                                               :options="level1"></b-form-select>
                                <label class="text-sm">Level 3</label>
                                <b-form-select class="form-select mb-2" v-model="selectedTaxHead.level_3"
                                               :options="level3"></b-form-select>
                                <label class="text-sm">Level 5</label>
                                <b-form-select class="form-select mb-2" v-model="selectedTaxHead.level_5"
                                               :options="level5"></b-form-select>
                            </b-col>
                            <b-col>
                                <label class="text-sm">Level 2</label>
                                <b-form-select class="form-select mb-2" v-model="selectedTaxHead.level_2"
                                               :options="level2"></b-form-select>
                                <label class="text-sm">Level 4</label>
                                <b-form-select class="form-select mb-2" v-model="selectedTaxHead.level_4"
                                               :options="level4"></b-form-select>
                                <label class="text-sm">Level 6</label>
                                <b-form-select class="form-select mb-2" v-model="selectedTaxHead.level_6"
                                               :options="level6"></b-form-select>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="8"></b-col>
                            <b-col cols="4">
                                <button class="w-full bg-blue text-white px-2 py-1 rounded-sm" @click="edit">
                                    <i class="fa fa-pen-square mx-1"></i> Edit
                                    <b-spinner v-if="spinner" small></b-spinner>
                                </button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-modal>
            </b-row>
            <b-row class="py-3">
                <b-col cols="10"></b-col>
                <b-col cols="2">
                    <button class="w-full bg-blue text-white py-1 rounded-sm" @click="openForm">
                        <i class="fa fa-plus mx-1"></i> Add Tax Head
                    </button>
                </b-col>
            </b-row>
            <div class="mx-auto">
                <b-progress value="100" v-if="loading" variant="#007ac5" height="0.2rem" animated></b-progress>
            </div>
            <b-row>
                <b-col>
                    <table class="min-w-full leading-normal">
                        <thead>
                        <tr class="border-b border-t border-grey">
                            <th class="t-header">#</th>
                            <th class="t-header">Name</th>
                            <th class="t-header">Tax Head</th>
                            <th class="t-header">Tax Head ID</th>
                            <th class="t-header">Base Value</th>
                            <th class="t-header text-center">Is Active</th>
                            <th class="t-header text-center">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(taxhead, idx) in taxheads" :key="idx">
                            <td class="t-body ">{{ idx + 1 }}</td>
                            <td class="t-body text-capitalize font-bold">{{ taxhead.taxHeadName }}</td>
                            <td class="t-body text-capitalize font-bold">{{ taxhead.taxHeadCode }}</td>
                            <td class="t-body text-capitalize font-bold">{{ taxhead.taxHeadId }}</td>
                            <td class="t-body text-capitalize font-bold">{{ taxhead.baseValue }}</td>
                            <td class="t-body text-capitalize font-bold text-center">
                                <i class="fa fa-check text-green" v-if="taxhead.isActive === 'YES'"></i>
                                <i class="fa fa-times text-red" v-if="taxhead.isActive === 'NO'"></i>
                            </td>
                            <td class="t-body text-center">
                                <button class="bg-blue px-2 py-1 rounded-sm text-white" @click="view(taxhead)">
                                    View
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td v-if="taxheads.length === 0" colspan="12" class="bg-inertgrey text-center py-1">
                                <span class="text-sm">No results found</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "URSBTaxHeads",
    data() {
        return {
            registerOccupation: false,
            loading: false,
            new: false,
            serviceCosts: [],
            search: null,
            spinner: false,
            rows: {
                odd: "border-t border-grey hover:bg-header_grey",
                even: "border-t border-grey bg-header_grey"
            },
            taxheads: [],
            selectedTaxHead: {},
            form: {
                taxHeadCode: null,
                taxHeadId: null,
                taxHeadName: null,
                taxHeadDescription: null,
                isActive: "YES",
                isAvailableOnPortal: "YES",
                isDescriptionDisplayed: "YES",
                isTinMandatory: "YES",
                isReferenceTypeMandatory: "YES",
                baseValue: 0,
                baseValueCurrency: "UGANDA_SHILLINGS",
                taxHeadCurrency: "UGANDA_SHILLINGS",
                percentage: 0,
                feesPerUnit: 0,
                level_1: null,
                level_2: null,
                level_3: null,
                level_4: null,
                level_5: null,
                level_6: ""
            },
            yesNo: [
                {
                    value: "YES",
                    text: "Yes"
                },
                {
                    value: "NO",
                    text: "No"
                }
            ],
            level1: [
                {
                    value: "UGANDA REGISTRATION SERVICES BUREAU",
                    text: "UGANDA REGISTRATION SERVICES BUREAU"
                },
            ],
            level2: [
                {
                    value: "ADMINISTRATION",
                    text: "ADMINISTRATION"
                },
                {
                    value: "BUSINESS NAME REGISTRATION DEPARTMENT",
                    text: "BUSINESS NAME REGISTRATION DEPARTMENT"
                },
                {
                    value: "CIVIL REGISTRATION DEPARTMENT",
                    text: "CIVIL REGISTRATION DEPARTMENT"
                },
                {
                    value: "INTELLECTUAL PROPERTY DEPARTMENT",
                    text: "INTELLECTUAL PROPERTY DEPARTMENT"
                },
                {
                    value: "OFFICIAL RECEIVER/LIQUIDATION DEPARTMENT",
                    text: "OFFICIAL RECEIVER/LIQUIDATION DEPARTMENT"
                },
            ],
            level3: [
                {
                    value: "TENDER FEES",
                    text: "TENDER FEES"
                },
                {
                    value: "BUSINESS NAMES",
                    text: "BUSINESS NAMES"
                },
                {
                    value: "COMPANY REGISTRATION",
                    text: "COMPANY REGISTRATION"
                },
                {
                    value: "FINES/PENALTY",
                    text: "FINES/PENALTY"
                }, {
                    value: "REGISTRATION OF CHARGES OF COMPANIES",
                    text: "REGISTRATION OF CHARGES OF COMPANIES"
                },
                {
                    value: "REGISTRATION OF CHATTELS",
                    text: "REGISTRATION OF CHATTELS"
                },
                {
                    value: "REGISTRATION OF DOCUMENTS",
                    text: "REGISTRATION OF DOCUMENTS"
                },
                {
                    value: "CHURCH/HINDU AND ISLAMIC MARRIAGES",
                    text: "CHURCH/HINDU AND ISLAMIC MARRIAGES"
                },
                {
                    value: "CUSTOMARY MARRIAGES",
                    text: "CUSTOMARY MARRIAGES"
                },
                {
                    value: "MARRIAGE UNDER MARRIAGE ACT",
                    text: "MARRIAGE UNDER MARRIAGE ACT"
                },
                {
                    value: "SINGLE STATUS LETTER",
                    text: "SINGLE STATUS LETTER"
                },
                {
                    value: "LOCAL",
                    text: "LOCAL"
                },
                {
                    value: "FOREIGN",
                    text: "FOREIGN"
                },
                {
                    value: "CORPORATE INSOLVENCY",
                    text: "CORPORATE INSOLVENCY"
                },
                {
                    value: "INDIVIDUAL INSOLVENCY",
                    text: "INDIVIDUAL INSOLVENCY"
                }, {
                    value: "REGULATION OF INSOLVENCY PRACTICE",
                    text: "REGULATION OF INSOLVENCY PRACTICE"
                },
            ],
            level4: [
                {
                    value: "UTILITY MODEL",
                    text: "UTILITY MODEL"
                },
                {
                    value: "TRADEMARKS",
                    text: "TRADEMARKS"
                },
                {
                    value: "TECHNOVATIONS",
                    text: "TECHNOVATIONS"
                },
                {
                    value: "PATENTS",
                    text: "PATENTS"
                },
                {
                    value: "INDUSTRIAL DESIGN",
                    text: "INDUSTRIAL DESIGN"
                },
                {
                    value: "GEOGRAPHICAL INDICATIONS",
                    text: "GEOGRAPHICAL INDICATIONS"
                },
                {
                    value: "COPYRIGHT",
                    text: "COPYRIGHT"
                },
            ],
            level5: [
                {
                    value: "CERTIFICATION MARKS",
                    text: "CERTIFICATION MARKS"
                },
                {
                    value: "DEFENSIVE MARK",
                    text: "DEFENSIVE MARK"
                },
                {
                    value: "TRADEMARKS",
                    text: "TRADEMARKS"
                },
            ],
            level6: []
        };
    },
    mounted() {
        this.loading = true
        this.fetchTaxHeads()
    },
    methods: {
        ...mapActions(["fetchTaxHeads", "createTaxHead"]),
        save() {
            this.spinner = true
            this.createTaxHead(this.form);
        },
        edit() {
            this.warningToast("New Feature", "Coming soon")
        },
        openForm() {
            this.$refs.newTaxHead.show()
        },
        view(taxhead) {
            this.selectedTaxHead = taxhead
            this.$refs.viewTaxHead.show()
        }
    },
    computed: {
        ...mapGetters(["getTaxHead", "getTaxHeads"])
    },
    watch: {
        getTaxHeads(data) {
            this.loading = false
            this.taxheads = data
        },
        getTaxHead() {
            this.spinner = false
            this.$refs.newTaxHead.hide()
            this.fetchTaxHeads()
        },
        getError() {
            this.errorToast("Error", "Service  creation failed");
        }
    }

}
</script>

<style scoped>

</style>