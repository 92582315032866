<template>
  <b-row>
    <b-col>
      <b-row class="my-3">
        <b-col>
          <b-form-input
            v-model="form.name"
            placeholder="Occupation Name"
            required>
          </b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="8"></b-col>
        <b-col cols="4" class="float-right">
          <button @click="addOccupation" class="w-full bg-blue text-white px-2 py-1 rounded-sm">
            Save
            <b-spinner v-if="spinner" small></b-spinner>
          </button>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "NewOccupation",
  data() {
    return {
      form: {
        name: null
      },
      spinner: false,
    }
  },

  methods: {
    ...mapActions([
      "createOccupation", "fetchOccupations"
    ]),
    addOccupation() {
      this.spinner = true;
      this.createOccupation(this.form);
    }
  },
  computed: {
    ...mapGetters([
      "getOccupation",
      "getOccupations"
    ])
  }
  ,
  watch: {
    getOccupation() {
      this.successToast("Success", "Occupation created successfully");
      this.spinner = false;
      this.fetchOccupations();
    },
    getError() {
      this.spinner = false;
      this.errorToast("Error", "Occupation creation failed");
    }
  }

}
</script>

<style scoped>

</style>