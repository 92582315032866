<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col>
          <b-row>
            <b-modal ref="create" title="Add Names" hide-footer>
              <b-row>
                <b-col>
                  <form @submit.prevent="saveUpload">
                    <b-input-group>
                      <b-form-file
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          label-size="sm"
                          type="file"
                          placeholder="Please provide a copy of you id..."
                          drop-placeholder="Drop file here..."
                          v-model="file"
                          class="form-control noFocus removeOnClickBorder rounded-0 mb-2"
                          plain></b-form-file>
                    </b-input-group>
                    <button class="bg-blue text-white px-3 py-1 rounded-sm mt-2 float-end" type="submit">
                      Upload <b-spinner v-if="spinner" small></b-spinner>
                    </button>
                  </form>
                </b-col>
              </b-row>
            </b-modal>
          </b-row>
          <b-row class="mx-auto justify-content-between mt-5">
            <b-col cols="4">
              <b-input-group class="border-2 border-gray rounded">
                <b-form-input
                    class="border-0 noFocus"
                    v-model="searchName"
                    placeholder="Search Name"
                    @keydown.enter="searchPhrase"
                    type="text"
                    required>
                </b-form-input>

                <b-input-group-append style="background: #ffffff;">
                  <i style="color: #909090;" class="fas fa-search mt-2 ml-1 mr-2"></i>

                </b-input-group-append>

              </b-input-group>
              <small style="color: #909090;">Press enter to search.</small>
            </b-col>
            <b-col cols="2">
              <button class="w-full bg-blue text-white px-2 py-1 rounded-sm" @click="openForm">
                <i class="fa fa-plus"></i>
                Add Names
              </button>
            </b-col>
          </b-row>
          <div class="mx-auto mt-2">
            <b-progress value="100" v-if="loading" variant="#007ac5" height="0.2rem" animated></b-progress>
          </div>
          <b-row>
            <b-col cols="12">
              <div class="mx-2 my-3 px-2">
                <b-row>
                  <b-col cols="12">
                    <table class="w-full leading-normal">
                      <thead>
                      <tr class="border-b border-t border-grey">
                        <th class="t-header">#</th>
                        <th class="t-header">BRN/RN</th>
                        <th class="t-header">Name</th>
                        <th class="t-header">Entity Type</th>
                        <!--            <th class="t-header text-center">Status</th>-->
                        <!--            <th class="t-header">Res / Reg Date</th>-->
                        <!--            <th class="t-header">Last Change</th>-->
                      </tr>
                      </thead>
                      <tbody>
                      <tr :class="[(idx%2) ? rows.even : rows.odd]" class="hover:bg-header_grey" v-for="(list, idx) in names" :key="idx">
                        <td class="t-body">{{idx+1}}</td>
                        <td class="t-body text-blue font-bold">
                          {{list.no}}
                        </td>
                        <td class="t-body text-gray font-bold">{{list.name}}</td>
                        <td class="t-body text-gray text-capitalize">{{list.type}}</td>
                        <!--            <td class="t-body text-white text-center">-->
                        <!--              <b-badge class="bg-orange mx-auto" v-if="list.status == 'Reserved'">{{list.status}}</b-badge>-->
                        <!--              <b-badge class="bg-blue mx-auto" v-else>{{list.status}}</b-badge>-->
                        <!--            </td>-->
                        <!--            <td class="t-body text-gray italic">-->
                        <!--              {{dateFormat(list.createdAt)}}-->
                        <!--            </td>-->
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "QuickNameSearch",
  data () {
    return {
      searchName:null,
      searchError:null,
      loading:false,
      spinner: false,
      file:null,
      names: [],
      rows:{
        odd:"border-b border-grey",
        even:"border-b border-grey bg-header_grey"
      },
    }
  },
  mounted() {
  },
  methods: {
    ...mapActions(['doSearch', 'uploadBusinessNames']),
    openForm(){
      this.$refs.create.show()
    },
    searchPhrase(){
      this.searchError = ""
      this.loading = true
      this.doSearch(this.appendQueryParams({"name":this.searchName, "scope":''}))
    },
    saveUpload(){
      let formData = new FormData;
      formData.set("file", this.file);
      this.spinner = true
      this.uploadBusinessNames(formData);
    },
  },
  computed: {
    ...mapGetters(["getSearchResults", "getNameFileUpload", "getMessageError"])
  },
  watch: {
    getSearchResults(data){
      if(data){
        this.names = data
        this.loading = false
      }
    },
    getNameFileUpload(data){
      if(data){
        this.names = data
        this.loading = false
      }
    },
    getMessageError(error){
      if(error){
        this.loading = false
        this.searchError = error.message
        this.errorToast("Error", error.message)
      }
    }
  },
};
</script>

<style scoped>

</style>