<template>
  <b-row>
    <b-col>
      <b-row>

        <b-modal size="lg" title="Add Account" ref="addAccountForm" hide-footer>
          <b-col cols="11" class="mx-auto">

          <b-row>
              <b-col>
                <b-form-select disabled class="form-select my-2" v-model="form.idType">
                  <b-select-option value="NATIONAL_ID">National ID</b-select-option>
                </b-form-select>
              </b-col>
              <b-col>
                <b-form-input class="my-2" type="text" v-model="form.idNumber"
                              @keyup="validateIdTypes"
                              placeholder="National ID Number"></b-form-input>
                <b-button v-if="loaderNinProgress" variant="primary" disabled>
                  <b-spinner v-if="loaderNinProgress" small></b-spinner>
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-input class="my-2" type="text" v-model="form.surname"
                              disabled
                              placeholder="Surname"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-input class="my-2"
                              disabled
                              type="text" v-model="form.middleName"
                              placeholder="Middle Name"></b-form-input>
                <b-form-input class="my-2" type="text" v-model="form.email"
                              placeholder="Email Address"></b-form-input>
                <label class="text-sm">Date of Birth</label>
                <b-form-input class="mb-2"
                              disabled
                              type="date" v-model="form.dob"></b-form-input>
              </b-col>
              <b-col>
                <b-form-input class="my-2"
                              disabled
                              type="text" v-model="form.givenName"
                              placeholder="Given Name"></b-form-input>
                <b-form-input class="my-2" type="text" v-model="form.phoneNumber"
                              placeholder="Phone Number"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label class="text-sm">Gender</label>
                <b-form-select disabled class="form-select mb-2" v-model="form.gender">
                  <b-select-option :value="null">-- Select Gender --</b-select-option>
                  <b-select-option value="MALE">Male</b-select-option>
                  <b-select-option value="FEMALE">Female</b-select-option>
                </b-form-select>
                <label class="text-sm">Role</label>
                <b-form-select class="form-select mb-2" v-model="form.roleId">
                  <b-select-option :value="null">-- Select Role --</b-select-option>
                  <b-select-option v-for="(role,idx) in rolesDataList" :value="role.value" :key="idx"
                                   :id="role.roleId">{{ role.text }}
                  </b-select-option>
                </b-form-select>
              </b-col>
              <b-col>
                <label class="text-sm">Nationality</label>
                <b-form-select
                  class="form-select mb-2"
                  disabled
                  v-model="form.nationality"
                  :options="countries"></b-form-select>
                <label class="text-sm">Department</label>
                <b-form-select class="form-select mb-2" v-model="form.departmentId">
                  <b-select-option :value="null">-- Select Department --</b-select-option>
                  <b-select-option v-for="(dept,idx) in departmentList" :value="dept.id" :key="idx">
                    {{ dept.departmentName }}
                  </b-select-option>
                </b-form-select>
              </b-col>
            </b-row>
            <b-row class="my-2">
              <b-col class="mx-auto">
                <label class="text-sm">Attach Signature</label>
                <b-form-file type="file"
                             ref="FileInput"
                             accept="image/png, image/jpeg, image/bmp"
                             @change="onFileSelect"
                             label-size="sm"
                             placeholder="Please provide a copy of you id..."
                             drop-placeholder="Drop file here..."
                             class="form-control noFocus removeOnClickBorder rounded-0 mb-2"
                             plain

                ></b-form-file>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="10"></b-col>
              <b-col cols="2">
                <button class="w-full bg-blue text-white px-2 py-1 rounded-sm"
                        @click="registerInternalUser">
                  <i class="fa fa-save mx-1"></i> Save
                  <b-spinner v-if="registerSpinner" small />
                </button>
              </b-col>
            </b-row>
          </b-col>
        </b-modal>

        <b-modal size="lg" title="Edit Account" ref="editAccountForm" hide-footer>
          <b-col cols="11" class="mx-auto">
            <b-row>
              <b-col>
                <b-form-select :disabled="true" class="form-select my-2" v-model="editForm.idType">
                  <b-select-option value="NATIONAL_ID">National ID</b-select-option>
                </b-form-select>
              </b-col>
              <b-col>
                <b-form-input class="my-2" :disabled="true" type="text" v-model="editForm.idNumber"
                              placeholder="National ID Number"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-input class="my-2" :disabled="true" type="text" v-model="editForm.surname"
                              placeholder="Surname"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-input class="my-2" type="text" :disabled="true" v-model="editForm.middleName"
                              placeholder="Middle Name"></b-form-input>
                <b-form-input class="my-2" type="text" v-model="editForm.email"
                              placeholder="Email Address"></b-form-input>
              </b-col>
              <b-col>
                <b-form-input class="my-2" type="text" :disabled="true" v-model="editForm.givenName"
                              placeholder="Given Name"></b-form-input>
                <b-form-input class="my-2" type="text" v-model="editForm.phoneNumber"
                              placeholder="Phone Number"></b-form-input>
              </b-col>
            </b-row>
            <b-row class="mx-auto">
              <b-col>
                <label class="text-sm">Gender</label>
                <b-form-select class="form-select mb-2" :disabled="true" v-model="editForm.gender">
                  <b-select-option :value="null">-- Select Gender --</b-select-option>
                  <b-select-option value="MALE">Male</b-select-option>
                  <b-select-option value="FEMALE">Female</b-select-option>
                </b-form-select>
                <label class="text-sm">Role</label>
                <b-form-select class="form-select mb-2" :disabled="true" v-model="editForm.roleId">
                  <b-select-option :value="null">-- Select Role --</b-select-option>
                  <b-select-option value="MALE">Male</b-select-option>
                  <b-select-option value="FEMALE">Female</b-select-option>
                </b-form-select>
              </b-col>
              <b-col>
                <label class="text-sm">Nationality</label>
                <b-form-select class="form-select mb-2" :disabled="true" v-model="editForm.nationality"
                               :options="countries"></b-form-select>
                <label class="text-sm">Department</label>
                <b-form-select class="form-select mb-2" :disabled="true"
                               v-model="editForm.departmentId">
                  <b-select-option :value="null">-- Select Department --</b-select-option>
                  <b-select-option value="MALE">Male</b-select-option>
                  <b-select-option value="FEMALE">Female</b-select-option>
                </b-form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="10"></b-col>
              <b-col cols="2">
                <button class="w-full bg-blue text-white px-2 py-1 rounded-md text-xs"
                        @click="updateUserDetails">
                  <i class="fa fa-save mx-1"></i> Save changes
                </button>
              </b-col>
            </b-row>
          </b-col>
        </b-modal>

          <b-modal size="md" title="Make Account Official Receiver" ref="makeReceiverForm" hide-footer>
              <b-col cols="11" class="mx-auto">

                  <b-row>
                      <b-col>
                          By clicking the button below, you are making this account an official receiver
                          <b-form-input disabled class="my-2" type="text" v-model="userName"
                                        placeholder="Organisation Name"></b-form-input>
                      </b-col>
                  </b-row>

                  <make-official-receiver v-on:selected="selectIndividualAccount"/>

                  <b-row>
                      <b-col cols="3">
                          <button class="w-full bg-red text-white px-2 py-1 rounded-md text-sm"
                          >
                              <i class="fa fa-save mr-1 text-xs"></i> Cancel
                              <b-spinner v-if="loading" small/>
                          </button>
                      </b-col>

                      <b-col cols="6"></b-col>


                      <b-col cols="3">
                          <button class="w-full bg-green text-white px-2 py-1 rounded-md text-sm"
                                  @click="makeUserOfficialReceiver()">
                              <i class="fa fa-save mr-1 text-xs"></i> Accept
                              <b-spinner v-if="loading" small/>
                          </button>
                      </b-col>
                  </b-row>
              </b-col>
          </b-modal>


          <b-modal size="lg" title="Edit Staff Signature" ref="editSignatureForm" hide-footer>
          <b-col cols="11" class="mx-auto">

            <b-row>
              <b-col>
                <b-form-input class="my-2" :disabled="true" type="text" v-model="editForm.surname"
                              placeholder="Surname"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-input class="my-2" type="text" :disabled="true" v-model="editForm.middleName"
                              placeholder="Middle Name"></b-form-input>
                <b-form-input class="my-2" type="text" v-model="editForm.email"
                              readonly
                              placeholder="Email Address"></b-form-input>
              </b-col>
              <b-col>
                <b-form-input class="my-2" type="text"
                              :disabled="true"
                              v-model="editForm.givenName"
                              placeholder="Given Name"
                              readonly
                ></b-form-input>

                <b-form-input class="my-2"
                              type="text"
                              v-model="editForm.phoneNumber"
                              readonly
                              placeholder="Phone Number"></b-form-input>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <StaffSignature :user-id="editForm.userId" :signature-file="editForm.signature" />
              </b-col>
            </b-row>


            <b-row class="my-2">
              <b-col class="mx-auto">
                <label class="text-sm">Attach Signature</label>
                <b-form-file type="file"
                             ref="FileInput"
                             accept="image/png, image/jpeg, image/bmp"
                             @change="onFileSelect"
                             label-size="sm"
                             placeholder="Please provide a copy of you id..."
                             drop-placeholder="Drop file here..."
                             class="form-control noFocus removeOnClickBorder rounded-0 mb-2"
                             plain

                ></b-form-file>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="10"></b-col>
              <b-col cols="2">
                <button class="w-full bg-blue text-white px-2 py-1 rounded-md text-sm"
                        @click="updateStaffSignature">
                  <i class="fa fa-save mr-1 text-xs"></i> Save changes
                  <b-spinner v-if="updateSignatureSpinner" small />
                </button>
              </b-col>
            </b-row>
          </b-col>
        </b-modal>
      </b-row>
      <b-modal size="lg" title="Staff Account Signature" ref="signatureForm" hide-footer>
        <b-card
          title="Crop staff signature"
          class="mb-2">
          <b-card-text>
            <VueCropper v-show="selectedFile" ref="cropper" :src="selectedFile"
                        alt="Source Image"></VueCropper>
          </b-card-text>
          <b-button class="float-left mx-2" @click="this.$refs.signatureForm.hide()" variant="primary">Cancel
          </b-button>
          <b-button class="float-right mx-2" @click="saveImage(); (dialog = false) " variant="primary">Crop
            signature
          </b-button>


        </b-card>


      </b-modal>
      <b-modal title="Account Roles" ref="accountRoles" hide-footer>
        <b-row>
          <b-col class="bg-header_grey rounded px-2 py-2">
            <p v-for="(list, index) in userRoles" :key="index" class="text-sm">
              <label v-if="list.name !=='ROLE_USER'">
                -
                {{ list.name.replaceAll("_", " ") }}
                <i class="fa fa-times-circle text-red mx-1" style="cursor: pointer"
                   title="Click to remove  role from this user "
                   @click="removeRole(list.roleId, selectedAccountId)"></i>
              </label>
            </p>
          </b-col>
        </b-row>
      </b-modal>
      <b-row class="py-3">
        <b-col cols="3">
          <b-form-input
            class="text-gray text-sm noFocus ml-1"
            style="margin-top: 1px; padding:6px 5px;"
            v-model="name"
            type="text"
            placeholder="Search by Name"
            required
            v-on:keyup.enter="fetch(1)">
          </b-form-input>
        </b-col>

        <b-col cols="3">
          <b-form-input
            class="text-gray text-sm noFocus ml-1"
            style="margin-top: 1px; padding:6px 5px;"
            v-model="email"
            type="text"
            placeholder="Search by Email"
            required
            v-on:keyup.enter="fetch(1)">
          </b-form-input>
        </b-col>

        <b-col cols="2">
          <b-form-select
            class="form-select text-sm noFocus text-gray"
            v-model="status"
            @change="fetch(1)"
            v-on:keyup.enter="fetch">
            <b-select-option :value="null">--Filter by Status--</b-select-option>
            <b-select-option :value="null">All</b-select-option>
            <b-select-option value="ACTIVE">Active</b-select-option>
            <b-select-option value="INACTIVE">Inactive</b-select-option>
          </b-form-select>
        </b-col>

        <b-col cols="2">
          <b-form-select
            class="form-select text-sm noFocus text-gray"
            v-model="gender"
            @change="fetch(1)"
            v-on:keyup.enter="fetch(1)">
            <b-select-option :value="null">--Filter by gender--</b-select-option>
            <b-select-option :value="null">All</b-select-option>
            <b-select-option value="MALE">Male</b-select-option>
            <b-select-option value="FEMALE">Female</b-select-option>
          </b-form-select>
        </b-col>

        <b-col cols="2">
            <button v-if="canViewMenuItem('can_edit_user_contacts')"
                    class="w-full bg-blue text-white px-2 py-1 rounded-sm" @click="openNewAccountForm">
            <i class="fa fa-plus mx-1"></i> Add Account
          </button>
        </b-col>

      </b-row>
      <div class="mx-auto">
        <b-progress value="100" v-if="loading" variant="#007ac5" height="0.2rem" animated></b-progress>
      </div>
      <b-row>
        <b-col cols="12">
          <table class="min-w-full leading-normal">
            <thead>
            <tr class="border-b border-t border-grey">
              <th class="t-header" width="20%">Name</th>
              <th class="t-header">Role(s)</th>
              <th class="t-header">ID Number</th>
              <th class="t-header">Email</th>
              <th class="t-header">Phone</th>
              <th class="t-header">Date Created</th>
              <th class="t-header">Status</th>
              <th class="t-header">Action</th>
            </tr>
            </thead>

            <tbody>
            <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(account, idx) in accounts" :key="idx">
              <td class="t-body font-bold text-uppercase" width="150px">
                <span>{{ account.givenName }} {{ account.surName }}</span>
                <span class="mx-2" v-if="account.middleName !== 'null'">{{ account.middleName }}</span>
              </td>
              <td>
                              <span v-if="account.userRoles.length === 2">
                                <span v-for="(list, index) in account.userRoles" :key="index" class="text-xs">
                                  <label v-if="list.name !=='ROLE_USER'">
                                    {{ list.name.replaceAll("_", " ") }}
                                    <i class="fa fa-times-circle text-red mx-1" style="cursor: pointer"
                                       title="Click to remove  role from this user "
                                       @click="removeRole(list.roleId, account.id)"></i>
                                  </label>
                               </span>
                              </span>
                <span v-else @click="showUserRoles(account.userRoles, account.id)"
                      class="text-xs italic text-blue cursor-pointer hover:underline">
                                {{ account.userRoles.length - 1 }} ROLES
                              </span>

              </td>
              <td class="t-body">
                <span>{{ account.idNumber.split(",")[0] }}</span>
              </td>
              <td class="t-body">
                              <span class="flex">
                                {{ account.email.split(",")[0] }}<i class="fa fa-check-circle text-green mx-1"
                                                                    v-if="account.emailVerified"></i>
                                <i class="fa fa-times text-orange mx-1" v-else></i>
                              </span>
              </td>
              <td class="t-body">
                              <span class="flex">
                                {{ account.phoneNumber.split(",")[0] }}
                                <i class="fa fa-check-circle text-green mx-1" v-if="account.phoneVerified"></i>
                                <i class="fa fa-times text-orange mx-1" v-else></i>
                              </span>
              </td>
              <td class="t-body"><span>{{ formatDate(account.createdAt) }}</span></td>
              <td class="t-body font-bold">
                <span class="text-green" v-if="account.enabled">Active</span>
                <span class="text-red" v-else>Inactive</span>
              </td>
              <td class="t-body text-center">
                <b-row>
                  <b-col>
                      <i v-if="account.enabled && canViewMenuItem('can_update_staff_signature')"
                       :class="(account.signatureUrl) ? 'fa fa-photo mr-1 text-gray' : 'fa fa-photo mr-1 text-red'"
                       style="cursor: pointer" title="Click to update signature"
                       @click="openEditSignature(account)" />
                    <i class="fa fa-times mr-1 text-red"
                       v-if="account.enabled && canViewMenuItem('can_update_account_status')"
                       style="cursor: pointer"
                       @click="changeStatus(account.id,'INACTIVE')"
                       title="Click to disable account" />

                    <i class="fa fa-check mr-1 text-green"
                       style="cursor: pointer"
                       @click="changeStatus(account.id,'ACTIVE')"
                       title="Click to activate account"
                       v-else-if="canViewMenuItem('can_update_account_status')"/>

                    <i class="fa fa-reply mr-1 text-blue"
                       v-if="!account.enabled && canViewMenuItem('can_resend_account_emails')"
                       style="cursor: pointer"
                       title="Click to resend activation link "
                       @click="resendActivation(account.id)" />

                    <i class="fa fa-pen-square mr-1 text-gray"
                       v-if="canViewMenuItem('can_edit_user_contacts')"
                       style="cursor: pointer"
                       title="Click to edit profile"
                       @click="openEditForm(account)" />
                  </b-col>
                </b-row>
                  <b-row>
                      <b-col>
                          <button v-if="canViewMenuItem('can_make_account_practitioner')"
                                  class="bg-blue p-1 rounded text-white text-xs"
                                  @click="showReceiverForm(account)">Make Official receiver
                          </button>
                      </b-col>
                  </b-row>
              </td>
            </tr>
            <tr>
              <td v-if="accounts.length === 0" colspan="12" class="bg-inertgrey text-center py-1">
                <span class="text-sm">No results found</span>
              </td>
            </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
      <b-row class="mx-1" v-if="pagination.totalPages > 1">
        <b-col cols="1">
          <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
        </b-col>
        <b-col cols="1">
          <b-row>
            <b-col cols="12">
              <p class="paginate-details text-center">
                <b-badge class="bg-blue text-white">
                  {{ (currentPage) }}/{{ pagination.totalPages }}
                </b-badge>
              </p>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="1">
          <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">
            Next
          </button>
        </b-col>
        <b-col class="9"></b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import StaffSignature from "@/views/home/services/accounts/staff/StaffSignature.vue";
import {api} from "@/app/services/api.service";
import MakeOfficialReceiver from "@/views/home/services/accounts/breakdown/MakeOfficialReceiver.vue";

export default {
  name: "BackofficeAccounts",
  components: {
      MakeOfficialReceiver,
    StaffSignature,
    VueCropper
  },
  data() {
    return {
      loading: false,
      spinner: false,
      currentPage: 1,
      limit: 12,
      accounts: [],
      pagination: {
        totalElements: 0,
        totalPages: 0
      },
      rows: {
        odd: "border-t border-grey hover:bg-header_grey",
        even: "border-t border-grey bg-header_grey"
      },
      userRoles: [],
      selectedAccountId: null,
      form: {
        idType: "NATIONAL_ID",
        gender: null,
        surname: null,
        givenName: null,
        middleName: null,
        email: null,
        phoneNumber: null,
        roleId: null,
        nationality: "UG",
        idNumber: null,
        idAttachment: null,
        signature: null,
        departmentId: null,
        dob: null
      },
      editForm: {
        userId: null,
        idType: "NATIONAL_ID",
        gender: null,
        surname: null,
        givenName: null,
        middleName: null,
        email: null,
        phoneNumber: null,
        roleId: null,
        nationality: "UG",
        idNumber: null,
        idAttachment: null,
        signature: null,
        departmentId: null,
        dob: null
      },
      editSignaturePayload: {
        signature: null,
        userId: null
      },
      updateSignature: false,
      updateSignatureSpinner: false,
      countries: [
        {
          value: "UG",
          text: "Ugandan"
        },
        {
          value: "ot",
          text: "Other"
        }
      ],
      search: "",
      status: null,
      email: null,
      name: null,
      gender: null,
      idType: null,
      nationality: null,
      type: null,
      mime_type: "",
      cropedImage: "",
      autoCrop: false,
      selectedFile: "",
      image: "",
      dialog: false,
      files: "",
      registerSpinner: false,
      formData: null,
      rolesDataList: [],
      usersList: [],
      departmentList: [],
      loaderNinProgress: false,
        baseUrl: null,
        userId: null,
        userName: null,
        individualAccountId: null

    };
  },
  mounted() {
    this.formData = new FormData();
    this.fetch();
    this.fetchRoles();
    this.fetchDepartments();
  },
  methods: {
    ...mapActions([
      "fetchStaffAccounts",
      "doRegisterUser",
      "fetchRoles",
      "fetchDepartments",
      "validateNin",
      "checkIdAvailability",
      "fetchRoles",
      "fetchPermissions",
      "assignRole",
      "doResendActivationLink",
      "changeAccountStatus", "removeUserRole",
      "updateStaffContacts",
      "doUpdateStaffSignature"
    ]),
    saveImage() {
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        if (this.updateSignature) {
          this.formData.set("signature", blob, "file.jpeg");
        } else {
          this.formData.set("signatureAttachment", blob, "file.jpeg");
        }

      }, this.mime_type);

      this.$refs.signatureForm.hide();
    },
    onFileSelect(e) {
      this.showCropper();
      const file = e.target.files[0];
      this.mime_type = file.type;

      if (typeof FileReader === "function") {
        this.dialog = true;
        const reader = new FileReader();
        reader.onload = (event) => {
          this.selectedFile = event.target.result;
          this.$refs.cropper.replace(this.selectedFile);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    registerInternalUser() {
      this.registerSpinner = true;

      this.formData.set("email", this.form.email);
      this.formData.set("phoneNumber", this.form.phoneNumber);
      this.formData.set("givenName", this.form.givenName);
      this.formData.set("middleName", this.form.middleName);
      this.formData.set("surname", this.form.surname);
      this.formData.set("roleId", this.form.roleId);
      this.formData.set("nationality", this.form.nationality);
      this.formData.set("gender", this.form.gender);
      this.formData.set("departmentId", this.form.departmentId);
      this.formData.set("dob", this.form.dob);
      this.formData.set("idNumber", this.form.idNumber);
      this.formData.set("idType", this.form.idType);

      /* if (this.form.idAttachment) {
         formData.append("idAttachment", this.form.idAttachment);
       }*/

      this.doRegisterUser(this.formData);
    },
    updateStaffSignature() {
      this.updateSignatureSpinner = true;
      this.formData.set("userId", this.editForm.userId);
      this.doUpdateStaffSignature(this.formData);
    },
    filterRolesList(dataList) {
      let dataObj = [{ text: "Select Role", id: null, disabled: true }];
      let dataJson = {};
      for (let i = 0; i < dataList.length; i++) {
        dataJson = {
          value: dataList.name,
          id: dataList.roleId,
          disabled: false
        };
        dataObj.push(dataJson);
      }
      return dataObj;
    },
      async validateIdTypes() {

      this.form.idNumber = this.form.idNumber.toUpperCase();

      this.errorMessage = "";
      this.ninStatusMsg = "";
      this.loaderProgress = false;
      this.loaderNinProgress = false;


      ///validate first two characters if they are alphabet
      if (!(/^[a-zA-Z()]+$/.test(this.form.idNumber.slice(0, 2)))) {
        this.ninStatusMsg = "Invalid Nin [NIN starts with letters!]";
        return true;
      }

          this.clearData();

      if (this.form.idNumber.length === 14) {
        this.loaderNinProgress = true;
          let ninData = {nin: this.form.idNumber, reference: this.getReference()};
          await api().post("/integration/api/nira", ninData).then(response => {
              let data = response.data;
              // Do something with the response
              this.loaderNinProgress = false;

              this.form.surname = data.surname;
              this.form.givenName = data.givenNames;
              this.form.middleName = data.maidenNames;
              this.form.gender = data.gender === "M" ? "MALE" : "FEMALE";


              let d = data.dateOfBirth;
              let day = d.substring(0, 2);
              let month = d.substring(5, 3);
              let year = d.substring(10 - 4);


              this.form.dob = year + "-" + month + "-" + day;

          }).catch(error => {
              this.loaderNinProgress = false;
              console.log(error);
              this.errorToast("Invalid Nin [NIN should be 14 characters!]");
              // return true;
          })

      }


      },
    clearData() {
      this.form.surname = "";
      this.form.givenName = "";
      this.form.middleName = "";
      this.form.gender = "";
      this.form.dob = "";
    },
    fetch(startPage = null) {
      this.loading = true;
      let filters = { "accountType": "BACKOFFICE" };

      if (this.status) {
        filters.accountStatus = this.status;
      }
      if (this.gender) {
        filters.gender = this.gender;
      }

      if (this.idType) {
        filters.idType = this.idType;
      }

      if (this.name) {
        filters.name = this.name;
      }

      if (this.email) {
        filters.email = this.email;
      }
      if (this.nationality) {
        filters.nationality = this.nationality;
      }

      filters.page = startPage ? startPage : this.currentPage;
      filters.perPage = this.limit;

      this.fetchStaffAccounts(this.objectToQueryParams(filters));
    },
    next() {
      if (this.currentPage < this.pagination.totalPages) {
        this.currentPage++;
        this.fetch();
      }
    },
    previous() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetch();
      } else {
        this.warningToast("Observation", "No more data to show");
      }
    },
    showUserRoles(roles, id) {
      this.userRoles = roles;
      this.selectedAccountId = id;
      this.$refs.accountRoles.show();
    },

    openNewAccountForm() {
      this.$refs.addAccountForm.show();
    },
    openEditForm(account) {

      this.editForm = {
        userId: account.id,
        idType: "NATIONAL_ID",
        gender: account.gender,
        surname: account.surName,
        givenName: account.givenName,
        middleName: account.middleName,
        email: account.email,
        phoneNumber: account.phoneNumber,
        roleId: account.roleId,
        nationality: account.nationality,
        idNumber: account.idNumber,
        idAttachment: account.idAttachment,
        signature: account.signature,
        departmentId: account.departmentId,
        dob: account.dob
      };
      this.$refs.editAccountForm.show();
    },

    openEditSignature(account) {
      this.editForm = {
        userId: account.id,
        idType: "NATIONAL_ID",
        gender: account.gender,
        surname: account.surName,
        givenName: account.givenName,
        middleName: account.middleName,
        email: account.email,
        phoneNumber: account.phoneNumber,
        signature: account.signatureUrl
      };
      this.updateSignature = true;
      this.$refs.editSignatureForm.show();
    },

    closeEditForm() {
      this.$refs.editAccountForm.hide();
    },
    closeEditSignatureForm() {
      this.$refs.editSignatureForm.hide();
    },

    showCropper() {
      this.$refs.signatureForm.show();
    },

    filterUserList(dataList) {
      let dataObj = [{ text: "Select User", id: null }];
      let dataJson = {};
      for (let i = 0; i < dataList.length; i++) {
        let userData = dataList[i];
        dataJson = {
          text: userData.surName + " " + userData.givenName,
          value: dataList[i].id
        };
        dataObj.push(dataJson);
      }
      return dataObj;
    },
    filterRolesListData(dataList) {
      let dataObj = [{ text: "Select Role", id: null, disabled: true }];
      let dataJson = {};
      for (let i = 0; i < dataList.length; i++) {
        dataJson = {
          text: dataList[i].name,
          value: dataList[i].roleId
        };
        dataObj.push(dataJson);
      }
      return dataObj;
    },
    resendActivation(userId) {
      this.showConfirmDialog().then(value => {
        if (value) {
          this.doResendActivationLink({ userId });
        }
      });


    },
    async changeStatus(userId, status) {
      this.showConfirmDialog().then(value => {
        if (value) {
          this.changeAccountStatus({ userId, status });
        }
      });

    },
    async removeRole(roleId, userId) {

      this.showConfirmDialog().then(value => {
        if (value) {
          let payload = {
            roleIds: [
              roleId
            ],
            userId: userId
          };
          this.removeUserRole(payload);
        }

      });


    },
    updateUserDetails() {
      let data = {
        "email": this.editForm.email,
        "phone": this.editForm.phoneNumber,
        "userId": this.editForm.userId
      };

      this.updateStaffContacts(data);


    },
      canViewMenuItem(permission) {
          return this.$can(permission, "general") === true;
      },
      showReceiverForm(account) {
          this.userName = account.givenName + " " + account.surName
          this.userId = account.id
          this.$refs.makeReceiverForm.show();
      },
      async makeUserOfficialReceiver() {

          let payload = {
              individualAccountId: this.individualAccountId,
              backOfficeAccountId: this.userId,
          }

          await api().post("insolvency/official-receivers", payload).then(response => {
              this.successToast("Success", response.data.message)
              this.loading = false
              // reload the DOM
              window.location.reload()

          }).catch(error => {
              this.loading = false
              this.errorToast("Error", error.response.data.message)
          });

      },
      selectIndividualAccount(account) {
          this.individualAccountId = account.id
          this.userName = this.userName + " Plus" + " " + account.givenName + " " + account.surName
      }

  },
  computed: {
    ...mapGetters([
      "getOfficerAccounts",
      "getRoleRemovalSuccess",
      "getReactivationError",
      "getReactivationSuccess",
      "getOfficerAccounts",
      "getDepartments",
      "getRolesList",
      "getRegistrationData",
      "getRegisterError",
      "getSignatureUpdateSuccess",
      "getSignatureUpdateError",
      "getNewRole"])
  },
  watch: {
    newRoleData() {
      this.changeRoleData();
    },
    getUserError(assignRoleError) {
      if (assignRoleError) {
        this.spinner = false;
        this.errorMessage = assignRoleError.message;
        this.errorToast("Error", assignRoleError.message);
      }
    },
    getNewRole() {
      this.spinner = false;
      this.errorMessage = null;
      this.form.roleIds = [];
      this.form.userId = null;
      this.successToast("Success", "Role Assigned Successfully");
      this.$emit("finished", true);
    },
    getOfficerAccounts(data) {
      this.loading = false;
      this.accounts = data.data.content;
      this.usersList = this.filterUserList(data.data.content);
      this.pagination = {
        totalPages: data.data.totalPages,
        totalElements: data.data.totalElements
      };
    },
    getRolesList(rolesList) {
      if (rolesList) {
        this.rolesDataList = this.filterRolesListData(rolesList);
      }
    },
    getRegisterError(userRegisterError) {
      if (userRegisterError) {
        this.registerSpinner = false;
        this.errorToast("Error", userRegisterError.message);
      }
    },
    getRegistrationData() {
      this.successToast("Success", "User registered successfully");
      this.registerSpinner = false;
      this.fetch();
      this.form = {
        idType: "NATIONAL_ID",
        gender: null,
        surname: null,
        givenName: null,
        middleName: null,
        email: null,
        phoneNumber: null,
        roleId: null,
        nationality: "UG",
        idNumber: null,
        idAttachment: null,
        signature: null,
        departmentId: null,
        dob: null
      };
      this.$refs.addAccountForm.hide();
      this.$emit("finished", true);
    },
    getDepartments(departments) {
      if (departments) {
        this.departmentList = departments;
      }
    },
    getNinInfo(data) {
      if (data) {
        this.loaderNinProgress = false;
        this.form.surname = data.surname;
        this.form.givenName = data.givenNames;
        this.form.middleName = data.maidenNames;
        this.form.gender = data.gender === "M" ? "MALE" : "FEMALE";
        let d = data.dateOfBirth;
        let day = d.substring(0, 2);
        let month = d.substring(5, 3);
        let year = d.substring(10 - 4);
        this.form.dob = year + "-" + month + "-" + day;
      }
    },
    getNinError(data) {
      if (data) {
        this.loaderNinProgress = false;
        this.ninStatusMsg = "Invalid Nin [NIN starts with letters!]";
      }
    },
    getReactivationSuccess(data) {
      this.successToast("Success", data.message);
      this.loading = true;
      this.closeEditForm();
      this.fetch();
    },
    getReactivationError(error) {
      if (error) {
        this.errorToast(error.message, "");
      }
    },
    getRoleRemovalSuccess() {
      this.successToast();
      this.loading = true;
      this.fetch();
    },

    getSignatureUpdateError(error) {
      this.errorToast("Error", error.message);
      this.updateSignatureSpinner = false;

    },
    getSignatureUpdateSuccess() {
      this.successToast("Success");
      this.updateSignatureSpinner = false;
      this.closeEditSignatureForm();
      this.fetch();
    }

  }
};
</script>

<style scoped>
.paginate-details {
  @apply px-3 py-2 bg-white my-2 text-sm text-gray;
}

.paginate-btn {
  @apply px-3 py-2 bg-white border border-white my-2 text-sm text-blue w-full;
}

.paginate-btn:hover {
  @apply border border-blue text-blue rounded-sm;
}


.inert-btn {
  @apply px-3 py-2 bg-white my-2 text-sm text-grey w-full;
}
</style>