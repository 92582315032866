<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col cols="12">
          <div class="flex h-full" style="cursor: pointer;">
            <div :class="[(currentRoute === 'info') ? 'menu-item-active' : 'menu-item']" @click="select('info')">
              <div class="flex mt-6">
                <div style="font-size: 14px">Practitioner Information</div>
              </div>
            </div>
            <div v-if="data.status === 'APPROVED'" :class="[(currentRoute === 'annual-returns') ? 'menu-item-active' : 'menu-item']" @click="select('annual-returns')">
              <div class="flex mt-6">
                <div style="font-size: 14px">Annual Returns</div>
              </div>
            </div>
            <div :class="[(currentRoute === 'attachments') ? 'menu-item-active' : 'menu-item']" @click="select('attachments')">
              <div class="flex mt-6">
                <div style="font-size: 14px">Filing History</div>
              </div>
            </div>
            <div v-if="data.status !== 'APPROVED'" :class="[(currentRoute === 'messages') ? 'menu-item-active' : 'menu-item']" @click="select('messages')">
              <div class="flex mt-6">
                <div style="font-size: 14px">Messages</div>
              </div>
            </div>
            <div v-if="data.status !== 'ASSIGNED'" :class="[(currentRoute === 'complaints') ? 'menu-item-active' : 'menu-item']" @click="select('complaints')">
              <div class="flex mt-6">
                <div style="font-size: 14px">Complaints</div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="my-2">
        <b-col>
          <b-row v-if="currentRoute === 'info'">
            <b-col cols="12">
              <b-row class="mt-4">
                <b-col cols="7">
                  <span class="h5">Bio</span>
                </b-col>
                <b-col cols="5">
                  <span class="h5">Professional Conduct</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="7">
                  <b-row>
                    <b-col>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Names"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="data.firstName +' '+ data.lastName"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Email Address"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-xs noFocus"
                          type="text"
                          :disabled="true"
                          :value="data.email"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Phone Number"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="data.phoneNumber"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Gender"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="data.gender"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Date Of Birth"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="dateFormat(data.dateOfBirth)"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Postal Address"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="data.postalAddress"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="5">
                  <b-row class="mt-3">
                    <b-col cols="10">
                      <span class="text-sm">Applicant Subjected To Any Disciplinary Proceedings</span>
                    </b-col>
                    <b-col cols="2">
                      <span class="font-bold" v-if="isDisciplined">YES</span>
                      <span class="font-bold" v-else>NO</span>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="10">
                      <span class="text-sm">Applicant Disqualified From Practicing Insolvency</span>
                    </b-col>
                    <b-col cols="2">
                      <span class="font-bold" v-if="isDisqualified">YES</span>
                      <span class="font-bold" v-else>NO</span>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="10">
                      <span class="text-sm">Applicant Subjected to Any Prohibition Under the Law</span>
                    </b-col>
                    <b-col cols="2">
                      <span class="font-bold" v-if="isProhibited">YES</span>
                      <span class="font-bold" v-else>NO</span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row class="mt-4">
                <b-col>
                  <span class="h5">Address</span>
                </b-col>
                <b-col>
                  <span class="h5">Bank Information</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-row v-if="address">
                    <b-col>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Country"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="address.country.name"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="District"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="address.district.name"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Parish"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="address.parish.name"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Region"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="address.region.name"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Sub County"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="address.subcounty.name"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Village"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="address.village.name"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <b-row v-for="(bank, idx) in data.bankAccountInformation" :key="idx">
                    <b-col cols="3">
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Bank Name"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="bank.bankName"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Account Name"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="bank.accName"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="3">
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Account Number"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="bank.accNo"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row class="mt-4">
                <b-col>
                  <span class="h5">Qualification(s)</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-row v-for="(item, idx) in data.qualifications" :key="idx">
                    <b-col>
                      <b-form-group
                          class="text-sm my-1"
                          id="input-group-1"
                          label="Award Type"
                          label-for="input-1">
                        <b-form-input
                            id="companyName"
                            class="form-text text-sm noFocus"
                            type="text"
                            :disabled="true"
                            :value="item.awardType"
                            required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Award"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="item.award"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Awarding Body"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="item.awardingBody"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Year of Award"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="item.year"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row class="mt-4">
                <b-col>
                  <span class="h5">Profession(s)</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-row v-for="(item, idx) in data.professions" :key="idx">
                    <b-col>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Profession"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="item.professionName"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Professional Body"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="item.professionBody"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Membership ID"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="item.memberShipId"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Membership Expiry"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="dateFormat(item.expiryOfMemberShip)"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row class="mt-4">
                <b-col>
                  <span class="h5">Indemnity</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-row v-for="(item, idx) in data.indemnity" :key="idx">
                    <b-col>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Insurance Policy Holder"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="item.insurancePolicyHolder"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                          class="text-sm my-1"
                          id="input-group-1"
                          label="Insurer"
                          label-for="input-1">
                        <b-form-input
                            id="companyName"
                            class="form-text text-sm noFocus"
                            type="text"
                            :disabled="true"
                            :value="item.insurer"
                            required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Value of Insurance"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="item.valueOfInsurance"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Date of Issue"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="dateFormat(item.dateOfIssuance)"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Date of Expiry"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="dateFormat(item.expiryOfInsurance)"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="currentRoute === 'attachments'">
            <b-col cols="5" class="mb-3">
              <b-row v-if="data != null">
                <b-col cols="12">
                  <b-row v-for="(form, idx) in form1s" :key="idx" class="mb-4">
                    <b-col cols="12">
                      <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                        <thead>
                        <tr class="bg-gray-pale">
                          <td v-if="form.status.toUpperCase() === 'APPROVED'" colspan="12" class="px-2 py-2 font-bold text-capitalize">{{ form.applicationType.toLowerCase() }} Documents - {{ form1Year(form) }}</td>
                          <td v-else colspan="12" class="px-2 py-2 font-bold text-capitalize">{{ form.applicationType.toLowerCase() }} Attachments - {{ form1Year(form) }}</td>
                        </tr>
                        <tr>
                          <th class="px-2 py-1">#</th>
                          <th class="px-2 py-1">File Name</th>
                          <th class="px-2 py-1 float-end">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(file, idx) in form.form1AttachmentList" :key="idx" style="border: solid 1px #dedede;">
                          <td class="px-2 py-1">{{idx + 1}}</td>
                          <td class="px-2 py-1 text-capitalize">{{cleanName(file.insolvencyDocument.replace(".PDF", ""))}}</td>
                          <td class="px-2 py-1">
                            <button class="float-end bg-gray rounded text-white hover:bg-gray-dark px-2" @click="openFile(file)">Show</button>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="7">
              <PractitionerAttachments ref="attachmentScreen"/>
            </b-col>
          </b-row>
          <PractitionerFilingMessages v-if="currentRoute === 'messages'"/>
          <PractitionerComplaints
              v-if="currentRoute === 'complaints' && data.status !== 'ASSIGNED'"
              :upn="upn"
              :name="practitionerFullName"
              :gender="practitionerGender"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import moment from "moment/moment";
import server from "../../../../../app/env/server.json"
import { mapActions, mapGetters } from "vuex";
import PractitionerAttachments from "@/views/home/services/registry/practitioners/PractitionerAttachments";
import PractitionerFilingMessages from "@/views/home/services/registry/practitioners/PractitionerFilingMessages";
import PractitionerComplaints from "@/views/home/services/registry/practitioners/PractitionerComplaints";

export default {
  name: "PractitionerDetails",
  components: {PractitionerComplaints, PractitionerFilingMessages, PractitionerAttachments},
  props:{
    data: {
      type: Object,
      default: null
    },
    upn:String,
    form1s: {
      type: Array,
      required: true
    }
  },
  data(){
    return{
      currentRoute:"info",
      attachmentUrl:null,
      address:null
    }
  },
  mounted() {
    // this.openFile(this.data.form1AttachmentList[0])
  },
  methods:{
    ...mapActions(["fetchAddressByVillageId"]),
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    select(route){
      this.currentRoute = route
    },
    openFile(data){
      this.attachmentUrl = server.IP+"/insolvency/api/practitioner-registration/attachment/"+data.filename
    },
    openCertificate(){
      this.attachmentUrl = server.IP+"/insolvency/api/practitioners/certificate?upn="+this.upn
    },
    cleanName(name){
      return name.replaceAll(".pdf", "")
    },
    form1Year(form) {
      return moment(form.registrationDate).year();
    },
  },
  computed:{
    ...mapGetters(["getAddress"]),
    practitionerGender() {
      if (this.form1s.length === 0) {
        return null
      }

      return this.form1s[0].gender
    },
    practitionerFullName() {
      if (this.form1s.length === 0) {
        return null
      }

      return `${this.form1s[0].firstName} ${this.form1s[0].lastName}`
    },
    isDisciplined() {
      if (this.data === null) {
        return false
      }
      return this.data.profConductDetails.find(c=>{
        return c.subjection === 'SUBJECTED_TO_DISCIPLINARY_PROCEEDING' && c.detailedInformation !== null
      }) !== undefined
    },
    isProhibited() {
      if (this.data === null) {
        return false
      }
      return this.data.profConductDetails.find(c=>{
        return c.subjection === 'SUBJECTED_TO_PROHIBITION' && c.detailedInformation !== null
      }) !== undefined
    },
    isDisqualified() {
      if (this.data === null) {
        return false
      }
      return this.data.profConductDetails.find(c=>{
        return c.subjection === 'DISQUALIFIED_FROM_INSOLVENCY' && c.detailedInformation !== null
      }) !== undefined
    },
  },
  watch:{
    data: {
      handler(d) {
        if (d !== null) {
          this.fetchAddressByVillageId(d.villageId)
        }
      },
      deep:true
    },
    getAddress(data){
      this.address = data.data
    },
    attachmentUrl:{
      handler(url){
        this.$refs.attachmentScreen.openFile(url)
      },
      deep:true
    }
  },
};
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>