<template>
  <b-row>
    <b-col cols="12">
      <b-row>
        <b-col cols="12" class="my-2">
          <b-row>
            <b-col cols="2">
              <b-form-input
                class="text-sm noFocus ml-1"
                v-model="search"
                type="text"
                placeholder="Search"
                required
                v-on:keyup.enter="fetch">
              </b-form-input>
            </b-col>
            <b-col cols="2">
              <b-form-select
                class="form-select text-sm noFocus text-gray"
                v-model="type"
                :options="documentOptions"
                v-on:keyup.enter="fetch">
              </b-form-select>
            </b-col>
            <b-col cols="5"></b-col>
            <b-col cols="1">
              <b-form-select v-model="limit" class="w-full text-xs form-select border-0 paginate-details" v-on:change="fetch">
                <b-select-option value="15">15</b-select-option>
                <b-select-option value="20">20</b-select-option>
                <b-select-option value="50">50</b-select-option>
                <b-select-option value="100">100</b-select-option>
              </b-form-select>
            </b-col>
            <b-col cols="2">
              <b-row>
                <b-col class="mx-auto">
                  <p class="paginate-details text-center text-xs font-bold">
                    <span>{{((currentPage - 1) * limit) + 1}}</span> -
                    <span v-if="limit > documents.length ">{{pagination.totalElements}}</span>
                    <span v-else>{{currentPage * limit}}</span>
                    of {{pagination.totalElements}}
                  </p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div class="mx-auto">
        <b-progress value="100" v-if="loading"  variant="#007ac5" height="0.2rem" animated></b-progress>
      </div>
      <b-row>
        <b-col cols="12">
          <table class="min-w-full leading-normal">
            <thead>
            <tr class="border-b border-t border-grey">
              <th class="t-header">Instrument No.</th>
              <th class="t-header">Document Type</th>
              <th class="t-header">Subject</th>
              <th class="t-header">Filing Date</th>
              <th class="t-header text-center">Execution Date</th>
              <th class="t-header text-center">Status</th>
              <th class="t-header text-center">Action</th>
            </tr>
            </thead>

            <tbody>
            <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(document, idx) in documents" :key="idx">
              <td class="t-body text-blue font-bold">
              <span class="hover:text-blue-dark hover:underline" @click="viewFile(document.instrumentNumber)">
                {{document.instrumentNumber}}
              </span>
              </td>
              <td class="t-body">
                <span class="text-capitalize">{{cleanEntityType(document.documentType.toLowerCase())}}</span>
              </td>
              <td class="t-body">
                <span class="text-capitalize">{{cleanEntityType(document.subjectMatter)}}</span>
              </td>
              <td class="t-body">
                <span>{{dateFormat(document.filingDate)}}</span>
              </td>
              <td class="t-body text-center">
                <span>{{dateFormat(document.dateOfExecution)}}</span>
              </td>
              <td class="t-body text-center text-capitalize">
                <span class="px-1 py-1 text-green text-xs">
                  Registered
                </span>
              </td>
              <td class="text-center">
                <button class="bg-blue text-white rounded-sm px-2 py-1 text-xs hover:bg-blue-dark" @click="viewFile(document.instrumentNumber)">
                  <i class="fa fa-eye mr-1"></i>View</button>
              </td>
            </tr>
            <tr>
              <td v-if="documents.length === 0" colspan="8" class="bg-inertgrey text-center py-1">
                <span class="text-sm">No results found</span>
              </td>
            </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
      <b-row class="mx-1" v-if="pagination.totalPages > 1">
        <b-col cols="1">
          <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
        </b-col>
        <b-col cols="1">
          <b-row>
            <b-col cols="12">
              <p class="paginate-details text-center">
                <b-badge class="bg-blue text-white">
                  {{currentPage}}/{{pagination.totalPages}}
                </b-badge>
              </p>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="1" >
          <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">Next</button>
        </b-col>
        <b-col class="6"></b-col>
        <b-col cols="1">
          <b-form-select v-model="limit" class="w-full text-xs form-select border-0 paginate-details" v-on:change="fetch">
            <b-select-option value="15">15</b-select-option>
            <b-select-option value="20">20</b-select-option>
            <b-select-option value="50">50</b-select-option>
            <b-select-option value="100">100</b-select-option>
          </b-form-select>
        </b-col>
        <b-col cols="2">
          <b-row>
            <b-col class="mx-auto">
              <p class="paginate-details text-center text-xs font-bold">
                <span>{{((currentPage - 1) * limit) + 1}}</span> -
                <span v-if="limit > documents.length ">{{pagination.totalElements}}</span>
                <span v-else>{{currentPage * limit}}</span>
                of {{pagination.totalElements}}
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>

import {mapActions, mapGetters} from "vuex"
import moment from "moment";
import router from "@/router";

export default {
  name: "LegalDocumentsRegistry",
  data(){
    return{
      loading:false,
      currentPage:1,
      limit:15,
      documents:[],
      pagination:{
        totalElements: 0,
        totalPages:0
      },
      rows:{
        odd:"border-t border-grey hover:bg-header_grey",
        even:"border-t border-grey bg-header_grey"
      },
      search:"",
      type:"",
      documentOptions:[
        {
          text:"-- Select Type --",
          value: "",
        },
        {
          text:"Agreements",
          value:"AGREEMENT",
        },
        {
          text:"Guarantee",
          value:"GUARANTEE",
        },
        {
          text:"Powers of Attorney",
          value:"POWER_OF_ATTORNEY",
        },
        {
          text:"Affidavit",
          value:"AFFIDAVIT"
        },
        {
          text:"Statutory Declaration",
          value:"STATUTORY_DECLARATION"
        },
        {
          text:"Chattel",
          value:"CHATTEL"
        },
        {
          text:"Debenture",
          value:"DEBENTURE"
        },
        {
          text:"Demand Promissory Note",
          value:"DEMAND_PROMISSORY_NOTE"
        },
        {
          text:"Report of Directors",
          value:"REPORT_OF_DIRECTORS"
        },
        {
          text:"Constitution",
          value:"CONSTITUTION"
        },
        {
          text:"Loan Facility Letter",
          value:"FACILITY_LETTER"
        },
        {
          text:"Deed of Assignment",
          value:"DEED_OF_ASSIGNMENT"
        },
        {
          text:"DeedPoll",
          value:"DEED_POLL"
        },
        {
          text:"mortgage Deed",
          value:"MORTGAGE_DEED"
        },
        {
          text:"Spousal Consent",
          value:"SPOUSAL_CONSENT"
        },
        {
          text:"Will",
          value:"WILL"
        },
        {
          text:"Independent Advice Form",
          value:"INDEPENDENT_ADVICE_FORM"
        },
        {
          text:"Resolution as document",
          value:"resolution"
        },
        {
          text:"Others",
          value:"OTHER"
        }
      ],
    }
  },
  mounted() {
    this.fetch()
  },
  methods:{
    ...mapActions(["fetchLegalDocumentsRegistry"]),
    fetch(){
      let data = {
        search:this.search,
        page:this.currentPage,
        limit:this.limit,
        type:this.type
      }
      this.loading = true
      this.fetchLegalDocumentsRegistry(data)
    },
    viewFile(id){
      router.push("/home/registry/document-viewer/"+id)
      router.go(0)
    },
    next(){
      if(this.currentPage < this.pagination.totalPages){
        this.currentPage++
        this.fetch()
      }
    },
    previous(){
      if(this.currentPage > 1){
        this.currentPage--
        this.fetch()
      }else{
        this.warningToast("Observation", "No more data to show")
      }
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    cleanEntityType(name){
      let n = name.replaceAll("_", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
  },
  computed:{
    ...mapGetters(["getLegalDocumentRegistry", "getDocumentRegistryError"])
  },
  watch:{
    search:{
      handler(){
        this.fetch()
      }
    },
    type:{
      handler(){
        this.fetch()
      }
    },
    getLegalDocumentRegistry(data){
      this.loading = false
      this.documents = data.content
      this.pagination = {
        totalPages:data.totalPages,
        totalElements:data.totalElements
      }
    },
    getDocumentRegistryError(data){
      this.loading = false
      this.errorToast("Error", JSON.stringify(data.message))
    }
  }
}
</script>

<style scoped>
@import "../../../../../assets/styles/table.css";

.verified{
  @apply text-xs font-bold;
  color: green;
  font-style: italic;
}

.unverified{
  @apply text-xs font-bold;
  color: #b80404;
  font-style: italic;
}

.assign-btn{
  @apply px-3 py-1 bg-white border-2 font-bold border-green text-green text-xs w-full;
}

.assign-btn:hover{
  @apply bg-green text-white;
}

.de-assign-btn{
  @apply px-3 py-1 bg-white border-2 border-red text-red font-bold text-xs w-full;
}

.de-assign-btn:hover{
  @apply bg-red text-white;
}

.paginate-details{
  @apply px-3 py-2 bg-white my-2 text-sm text-gray;
}

.paginate-btn{
  @apply px-3 py-2 bg-white border border-white my-2 text-sm text-blue w-full;
}

.paginate-btn:hover{
  @apply border border-blue text-blue rounded-sm;
}

.inert-btn{
  @apply px-3 py-2 bg-white my-2 text-sm text-grey w-full;
}
</style>