<template>
    <b-row>
        <b-col>
            <b-row class="py-3">

                <b-col cols="2">
                    <b-form-input
                            class="text-gray text-sm noFocus ml-1"
                            style="margin-top: 1px; padding:6px 5px;"
                            v-model="name"
                            type="text"
                            placeholder="Search by Name"
                            required
                            v-on:keyup.enter="fetch(1)">
                    </b-form-input>
                </b-col>

                <b-col cols="2">
                    <b-form-input
                            class="text-gray text-sm noFocus ml-1"
                            style="margin-top: 1px; padding:6px 5px;"
                            v-model="brn"
                            type="text"
                            placeholder="Search by BRN"
                            required
                            v-on:keyup.enter="fetch(1)">
                    </b-form-input>
                </b-col>

                <b-col cols="2">
                    <b-form-input
                            class="text-gray text-sm noFocus ml-1"
                            style="margin-top: 1px; padding:6px 5px;"
                            v-model="email"
                            type="text"
                            placeholder="Search by Email"
                            required
                            v-on:keyup.enter="fetch(1)">
                    </b-form-input>
                </b-col>

                <b-col cols="2">
                    <b-form-input
                            class="text-gray text-sm noFocus ml-1"
                            style="margin-top: 1px; padding:6px 5px;"
                            v-model="phone"
                            type="text"
                            placeholder="Search by phone"
                            required
                            v-on:keyup.enter="fetch(1)">
                    </b-form-input>
                </b-col>

                <b-col cols="2">
                    <b-form-select
                            class="form-select text-sm noFocus text-gray"
                            v-model="status"
                            @change="fetch(1)"
                            v-on:keyup.enter="fetch(1)">
                        <b-select-option :value="null">--Filter by Status--</b-select-option>
                        <b-select-option :value="null">All</b-select-option>
                        <b-select-option value="ACTIVE">Active</b-select-option>
                        <b-select-option value="INACTIVE">Inactive</b-select-option>
                    </b-form-select>
                </b-col>


            </b-row>
            <div class="mx-auto">
                <b-progress value="100" v-if="loading" variant="#007ac5" height="0.2rem" animated></b-progress>
            </div>
            <b-row>
                <b-col cols="12">
                    <table class="min-w-full leading-normal">
                        <thead>
                        <tr class="border-b border-t border-grey">
                            <th class="t-header">Name</th>
                            <th class="t-header">BRN</th>
                            <th class="t-header">Entity Type</th>
                            <th class="t-header">Entity Sub Type</th>
                            <th class="t-header">Email</th>
                            <th class="t-header">Phone</th>
                            <th class="t-header">Date Created</th>
                            <th class="t-header">Status</th>
                            <th class="t-header">Action</th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(account, idx) in accounts" :key="idx">
                            <td class="t-body font-bold text-uppercase">
                                <span>{{ account.name }}</span>
                            </td>
                            <td class="t-body">
                                <span>{{ account.brn }}</span><br/>
                            </td>
                            <td class="t-body">
                                <span>{{ cleanEntityType(account.businessType) }}</span><br/>
                            </td>
                            <td class="t-body">
                                <span>{{ cleanEntityType(account.businessSubType) }}</span><br/>
                            </td>
                            <td class="t-body">
                <span>
                  <i class="fa fa-check-circle text-green mx-1" v-if="account.emailVerified"></i>
                  <i class="fa fa-times text-orange mx-1" v-else></i>
                  {{ account.email }}
                </span>
                            </td>
                            <td class="t-body">
                <span>
                  <i class="fa fa-check-circle text-green mx-1" v-if="account.phoneVerified"></i>
                  <i class="fa fa-times text-orange mx-1" v-else></i>
                  {{ account.phoneNumber }}
                </span>
                            </td>
                            <td class="t-body"><span>{{ formatDate(account.createdAt) }}</span></td>
                            <td class="t-body font-bold">
                                <span class="text-green" v-if="account.enabled">Active</span>
                                <span class="text-red" v-else>Inactive</span>
                            </td>
                            <td class="t-body text-center">

                                <b-row>
                                    <b-col>

                                        <i class="fa fa-times mr-1 text-red"
                                           v-if="account.enabled && canViewMenuItem('can_update_account_status')"
                                           style="cursor: pointer"
                                           @click="changeStatus(account.id,'INACTIVE')"
                                           title="Click to disable account"/>
                                        <i class="fa fa-check mr-1 text-green"
                                           style="cursor: pointer"
                                           @click="changeStatus(account.id,'ACTIVE')"
                                           title="Click to activate account"
                                           v-else-if="canViewMenuItem('can_update_account_status')"/>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <i class="fa fa-reply mr-1 text-blue"
                                           v-if="!account.enabled  && canViewMenuItem('can_resend_account_emails')"
                                           style="cursor: pointer"
                                           title="Click to resend activation link "
                                           @click="resendActivation(account.id)"/>

                                        <i v-if="!account.enabled && canViewMenuItem('can_edit_user_contacts')"
                                           style="cursor: pointer" title="Click to edit account"
                                           class="fa fa-pen-square mr-1 text-gray"
                                           @click="openEditBusinessForm(account)"/>
                                    </b-col>
                                </b-row>

                            </td>
                        </tr>
                        <tr>
                            <td v-if="accounts.length === 0" colspan="12" class="bg-inertgrey text-center py-1">
                                <span class="text-sm">No results found</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </b-col>
            </b-row>
            <b-row class="mx-1" v-if="pagination.totalPages > 1">
                <b-col cols="1">
                    <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
                </b-col>
                <b-col cols="1">
                    <b-row>
                        <b-col cols="12">
                            <p class="paginate-details text-center">
                                <b-badge class="bg-blue text-white">
                                    {{ (currentPage) }}/{{ pagination.totalPages }}
                                </b-badge>
                            </p>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="1">
                    <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">
                        Next
                    </button>
                </b-col>
                <b-col class="9"></b-col>
            </b-row>

            <b-modal size="lg" title="Edit Account" ref="editAccountForm" hide-footer>
                <b-col cols="11" class="mx-auto">
                    <b-row>

                        <b-col>

                            <label>BRN</label>

                            <b-form-input class="my-2" :disabled="true" type="text" v-model="editForm.brn"
                                          placeholder="BRN"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-input class="my-2" :disabled="true" type="text" v-model="editForm.name"
                                          placeholder="Name"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-input class="my-2" type="text" v-model="editForm.email"
                                          placeholder="Email Address"></b-form-input>
                        </b-col>
                        <b-col>
                            <b-form-input class="my-2" type="text" v-model="editForm.phoneNumber"
                                          placeholder="Phone Number"></b-form-input>
                        </b-col>
                    </b-row>


                    <b-row>
                        <b-col cols="10"></b-col>
                        <b-col cols="2">
                            <button class="w-full bg-blue text-white px-2 py-1 rounded-md"
                                    @click="updateUserDetails">
                                <i class="fa fa-save mx-1"></i> Save changes
                            </button>
                        </b-col>
                    </b-row>
                </b-col>
            </b-modal>


        </b-col>
    </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "BusinessAccounts",
    data() {
        return {
            loading: false,
            currentPage: 1,
            limit: 10,
            accounts: [],
            pagination: {
                totalElements: 0,
                totalPages: 0
            },
            rows: {
                odd: "border-t border-grey hover:bg-header_grey",
                even: "border-t border-grey bg-header_grey"
            },
            search: "",
            status: null,
            type: null,
            email: null,
            name: null,
            brn: null,
            phone: null,
            editForm: {
                userId: null,
                brn: null,
                name: null,
                email: null,
                phoneNumber: null,
            },
        }
    },
    mounted() {
        this.fetch()
    },
    methods: {
        ...mapActions(["fetchBusinessAccounts", "doResendActivationLink", "changeAccountStatus", "updateStaffContacts"]),
        fetch(startPage = null) {
            this.loading = true;
            let filters = {"accountType": "BUSINESS"}

            if (this.status) {
                filters.accountStatus = this.status
            }

            if (this.name) {
                filters.name = this.name
            }

            if (this.email) {
                filters.email = this.email
            }

            if (this.brn) {
                filters.brn = this.brn
            }

            if (this.phone) {
                filters.phone = this.phone
            }

            filters.page = startPage ? startPage : this.currentPage
            filters.perPage = this.limit

            this.fetchBusinessAccounts(this.objectToQueryParams(filters));
        },
        next() {
            if (this.currentPage < this.pagination.totalPages) {
                this.currentPage++
                this.fetch()
            }
        },

        async changeStatus(userId, status) {
            this.showConfirmDialog().then(value => {
                if (value) {
                    this.changeAccountStatus({userId, status})
                }
            })

        },
        resendActivation(userId) {
            this.doResendActivationLink({userId})

        },

        previous() {
            if (this.currentPage > 1) {
                this.currentPage--
                this.fetch()
            } else {
                this.warningToast("Observation", "No more data to show")
            }
        },
        openEditBusinessForm(account) {

            this.editForm = {
                userId: account.id,
                brn: account.brn,
                name: account.name,
                email: account.email,
                phoneNumber: account.phoneNumber,
            };
            this.$refs.editAccountForm.show();
        },
        updateUserDetails() {
            let data = {
                "email": this.editForm.email,
                "phone": this.editForm.phoneNumber,
                "userId": this.editForm.userId
            }

            this.updateStaffContacts(data)


        },
        closeEditForm() {
            this.$refs.editAccountForm.hide();
        },
        canViewMenuItem(permission) {
            return this.$can(permission, "general") === true;
        }
    },
    computed: {
        ...mapGetters(["getBusinessAccounts", "getReactivationError",
            "getReactivationSuccess",])
    },
    watch: {
        getBusinessAccounts(data) {
            this.loading = false
            this.accounts = data.data.content
            this.pagination = {
                totalPages: data.data.totalPages,
                totalElements: data.data.totalElements
            };
        },
        getReactivationSuccess(data) {
            this.successToast("Success", data.message);
            this.loading = true;
            this.closeEditForm();
            this.fetch();
        },
        getReactivationError(error) {
            if (error) {
                this.errorToast(error.message, error.message);
            }
        },
    }
};
</script>

<style scoped>
.paginate-details {
    @apply px-3 py-2 bg-white my-2 text-sm text-gray;
}

.paginate-btn {
    @apply px-3 py-2 bg-white border border-white my-2 text-sm text-blue w-full;
}

.paginate-btn:hover {
    @apply border border-blue text-blue rounded-sm;
}


.inert-btn {
    @apply px-3 py-2 bg-white my-2 text-sm text-grey w-full;
}
</style>