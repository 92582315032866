import {api} from "@/app/services/api.service";

const state = {
    serviceCosts: null,
    currencies: null,
    serviceCost: null,
    documentCost: null,
    documentCosts: null,
    editServiceCost: null,
    deleteServiceCost: null,
    error: null,
}

const getters = {
    getServiceCosts: state => state.serviceCosts,
    getCurrencies: state => state.currencies,
    getDeleteServiceCost: state => state.deleteServiceCost,
    getEditServiceCost: state => state.editServiceCost,
    getServiceCost: state => state.serviceCost,
    getDocumentCost: state => state.documentCost,
    getDocumentCosts: state => state.documentCosts,
    getError: state => state.error,
}

const actions = {
    async fetchServiceCosts({commit}) {
        api().get('configuration/service-charge/all')
            .then(response => {
                commit('setServiceCosts', response)
            })
            .catch(error => {
                commit('setError', error)
            })
    },
    async fetchCurrencies({commit}) {
        api().get('configuration/currency/all')
            .then(response => {
                commit('setCurrencies', response)
            })
            .catch(error => {
                commit('setError', error)
            })
    },
    async fetchDocumentCosts({commit}) {
        api().get('configuration/document-type/all')
            .then(response => {
                commit('setDocumentCosts', response)
            })
            .catch(error => {
                commit('setError', error)
            })
    },
    async deleteServiceCost({commit}, serviceId) {
        api().get('configuration/service-charge/delete-by-id/?serviceChargeId=' + serviceId)
            .then(response => {
                commit('setDeleteServiceCost', response)
            })
            .catch(error => {
                commit('setError', error)
            })
    },
    async createServiceCost({commit}, data) {

        api().post('configuration/service-charge/create', data)
            .then(response => {
                commit('setServiceCost', response)
            })
            .catch(error => {
                commit('setError', error)
            })
    },
    async createDocumentCost({commit}, data) {

        api().post('configuration/document-type/create', data)
            .then(response => {
                commit('setDocumentCost', response)
            })
            .catch(error => {
                commit('setError', error)
            })
    },
    async editServiceCost({commit}, data) {

        api().post('configuration/service-charge/update', data)
            .then(response => {
                commit('setEditServiceCost', response)
            })
            .catch(error => {
                commit('setError', error)
            })
    },
    async editDocumentCost({commit}, data) {

        api().post('configuration/document-type/update', data)
            .then(response => {
                commit('setEditServiceCost', response)
            })
            .catch(error => {
                commit('setError', error)
            })
    },
}

const mutations = {
    setServiceCosts: (state, payload) => {
        state.serviceCosts = payload.data;
    },
    setCurrencies: (state, payload) => {
        state.currencies = payload.data;
    },
    setServiceCost: (state, payload) => {
        state.serviceCost = payload.data;
    },
    setDocumentCosts: (state, payload) => {
        state.documentCosts = payload.data;
    },
    setDocumentCost: (state, payload) => {
        state.documentCost = payload.data;
    },
    setEditServiceCost: (state, payload) => {
        state.editServiceCost = payload.data;
    }, setDeleteServiceCost: (state, payload) => {
        state.deleteServiceCost = payload.data;
    },
    setError: (state, error) => {
        state.error = error.response.data;
    },
}


export default {
    state,
    getters,
    actions,
    mutations

}
