<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col>
          <table class="min-w-full leading-normal">
            <thead>
            <tr class="border-b border-t border-grey">
              <th class="t-header">Code</th>
              <th class="t-header">Description</th>
              <th class="t-header">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(item,idx) in isicList" :key="idx">
              <td class="t-body font-bold">{{ item.code }}</td>
              <td class="t-body font-bold">{{ item.description }}</td>
              <td class="t-body font-bold">
                <button class="w-full bg-green text-white text-xs px-2 py-1 rounded-sm"><i class="fa fa-pencil"></i> Edit</button>
              </td>
            </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "IsicTable",
  props:{
    search:String
  },
  data () {
    return {
      rows: {
        odd: "border-t border-grey hover:bg-header_grey",
        even: "border-t border-grey bg-header_grey"
      },
      headers: [
        {
          text: 'Code',
          align: 'start',
          value: 'code',
        },
        {
          text: 'Parent code',
          value: 'parent',
        },{
          text: 'Description',
          value: 'description',
        },{
          text: 'Action',
          sortable: false,
        }

      ],
      isicList: []
    }
  },

  mounted() {
    this.fetchIsic()
  },
  computed: {
    ...mapGetters(["getIsicFetch"]),
  },
  methods: {
    ...mapActions(["fetchIsic"]),
  },
  watch: {
    getIsicFetch(dataList){
      this.isicList = dataList
    },
    search:{
      handler(){

      },
      deep:true
    }

  }
}
</script>

<style scoped>

</style>