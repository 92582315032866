import { render, staticRenderFns } from "./WindingUpReasons.vue?vue&type=template&id=77e0cd4c&scoped=true&"
import script from "./WindingUpReasons.vue?vue&type=script&lang=js&"
export * from "./WindingUpReasons.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77e0cd4c",
  null
  
)

export default component.exports