<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col cols="4">
          <b-card title="Create a Dictionary">
            <form>
              <b-form-input v-model="form.name" type="text" placeholder="Dictionary Name" class="my-2"></b-form-input>
              <button
                class="bg-blue text-white px-2 py-1 rounded-sm"
                type="button"
                @click="submit">
                Save
                <b-spinner  v-if="spinner" small></b-spinner>
              </button>
            </form>
          </b-card>
        </b-col>
        <b-col cols="8">
          <table class="min-w-full leading-normal">
            <thead>
            <tr class="border-b border-t border-grey">
              <th class="t-header">#</th>
              <th class="t-header">Name</th>
              <th class="t-header">Number of Words</th>
              <th class="t-header">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(item, idx) in dictionaries" :key="idx">
              <td class="t-body">{{idx + 1}}</td>
              <td class="t-body">{{item.name}}</td>
              <td class="t-body">{{item.words}}</td>
              <td class="t-body">
                <button @click="deleteDictionary(item)" class="bg-red px-2 py-1 rounded-sm text-white text-sm">
                  <i class="fa fa-trash mr-1"></i> Remove
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
//import mapActions from "vuex";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "DictionaryTypes",
  data() {
    return {
      form: {name: null},
      checkPermissions: false,
      spinner: false,
      search: '',
      dictionaries: [],
      nameErrors: "",
      rows: {
        odd: "border-t border-grey hover:bg-header_grey",
        even: "border-t border-grey bg-header_grey"
      }
    }
  },
  methods: {
    ...mapActions(["fetchDictionary", "addDictionary"]),
    submit() {
      this.spinner = true
      this.addDictionary(this.form)
    },
    deleteDictionary(dictionary){
      console.log(dictionary)
    },
    clearForm() {
      this.spinner = false
      this.form = {name: null}
    }
  },
  computed: {
    ...mapGetters(["getDictionaryAdd", "getDictionaryFetch"]),
  },
  mounted() {
    this.fetchDictionary()
  },
  watch: {
    getDictionaryFetch(list) {
      this.dictionaries = list
    },
    getDictionaryAdd() {
      this.spinner = false
      this.successToast("Success", "Dictionary added")
      this.fetchDictionary()
    },
    getDictionaryAddError(response) {
      this.spinner = false
      this.errorToast("Error", response.message || "Failed to add")
    }

  }
}
</script>

<style scoped>

</style>