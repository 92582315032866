import {api} from "@/app/services/api.service";

const state = {
    occupations: null,
    occupation: null,
    error: null,
}

const getters = {
    getOccupations: state => state.occupations,
    getOccupation: state => state.occupation,
    getError: state => state.error,
}

const actions = {
    async fetchOccupations({commit}) {
        api().get('configuration/occupation/all')
            .then(response => {
                commit('setOccupations', response)
            })
            .catch(error => {
                commit('setError', error)
            })
    },
    async createOccupation({commit}, data) {

        api().post('configuration/occupation/create', data)
            .then(response => {
                commit('setOccupation', response)
            })
            .catch(error => {
                commit('setError', error)
            })
    },
}

const mutations = {
    setOccupations: (state, payload) => {
        state.occupations = payload.data;
    },
    setOccupation: (state, payload) => {
        state.occupation = payload.data;
    },
    setError: (state, error) => {
        state.error = error.response.data;
    },
}


export default {
    state,
    getters,
    actions,
    mutations

}
