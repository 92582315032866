<template>
  <div class="bg-white rounded-sm text-gray mx-2 my-3">
    <b-row>
      <b-col cols="12" class="mx-3 mt-3">
        <b-row>
          <span class="title">System Configurations</span>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-row>
          <b-col cols="12">
            <div class="flex h-full" style="cursor: pointer;">
              <div :class="[(currentRoute === 'dictionaries') ? 'menu-item-active' : 'menu-item']"
                   @click="select('dictionaries')">
                <div class="flex mt-6">
                  <div style="font-size: 14px" class="mt-1">Dictionaries</div>
                </div>
              </div>
              <div :class="[(currentRoute === 'isic') ? 'menu-item-active' : 'menu-item']"
                   @click="select('isic')">
                <div class="flex mt-6">
                  <div style="font-size: 14px" class="mt-1">ISIC (Nature of Business)</div>
                </div>
              </div>
              <div :class="[(currentRoute === 'reservation') ? 'menu-item-active' : 'menu-item']"
                   @click="select('reservation')">
                <div class="flex mt-6">
                  <div style="font-size: 14px" class="mt-1">Name Reservation</div>
                </div>
              </div>
              <div :class="[(currentRoute === 'name-search') ? 'menu-item-active' : 'menu-item']"
                   @click="select('name-search')">
                <div class="flex mt-6">
                  <div style="font-size: 14px" class="mt-1">Name Search</div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <Dictionaries v-if="currentRoute === 'dictionaries'"/>
            <NameReservation v-if="currentRoute === 'reservation'"/>
            <NatureOfBusiness v-if="currentRoute === 'isic'"/>
            <QuickNameSearch v-if="currentRoute === 'name-search'"/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Dictionaries from "@/views/home/services/configurations/tabs/Dictionaries";
import NameReservation from "@/views/home/services/configurations/tabs/NameReservation";
import NatureOfBusiness from "@/views/home/services/configurations/tabs/NatureOfBusiness";
import QuickNameSearch from "@/views/home/services/configurations/tabs/QuickNameSearch.vue";
export default {
  name: "Configurations",
  components: {QuickNameSearch, NatureOfBusiness, NameReservation, Dictionaries },
  data() {
    return {
      currentRoute: "name-search"
    };
  },
  methods: {
    select(route) {
      this.currentRoute = route;
    }
  }
};
</script>

<style scoped>

</style>