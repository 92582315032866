<template>
  <b-row>
    <b-col cols="12">
      <b-row>
        <b-col cols="12" class="my-2">
          <b-row>
            <b-col cols="2">
              <b-form-input
                class="text-sm noFocus ml-1"
                v-model="search"
                type="text"
                placeholder="Search"
                required
                v-on:keyup.enter="fetch">
              </b-form-input>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div class="mx-auto">
        <b-progress value="100" v-if="loading"  variant="#007ac5" height="0.2rem" animated></b-progress>
      </div>
      <b-row>
        <b-col cols="12">
          <table class="min-w-full leading-normal">
            <thead>
            <tr class="border-b border-t border-grey">
              <th class="t-header">Practitioner No.</th>
              <th class="t-header">Name</th>
              <th class="t-header">Phone</th>
              <th class="t-header">Profession</th>
              <th class="t-header text-center">Registration Date</th>
              <th class="t-header text-center">Status</th>
              <th class="t-header text-center">Action</th>
            </tr>
            </thead>

            <tbody>
            <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(practitioner, idx) in practitioners" :key="idx">
              <td class="t-body text-blue font-bold">
              <span class="hover:text-blue-dark hover:underline cursor-pointer" @click="viewFile(practitioner.uniquePractitionerNumber)">
                {{practitioner.uniquePractitionerNumber}}
              </span>
              </td>
              <td class="t-body font-bold">
                <span class="text-capitalize">{{practitioner.firstName}} {{practitioner.lastName}}</span>
              </td>
              <td class="t-body">
                <span>{{practitioner.phoneNumber}}</span>
              </td>
              <td class="t-body">
                <span v-if="practitioner.form1s[0]">{{practitioner.form1s[0].professions[0].professionName}}</span>
                <span v-else class="text-red">None</span>
              </td>
              <td class="t-body text-center">
                <span>{{dateFormat(practitioner.registrationDate)}}</span>
              </td>
              <td class="t-body text-center text-capitalize">
                <span class="px-1 py-1 text-green text-xs" v-if="practitioner.active">
                  Active
                </span>
                <span class="px-1 py-1 text-red text-xs" v-else>
                  Inactive
                </span>

              </td>
              <td class="text-center">
                <button class="bg-blue text-white rounded-sm px-2 py-1 text-xs hover:bg-blue-dark" @click="viewFile(practitioner.uniquePractitionerNumber)">View</button>
              </td>
            </tr>
            <tr>
              <td v-if="practitioners.length === 0" colspan="8" class="bg-inertgrey text-center py-1">
                <span class="text-sm">No results found</span>
              </td>
            </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
      <b-row class="mx-1" v-if="pagination.totalPages > 1">
        <b-col cols="1">
          <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
        </b-col>
        <b-col cols="1">
          <b-row>
            <b-col cols="12">
              <p class="paginate-details text-center">
                <b-badge class="bg-blue text-white">
                  {{currentPage}}/{{pagination.totalPages}}
                </b-badge>
              </p>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="1" >
          <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">Next</button>
        </b-col>
        <b-col class="9"></b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import router from "@/router";
import moment from "moment/moment";

export default {
  name: "InsolvencyPractitionersRegistry",
  data(){
    return{
      loading:false,
      currentPage:1,
      limit:10,
      practitioners:[],
      pagination:{
        totalElements: 0,
        totalPages:0
      },
      rows:{
        odd:"border-t border-grey hover:bg-header_grey",
        even:"border-t border-grey bg-header_grey"
      },
      search:"",
      type:"",
    }
  },
  mounted() {
    this.fetch()
  },
  methods:{
    ...mapActions(["fetchPractitionerRegistry"]),
    fetch(){
      let data = {
        search:this.search,
        page:this.currentPage,
        limit:this.limit,
      }
      this.loading = true
      this.fetchPractitionerRegistry(data)
    },
    viewFile(id){
      router.push("/home/registry/practitioner-viewer/"+id)
      router.go(0)
    },
    next(){
      if(this.currentPage < this.pagination.totalPages){
        this.currentPage++
        this.fetch()
      }
    },
    previous(){
      if(this.currentPage > 1){
        this.currentPage--
        this.fetch()
      }else{
        this.warningToast("Observation", "No more data to show")
      }
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    cleanEntityType(name){
      let n = name.replaceAll("_", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
  },
  computed:{
    ...mapGetters(["getPractitionerRegistry", "getPractitionerRegistryError"])
  },
  watch:{
    search:{
      handler(){
        this.fetch()
      }
    },
    type:{
      handler(){
        this.fetch()
      }
    },
    getPractitionerRegistry(data){
      this.loading = false
      this.practitioners = data.data.content
      this.pagination = {
        totalPages:data.totalPages,
        totalElements:data.totalElements
      }
    },
    getPractitionerRegistryError(data){
      this.loading = false
      this.errorToast("Error", JSON.stringify(data.message))
    }
  }
};
</script>

<style scoped>

</style>