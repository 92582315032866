<template>
  <b-row>
    <b-col cols="12">

      <b-row>
        <b-col>
            <BlackListRegistry v-if="currentRoute=== 'black-list'"></BlackListRegistry>
            <MDAListRegistry v-if="currentRoute==='mda-list'"></MDAListRegistry>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import BlackListRegistry from "@/views/home/services/registry/business/BlackListRegistry.vue";
import MDAListRegistry from "@/views/home/services/registry/business/MDAListRegistry.vue";

export default {
    name: "EntityWrapper",
    components: {
        BlackListRegistry,
        MDAListRegistry,

    },
    data() {
        return {
            currentRoute: "mda-list",
            search: "",
            currentPage: 0,
            limit: 15,
        }
    },
    mounted() {
        //this.fetch()
        if (this.$route.fullPath === "/home/registry/business") {
            this.currentRoute = "black-list"
        } else if (this.$route.fullPath === "/home/registry/mda") {
            this.currentRoute = "mda-list"
        }
  },
  methods:{
    ...mapActions(["fetchBuildingSocietyRegistry", "fetchCompanyRegistry", "fetchBusinessNameRegistry"]),
    fetch(){
      let data = {
        q:this.search,
        page:this.currentPage,
        limit:this.limit,
        offset:this.limit
      }
      this.fetchBuildingSocietyRegistry(data)
      this.fetchCompanyRegistry(data)
      this.fetchBusinessNameRegistry(data)
    },
    select(route){
      this.currentRoute = route
    },
  },
  computed:{
    ...mapGetters(["getBusinessNameRegistry", "getCompanyRegistry", "getBuildingSocietyRegistry"])
  }
}
</script>

<style scoped>

</style>