import { render, staticRenderFns } from "./PractitionerProfileWrapper.vue?vue&type=template&id=33d9c445&scoped=true&"
import script from "./PractitionerProfileWrapper.vue?vue&type=script&lang=js&"
export * from "./PractitionerProfileWrapper.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33d9c445",
  null
  
)

export default component.exports