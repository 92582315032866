<template>
  <b-col cols="12" class="mb-4 overflow-hidden">
    <b-row>
      <b-col cols="10" class="my-4"></b-col>
    </b-row>
    <b-row v-for="(item, idx) in menu" :key="idx">
      <b-col cols="12" v-if="full">
        <b-row :class="[(compareMenuItem(currentRoute, item.route)) ? 'side-menu-item-active' : 'side-menu-item']"
               v-show="canViewMenuItem(item.action, item.subject)" @click="select(item.route)">
          <div class="flex" v-if="canViewMenuItem(item.action, item.subject)">
            <b-col cols="1"></b-col>
            <b-col cols="2">
              <i :class="item.icon +' mt-2'"></i>
            </b-col>
            <b-col cols="8">
              <div>
                <div class="menu-text mt-1">{{ item.title }}</div>
              </div>
            </b-col>
          </div>
        </b-row>
        <b-row v-if="compareMenuItem(currentRoute, item.route)">
          <b-col class="bg-header_grey">
            <b-row v-for="(subItem, idx2) in item.children" :key="idx2">
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem(subItem.action, item.subject)">
                  <b-col cols="12"
                         :class="(compareMenuItem(currentRoute, subItem.route)) ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row @click="select(subItem.route)"
                           :class="[compareMenuItem(currentRoute, item.route) ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        {{ subItem.title }}
                      </b-col>
                      <b-col class="float-end" v-if="subItem.count != null">
                        <b-badge class="bg-blue float-end">
                          {{ subItem.count }}
                        </b-badge>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" v-else>
        <b-row :class="[compareMenuItem(currentRoute, item.route) ? 'side-menu-item-active' : 'side-menu-item']"
               v-show="canViewMenuItem(item.action, item.subject)"
               @click="select(item.route)">
          <b-col cols="10" class="mx-auto text-center">
            <i :class="item.icon +' mt-2'" :id="''+idx"></i>
            <b-tooltip :target="''+idx" triggers="hover" placement="right">
              <span>
                {{ item.title }}
              </span>
            </b-tooltip>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import router from "@/router";

export default {
  name: "SideMenu",
  props: {
    full: Boolean
  },
  data() {
    return {
      currentRoute: this.$route.fullPath,
      menu: [
        {
          title: "Accounts",
          route: "/home/accounts",
          icon: "fa fa-users",
            action: "can_access_system_accounts",
          resource: "general",
          children: [
            {
              title: "Classification",
              route: "/home/accounts/breakdown",
                action: "can_view_system_accounts",
              resource: "general"
            },
            {
              title: "Permissions",
              route: "/home/accounts/permissions",
                action: "can_view_permission_management",
              resource: "general"
            }
          ]
        },
        {
          title: "General Settings",
          route: "/home/settings",
          icon: "fa fa-sliders",
            action: "can_view_general_settings",
          resource: "general"
        },
          {
              title: "Insolvency Settings",
              route: "/home/insolvency-settings",
              icon: "fa fa-sliders",
              action: "can_view_insolvency_settings",
              resource: "general"
          },
        {
          title: "Configurations",
          route: "/home/configurations",
          icon: "fa fa-gears",
          action: "can_view_reservation_settings",
          resource: "general"
        },
        {
          title: "Internal Processing",
          route: "/home/internal-processes",
          icon: "fa fa-copy",
            action: "can_view_internal_processing",
          resource: "general"
        },
        {
          title: "Billing & Revenue",
          route: "/home/billing",
          icon: "fa fa-money",
            action: "can_view_billing_settings",
          resource: "general",
          children: [
              {
                  title: "Service Costs",
                  route: "/home/billing/service-costs",
                  action: "can_view_billing_settings",
                  resource: "general"
              },
              {
                  title: "Document Costs",
                  route: "/home/billing/document-costs",
                  action: "can_view_billing_settings",
                  resource: "general"
              },
              {
                  title: "Tax Heads",
                  route: "/home/billing/tax-heads",
                  action: "can_view_billing_settings",
                  resource: "general"
              },
              {
                title: "Transactions",
                route: "/home/billing/transactions",
                  action: "can_view_billing_settings",
                resource: "general"
              },
              {
                  title: "PRNs",
                  route: "/home/billing/prn-manager",
                  action: "can_view_billing_settings",
              resource: "general"
            }
          ]
        },
        {
          title: "URSB Registry",
          route: "/home/registry",
          icon: "fa fa-database",
            action: "can_view_admin_registry",
          resource: "general",
          children: [
            {
                title: "Black List Registry",
                route: "/home/registry/business",
                action: "can_view_blacklist_registry",
                resource: "general"
            },
              {
                  title: "MDA Registry",
                  route: "/home/registry/mda",
                  action: "can_view_blacklist_registry",
                  resource: "general"
              },
            {
              title: "Document Registry",
              route: "/home/registry/document",
                action: "can_view_doc_registry",
              resource: "general"
            },
            {
              title: "Practitioner Registry",
              route: "/home/registry/practitioners",
                action: "can_view_practitioners",
              resource: "general"
            }
          ]
        },
        {
          title: "Integrations",
          route: "/home/integrations",
          icon: "fa fa-user-secret",
            action: "can_view_integration_settings",
          resource: "general",
          children:[
            {
              title: "MDAs & Partners",
              route: "/home/integrations/mdas_and_partners",
                action: "can_view_integration_settings",
              resource: "general"
            },
            {
              title: "Credentials",
              route: "/home/integrations/credentials",
                action: "can_view_integration_settings",
              resource: "general"
            },
          ]
        },
          /* {
             title: "Reports",
             route: "/home/reports",
             icon: "fa fa-chart-line",
             action: "can_view_reservation_settings",
             resource: "general"
           }*/
      ]
    };
  },
  mounted() {
    this.currentRoute = this.$route.fullPath;
  },
  methods: {
    select(route) {
      router.push(route);
      this.currentRoute = route;
    },
    goHome() {
      router.push({ name: "Home" });
      window.location.reload();
    },
    compareMenuItem(currentRoute, selectedRoute){
      if(currentRoute.includes(selectedRoute)){
        return true
      }
      return false
    },
    canViewMenuItem(permission, group) {
        return this.$can(permission, group) === true;
    }
  }
};
</script>

<style scoped>
@import "../../assets/styles/menu.css"

</style>