<template>
  <div class="bg-white rounded-sm text-gray mx-2 my-3">
    <b-row>
      <b-col cols="8" class="mx-auto">
        <form>
          <b-form-textarea
            v-model="form.messageToSend"
            placeholder="What do you want to say?"
            rows="6">
          </b-form-textarea>
          <b-row class="mt-2">
            <b-col cols="3">
              <b-checkbox v-model="form.recipientList" value="INDIVIDUAL">
                <span class="mx-1">
                  User Accounts
                </span>
              </b-checkbox>
            </b-col>
            <b-col cols="3">
              <b-checkbox v-model="form.recipientList" value="BUSINESS">
                <span class="mx-1">
                  Business Accounts
                </span>
              </b-checkbox>
            </b-col>
            <b-col cols="3">
              <b-checkbox v-model="form.recipientList" value="BACKOFFICE">
                <span class="mx-1">
                  Staff Accounts
                </span>
              </b-checkbox>
            </b-col>
            <b-col cols="3">
              <b-checkbox v-model="form.recipientList" value="MDA">
                <span class="mx-1">
                  MDA Accounts
                </span>
              </b-checkbox>
            </b-col>
            <b-col cols="3">
              <b-checkbox v-model="form.recipientList" value="PARTNER">
                <span class="mx-1">
                  Partner Accounts
                </span>
              </b-checkbox>
            </b-col>
          </b-row>
          <button
            class="bg-blue text-white px-3 py-1 rounded-sm mt-2 float-end"
            @click="send" type="button">
            Send Message <b-spinner v-if="loading" small></b-spinner>
          </button>
        </form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Messenger",
  data(){
    return{
      loading:false,
      form:{
        messageToSend:null,
        recipientList:[],
        senderUserId:null
      }
    }
  },
  methods:{
    ...mapActions(["sendMessage"]),
    send(){
      this.loading = true
      this.form.senderUserId = JSON.parse(sessionStorage.getItem("current_user")).userId
      this.sendMessage(this.form)
    }
  },
  computed:{
    ...mapGetters(["getMessageSentSuccess"])
  },
  watch:{
    getMessageSentSuccess(){
      this.loading = false
      this.successToast("Success", "Message sent")
      this.form = {
        messageToSend:null,
        recipientList:[],
        senderUserId:null
      }
    }
  }
};
</script>

<style scoped>

</style>