<template>
  <b-row>
    <b-col>
      <form @submit.prevent="retry">
        <b-row class="my-20">
          <b-col cols="12" lg="3" class="flex">
            <b-form-input
                class="text-gray text-center text-sm noFocus my-auto"
                style="padding:6px 5px;"
                v-model.trim="instrumentNumber"
                type="text"
                placeholder="Document Instrument Number"
                required>
            </b-form-input>
          </b-col>
          <b-col cols="12" lg="3" class="flex">
            <button class="w-full bg-blue px-2 py-1 my-auto rounded-sm text-white" type="submit">
              Retry <b-spinner v-if="loading" small></b-spinner>
            </button>
          </b-col>
        </b-row>
      </form>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "LegalDocumentService",
  data(){
    return{
      loading:false,
      selectedIdx:null,
      currentPage: 1,
      limit: 12,
      stream:[],
      pagination: {
        totalElements: 0,
        totalPages: 0
      },
      statuses:null,
      process:{
        brn:null,
        owner:null,
        createdAt:null,
        type:null,
        endPoint:null,
        request:null,
        response:null
      },
      instrumentNumber: null,
      startDate:null,
      endDate:null,
      rows: {
        odd: "border-t border-grey hover:bg-header_grey",
        even: "border-t border-grey bg-header_grey"
      },
    }
  },
  mounted() {
    this.fetch()
  },
  methods:{
    ...mapActions(["retryDocumentProcess"]),
    retry(){
      this.loading = true
      this.retryDocumentProcess(this.instrumentNumber)
    },
  },
  computed:{
    ...mapGetters(["getDocumentStatusChangeSuccess", "getDocumentStatusChangeError"])
  },
  watch:{
    getDocumentStatusChangeSuccess(response) {
      this.loading = false
      this.instrumentNumber = null
      this.successToast('Success', response.data.message)
    },
    getDocumentStatusChangeError(response) {
      this.loading = false
      this.errorToast('Error', response.data.message)
    },
  }
};
</script>

<style scoped>

</style>