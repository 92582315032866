<template>
  <b-row class="">
    <b-col class="flex" cols="12" lg="2" md="4">
      <button @click="lodgeComplaintPrompt" type="button" class="bg-blue hover:bg-blue-dark text-white rounded-sm px-2 py-1 my-auto">
        Lodge Complaint
      </button>
    </b-col>
    <b-col class="pt-3" v-if="complaints.length === 0" cols="12">
      <b-alert variant="secondary" show>
        <i class="fa fa-info-circle mr-2"></i> {{ name }} has no complaints lodged against {{ gender === null || gender === 'MALE' ? 'him' : 'her'  }}.
      </b-alert>
    </b-col>
    <b-col v-else class="pt-3">
      <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
        <thead>
        <tr>
          <th class="px-2 py-1">Tracking Number</th>
          <th class="px-2 py-1">Complainant</th>
          <th class="px-2 py-1">Complaint</th>
          <th class="px-2 py-1 text-center">Date</th>
          <th class="px-2 py-1 text-center">Status</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(complaint, idx) in complaints" :key="idx" style="border: solid 1px #dedede;">
          <td class="px-2 py-1">{{complaint.trackingNumber}}</td>
          <td class="px-2 py-1 text-capitalize">{{complaint.complainantName}}</td>
          <td class="px-2 py-1 text-capitalize">
            {{complaint.complaint}} <i :id="'complaint-details-'+(idx+1)" href="#" tabindex="0" class="fa fa-info-circle text-blue ml-2 cursor-pointer"></i>
            <b-popover :target="'complaint-details-'+(idx+1)" triggers="focus" offset="1" placement="bottom">
              <template #title>{{complaint.complaint}}</template>
              {{complaint.description}}
            </b-popover>
          </td>
          <td class="px-2 py-1 text-center">{{complaint.status}}</td>
          <td class="px-2 py-1 text-center">{{dateFormat(complaint.dateOfApplication)}}</td>
        </tr>
        </tbody>
      </table>
    </b-col>

    <b-modal centered no-close-on-backdrop hide-header-close id="lodgeComplaints" hide-footer ref="lodgeComplaints" title="Lodge Complaint">
      <LodgeComplaint :practitioner-number="upn"/>
    </b-modal>
  </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment/moment";
import LodgeComplaint from "@/views/home/services/registry/practitioners/LodgeComplaint";

export default {
  name: "PractitionerComplaints",
  components: {LodgeComplaint},
  props: {
    upn: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: null
    },
    gender: {
      type: String,
      required: null
    },
  },
  mounted() {
    this.loading = true;
    this.fetchPractitionerComplaints(this.query)
  },
  data() {
    return {
      loading: false,
      complaints: [],
      pagination:{
        totalElements: 0,
        totalPages:0
      },
      query: {
        page: 1,
        perPage: 20,
        status: null,
        practitionerNumber: this.upn,
      },
      statusList: [
        { text: 'ALL', value: null },
        { text: 'PENDING', value: 'PENDING' },
        { text: 'RESOLVED', value: 'RESOLVED' },
        { text: 'DISMISSED', value: 'DISMISSED' },
      ],
      rows:{
        odd:"border-t border-grey hover:bg-header_grey",
        even:"border-t border-grey bg-header_grey"
      },
    }
  },
  methods: {
    ...mapActions([
      "fetchPractitionerComplaints",
    ]),
    fetch(){
      this.loading = true
      this.fetchPractitionerComplaints(this.query)
    },
    next(){
      if(this.query.page < this.pagination.totalPages){
        this.query.page++
        this.fetch()
      }
    },
    previous(){
      if(this.query.page > 1){
        this.query.page--
        this.fetch()
      }else{
        this.warningToast("Observation", "No more data to show")
      }
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    lodgeComplaintPrompt(){
      this.$refs.lodgeComplaints.show();
    },
  },
  computed: {
    ...mapGetters([
      "getPractitionerComplaints",
      "getPractitionerComplaintsError",
    ])
  },
  watch: {
    getPractitionerComplaints(response){
      this.complaints = response.data
      this.loading = false;
    },
  },
}
</script>

<style scoped>

</style>