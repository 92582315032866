<template>
  <b-row>
    <b-col cols="1"></b-col>
    <b-col cols="10">
      <div class="flex">
        <div class="flex" v-for="(input, idx) in inputNumber" :key="idx">
          <input
              v-model="data[idx]"
              :id="'input_'+input"
              type="number"
              required
              v-on:input="moveFocus"
              v-on:keydown.delete="moveFocusBack"
              :class="'w-'+inputNumber+'/12 border-2'"/>
        </div>
      </div>
    </b-col>
    <b-col cols="1"></b-col>
  </b-row>
</template>

<script>
export default {
  name: "Otp",
  props: {inputNumber:Number},
  data(){
    return{
      otp:null,
      focusedIdx:1,
      data:[]
    }
  },
  methods:{
    moveFocus(){
      let step = this.data.length + 1
      this.focusedIdx = step
      if(this.data.length < this.inputNumber){
        document.getElementById("input_"+step).focus()
      }
    },
    moveFocusBack(){
      let step = this.focusedIdx - 1
      this.data.pop()
      this.focusedIdx = step
      if(this.data.length >= 0){
        document.getElementById("input_"+step).focus()
      }
    },
    flush(){
      this.data = []
    }
  },
  watch:{
   otp: {
      handler(value) {
        this.$emit("otpData", value)
      }
    },
    focusedIdx: {
      handler(value) {
        console.log("focus: "+value)
      }
    },
    data: {
      handler(value) {
        if(value.length === this.inputNumber){
          let otp = parseInt(value.toString().replaceAll(",", ""))
          if(isNaN(otp)){
            alert("Enter Numbers only")
          }else{
            this.otp = otp.toString()
          }

        }
      }
    }
  }
}
</script>

<style scoped>

input{
  width: 100%;
  height: 85px;
  font-size: 20px;
  @apply rounded border-blue text-center mx-1;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

</style>