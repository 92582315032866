<template>
    <b-row>
        <b-col>
            <b-row>
                <b-col cols="10" class="mx-auto">
                    <form>
                        <b-form-input class="form-text my-2" v-model="form.name"
                                      placeholder="Name of Role"></b-form-input>
                        <b-form-textarea class="form-text my-2" rows="4" v-model="form.description"
                                         placeholder="Description"></b-form-textarea>
                    </form>
                </b-col>
            </b-row>

            <b-row class="mt-3">
                <b-col>

                    <b-row v-for="(group, idx) in groups" :key="idx">
                        <b-col cols="11" class="mx-auto border-t border-gray py-2">
                            <b-row>
                                <b-col cols="3">
                                    <p>
                                        {{ cleanUnderscoreString(group.name.replace('-', ' ')) }}
                                    </p>
<!--                                    <button class="bg-blue px-2 text-white rounded-sm"><i class="fa fa-check mr-1"/>select
                                        all
                                    </button>-->
                                </b-col>
                                <b-col cols="9" class="border-l border-gray">
                                  <b-row>
                                    <b-col cols="12" v-for="(permission, idx) in permissions" :key="idx">
                                      <div v-if="group.name === permission.group" class="text-lowercase flex">
                                        <b-form-checkbox
                                                v-model="form.permissions"
                                                :id="idx+'l'"
                                                color="#24a4f6"
                                                :name="cleanUnderscoreString(permission.name)"
                                                :value="permission.id">
                                        </b-form-checkbox>
                                        <span class="ml-2">{{ cleanUnderscoreString(permission.name) }}</span>
                                      </div>
                                    </b-col>
                                  </b-row>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="6" class="mx-auto">
                    <button class="w-full bg-blue text-white px-3 py-3 my-5 rounded-sm hover:bg-blue-dark"
                            @click="submitRole">
                        <i class="fa fa-save mr-2"></i> {{ role_uid ? 'Save changes role' : 'Save Role' }}
                        {{ roleId }}
                    </button>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "CreateRole",
    data() {
        return {
            errorMessage: null,
            checkPermissions: false,
            permissions: [],
            form: {
                name: null,
                description: null,
                permissions: [],
            },
            groups: [],
            spinner: false,

            allPermissions: [],
            permissionsToRemove: []
        }
    },
    props: {
        roleId: {type: String, default: null}
    },
    mounted() {
        this.fetchPermissionGroups()
        this.fetchPermissions()
    },
    methods: {
        ...mapActions(["fetchRoles", "fetchRoleDetails", "fetchPermissions",
            "fetchPermissionGroups", "saveNewRole", "addPermission", "addPermissionGroup",
            "updateRoleAndPermission"]),
        submitRole() {
            this.spinner = true
            this.errorMessage = null
            if (this.role_uid) {
                this.permissionsToRemove = []
                this.permissions.forEach(permission => {
                    if (!this.form.permissions.includes(permission.id)) {
                        this.permissionsToRemove.push(permission.id)
                    }
                })
                let data = {
                    description: this.form.description,
                    name: this.form.name,
                    permissions: this.allPermissions,
                    permissionsToAdd: this.form.permissions,
                    permissionsToRemove: this.permissionsToRemove,
                    roleId: this.role_uid
                }
                this.updateRoleAndPermission(data)
            } else {
                this.saveNewRole(this.form)
            }
        },
        filterRoleEdit(roleDataEdit) {
            let role_data = roleDataEdit
            this.form.name = role_data.name
            this.form.description = role_data.description
            this.role_uid = role_data.uid
            let role = role_data.rolePermissions
            this.form.active_permissions = []
            for (let i = 0; i < role.length; i++) {
                if (role[i].value === "Yes") {
                    this.form.active_permissions.push(role[i].permission)
                }
            }

            this.fetchRoles()

        },
    },
    computed: {
        ...mapGetters(["getRoleDetails", "getPermissionList",
            "getRolesError", "getPermissionGroupsList", "getRolesAndPermissionUpdate",
            "getNewPermissionGroup", "getNewRole", "getNewRoleError"])
    },
    watch: {
        getPermissionGroupsList(data) {
            this.loading = false
            this.groups = data
        },
        getRolesError(rolesError) {
            if (rolesError) {
                this.spinner = false
                this.errorMessage = rolesError.message
                this.errorToast("Error", rolesError.message)
            }
        },
        getDoAssignRole(assignRoleData) {
            if (assignRoleData) {
                this.successToast("Success", assignRoleData.message)
            }
        },
        getPermissionList(permissionList) {
            this.permissions = permissionList
        },
        getRolesAndPermissionUpdate(roleDataUpdate) {
            if (roleDataUpdate) {
                this.successToast("Success", "Role updated successfully")
                this.form.permissions = []
                this.permissionsToRemove = []
                this.form.name = null
                this.form.description = null
                this.role_uid = null
                this.$emit("finished", true)
                this.fetchRoles()
                this.fetchPermissionGroups()
                this.fetchPermissions()
            }
        },
        getNewRole() {
            this.successToast("Role added ")
            this.spinner = false
            this.form = {
                name: null,
                description: null,
                permissions: [],
            }
            this.$emit("finished", true)
        },
        getNewRoleError() {
            this.errorToast()
        },
        getRoleDetails(roleDetails) {
            if (roleDetails) {
                this.form.name = roleDetails.name
                this.form.description = roleDetails.description
                this.role_uid = roleDetails.roleId

                this.form.permissions = []


                roleDetails.permissions.forEach(permission => {
                    this.form.permissions.push(permission.permissionId)
                });

                this.allPermissions = []
                this.permissionsToRemove = []
                this.permissions.forEach(permission => {
                    this.allPermissions.push(permission.id)
                })

            }


        }
    }
};
</script>

<style scoped>

</style>