<template>
  <div>
    <b-row class="mx-auto">
        <b-col cols="4">
          <b-card title="Create Expiry Entry">
            <b-form-input
              placeholder="Enter number"
              v-model="reservation.value.period"
              type="number">
            </b-form-input>
            <b-form-select
              class="form-select my-2"
              v-model="reservation.key"
              :options="types">
            </b-form-select>
            <b-form-select
              class="form-select my-2"
              v-model="reservation.value.type"
              :options="options">
            </b-form-select>
            <button class="mt-2 bg-blue text-white px-2 py-1 rounded-sm" @click="addExpiryTime">
              <i class="fa fa-plus"></i>
              Add Expiry Time
              <b-spinner v-if="loading" small></b-spinner>
            </button>
          </b-card>
        </b-col>

        <b-col cols="8">
          <table class="min-w-full leading-normal">
            <thead>
            <tr class="border-b border-t border-grey">
              <th class="t-header">Name</th>
              <th class="t-header">Quantity</th>
              <th class="t-header">Unit</th>
              <th class="t-header">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(item, idx) in expiryTimes" :key="idx">
              <td class="t-body">{{item.key.replaceAll("_", " ")}}</td>
              <td class="t-body">{{item.value.period}}</td>
              <td class="t-body text-capitalize">{{item.value.type.toLowerCase()}}</td>
              <td class="t-body">
                <button @click="removeExpiryTime(item)" class="bg-red px-2 py-1 rounded-sm text-white text-sm">
                  <i class="fa fa-trash mr-1"></i> Remove
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ReservationExpiry",
  data() {
    return {
      loading: false,
      reservation: {
        key: null,
        value: {
          period: 0,
          type: null
        }
      },
      notification: {},
      types: [
        {
          text:"- Select Type -",
          value:null
        },
        {
          text:"ASSIGNED EXPIRY DATE",
          value:"ASSIGNED_EXPIRY_DATE"
        },
        {
          text:"DRAFT EXPIRY DATE",
          value:"DRAFT_EXPIRY_DATE"
        },
        {
          text:"EXPIRY NOTIFICATION",
          value:"EXPIRY_NOTIFICATION"
        },
        {
          text:"PENDING APPLICATION EXPIRY DATE",
          value:"PENDING_APPLICATION_EXPIRY_DATE"
        },
      ],
      options: [
        {
          text:"- Select Unit -",
          value:null
        },
        {
          text:"DAYS",
          value:"DAYS"
        },
        {
          text:"HOURS",
          value:"HOURS"
        },
        {
          text:"MINUTES",
          value:"MINUTES"
        },
        {
          text:"MONTHS",
          value:"MONTHS"
        },
        {
          text: "YEARS",
          value: "YEARS"
        }
      ],
      expiryTimes: [],
      rows: {
        odd: "border-t border-grey hover:bg-header_grey",
        even: "border-t border-grey bg-header_grey"
      }
    };
  },
  mounted() {
    this.fetchReservationExpiryTimes();
  },
  methods: {
    ...mapActions(["addReservationExpiryTime", "addReservationNotificationTime", "fetchReservationExpiryTimes"]),
    addExpiryTime() {
      this.loading = true;
      if (this.reservation.key === "EXPIRY_NOTIFICATION") {
        this.addReservationNotificationTime(this.reservation);
      } else {
        this.addReservationExpiryTime(this.reservation);
      }


    },
    removeExpiryTime(item) {
      this.expiryTimes = this.expiryTimes.filter(i => i !== item);
    }
  },
  computed: {
    ...mapGetters(["getAddStatus", "getReservationExpiryTimes", "getAddStatus", "getAddError"])
  },
  watch: {
    getAddStatus() {
        this.successToast()
        this.loading = false;
      this.fetchReservationExpiryTimes();
    },
    getReservationExpiryTimes(data) {
      this.expiryTimes = data;
    },
    getAddError(error) {
      this.loading = false;
      this.errorToast("Error", error.message || "something went wrong");
    }
  }
};
</script>

<style scoped>

</style>