<template>
  <b-col>
    <b-row v-if="isFiled">
      <b-overlay :show="loading" rounded="sm">
        <b-col cols="12" class="mx-auto mt-1">
          <b-row class="mx-1 py-1">
            <b-col>
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="Country"
                label-for="input-1">
                <b-form-input
                  id="companyName"
                  class="form-text text-sm noFocus"
                  type="text"
                  disabled="true"
                  :value="getCompanyAddress.data.village.country.name"
                  required>
                </b-form-input>
              </b-form-group>
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="Region"
                label-for="input-1">
                <b-form-input
                  id="companyName"
                  class="form-text text-sm noFocus"
                  type="text"
                  disabled="true"
                  :value="getCompanyAddress.data.village.region.name"
                  required>
                </b-form-input>
              </b-form-group>
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="District"
                label-for="input-1">
                <b-form-input
                  id="companyName"
                  class="form-text text-sm noFocus text-capitalize"
                  type="text"
                  disabled="true"
                  :value="getCompanyAddress.data.village.district.name.toLowerCase()"
                  required>
                </b-form-input>
              </b-form-group>
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="Sub County"
                label-for="input-1">
                <b-form-input
                  id="companyName"
                  class="form-text text-sm noFocus text-capitalize"
                  type="text"
                  disabled="true"
                  :value="getCompanyAddress.data.village.subcounty.name"
                  required>
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="Parish"
                label-for="input-1">
                <b-form-input
                  id="companyName"
                  class="form-text text-sm noFocus"
                  type="text"
                  disabled="true"
                  :value="getCompanyAddress.data.village.parish.name"
                  required>
                </b-form-input>
              </b-form-group>
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="Village"
                label-for="input-1">
                <b-form-input
                  id="companyName"
                  class="form-text text-sm noFocus text-capitalize"
                  type="text"
                  disabled="true"
                  :value="getCompanyAddress.data.village.village.name"
                  required>
                </b-form-input>
              </b-form-group>
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="Plot"
                label-for="input-1">
                <b-form-input
                  id="companyName"
                  class="form-text text-sm noFocus"
                  type="text"
                  disabled="true"
                  :value="getCompanyAddress.data.address.plot"
                  required>
                </b-form-input>
              </b-form-group>
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="Street"
                label-for="input-1">
                <b-form-input
                  id="companyName"
                  class="form-text text-sm noFocus text-capitalize"
                  type="text"
                  disabled="true"
                  :value="getCompanyAddress.data.address.street"
                  required>
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="Postal Address"
                label-for="input-1">
                <b-form-input
                  id="companyName"
                  class="form-text text-sm noFocus text-capitalize"
                  type="text"
                  disabled="true"
                  :value="getCompanyAddress.data.address.postalAddress"
                  required>
                </b-form-input>
              </b-form-group>
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="Description"
                label-for="input-1">
                <b-form-textarea
                  id="companyName"
                  class="form-text text-sm noFocus text-capitalize"
                  type="text"
                  rows="4"
                  disabled="true"
                  :value="getCompanyAddress.data.address.description"
                  required>
                </b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-overlay>
    </b-row>
    <b-row v-else>
      <b-col class="mt-3 text-sm" v-if="lockDown">
        <b-alert variant="secondary" show>
          <i class="fa fa-info-circle mr-2"></i> Form 18 is not yet Filed. Please click <a class="text-blue underline">here</a> to have it filed after your account is activated back
        </b-alert>
      </b-col>
      <b-col class="mt-3 text-sm" v-else>
        <b-alert variant="danger" show>
          <i class="fa fa-info-circle mr-2"></i> Form 18 is not yet Filed. Please click <router-link class="text-blue underline" :to="{name:'file-resolutions'}">here</router-link> to have it filed
        </b-alert>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import {mapGetters, mapActions} from "vuex";

export default {
  name: "CompanyAddress",
  props:{
    isFiled:Boolean,
    brn:String
  },
  data(){
    return{
      lockDown:false,
      loading:true
    }
  },
  mounted(){
    this.fetchCompanyAddress(this.brn)
    this.fetchFilingCompliance(this.brn)
  },
  methods:{
    ...mapActions(["fetchCompanyAddress", "fetchFilingCompliance"])
  },
  computed:{
    ...mapGetters(["getCompanyAddress", "getFilingCompliance"])
  },
  watch:{
    getCompanyAddress(){
      this.loading = false
    },
    getFilingCompliance(data){
      this.lockDown = this.determineLockDown(data.data)
    }
  }
}
</script>

<style scoped>

</style>