<template>
    <b-row>
        <b-col>
            <b-row class="py-3">
                <b-col cols="2">
                    <b-form-input
                            class="text-gray text-sm noFocus ml-1"
                            style="margin-top: 1px; padding:6px 5px;"
                            v-model="name"
                            type="text"
                            placeholder="Search by Name"
                            required
                            v-on:keyup.enter="fetch(1)">
                    </b-form-input>
                </b-col>

                <b-col cols="2">
                    <b-form-input
                            class="text-gray text-sm noFocus ml-1"
                            style="margin-top: 1px; padding:6px 5px;"
                            v-model="phone"
                            type="text"
                            placeholder="Search by phone"
                            required
                            v-on:keyup.enter="fetch(1)">
                    </b-form-input>
                </b-col>
                <b-col cols="2">
                    <b-form-input
                            class="text-gray text-sm noFocus ml-1"
                            style="margin-top: 1px; padding:6px 5px;"
                            v-model="email"
                            type="text"
                            placeholder="Search by Email"
                            required
                            v-on:keyup.enter="fetch(1)">
                    </b-form-input>
                </b-col>

                <b-col cols="2">
                    <b-form-input
                            class="text-gray text-sm noFocus ml-1"
                            style="margin-top: 1px; padding:6px 5px;"
                            v-model="idNumber"
                            type="text"
                            placeholder="Id Number"
                            required
                            v-on:keyup.enter="fetch(1)">
                    </b-form-input>
                </b-col>

                <b-col cols="2">
                    <b-form-select
                            class="form-select text-sm noFocus text-gray"
                            v-model="status"
                            @change="fetch(1)"
                            v-on:keyup.enter="fetch">
                        <b-select-option :value="null">--Filter by Status--</b-select-option>
                        <b-select-option :value="null">All</b-select-option>
                        <b-select-option value="ACTIVE">Active</b-select-option>
                        <b-select-option value="INACTIVE">Inactive</b-select-option>
                    </b-form-select>
                </b-col>

                <b-col cols="2">
                    <b-form-select
                            class="form-select text-sm noFocus text-gray"
                            v-model="gender"
                            @change="fetch(1)"
                            v-on:keyup.enter="fetch(1)">
                        <b-select-option :value="null">--Filter by gender--</b-select-option>
                        <b-select-option :value="null">All</b-select-option>
                        <b-select-option value="MALE">Male</b-select-option>
                        <b-select-option value="FEMALE">Female</b-select-option>
                    </b-form-select>
                </b-col>


            </b-row>
            <b-row>
                <b-col cols="2">
                    <b-form-select
                            class="form-select text-sm noFocus text-gray"
                            v-model="idType"
                            @change="fetch(1)"
                            v-on:keyup.enter="fetch(1)">
                        <b-select-option :value="null">--Filter by ID Type--</b-select-option>
                        <b-select-option :value="null">All</b-select-option>
                        <b-select-option value="NATIONAL_ID">National Id</b-select-option>
                        <b-select-option value="PASSPORT">Passport</b-select-option>
                        <b-select-option value="ALIEN_ID">Alien ID</b-select-option>
                    </b-form-select>
                </b-col>

                <b-col cols="2" class="my-1">
                    <b-form-select
                            class="form-select text-sm noFocus text-gray"
                            v-model="nationality"
                            @change="fetch(1)"
                            v-on:keyup.enter="fetch(1)">
                        <b-select-option :value="null">--Nationality--</b-select-option>
                        <b-select-option v-for="(nationality,idx) in nationalities" :key="idx" :value="nationality">
                            {{ nationality }}
                        </b-select-option>

                    </b-form-select>
                </b-col>
            </b-row>
            <div class="mx-auto">
                <b-progress value="100" v-if="loading" variant="#007ac5" height="0.2rem" animated></b-progress>
            </div>
            <b-row class="mt-2">
                <b-col cols="12">
                    <table class="min-w-full leading-normal">
                        <thead>
                        <tr class="border-b border-t border-grey">
                            <th class="t-header">Name</th>
                            <th class="t-header">ID Number</th>
                            <th class="t-header">Email</th>
                            <th class="t-header">Phone</th>
                            <th class="t-header">Nationality</th>
                            <th class="t-header">Date Created</th>
                            <th class="t-header">Status</th>
                            <th class="t-header">Action</th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(account, idx) in accounts" :key="idx">
                            <td class="t-body font-bold text-uppercase">
                                <span>{{ account.givenName ? account.givenName : "" }}</span>
                                <span class="mx-2">{{ account.surName ? account.surName : "" }}</span>
                                <span class="mx-2" v-if="account.middleName !== 'null'">{{
                                        account.middleName ? account.middleName : ""
                                    }}</span>
                            </td>
                            <td class="t-body">
                                <div v-if="account.nationality =='Ugandan'">
                                    <span>{{ account.idNumber }}</span><br/>
                                    <small class="text-green">({{
                                            account.idType ? cleanUnderscoreString(account.idType) : ""
                                        }})</small>
                                </div>
                                <div v-else style="cursor: pointer" @click="showIdAttachment(account.id)"
                                     title="Click to view ID attachment">

                                    <span class="text-blue text-sm">
                                    <i class="fa fa-eye"></i>
                                        {{ account.idNumber }}</span><br/>
                                    <small class="text-green">({{
                                            account.idType ? cleanUnderscoreString(account.idType) : ""
                                        }})</small>
                                </div>

                            </td>
                            <td class="t-body">
                <span>
                  <i class="fa fa-check-circle text-green mx-1" v-if="account.emailVerified"></i>
                  <i class="fa fa-times text-orange mx-1" v-else></i>
                  {{ account.email }}
                </span>
                            </td>
                            <td class="t-body">
                <span>
                  <i class="fa fa-check-circle text-green mx-1" v-if="account.phoneVerified"></i>
                  <i class="fa fa-times text-orange mx-1" v-else></i>
                  {{ account.phoneNumber }}
                </span>
                            </td>
                            <td class="t-body">
                                <span>{{ account.nationality ? account.nationality : "" }}</span>
                            </td>
                            <td class="t-body"><span>{{ formatDate(account.createdAt) }}</span></td>
                            <td class="t-body font-bold">
                                <span class="text-green" v-if="account.enabled">Active</span>
                                <span class="text-red" v-else>Inactive</span>
                            </td>
                            <td class="t-body text-center">
                                <b-row>
                                    <b-col>

                                        <i class="fa fa-times mr-1 text-red"
                                           v-if="account.enabled && canViewMenuItem('can_update_account_status')"
                                           style="cursor: pointer"
                                           @click="changeStatus(account.id,'INACTIVE')"
                                           title="Click to disable account"/>
                                        <i class="fa fa-check mr-1 text-green"
                                           style="cursor: pointer"
                                           @click="changeStatus(account.id,'ACTIVE')"
                                           title="Click to activate account"
                                           v-else-if="canViewMenuItem('can_update_account_status')"/>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <i class="fa fa-reply mr-1 text-blue"
                                           v-if="!account.enabled && canViewMenuItem('can_edit_user_contacts')"
                                           style="cursor: pointer"
                                           title="Click to resend activation link "
                                           @click="resendActivation(account.id)"/>

                                        <i v-if="!account.enabled && canViewMenuItem('can_edit_user_contacts')"
                                           style="cursor: pointer" title="Click to edit account"
                                           class="fa fa-pen-square mr-1 text-gray"
                                           @click="openEditForm(account)"/>


                                    </b-col>
                                </b-row>
                            </td>
                        </tr>
                        <tr>
                            <td v-if="accounts.length === 0" colspan="12" class="bg-inertgrey text-center py-1">
                                <span class="text-sm">No results found</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </b-col>
            </b-row>

            <b-modal size="lg" title="Edit Account" ref="editAccountForm" hide-footer>
                <b-col cols="11" class="mx-auto">
                    <b-row>
                        <b-col>
                            <b-form-select :disabled="true" class="form-select my-2" v-model="editForm.idType">
                                <b-select-option value="NATIONAL_ID">National ID</b-select-option>
                            </b-form-select>
                        </b-col>
                        <b-col>
                            <b-form-input class="my-2" :disabled="true" type="text" v-model="editForm.idNumber"
                                          placeholder="National ID Number"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-input class="my-2" :disabled="true" type="text" v-model="editForm.surname"
                                          placeholder="Surname"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-input class="my-2" type="text" :disabled="true" v-model="editForm.middleName"
                                          placeholder="Middle Name"></b-form-input>
                            <b-form-input class="my-2" type="text" v-model="editForm.email"
                                          placeholder="Email Address"></b-form-input>
                        </b-col>
                        <b-col>
                            <b-form-input class="my-2" type="text" :disabled="true" v-model="editForm.givenName"
                                          placeholder="Given Name"></b-form-input>
                            <b-form-input class="my-2" type="text" v-model="editForm.phoneNumber"
                                          placeholder="Phone Number"></b-form-input>
                        </b-col>
                    </b-row>


                    <b-row>
                        <b-col cols="10"></b-col>
                        <b-col cols="2">
                            <button class="w-full bg-blue text-white px-2 py-1 rounded-md"
                                    @click="updateUserDetails">
                                <i class="fa fa-save mx-1"></i> Save changes
                            </button>
                        </b-col>
                    </b-row>
                </b-col>
            </b-modal>

            <b-modal size="lg" title="ID Preview" ref="renderIdAttachment" hide-footer>


                <a target="_blank"
                   class="text-blue text-lg-center"

                   :href="url">
                    Click this link
                </a> to View the attachment in case the attachment fails to load
                <iframe class="pdf" :src="url+'#toolbar=0&navpanes=0&scrollbar=0'"
                        height="100%"
                        width="100%">
                </iframe>
            </b-modal>

            <b-modal size="lg" title="Account profile picture" ref="signatureForm" hide-footer>
                <b-card
                        title="Crop image"
                        class="mb-2">
                    <b-card-text>
                        <VueCropper v-show="selectedFile" ref="cropper" :src="selectedFile"
                                    alt="Source Image"></VueCropper>
                    </b-card-text>
                    <div class="my-2">
                        <b-button class="float-left mx-2 text-white bg-red rounded"
                                  @click="this.$refs.signatureForm.hide()" variant="primary">Cancel
                        </b-button>
                        <b-button class="float-right mx-2 bg-blue text-white rounded"
                                  @click="saveImage(); (dialog = false) " variant="primary">Crop
                            logo
                        </b-button>
                    </div>


                </b-card>


            </b-modal>


            <b-row class="mx-1" v-if="pagination.totalPages > 1">
                <b-col cols="1">
                    <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
                </b-col>
                <b-col cols="1">
                    <b-row>
                        <b-col cols="12">
                            <p class="paginate-details text-center">
                                <b-badge class="bg-blue text-white">
                                    {{ (currentPage) }}/{{ pagination.totalPages }}
                                </b-badge>
                            </p>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="1">
                    <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">
                        Next
                    </button>
                </b-col>
                <b-col class="9"></b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import server from "@/app/env/server.json"
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
// import ResidenceOfPartner from "@/views/home/services/accounts/breakdown/ResidenceofPartner.vue";

export default {
    name: "ClientAccounts",
    components: {VueCropper},
    data() {
        return {
            loading: false,
            currentPage: 1,
            limit: 10,
            accounts: [],
            pagination: {
                totalElements: 0,
                totalPages: 0
            },
            rows: {
                odd: "border-t border-grey hover:bg-header_grey",
                even: "border-t border-grey bg-header_grey"
            },
            search: "",
            status: null,
            email: null,
            phone: null,
            name: null,
            gender: null,
            idType: null,
            idNumber: null,
            nationality: null,
            nationalities: [
                "Ugandan",
                "Afghan",
                "Albanian",
                "Algerian",
                "American",
                "Andorran",
                "Angolan",
                "Antiguans",
                "Argentinean",
                "Armenian",
                "Australian",
                "Austrian",
                "Azerbaijani",
                "Bahamian",
                "Bahraini",
                "Bangladeshi",
                "Barbadian",
                "Barbudans",
                "Batswana",
                "Belarusian",
                "Belgian",
                "Belizean",
                "Beninese",
                "Bhutanese",
                "Bolivian",
                "Bosnian",
                "Brazilian",
                "British",
                "Bruneian",
                "Bulgarian",
                "Burkinabe",
                "Burmese",
                "Burundian",
                "Cambodian",
                "Cameroonian",
                "Canadian",
                "Cape Verdean",
                "Central African",
                "Chadian",
                "Chilean",
                "Chinese",
                "Colombian",
                "Comoran",
                "Congolese",
                "Costa Rican",
                "Croatian",
                "Cuban",
                "Cypriot",
                "Czech",
                "Danish",
                "Djibouti",
                "Dominican",
                "Dutch",
                "East Timorese",
                "Ecuadorean",
                "Egyptian",
                "Emirian",
                "Equatorial Guinean",
                "Eritrean",
                "Estonian",
                "Ethiopian",
                "Fijian",
                "Filipino",
                "Finnish",
                "French",
                "Gabonese",
                "Gambian",
                "Georgian",
                "German",
                "Ghanaian",
                "Greek",
                "Grenadian",
                "Guatemalan",
                "Guinea-Bissauan",
                "Guinean",
                "Guyanese",
                "Haitian",
                "Herzegovinian",
                "Honduran",
                "Hungarian",
                "I-Kiribati",
                "Icelander",
                "Indian",
                "Indonesian",
                "Iranian",
                "Iraqi",
                "Irish",
                "Israeli",
                "Italian",
                "Ivorian",
                "Jamaican",
                "Japanese",
                "Jordanian",
                "Kazakhstani",
                "Kenyan",
                "Kittian and Nevisian",
                "Kuwaiti",
                "Kyrgyz",
                "Laotian",
                "Latvian",
                "Lebanese",
                "Liberian",
                "Libyan",
                "Liechtensteiner",
                "Lithuanian",
                "Luxembourger",
                "Macedonian",
                "Malagasy",
                "Malawian",
                "Malaysian",
                "Maldivan",
                "Malian",
                "Maltese",
                "Marshallese",
                "Mauritanian",
                "Mauritian",
                "Mexican",
                "Micronesian",
                "Moldovan",
                "Monacan",
                "Mongolian",
                "Moroccan",
                "Mosotho",
                "Motswana",
                "Mozambican",
                "Namibian",
                "Nauruan",
                "Nepalese",
                "New Zealander",
                "Nicaraguan",
                "Nigerian",
                "Nigerien",
                "North Korean",
                "Northern Irish",
                "Norwegian",
                "Omani",
                "Pakistani",
                "Palauan",
                "Panamanian",
                "Papua New Guinean",
                "Paraguayan",
                "Peruvian",
                "Polish",
                "Portuguese",
                "Qatari",
                "Romanian",
                "Russian",
                "Rwandan",
                "Saint Lucian",
                "Salvadoran",
                "Samoan",
                "San Marinese",
                "Sao Tomean",
                "Saudi",
                "Scottish",
                "Senegalese",
                "Serbian",
                "Seychellois",
                "Sierra Leonean",
                "Singaporean",
                "Slovakian",
                "Slovenian",
                "Solomon Islander",
                "Somali",
                "South African",
                "South Korean",
                "Spanish",
                "Sri Lankan",
                "Sudanese",
                "Surinamer",
                "Swazi",
                "Swedish",
                "Swiss",
                "Syrian",
                "Taiwanese",
                "Tajik",
                "Tanzanian",
                "Thai",
                "Togolese",
                "Tongan",
                "Trinidadian or Tobagonian",
                "Tunisian",
                "Turkish",
                "Tuvaluan",
                "Ukrainian",
                "Uruguayan",
                "Uzbekistani",
                "Venezuelan",
                "Vietnamese",
                "Welsh",
                "Yemenite",
                "Zambian",
                "Zimbabwean"
            ],
            type: null,
            editForm: {
                userId: null,
                idType: "NATIONAL_ID",
                gender: null,
                surname: null,
                givenName: null,
                middleName: null,
                email: null,
                phoneNumber: null,
                roleId: null,
                nationality: "UG",
                idNumber: null,
                idAttachment: null,
                signature: null,
                departmentId: null,
                dob: null
            },
            url: null,
            insolvency: {
                name: null,
                street: null,
                plot: null,
                passportPhoto: null,
                villageId: null,
                upn: null
            },
            mime_type: "",
            cropedImage: "",
            autoCrop: false,
            selectedFile: "",
            image: "",
            dialog: false,
            files: "",
            rawfile: null,
            userId: null,
            userName: null
        }
    },
    mounted() {
        this.fetch(1)
    },
    methods: {
        ...mapActions(["fetchClientAccounts", "fetchUserDetails", "doResendActivationLink", "changeAccountStatus", "updateStaffContacts"]),
        fetch(startPage = null) {
            this.loading = true
            let filters = {"accountType": "INDIVIDUAL"}

            if (this.status) {
                filters.accountStatus = this.status
            }
            if (this.gender) {
                filters.gender = this.gender
            }

            if (this.idType) {
                filters.idType = this.idType
            }

            if (this.name) {
                filters.name = this.name
            }

            if (this.email) {
                filters.email = this.email
            }

            if (this.phone) {
                filters.phone = this.phone
            }


            if (this.idNumber) {
                filters.idNumber = this.idNumber
            }


            if (this.nationality) {
                filters.nationality = this.nationality
            }

            filters.page = startPage ? startPage : this.currentPage
            filters.perPage = this.limit

            this.fetchClientAccounts(this.objectToQueryParams(filters))
        },
        next() {
            if (this.currentPage < this.pagination.totalPages) {
                this.currentPage++
                this.fetch()
            }
        },

        resendActivation(userId) {
            this.doResendActivationLink({userId})

        },
        previous() {
            if (this.currentPage > 1) {
                this.currentPage--
                this.fetch()
            } else {
                this.warningToast("Observation", "No more data to show")
            }
        },
        async changeStatus(userId, status) {
            this.showConfirmDialog().then(value => {
                if (value) {
                    this.changeAccountStatus({userId, status})
                }
            })

        },
        openEditForm(account) {
            this.editForm = {
                userId: account.id,
                idType: "NATIONAL_ID",
                gender: account.gender,
                surname: account.surName,
                givenName: account.givenName,
                middleName: account.middleName,
                email: account.email,
                phoneNumber: account.phoneNumber,
                roleId: account.roleId,
                nationality: account.nationality,
                idNumber: account.idNumber,
                idAttachment: account.idAttachment,
                signature: account.signature,
                departmentId: account.departmentId,
                dob: account.dob
            };
            this.$refs.editAccountForm.show();
        },
        closeEditForm() {
            this.$refs.editAccountForm.hide();
        },
        updateUserDetails() {
            let data = {
                "email": this.editForm.email,
                "phone": this.editForm.phoneNumber,
                "userId": this.editForm.userId
            }

            this.updateStaffContacts(data)


        },
        showIdAttachment(userId) {
            this.fetchUserDetails(userId);

        },
        canViewMenuItem(permission) {
            return this.$can(permission, "general") === true;
        },
        saveImage() {
            this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {

                this.rawfile = blob;


            }, this.mime_type);

            this.$refs.signatureForm.hide();
        },
        onFileSelect(e) {
            this.showCropper();
            const file = e.target.files[0];
            this.mime_type = file.type;

            if (typeof FileReader === "function") {
                this.dialog = true;
                const reader = new FileReader();
                reader.onload = (event) => {
                    this.selectedFile = event.target.result;
                    this.$refs.cropper.replace(this.selectedFile);
                };
                reader.readAsDataURL(file);
            } else {
                alert("Sorry, FileReader API not supported");
            }
        },
        showReceiverForm(account) {
            this.userName = account.givenName + " " + account.surName
            this.userId = account.id
            this.$refs.makeReceiverForm.show();
        },
        showCropper() {
            this.$refs.signatureForm.show();
        },

        setVillageId(data) {
            this.insolvency.villageId = data.villageId ? data.villageId : null
        },
        cancelAction() {
            this.$refs.makeReceiverForm.hide();
        },
    },
    computed: {
        ...mapGetters(["getUserAccounts", "getReactivationError",
            "getReactivationSuccess", "getUserDetails"])
    },
    watch: {
        getUserAccounts(data) {
            this.loading = false
            this.accounts = data.data.content
            this.pagination = {
                totalPages: data.data.totalPages,
                totalElements: data.data.totalElements
            };
        },
        getReactivationSuccess(data) {
            this.successToast("Success", data.message);
            this.loading = true;
            this.closeEditForm();
            this.fetch();
        },
        getReactivationError(error) {
            if (error) {
                this.errorToast(error.message, error.message);
            }
        },
        getUserDetails(data) {
            this.$refs.renderIdAttachment.show();
            this.url = server.IP + "/auth/api/users/downloadFile/attachment/" + data.attachmentUrl


        }
    }
};
</script>

<style scoped>
.paginate-details {
    @apply px-3 py-2 bg-white my-2 text-sm text-gray;
}

.paginate-btn {
    @apply px-3 py-2 bg-white border border-white my-2 text-sm text-blue w-full;
}

.paginate-btn:hover {
    @apply border border-blue text-blue rounded-sm;
}


.inert-btn {
    @apply px-3 py-2 bg-white my-2 text-sm text-grey w-full;
}


.pdf {
    width: 100%;
    height: 1000px;
    overflow-x: auto;
    border: 1px solid #b1b1b1;
}
</style>