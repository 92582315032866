<template>
    <div class="bg-white rounded-sm text-gray mx-2 my-3">
        <b-row>
            <b-modal ref="addCountry" title="Add country" hide-footer>
                <NewCountry/>
            </b-modal>

            <b-modal ref="editCountry" title="Edit country" hide-footer>
                <div>
                    <b-row class="my-3">
                        <b-col>
                            <b-form-input v-model="form.name"
                                          placeholder="Country Name" required>
                            </b-form-input>
                        </b-col>
                    </b-row>
                    <b-row class="my-3">
                        <b-col>
                            <b-form-input v-model="form.code"
                                          placeholder="Country code eg . UG, US" required>
                            </b-form-input>

                            <input type="hidden" v-model="form.id">
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="8"></b-col>
                        <b-col cols="4" class="float-right">
                            <button @click="newCountry" class="w-full bg-blue text-white px-2 py-1 rounded-sm">
                                Save
                                <b-spinner v-if="spinner" small></b-spinner>
                            </button>
                        </b-col>
                    </b-row>
                </div>
            </b-modal>
        </b-row>
        <b-row>
            <b-col>
                <b-row>
                    <b-col cols="10"></b-col>
                    <b-col cols="2">
                        <button class="w-full bg-blue text-white px-2 py-1 rounded-sm" @click="openForm"><i
                                class="fa fa-plus"></i>
                            Add country
                        </button>
                    </b-col>
                </b-row>
                <b-row class="my-3">
                    <b-col>
                        <table class="min-w-full leading-normal">
                            <thead>
                            <tr class="border-b border-t border-grey">
                                <th class="t-header">#</th>
                                <th class="t-header" width="85%">Name</th>
                                <th class="t-header text-center">Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(country,idx) in countries"
                                :key="country.id">
                                <td class="t-body">{{ idx + 1 }}</td>
                                <td class="t-body font-bold text-capitalize">{{ country.name }}</td>
                                <td class="t-body font-bold">
                                    <b-row>
                                        <b-col>
                                            <button class="w-full bg-green text-white text-xs px-2 py-1 rounded-sm"><i
                                                    class="fa fa-pencil"></i> Edit
                                            </button>
                                        </b-col>
                                        <!--                    <b-col>
                                                              <button class="w-full bg-red text-white text-xs px-2 py-1 rounded-sm"><i class="fa fa-trash"></i> Delete</button>
                                                            </b-col>-->
                                    </b-row>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import NewCountry from "@/views/home/services/settings/countries/NewCountry";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "Countries",
    components: {
        NewCountry
    },
    data() {
        return {
            registerDepartment: false,
            newDepartment: false,
            countries: [],
            rows: {
                odd: "border-t border-grey hover:bg-header_grey",
                even: "border-t border-grey bg-header_grey"
            },
            form: {
                id: null,
                code: null,
                name: null

            }
        };
    },
    mounted() {
        this.fetchCountries();
    },

    methods: {
        ...mapActions(["fetchCountries"]),
        openForm() {
            this.$refs.addCountry.show();
        }
    }
    ,
    computed: {
        ...mapGetters([
            "getCountry",
            "getCountries",
            "getCountryError"
        ])
    },

    watch: {
        getCountries(countries) {
            this.countries = countries;
        },
        getCountry() {
            this.$refs.addCountry.hide();
        }

    }

};
</script>

<style scoped>

</style>