<template>
  <b-row>
    <b-col>
      <b-row>
        <b-modal ref="details" size="xl" title="Details of Process" hide-footer>
          <b-row>
             <b-col>
               <b-row>
                 <b-col>
                   <p><b>BRN:</b> {{process.brn}}</p>
                   <p><b>Type of Issue:</b> {{process.type}}</p>
                   <p><b>Entity Name:</b> {{process.owner.name}}</p>
                   <p><b>Action ID Token:</b> {{process.actionIdToken}}</p>
                   <p><b>Number of Retires:</b> {{process.retryCount}}</p>
                   <p><b>Date Time:</b> {{dateFormat(process.createdAt)}}</p>
                   <p><b>Endpoint:</b> <i class="border-2 border-green text-green text-sm rounded px-1">{{process.endPoint}}</i></p>
                 </b-col>
               </b-row>
               <b-row class="mt-4">
                 <b-col>
                   <b-row>
                     <b-col>
                       <p class="font-bold">Request</p>
                       <pre v-if="process.request" class="text-green text-xs bg-header_grey border-2 border-green p-2 rounded">
                         {{JSON.parse(process.request)}}
                       </pre>
                     </b-col>
                   </b-row>
                   <b-row>
                     <b-col>
                       <p class="font-bold">Response</p>
                       <p v-if="process.response" class="text-red text-xs bg-header_grey border-2 border-red p-2 rounded">
                         {{process.response}}
                       </p>
                     </b-col>
                   </b-row>
                 </b-col>
               </b-row>
             </b-col>
          </b-row>
        </b-modal>
      </b-row>
        <b-row class="mt-3 px-1">
            <b-col cols="2">
                <b-row>
                    <b-col class="cursor-pointer"
                           :class="(tab === 'processes') ? 'bg-blue font-bold text-white rounded-r px-2 py-1 my-1' : 'border border-blue rounded-r px-2 py-1 my-1'"
                           @click="selectTab('processes')">
                      <span>
                          <b>Processes</b>
                      </span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="cursor-pointer"
                           :class="(tab === 'statistics') ? 'bg-blue font-bold text-white rounded-r px-2 py-1 my-1' : 'border border-blue rounded-r px-2 py-1 my-1'"
                           @click="selectTab('statistics')">
                      <span>
                          <b>Statistics</b>
                      </span>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="10">
                <b-row v-if="tab === 'statistics'">
                    <b-col v-if="stats">
                        <b-row class="m-2">
                            <b-col cols="3" class="p-3 m-2 stat-count shadow bg-white rounded-md" v-if="stats.all">
                                <b-row>
                                    <b-col>
                                        <div class="waiting">
                                            <p class="text-xs mx-auto">Pending</p>
                                            <p class="text-xs mx-auto">Sent</p>
                                            <p class="text-xs mx-auto">Paused</p>
                                            <p class="text-xs mx-auto">Expired</p>
                                        </div>
                                    </b-col>
                                    <b-col>
                                        <div class="waiting">
                                            <p class="text-xs mx-auto font-bold text-gray">{{ stats.all.pending }}</p>
                                            <p class="text-xs mx-auto font-bold text-green">{{ stats.all.sent }}</p>
                                            <p class="text-xs mx-auto font-bold text-blue">{{ stats.all.paused }}</p>
                                            <p class="text-xs mx-auto font-bold text-red">{{ stats.all.expired }}</p>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <div class="waiting mt-2">
                                            <span class="text-xs mx-auto font-bold">All</span>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="3" class="p-3 m-2 stat-count shadow bg-white rounded-md"
                                   v-if="stats.certificates">
                                <b-row>
                                    <b-col>
                                        <div class="waiting">
                                            <p class="text-xs mx-auto">Pending</p>
                                            <p class="text-xs mx-auto">Sent</p>
                                            <p class="text-xs mx-auto">Paused</p>
                                            <p class="text-xs mx-auto">Expired</p>
                                        </div>
                                    </b-col>
                                    <b-col>
                                        <div class="waiting">
                                            <p class="text-xs mx-auto font-bold text-gray">{{
                                                    stats.certificates.pending
                                                }}</p>
                                            <p class="text-xs mx-auto font-bold text-green">{{
                                                    stats.certificates.sent
                                                }}</p>
                                            <p class="text-xs mx-auto font-bold text-blue">{{
                                                    stats.certificates.paused
                                                }}</p>
                                            <p class="text-xs mx-auto font-bold text-red">{{
                                                    stats.certificates.expired
                                                }}</p>
                                        </div>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col>
                                        <div class="waiting mt-2">
                                            <span class="text-sm mx-auto font-bold">Certificates</span>
                                        </div>
                                    </b-col>
                                </b-row>

                            </b-col>
                            <b-col cols="3" class="p-3 m-2 stat-count shadow bg-white rounded-md"
                                   v-if="stats.digitalSigning">

                                <b-row>
                                    <b-col>
                                        <div class="waiting">
                                            <p class="text-xs mx-auto">Pending</p>
                                            <p class="text-xs mx-auto">Sent</p>
                                            <p class="text-xs mx-auto">Paused</p>
                                            <p class="text-xs mx-auto">Expired</p>
                                        </div>
                                    </b-col>
                                    <b-col>
                                        <div class="waiting">
                                            <p class="text-xs mx-auto font-bold text-gray">
                                                {{ stats.digitalSigning.pending }}</p>
                                            <p class="text-xs mx-auto font-bold text-green">{{
                                                    stats.digitalSigning.sent
                                                }}</p>
                                            <p class="text-xs mx-auto font-bold text-blue">
                                                {{ stats.digitalSigning.paused }}</p>
                                            <p class="text-xs mx-auto font-bold text-red">
                                                {{ stats.digitalSigning.expired }}</p>
                                        </div>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col>
                                        <div class="waiting mt-2">
                                            <span class="text-xs mx-auto font-bold">Digital Signing</span>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="3" class="p-3 m-2 stat-count shadow bg-white rounded-md" v-if="stats.edms">
                                <b-row>
                                    <b-col>
                                        <div class="waiting">
                                            <p class="text-xs mx-auto">Pending</p>
                                            <p class="text-xs mx-auto">Sent</p>
                                            <p class="text-xs mx-auto">Paused</p>
                                            <p class="text-xs mx-auto">Expired</p>
                                        </div>
                                    </b-col>
                                    <b-col>
                                        <div class="waiting">
                                            <p class="text-xs mx-auto font-bold text-gray">{{ stats.edms.pending }}</p>
                                            <p class="text-xs mx-auto font-bold text-green">{{ stats.edms.sent }}</p>
                                            <p class="text-xs mx-auto font-bold text-blue">{{ stats.edms.paused }}</p>
                                            <p class="text-xs mx-auto font-bold text-red">{{ stats.edms.expired }}</p>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <div class="waiting mt-2">
                                            <span class="text-xs mx-auto font-bold">Digital Signing</span>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="3" class="p-3 m-2 stat-count shadow bg-white rounded-md"
                                   v-if="stats.businessAccount">
                                <b-row>
                                    <b-col>
                                        <div class="waiting">
                                            <p class="text-xs mx-auto">Pending</p>
                                            <p class="text-xs mx-auto">Sent</p>
                                            <p class="text-xs mx-auto">Paused</p>
                                            <p class="text-xs mx-auto">Expired</p>
                                        </div>
                                    </b-col>
                                    <b-col>
                                        <div class="waiting">
                                            <p class="text-xs mx-auto font-bold text-gray">
                                                {{ stats.businessAccount.pending }}</p>
                                            <p class="text-xs mx-auto font-bold text-green">
                                                {{ stats.businessAccount.sent }}</p>
                                            <p class="text-xs mx-auto font-bold text-blue">
                                                {{ stats.businessAccount.paused }}</p>
                                            <p class="text-xs mx-auto font-bold text-red">
                                                {{ stats.businessAccount.expired }}</p>
                                        </div>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col>
                                        <div class="waiting mt-2">
                                            <span class="text-xs mx-auto font-bold">Business Account</span>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="3" class="p-3 m-2 stat-count shadow bg-white rounded-md"
                                   v-if="stats.businessAccountSubType">
                                <b-row>
                                    <b-col>
                                        <div class="waiting">
                                            <p class="text-xs mx-auto">Pending</p>
                                            <p class="text-xs mx-auto">Sent</p>
                                            <p class="text-xs mx-auto">Paused</p>
                                            <p class="text-xs mx-auto">Expired</p>
                                        </div>
                                    </b-col>
                                    <b-col>
                                        <div class="waiting">
                                            <p class="text-xs mx-auto font-bold text-gray">
                                                {{ stats.businessAccountSubType.pending }}</p>
                                            <p class="text-xs mx-auto font-bold text-green">
                                                {{ stats.businessAccountSubType.sent }}</p>
                                            <p class="text-xs mx-auto font-bold text-blue">
                                                {{ stats.businessAccountSubType.paused }}</p>
                                            <p class="text-xs mx-auto font-bold text-red">
                                                {{ stats.businessAccountSubType.expired }}</p>
                                        </div>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col>
                                        <div class="waiting mt-2">
                                            <span class="text-xs mx-auto font-bold">Business Account SubType</span>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="3" class="p-3 m-2 stat-count shadow bg-white rounded-md"
                                   v-if="stats.businessAccountName">
                                <b-row>
                                    <b-col>
                                        <div class="waiting">
                                            <p class="text-xs mx-auto">Pending</p>
                                            <p class="text-xs mx-auto">Sent</p>
                                            <p class="text-xs mx-auto">Paused</p>
                                            <p class="text-xs mx-auto">Expired</p>
                                        </div>
                                    </b-col>
                                    <b-col>
                                        <div class="waiting">
                                            <p class="text-xs mx-auto font-bold text-gray">
                                                {{ stats.businessAccountName.pending }}</p>
                                            <p class="text-xs mx-auto font-bold text-green">
                                                {{ stats.businessAccountName.sent }}</p>
                                            <p class="text-xs mx-auto font-bold text-blue">
                                                {{ stats.businessAccountName.paused }}</p>
                                            <p class="text-xs mx-auto font-bold text-red">
                                                {{ stats.businessAccountName.expired }}</p>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <div class="waiting mt-2">
                                            <span class="text-xs mx-auto font-bold">Business Account Name</span>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="3" class="p-3 m-2 stat-count shadow bg-white rounded-md"
                                   v-if="stats.centralRegistry">
                                <b-row>
                                    <b-col>
                                        <div class="waiting">
                                            <p class="text-xs mx-auto">Pending</p>
                                            <p class="text-xs mx-auto">Sent</p>
                                            <p class="text-xs mx-auto">Paused</p>
                                            <p class="text-xs mx-auto">Expired</p>
                                        </div>
                                    </b-col>
                                    <b-col>
                                        <div class="waiting">
                                            <p class="text-xs mx-auto font-bold text-gray">
                                                {{ stats.centralRegistry.pending }}</p>
                                            <p class="text-xs mx-auto font-bold text-green">
                                                {{ stats.centralRegistry.sent }}</p>
                                            <p class="text-xs mx-auto font-bold text-blue">
                                                {{ stats.centralRegistry.paused }}</p>
                                            <p class="text-xs mx-auto font-bold text-red">
                                                {{ stats.centralRegistry.expired }}</p>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <div class="waiting mt-2">
                                            <span class="text-xs mx-auto font-bold">Registry</span>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="3" class="p-3 m-2 stat-count shadow bg-white rounded-md"
                                   v-if="stats.pushDocumentIdToCentralRegistry">
                                <b-row>
                                    <b-col>
                                        <div class="waiting">
                                            <p class="text-xs mx-auto">Pending</p>
                                            <p class="text-xs mx-auto">Sent</p>
                                            <p class="text-xs mx-auto">Paused</p>
                                            <p class="text-xs mx-auto">Expired</p>
                                        </div>
                                    </b-col>
                                    <b-col>
                                        <div class="waiting">
                                            <p class="text-xs mx-auto font-bold text-gray">
                                                {{ stats.pushDocumentIdToCentralRegistry.pending }}</p>
                                            <p class="text-xs mx-auto font-bold text-green">
                                                {{ stats.pushDocumentIdToCentralRegistry.sent }}</p>
                                            <p class="text-xs mx-auto font-bold text-blue">
                                                {{ stats.pushDocumentIdToCentralRegistry.paused }}</p>
                                            <p class="text-xs mx-auto font-bold text-red">
                                                {{ stats.pushDocumentIdToCentralRegistry.expired }}</p>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <div class="waiting mt-2">
                                            <span class="text-xs mx-auto font-bold">DocumentId To Registry</span>
                                        </div>
                                    </b-col>
                                </b-row>

                            </b-col>
                            <b-col cols="3" class="p-3 m-2 stat-count shadow bg-white rounded-md"
                                   v-if="stats.legalDocuments">
                                <b-row>
                                    <b-col>
                                        <div class="waiting">
                                            <p class="text-xs mx-auto">Pending</p>
                                            <p class="text-xs mx-auto">Sent</p>
                                            <p class="text-xs mx-auto">Paused</p>
                                            <p class="text-xs mx-auto">Expired</p>
                                        </div>
                                    </b-col>
                                    <b-col>
                                        <div class="waiting">
                                            <p class="text-xs mx-auto font-bold text-gray">
                                                {{ stats.legalDocuments.pending }}</p>
                                            <p class="text-xs mx-auto font-bold text-green">{{
                                                    stats.legalDocuments.sent
                                                }}</p>
                                            <p class="text-xs mx-auto font-bold text-blue">
                                                {{ stats.legalDocuments.paused }}</p>
                                            <p class="text-xs mx-auto font-bold text-red">
                                                {{ stats.legalDocuments.expired }}</p>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <div class="waiting mt-2">
                                            <span class="text-xs mx-auto font-bold">Legal Documents</span>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="3" class="p-3 m-2 stat-count shadow bg-white rounded-md"
                                   v-if="stats.instantTin">
                                <b-row>
                                    <b-col>
                                        <div class="waiting">
                                            <p class="text-xs mx-auto">Pending</p>
                                            <p class="text-xs mx-auto">Sent</p>
                                            <p class="text-xs mx-auto">Paused</p>
                                            <p class="text-xs mx-auto">Expired</p>
                                        </div>
                                    </b-col>
                                    <b-col>
                                        <div class="waiting">
                                            <p class="text-xs mx-auto font-bold text-gray">{{
                                                    stats.instantTin.pending
                                                }}</p>
                                            <p class="text-xs mx-auto font-bold text-green">{{
                                                    stats.instantTin.sent
                                                }}</p>
                                            <p class="text-xs mx-auto font-bold text-blue">{{
                                                    stats.instantTin.paused
                                                }}</p>
                                            <p class="text-xs mx-auto font-bold text-red">{{
                                                    stats.instantTin.expired
                                                }}</p>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <div class="waiting mt-2">
                                            <span class="text-xs mx-auto font-bold">Instant Tin</span>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="3" class="p-3 m-2 stat-count shadow bg-white rounded-md"
                                   v-if="stats.updateCentralRegistry">
                                <b-row>
                                    <b-col>
                                        <div class="waiting">
                                            <p class="text-xs mx-auto">Pending</p>
                                            <p class="text-xs mx-auto">In Progress</p>
                                            <p class="text-xs mx-auto">Sent</p>
                                            <p class="text-xs mx-auto">Paused</p>
                                            <p class="text-xs mx-auto">Expired</p>
                                        </div>
                                    </b-col>
                                    <b-col>
                                        <div class="waiting">
                                            <p class="text-xs mx-auto font-bold text-gray">
                                                {{ stats.updateCentralRegistry.pending }}</p>
                                            <p class="text-xs mx-auto font-bold text-blue">
                                                {{ stats.updateCentralRegistry.in_progress }}</p>
                                            <p class="text-xs mx-auto font-bold text-green">
                                                {{ stats.updateCentralRegistry.sent }}</p>
                                            <p class="text-xs mx-auto font-bold text-blue">
                                                {{ stats.updateCentralRegistry.paused }}</p>
                                            <p class="text-xs mx-auto font-bold text-red">
                                                {{ stats.updateCentralRegistry.expired }}</p>
                                        </div>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col>
                                        <div class="waiting mt-2">
                                            <span class="text-xs mx-auto font-bold">Update Registry</span>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row v-if="tab === 'processes'">
                    <b-col>
                        <b-row>
                            <b-col cols="2">
                                <b-form-input
                                        class="text-gray text-sm noFocus mx-2 my-2"
                                        style="padding:6px 5px;"
                                        v-model="search"
                                        type="text"
                                        placeholder="Search by BRN."
                                        required
                                        v-on:keyup.enter="fetch">
                                </b-form-input>
                            </b-col>
                            <b-col cols="2">
                                <b-form-group>
                                    <b-form-select
                                            v-model="statuses"
                                            v-on:change="fetch"
                                            class="form-select noFocus text-gray mx-2 my-2 text-xs">
                                        <b-select-option :value="null">--Filter by Status--</b-select-option>
                                        <b-select-option value="PENDING">Pending</b-select-option>
                                        <b-select-option value="PAUSED">Paused</b-select-option>
                                        <b-select-option value="IN_PROGRESS">In Progress</b-select-option>
                                        <b-select-option value="SENT">Sent</b-select-option>
                                        <b-select-option value="EXPIRED">Expired</b-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col cols="2">
                                <b-form-input class="form-text text-xs mt-2" type="date" v-on:change="fetch"
                                              v-model="startDate"></b-form-input>
                            </b-col>
                            <b-col cols="2">
                                <b-form-input class="form-text text-xs mt-2" type="date" v-on:change="fetch"
                                              v-model="endDate"></b-form-input>
                            </b-col>
                            <b-col cols="1">
                                <b-form-select v-model="limit"
                                               class="w-full text-xs form-select border-0 paginate-details"
                                               v-on:change="fetch">
                                    <b-select-option value="12">12</b-select-option>
                                    <b-select-option value="20">20</b-select-option>
                                    <b-select-option value="50">50</b-select-option>
                                    <b-select-option value="100">100</b-select-option>
                                </b-form-select>
                            </b-col>
                            <b-col cols="2">
                                <b-row>
                                    <b-col class="mx-auto">
                                        <p class="paginate-details text-center text-xs font-bold">
                                            <span>{{ ((currentPage - 1) * limit) + 1 }}</span> -
                                            <span v-if="limit > stream.length ">{{ pagination.totalElements }}</span>
                                            <span v-else>{{ currentPage * limit }}</span>
                                            of {{ pagination.totalElements }}
                                        </p>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <div class="mx-auto">
                            <b-progress value="100" v-if="loading" variant="#007ac5" height="0.2rem"
                                        animated></b-progress>
                        </div>
                        <b-row>
                            <b-col>
                                <table class="min-w-full leading-normal">
                                    <thead>
                                    <tr class="border-b border-t border-grey text-sm">
                                        <th class="t-header">#</th>
                                        <th class="t-header">BRN</th>
                                        <th class="t-header">Type</th>
                                        <th class="t-header text-center">Retry Count</th>
                                        <th class="t-header text-center">Status</th>
                                        <th class="t-header text-center">Date</th>
                                        <th class="t-header text-center">Last Ran</th>
                                        <th class="t-header text-center">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(action, idx) in stream"
                                        :key="idx">
                                        <td class="t-body">{{ (currentPage - 1) * limit + idx + 1 }}</td>
                                        <td class="t-body font-bold">
                                            <p class="cursor-pointer hover:underline" @click="searchTerm(action.brn)">
                                                {{ action.brn }}</p>
                                        </td>
                                        <td class="t-body">
                                            <p class="italic text-blue cursor-pointer hover:underline"
                                               @click="searchTerm(action.type)">
                                                {{ action.type }}
                                            </p>
                                        </td>
                                        <td class="t-body text-center">{{ action.retryCount }}</td>
                                        <td class="t-body text-center">
                                            <p class="text-xs px-2 py-1 text-capitalize text-center"
                                               style="background: #f1f1f1">
                                            <span :class="(action.status === 'SENT') ? 'text-green' :
                                                          (action.status === 'EXPIRED') ? 'text-red' :
                                                          (action.status === 'IN_PROGRESS') ? 'text-blue' :
                                                          (action.status === 'PENDING') ? 'text-gray' :
                                                          (action.status === 'PAUSED')? 'text-orange': ''">
                                               {{ action.status.toLowerCase() }}
                                            </span>
                                            </p>
                                        </td>
                                        <td class="t-body text-center text-xs">{{ dateFormat(action.createdAt) }}</td>
                                        <td class="t-body text-center text-xs">{{ dateFormat(action.lastRanAt) }}</td>
                                        <td class="t-body text-center text-xs">
                                            <button class="bg-red px-2 py-1 rounded-sm text-white mx-1"
                                                    v-if="action.status === 'EXPIRED' || action.status === 'PAUSED'"
                                                    @click="redo(action, idx)">
                                                <i class="fa fa-redo mx-1"></i>
                                                <b-spinner v-if="loadingItem && selectedIdx === idx" small></b-spinner>
                                            </button>
                                            <button class="bg-blue px-2 py-1 rounded-sm text-white"
                                                    @click="view(action)">
                                                <i class="fa fa-eye mx-1"></i>
                                            </button>
                                            <button class="bg-green px-2 py-1 mx-1 rounded-sm text-white"
                                                    @click="runNow(action, idx)">
                                                <i class="fa fa-play mx-1"></i>
                                                <b-spinner v-if="loadingRunItem && selectedIdx === idx"
                                                           small></b-spinner>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td v-if="stream.length === 0" colspan="12"
                                            class="bg-inertgrey text-center py-1">
                                            <span class="text-sm">No results found</span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </b-col>
                        </b-row>
                        <b-row class="mx-1" v-if="pagination.totalPages > 1">
                            <b-col cols="1">
                                <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">
                                    Previous
                                </button>
                            </b-col>
                            <b-col cols="1">
                                <b-row>
                                    <b-col cols="12">
                                        <p class="paginate-details text-center">
                                            <b-badge class="bg-blue text-white">
                                                {{ currentPage }}/{{ pagination.totalPages }}
                                            </b-badge>
                                        </p>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="1">
                                <button @click="next"
                                        :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">
                                    Next
                                </button>
                            </b-col>
                            <b-col cols="6"></b-col>
                            <b-col cols="1">
                                <b-form-select v-model="limit" class="w-full form-select border-0 paginate-details"
                                               v-on:change="fetch">
                                    <b-select-option value="12">12</b-select-option>
                                    <b-select-option value="20">20</b-select-option>
                                    <b-select-option value="50">50</b-select-option>
                                    <b-select-option value="100">100</b-select-option>
                                </b-form-select>
                            </b-col>
                            <b-col cols="2">
                                <b-row>
                                    <b-col class="mx-auto">
                                        <p class="paginate-details text-center text-xs font-bold">
                                            <span v-if="currentPage === 1">1</span>
                                            <span v-else>{{ ((currentPage - 1) * limit) + 1 }}</span> -
                                            {{ currentPage * limit }} of {{ pagination.totalElements }}
                                        </p>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
      </b-row>


    </b-col>
  </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment/moment";

export default {
  name: "IncorporationService",
  data(){
    return{
      loading:false,
      loadingItem:false,
      loadingRunItem:false,
      selectedIdx:null,
      currentPage: 1,
      limit: 12,
      stream:[],
      pagination: {
        totalElements: 0,
        totalPages: 0
      },
        tab: "processes",
      startDate:null,
      endDate:null,
      statuses:null,
      process:{
        brn:null,
        owner:{name:null},
        createdAt:null,
        type:null,
        endPoint:null,
        request:null,
        response:null
      },
      stats:null,
      search: "",
      rows: {
        odd: "border-t border-grey hover:bg-header_grey",
        even: "border-t border-grey bg-header_grey"
      },
    }
  },
  mounted() {
    this.fetch()
  },
  methods:{
    ...mapActions(["fetchIncorporationProcessesStream", "fetchIncorporationProcessStatistics", "fetchIncorporationProcessesPending", "fetchIncorporationProcessesFailed", "updateProcessStatus", "runProcessNow"]),
    fetch(){
      this.loading = true
      let options = this.statuses
      if(this.statuses === null){
          options = "PENDING,FAILED,PAUSED,IN_PROGRESS"
      }
      if(this.search !== ""){
        this.currentPage = 1
      }
      let data = {
        search: this.search,
        statuses:options,
        page:this.currentPage,
        startDate:this.startDate,
        endDate:this.endDate,
        limit:this.limit,
      }
      this.fetchIncorporationProcessesStream(data)
      this.fetchIncorporationProcessStatistics()
        // this.fetchIncorporationProcessesPending()
        // this.fetchIncorporationProcessesFailed()
    },
      selectTab(tab) {
          this.tab = tab
      },
    searchTerm(term){
      this.search = term
      this.fetch()
    },
    view(details){
      this.process = details
      this.$refs.details.show()
    },
    runNow(details, idx){
      this.selectedIdx = idx
      let data = {
        actionId:details.id,
      }
      this.loadingRunItem = true
      this.runProcessNow(data)
    },
    redo(action, idx){
      this.selectedIdx = idx
      let data = {
        id:action.id,
        status: "PENDING"
      }
      this.loadingItem = true
      this.updateProcessStatus(data)
    },
    next(){
      if(this.currentPage < this.pagination.totalPages){
        this.currentPage++
        this.fetch()
      }
    },
    dateFormat(date) {
     if(date){
       return moment(date).format("DD-MM-YYYY HH:mm:ss")
     }
     return ""
    },
    previous(){
      if(this.currentPage > 1){
        this.currentPage--
        this.fetch()
      }else{
        this.warningToast("Observation", "No more data to show")
      }
    },
  },
  computed:{
    ...mapGetters(["getIncorporationProcessStream", "getIncorporationProcessStats", "getStatusChangeSuccess", "getStatusChangeError", "getInternalProcessStreamError",
    "getIncorporationProcessStreamPending", "getIncorporationProcessStreamFailed"])
  },
  watch:{
    getIncorporationProcessStream(data){
      this.loading = false
      this.stream = data.content;
      this.pagination = {
        totalPages:data.totalPages,
        totalElements:data.totalElements
      }
    },
    getIncorporationProcessStats(data){
      this.stats = data
    },
    getStatusChangeSuccess(){
      this.loadingItem = false
      this.loadingRunItem = false
      this.successToast("Success", "Process returned to queue")
      this.fetch()
    },
    getStatusChangeError(){
      this.loadingItem = false
      this.loadingRunItem = false
      this.errorToast("Error", "Process failed to return to queue. Try again later.")
    }
  }
};
</script>

<style scoped>

.paginate-details{
  @apply px-3 py-2 bg-white my-2 text-sm text-gray;
}

.paginate-btn{
  @apply px-3 py-2 bg-white border border-white my-2 text-sm text-blue w-full;
}

.paginate-btn:hover{
  @apply border border-blue text-blue rounded-sm;
}

.inert-btn{
  @apply px-3 py-2 bg-white my-2 text-sm text-grey w-full;
}

.waiting{
    @apply text-gray;
}

.normal{
  @apply flex text-blue;
}

.negative{
  @apply flex text-red-light;
}

.stat-count{
  display: inline;
}
</style>