<template>
  <b-overlay rounded="sm" :show="loading">
    <form @submit.prevent="saveComplaint">
      <b-row>
        <label class="">Practitioner Number<i v-if="!formSubmitted" style="font-size: 10px" class="fas fa-asterisk text-red"></i></label>
        <b-col cols="12">
          <b-input-group class="mb-2">
            <b-form-input :readonly="practitionerNumber !== null" required @input="searchPractitionerUpn" v-model.trim="upn" :class="[practitioner || practitionerNumber !== null ? 'focus:border-green border-green':'focus:border-red border-red' ,'form-text noFocus my-0 border-r-0']"></b-form-input>
            <template #append>
              <b-input-group-text :class="[practitioner || practitionerNumber !== null ? 'border-green':'border-red', 'bg-white  border-l-0 rounded-l-none']" >
                <i v-if="searchingPrn" style="font-size: 24px;" class="text-red fa fa-spinner fa-spin fa-fw"></i>
                <i v-if="!searchingPrn && !practitioner && practitionerNumber === null" style="font-size: 24px;" class="text-red fa fa-exclamation-triangle fa-fw"></i>
                <i v-if="(!searchingPrn && practitioner) || practitionerNumber !== null" style="font-size: 24px;" class="text-green fa fa-check fa-fw"></i>
              </b-input-group-text>
            </template>
          </b-input-group>
          <span v-if="practitionerError !== null" class="bg-red px-2 py-1 text-xs rounded-full text-white">{{ practitionerError }}</span>
        </b-col>
      </b-row>
      <b-row class="mb-2" v-if="practitionerAvatarUrl !== null || practitionerNumber === null">
        <b-col cols="12">
          <b-avatar :src="practitionerAvatarUrl" class="mr-3"></b-avatar>
          <span class="mr-auto">{{ practitionerFullName }}</span>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="12">
          <label class="">Complaint<i v-if="!formSubmitted" style="font-size: 10px" class="fas fa-asterisk text-red"></i></label>
          <b-form-group
              class="mb-2"
              id="input-group-1"
              label-for="input-1">
            <b-form-input
                id="reason"
                :class="[form.complaint === null || form.complaint.length < 5 ? 'is-invalid':'', 'form-text noFocus']"
                type="text"
                v-model.trim="form.complaint"
                :required="!formSubmitted"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="12">
          <label class="">
            Description
            <i v-if="!formSubmitted" style="font-size: 10px" class="fas fa-asterisk text-red"></i>
          </label>
          <b-form-textarea
              @update="countChars()"
              id="textarea"
              v-model="form.descriptionOfComplaint"
              placeholder="Enter description..."
              rows="3"
              max-rows="6"
              :class="[form.descriptionOfComplaint !== null && (form.descriptionOfComplaint.length >= 10 && form.descriptionOfComplaint.length <= 200) ? '' : 'is-invalid' ,'noFocus']"
              :required="!formSubmitted"
          ></b-form-textarea>
          <div v-if="form.descriptionOfComplaint !== null" class="w-full flex flex-row justify-between">
            <span v-if="form.descriptionOfComplaint.length < min" class="float-right text-xs text-red">Description needs to be at least {{ min }} characters</span>
            <span v-if="form.descriptionOfComplaint.length >= min" class="float-right text-xs text-red">{{ remaining }} characters remaining for the description</span>
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-5">
        <b-col>
          <button type="button" @click="hideComplaintForm" class="w-100 block border-2 hover:bg-gray hover:text-white border-gray rounded-sm text-gray px-2 py-1">
            <i class="fa fa-times"></i> Cancel
          </button>
        </b-col>
        <b-col>
          <button type="submit" class="w-100 block border-2 hover:bg-green-dark text-white bg-green rounded-sm px-2 py-1">
            <i class="fa fa-check-circle"></i> Save Complaint
          </button>
        </b-col>
      </b-row>
    </form>
  </b-overlay>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import server from "@/app/env/server.json";

export default {
  name: "LodgeComplaint",
  props: {
    practitionerNumber: {
      type: String,
      default: null
    },
  },
  mounted() {
    let userProfile = JSON.parse(sessionStorage.getItem('current_user'))
    this.form.complainantName = `${userProfile.surName.toUpperCase()} ${userProfile.givenName.toUpperCase()}`
    if (this.practitionerNumber !== null) {
      this.form.uniquePractitionerNumber = this.practitionerNumber
      this.upn = this.practitionerNumber
    }
  },
  data() {
    return {
      upn: null,
      min: 10,
      max: 200,
      loading: false,
      searchingPrn: false,
      formSubmitted: false,
      practitionerProfile: null,
      practitioner:false,
      practitionerError:null,
      form : {
        complaint: null,
        complainantName: null,
        descriptionOfComplaint: null,
        uniquePractitionerNumber: null
      }
    }
  },
  methods: {
    ...mapActions([
      "fetchPractitionerDetails",
      "logPractitionerComplaint",
      "fetchPractitionerComplaints",
    ]),
    saveComplaint() {
      this.loading = false;
      if (this.form.complainantName === null) {
        this.errorToast('complainant name missing');
      } else if (this.form.descriptionOfComplaint === null) {
        this.errorToast('description name missing');
      } else if (this.form.complaint === null) {
        this.errorToast('complaint missing');
      } else if (this.form.uniquePractitionerNumber === null) {
        this.errorToast('practitioner number missing');
      } else {
        this.loading = true;
        this.logPractitionerComplaint(this.form)
      }
    },
    searchPractitionerUpn() {
      this.practitionerError = null;
      this.practitionerProfile = null;
      if (this.upn !== null && this.upn.length) {
        this.searchingPrn = true;
        this.fetchPractitionerDetails(this.upn);
      }
    },
    countChars() {
      if (this.form.descriptionOfComplaint.length > this.max) {
        this.form.descriptionOfComplaint = this.form.descriptionOfComplaint.substr(0, this.max);
      }
    },
    hideComplaintForm(){
      this.$parent.$parent.$parent.$refs.lodgeComplaints.hide()
      this.form = {
        complaint: null,
        complainantName: null,
        descriptionOfComplaint: null,
        uniquePractitionerNumber: this.practitionerNumber
      }
    },
  },
  computed: {
    ...mapGetters([
      "getPractitioner",
      "getPractitionerError",
      "getPractitionerComplaint",
      "getPractitionerComplaintError",
    ]),
    practitionerAvatarUrl(){
      if (this.practitionerProfile === null) {
        return null;
      }
      if (!this.practitionerProfile.form1s.length) {
        return null;
      }

      const baseUrl = server.IP+'/insolvency/api/practitioner-registration/passport-photo/';
      const form1 = this.practitionerProfile.form1s.find(f=>{
        return f.passportPhotos.length > 0
      });

      if (form1 === undefined) {
        return null;
      }

      return baseUrl+form1.passportPhotos[0].filename
    },
    practitionerFullName(){
      if (this.practitionerProfile === null) {
        return null;
      }

      let name = "";

      name += this.practitionerProfile.lastName.toUpperCase()
      name += " "
      name += this.practitionerProfile.firstName.toUpperCase()

      return name;
    },
    remaining: function() {
      return this.form.descriptionOfComplaint === null ? this.max : this.max-this.form.descriptionOfComplaint.length;
    }
  },
  watch: {
    getPractitioner(data) {
      this.practitioner = true;
      this.practitionerProfile = data
      this.form.uniquePractitionerNumber = this.upn
      this.searchingPrn = false;
    },
    getPractitionerError(error) {
      this.practitionerError = error.message
      this.form.uniquePractitionerNumber = null
      this.searchingPrn = false;
    },
    getPractitionerComplaint() {
      this.loading = false
      this.query = {
        page: 1,
        perPage: 20,
        status: null,
        practitionerNumber: this.practitionerNumber,
      }

      if (this.practitionerNumber === null) {
        this.query.status = "PENDING"
      }

      this.fetchPractitionerComplaints(this.query);
      this.hideComplaintForm();
    },
    getPractitionerComplaintError(response) {
      console.log(response)
      this.loading = false
    },
  },
}
</script>

<style scoped>

</style>