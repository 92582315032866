<template>
  <b-col>
    <b-row>
      <b-col cols="4" class="mt-4">
        <button class="bg-blue px-2 py-1 text-white text-sm rounded-sm" @click="sendDocument('REGISTRATION_CERTIFICATE')">
          <i class="fa fa-reply mx-1"></i> Resend Certificate <b-spinner v-if="resending" small></b-spinner>
        </button>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="4" class="mt-1">
        <table class="w-full leading-normal text-gray" style="border: solid 1px #dedede;">
          <thead class="bg-gray-pale text-xs">
          <tr class="bg-gray-pale">
            <th class="px-2 py-1">#</th>
            <th class="px-2 py-1">Document</th>
            <th class="px-2 py-1 text-center">EDMS</th>
            <th class="px-2 py-1 text-center">Date</th>
          </tr>
          </thead>
          <tbody>
            <tr>
              <td class="px-2 py-1" colspan="12">
                <b-form-input v-model="search" v-on:change="fetch" placeholder="Search Document Name" class="form-text text-xs"></b-form-input>
                <div class="mx-auto">
                  <b-progress value="100" v-if="loading"  variant="#007ac5" height="0.2rem" animated></b-progress>
                </div>
              </td>
            </tr>
            <tr style="border: solid 1px #dedede;" v-for="(document, idx) in documents"
                class="cursor-pointer text-gray hover:underline hover:text-blue hover:font-bold"
                :key="idx"
                @click="view(document)">
              <td class="px-2 py-1">
                 <span class="text-xs">
                   {{(currentPage - 1) * limit + idx + 1}}
                 </span>
              </td>
              <td class="px-2 py-1">
                 <span class="text-xs text-capitalize">
                   {{cleanEntityType(document.name.toLowerCase())}}
                 </span>
              </td>
              <td class="px-2 py-1 text-center text-xs">
                <i v-if="document.edmsId" class="text-green fa fa-check-double">Yes</i>
                <i v-else class="text-gray_title fa fa-check">No</i>
              </td>
              <td class="px-2 py-1 text-xs text-center">
                <span>{{dateFormat(document.createdAt)}}</span>
              </td>
            </tr>
            <tr v-if="documents.length === 0">
              <td colspan="12" class="bg-inertgrey text-center py-1">
                <span class="text-xs">No results found</span>
              </td>
            </tr>
          </tbody>
        </table>
        <b-row class="mx-1" v-if="pagination.totalPages > 1">
          <b-col cols="2">
            <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
          </b-col>
          <b-col cols="2">
            <b-row>
              <b-col cols="12">
                <p class="paginate-details text-center">
                  <b-badge class="bg-blue text-white">
                    {{(currentPage)}}/{{pagination.totalPages}}
                  </b-badge>
                </p>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="2" >
            <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">Next</button>
          </b-col>
          <b-col cols="2"></b-col>
          <b-col cols="4">
            <b-row>
              <b-col class="mx-auto">
                <p class="paginate-details text-center text-xs font-bold">
                  <span>{{((currentPage - 1) * limit)}}</span> -
                  <span v-if="limit > documents.length ">{{pagination.totalElements}}</span>
                  <span v-else>{{currentPage * limit}}</span>
                  of {{pagination.totalElements}}
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="8" class="mt-1">
        <b-overlay :show="loadingDocument" rounded="sm">
          <b-row>
            <b-col>
              <EDMSDocument class="w-full border border-gray" ref="edmsFile" :file="getEDMSFile"/>
            </b-col>
          </b-row>
        </b-overlay>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import EDMSDocument from "@/views/home/services/registry/business/document/EDMSDocument";

export default {
  name: "CompanyDocuments",
  components: {EDMSDocument},
  props:{
    brn:String
  },
  data(){
    return{
      loading: false,
      loadingDocument: false,
      resending:false,
      currentPage:1,
      limit:10,
      documents:[],
      pagination:{
        totalElements: 0,
        totalPages:0
      },
      search:""
    }
  },
  mounted() {
    this.fetch()
  },
  methods:{
    ...mapActions(["fetchCompanyDocuments", "fetchCompanyEDMSFile", "sendCompanyDocumentToClient"]),
    fetch(){
      this.loading = true
      let data = {
        brn:this.brn,
        limit:this.limit,
        page:this.currentPage,
        search:this.search
      }
      this.fetchCompanyDocuments(data)
    },
    cleanEntityType(name){
      let n = name.replaceAll("_", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    view(document){
      if(document.edmsId){
        let data = {
          documentHandle: document.edmsId,
          overlay: ""
        }
        this.loadingDocument = true
        this.fetchCompanyEDMSFile(data)
        this.$refs.edmsFile.show();
      }else{
        this.errorToast("Document Error", "This document is not yet in EDMS")
      }
    },
    sendDocument(name){
      let data = {
        type:name,
        brn: this.brn
      }
      this.resending = true
      this.sendCompanyDocumentToClient(data)
    },
    next(){
      if(this.currentPage < this.pagination.totalPages){
        this.currentPage++
        this.fetch()
      }
    },
    previous(){
      if(this.currentPage > 1){
        this.currentPage--
        this.fetch()
      }else{
        this.warningToast("Observation", "No more data to show")
      }
    },
  },
  computed:{
    ...mapGetters(["getCompanyDocuments", "getEDMSFile", "getSendDocumentSuccess", "getSendDocumentError",
      "getDocumentFetcherError", "getNetworkStatus", "getRegistryError", "getEntityFileError"])
  },
  watch:{
    getCompanyDocuments(data){
      this.loading = false
      this.documents = data.content
      this.pagination = {
        totalPages:data.totalPages,
        totalElements:data.totalElements
      }
      //this.view(this.documents[0])
    },
    getEDMSFile(){
      this.loadingDocument = false
    },
    getEntityFileError(){
      this.loadingDocument = false
      this.errorToast("Error", "Failed to get document")
    },
    getNetworkStatus(){
      this.loadingDocument = false
      this.errorToast("Document Error", "Document loading times out")
    },
    getSendDocumentSuccess(){
      this.resending = false
      this.successToast("Success", "Document resent")
    },
    getSendDocumentError(){
      this.resending = false
      this.errorToast("Error", "Failed to resend document")
    }
  }
}
</script>

<style scoped>
.button {
  @apply text-center rounded-sm bg-blue text-xs text-white border border-blue px-2 py-1;
  font-size: 14px;
}

.button:hover {
  @apply bg-blue-dark;
}

.paginate-details{
  @apply px-3 py-2 bg-white my-2 text-sm text-gray;
}

.paginate-btn{
  @apply px-3 py-2 bg-white border border-white my-2 text-sm text-blue w-full;
}

.paginate-btn:hover{
  @apply border border-blue text-blue rounded-sm;
}

.inert-btn{
  @apply px-3 py-2 bg-white my-2 text-sm text-grey w-full;
}
</style>