<template>
    <div>
        <b-row class="my-1">
            <b-col>
                Name
                <b-form-input v-model="form.name"
                              placeholder="eg Bachelors " required>
                </b-form-input>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="8"></b-col>
            <b-col cols="4" class="float-right">
                <button @click="addBody" class="w-full bg-blue text-white px-2 py-1 rounded-sm">
                    Save
                    <b-spinner v-if="spinner" small></b-spinner>
                </button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "NewAcademicQualification",
    data() {
        return {
            form: {
                name: null,
            },
            spinner: false,
        }
    },
    methods: {
        ...mapActions([
            "createAcademicQualification", "fetchAcademicQualifications"
        ]),
        addBody() {
            this.spinner = true
            this.createAcademicQualification(this.form)
        }
    },
    computed: {
        ...mapGetters([
            "getAcademicQualifications",
            "getAcademicQualification",
            "getInsolvencyError"
        ])
    },
    watch: {
        getAcademicQualification() {
            this.spinner = false
            this.fetchAcademicQualifications()
            this.successToast("Success", "Academic Qualification created successfully");
        },
        getInsolvencyError() {
            this.spinner = false
            this.errorToast("Error", "An error occurred while creating department");
        }
    }

}
</script>

<style scoped>

</style>