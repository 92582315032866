<template>
  <b-row>
    <b-col>
      <b-row class="p-3 mt-4">
        <b-col cols="2">
          <p @click="select('expiry')" :class="[(currentRoute === 'expiry') ? 'border-b border-r border-t border-blue text-sm cursor-pointer font-bold py-2 text-blue' : 'text-sm cursor-pointer py-2 hover:text-blue']">Expiry & Notifications</p>
          <p @click="select('responses')" :class="[(currentRoute === 'responses') ? 'border-b border-r border-t border-blue text-sm cursor-pointer font-bold py-2 text-blue' : 'text-sm cursor-pointer py-2 hover:text-blue']">Response Templates</p>
        </b-col>
        <b-col cols="10">
          <b-row>
            <b-col>
              <ReservationExpiry v-if="currentRoute === 'expiry'"/>
              <ResponsesTemplate v-if="currentRoute === 'responses'"/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import ReservationExpiry from "@/views/home/services/configurations/reservations/ReservationExpiry";
import ResponsesTemplate from "@/views/home/services/configurations/reservations/ResponsesTemplate";
export default {
  name: "NameReservation",
  components: { ResponsesTemplate, ReservationExpiry },
  data() {
    return {
      currentRoute: "expiry"
    };
  },
  methods: {
    select(route) {
      this.currentRoute = route;
    }
  }
};
</script>

<style scoped>

</style>