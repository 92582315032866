<template>
    <b-row>
        <b-col cols="12">
            <b-row>
                <b-col cols="12" class="mx-3 mt-3">
                    <b-row>
                        <span class="title">Services Costs Configurations</span>
                    </b-row>
                </b-col>
            </b-row>
            <b-row>
                <b-modal ref="newService" hide-footer title="New Service">
                    <b-col cols="11" class="mx-auto">
                        <b-form-input class="my-2" type="text" v-model="form.serviceName"
                                      placeholder="Service Name"></b-form-input>

                        <b-form-input class="my-2" type="text" v-model="form.serviceCode"
                                      placeholder="Service Code"></b-form-input>

                        <b-form-input class="my-2" type="number" v-model="form.serviceCost"
                                      placeholder="Service Cost"></b-form-input>

                        <b-form-select
                                class="form-select noFocus text-gray my-2"
                                v-model="form.taxHeadId"
                                placeholder="Tax Head">
                            <b-select-option disabled value="">Select tax head</b-select-option>
                            <b-select-option v-for="(item,idx) in getTaxHeads" :key="idx" :value="item.id">
                                {{ item.taxHeadCode }}
                            </b-select-option>

                        </b-form-select>

                        <b-form-select
                                class="form-select noFocus text-gray my-2"
                                v-model="form.currencyId"
                                placeholder="Tax Head">
                            <b-select-option disabled value="">Select currency</b-select-option>
                            <b-select-option v-for="(item,idx) in currencies" :key="idx" :value="item.id">
                                {{ item.code }} -{{ item.name }}
                            </b-select-option>

                        </b-form-select>
                        <b-row>
                            <b-col cols="8"></b-col>
                            <b-col cols="4">
                                <button class="w-full bg-blue text-white px-2 py-1 rounded-sm" @click="save">
                                    <i class="fa fa-save mx-1"></i> Save
                                    <b-spinner v-if="spinner" small></b-spinner>
                                </button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-modal>
                <b-modal ref="editService" hide-footer title="Edit Service">
                    <b-col cols="11" class="mx-auto">
                        <b-form-input class="my-2" type="text" v-model="editForm.serviceName"
                                      placeholder="Service Name"></b-form-input>

                        <b-form-input class="my-2" type="text" v-model="editForm.serviceCode"
                                      placeholder="Service Code"></b-form-input>

                        <b-form-input class="my-2" type="number" v-model="editForm.serviceCost"
                                      placeholder="Service Cost"></b-form-input>

                        <b-form-select
                                class="form-select noFocus text-gray my-2"
                                v-model="editForm.taxHeadId"
                                placeholder="Tax Head">
                            <b-select-option disabled value="">Select tax head</b-select-option>
                            <b-select-option v-for="(item,idx) in getTaxHeads" :key="idx" :value="item.id">
                                {{ item.taxHeadCode }}
                            </b-select-option>

                        </b-form-select>

                        <b-form-select
                                class="form-select noFocus text-gray my-2"
                                v-model="editForm.currencyId"
                                placeholder="Tax Head">
                            <b-select-option disabled value="">Select currency</b-select-option>
                            <b-select-option v-for="(item,idx) in currencies" :key="idx" :value="item.id">
                                {{ item.code }} -{{ item.name }}
                            </b-select-option>

                        </b-form-select>

                        <b-row>
                            <b-col cols="6"></b-col>
                            <b-col cols="6">
                                <button class="w-full bg-blue text-white px-2 py-1 rounded-sm"
                                        @click="updateServiceCost">
                                    <i class="fa fa-pencil mx-1"></i> Save Changes
                                    <b-spinner v-if="spinner" small></b-spinner>
                                </button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-modal>
            </b-row>
            <b-row class="py-3">
                <b-col cols="2">
                    <b-form-input
                            class="text-gray text-sm noFocus ml-1"
                            style="margin-top: 1px; padding:6px 5px;"
                            v-model="search"
                            type="text"
                            placeholder="Search by Name"
                            required
                            v-on:keyup.enter="searchServices">
                    </b-form-input>
                </b-col>
                <b-col cols="8"></b-col>
                <b-col cols="2">
                    <button v-if="canViewMenuItem('can_modify_service_costs')"
                            class="w-full bg-blue text-white py-1 rounded-sm" @click="openNewServiceForm">
                        <i class="fa fa-plus mx-1"></i> Add Service
                    </button>
                </b-col>
            </b-row>
            <div class="mx-auto">
                <b-progress value="100" v-if="loading" variant="#007ac5" height="0.2rem" animated></b-progress>
            </div>
            <b-row>
                <b-col>
                    <table class="min-w-full leading-normal">
                        <thead>
                        <tr class="border-b border-t border-grey">
                            <th class="t-header">#</th>
                            <th class="t-header">Name</th>
                            <th class="t-header">Tax Head</th>
                            <th class="t-header">Code</th>
                            <th class="t-header">Cost</th>
                            <th class="t-header text-center">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(service,idx) in serviceCosts"
                            :key="service.id">
                            <td class="t-body ">{{ idx + 1 }}</td>
                            <td class="t-body text-capitalize font-bold">
                                {{ service.serviceName.replaceAll("_", " ").toLowerCase() }}
                            </td>
                            <td class="t-body  text-capitalize">{{ service.taxHeadCode }}</td>
                            <td class="t-body  text-capitalize">{{ service.serviceCode }}</td>
                            <td class="t-body  text-capitalize">{{ service.serviceCost.toLocaleString() }}</td>
                            <td class="t-body  text-capitalize text-center">
                                <button v-if="canViewMenuItem('can_modify_service_costs')"
                                        class="bg-blue mx-2 px-2 py-1 rounded-sm text-white" @click="view(service)">
                                    Edit
                                </button>
                                <button v-if="canViewMenuItem('can_modify_service_costs')"
                                        class="bg-red px-2 py-1 rounded-sm text-white"
                                        @click="deleteService(service.id)">
                                    Delete
                                </button>

                            </td>
                        </tr>
                        <tr>
                            <td v-if="serviceCosts.length === 0" colspan="12" class="bg-inertgrey text-center py-1">
                                <span class="text-sm">No results found</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "ServiceCost",
    data() {
        return {
            registerOccupation: false,
            loading: false,
            new: false,
            serviceCosts: [],
            currencies: [],
            search: null,
            spinner: false,
            rows: {
                odd: "border-t border-grey hover:bg-header_grey",
                even: "border-t border-grey bg-header_grey"
            },
            form: {
                serviceName: null,
                serviceCost: null,
                serviceCode: null,
                taxHeadId: null,
                currencyId: null
            },
            editForm: {
                serviceChargeId: null,
                serviceName: null,
                serviceCost: null,
                serviceCode: null,
                taxHeadId: null,
                currencyId: null
            }

        };
    },
    mounted() {
        this.loading = true;
        this.fetchServiceCosts();
        this.fetchCurrencies();
        this.fetchTaxHeads();
    },
    methods: {
        ...mapActions(["fetchServiceCosts", "createServiceCost", "fetchCurrencies",
            "fetchTaxHeads", "deleteServiceCost", "editServiceCost"]),
        save() {
            this.spinner = true;
            this.createServiceCost(this.form);
        },
        edit() {
            this.spinner = true;
            this.editServiceCost(this.form);
        },
        view(data) {
            this.$refs.editService.show()
            this.editForm = data;
        },
        deleteService(serviceCostId) {
            this.loading = true
            this.deleteServiceCost(serviceCostId);
        },
        updateServiceCost() {
            this.loading = true
            let payload = {
                "serviceChargeId": this.editForm.id,
                "serviceName": this.editForm.serviceName,
                "serviceCode": this.editForm.serviceCode,
                "serviceCost": this.editForm.serviceCost,
                "taxHeadId": this.editForm.taxHeadId,
                currencyId: this.editForm.currencyId
            }
            this.editServiceCost(payload);
        },
        openNewServiceForm() {
            this.$refs.newService.show();
        },
        closeServiceForm() {
            this.$refs.newService.hide();
        },
        closeEditServiceForm() {
            this.$refs.editService.hide();
        },
        canViewMenuItem(permission) {
            return this.$can(permission, "general") === true;
        }
    },
    computed: {
        ...mapGetters(["getServiceCost", "getServiceCosts",
            "getTaxHeads", "getError", "getEditServiceCost",
            "getCurrencies", "getDeleteServiceCost"])
    },
    watch: {
        getServiceCosts(serviceCosts) {

            this.loading = false;
            this.spinner = false;
            this.serviceCosts = serviceCosts;
            this.form = {
                serviceName: null,
                serviceCost: null,
                serviceCode: null,
                taxHeadId: null,
                currencyId: null
            };
        },
        getCurrencies(currencies) {
            this.loading = false;
            this.spinner = false;
            this.currencies = currencies.data;
        },
        getServiceCost() {

            this.successToast("Success", "Service cost created successfully");
            this.loading = false;
            this.form = {
                serviceName: null,
                serviceCost: null,
                serviceCode: null,
                taxHeadId: null
            };
            this.fetchServiceCosts();
            this.closeServiceForm();
        },
        getEditServiceCost() {
            this.loading = false;
            this.spinner = false;
            this.editForm = {
                serviceName: null,
                serviceCost: null,
                serviceCode: null,
                taxHeadId: null
            };
            this.fetchServiceCosts()
            this.closeEditServiceForm()
            this.successToast("Updated", "Service  cost updated");
        },
        getError() {
            this.loading = false;
            this.spinner = false;
            this.form = {
                serviceName: null,
                serviceCost: null,
                serviceCode: null,
                taxHeadId: null
            };
            this.errorToast("Error", "Service  creation failed");
        },
        getDeleteServiceCost() {
            this.loading = false;
            this.spinner = false;

            this.fetchServiceCosts()
            this.successToast("Deleted", "Service  cost removed");
        }
    },


};
</script>

<style scoped>

</style>