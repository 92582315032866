x
<template>
    <div class="bg-white rounded-sm mx-2 my-3">
        <b-row>
            <b-col cols="12" class="mx-3 mt-3">

                <span class="title">Business Registry</span>

            </b-col>
            <b-col>

                <b-row>
                    <b-modal size="lg" ref="strikeCompanyModal" title="Add Companies to remove from register"
                             hide-footer>
                        <b-row>
                            <b-col class="mx-auto mb-2">
                                <div class="flex h-full" style="cursor: pointer;">
                                    <div :class="[(option === 'form') ? 'menu-item-active' : 'menu-item']"
                                         @click="select('form')">
                                        <div class="flex mt-6">
                                            <div style="font-size: 14px" class="mt-1">Enter BRN</div>
                                        </div>
                                    </div>
                                    <div :class="[(option === 'upload') ? 'menu-item-active' : 'menu-item']"
                                         @click="select('upload')">
                                        <div class="flex mt-6">
                                            <div style="font-size: 14px" class="mt-1">Upload File</div>
                                        </div>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="8" class="mx-auto">
                                <form v-if="option === 'form'">
                                    <b-form-input
                                            v-model="form.brn"
                                            placeholder="Add BRN here"
                                            rows="6">
                                    </b-form-input>


                                    <button class="bg-blue text-white px-3 py-1 rounded-sm mt-2 float-end" @click="save"
                                            type="button">
                                        Submit
                                        <b-spinner v-if="spinner" small></b-spinner>
                                    </button>
                                </form>
                                <form @submit.prevent="saveUpload" v-if="option === 'upload'">
                                    <b-input-group>
                                        <b-form-file
                                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                label-size="sm"
                                                type="file"
                                                placeholder="Please provide the excel file..."
                                                drop-placeholder="Drop file here..."
                                                v-model="form.file"
                                                class="form-control noFocus removeOnClickBorder rounded-0 mb-2"
                                                plain></b-form-file>
                                    </b-input-group>
                                    <button class="bg-blue text-white px-3 py-1 rounded-sm mt-2 float-end"
                                            type="submit">
                                        Upload
                                        <b-spinner v-if="spinner" small></b-spinner>
                                    </button>
                                </form>
                            </b-col>
                        </b-row>
                    </b-modal>
                </b-row>
                <b-row>
                    <b-col>
                        <b-row>
                            <b-col cols="10"></b-col>
                            <b-col cols="2">
                                <button class="w-full bg-blue text-white px-2 py-1 rounded-sm text-sm"
                                        @click="openForm"><i
                                        class="fa fa-plus"></i>
                                    Strike/Blacklist Company
                                </button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <EntityWrapper/>
            </b-col>
        </b-row>
    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import EntityWrapper from "@/views/home/services/registry/business/EntityWrapper";
import {api} from "@/app/services/api.service";

export default {
    name: "BusinessRegistry",
    components: {EntityWrapper},
    data() {
        return {
            currentPath: this.$route.fullPath,
            items: [],
            form: {
                file: null,
                brn: null
            },
            option: "form",
            spinner: false,
        }
    },
    mounted() {
        let paths = this.$route.path.split('/')
        let basePath = "/home/registry/"
        for (let i = 3; i < paths.length; i++) {
            basePath += paths[i]
            let path = {
                text: paths[i].toLowerCase().replaceAll("-", " "),
                href: basePath
            }
            this.items.push(path)
        }
    },
    computed: {
        ...mapGetters(["getRegistryError", "getEntityFile"])
    },
    methods: {
        ...mapActions(["uploadBatchBrnsForStrikingOff"]),
        select(route) {
            this.option = route
        },
        openForm() {
            this.$refs.strikeCompanyModal.show()
        },
        async save() {
            this.spinner = true
            let data = {"registration_no": this.form.brn, "old_registration_no": "-"}

            await api().post("/integration/get-entity-full", data)
                .then(response => {
                    let payload = {
                        "brn": this.form.brn,
                        "companyName": response.data.entity_name
                    }
                    this.strikeCompanyOffRegistry(payload)

                }).catch(error => {
                    this.errorToast("Error ", error.response.data.message)
                });

        },
        async strikeCompanyOffRegistry(data) {

            await api()
                .post("/data-update/blacklisted-brns/" + data.brn + "/add", data)
                .then(response => {
                    this.successToast("Success", "Added to black list")
                    this.spinner = false
                    this.form.brn = null
                    console.log(response)
                })
                .catch(error => {
                    this.errorToast("Error ", error.response.data.message)
                    this.spinner = false
                });
        },
        saveUpload() {
            let formData = new FormData;
            formData.set("file", this.form.file);
            this.spinner = true
            this.uploadBatchBrnsForStrikingOff(formData);
        },
    },

    watch: {
        getEntityFile() {
            this.successToast("Success", "Added files to black list")
            window.location.reload();
        }
    }

}
</script>

<style scoped>
@import "../../../../assets/styles/menu.css";

</style>