import { render, staticRenderFns } from "./BuildingSocietyMembers.vue?vue&type=template&id=6778d5bb&scoped=true&"
import script from "./BuildingSocietyMembers.vue?vue&type=script&lang=js&"
export * from "./BuildingSocietyMembers.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6778d5bb",
  null
  
)

export default component.exports