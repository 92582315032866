<template>
  <div class="bg-white rounded-sm mx-3 my-3">
    <b-row>
      <b-col cols="12" class="mt-3">
        <b-row class="mx-1">
          <b-col cols="11">
            <span class="title mx-1">Legal Document</span>
          </b-col>
          <b-col cols="1" class="mx-auto text-center">
            <button class="w-full bg-blue rounded px-2 py-1 text-white hover:bg-blue-dark" @click="back">
              <i class="fa fa-arrow-left text-white mr-2"></i>Back
            </button>
          </b-col>
        </b-row>

      </b-col>
    </b-row>
    <b-row class="mx-3 mt-2">
      <b-col cols="12" class="mx-auto bg-header_grey py-3 rounded">
         <span class="text-gray">Document / {{instrumentNumber}} / {{cleanDocumentType(document.documentType.toUpperCase())}}</span>
      </b-col>
    </b-row>

    <b-row class="mx-3 mt-2">
      <b-col>
        <b-row>
          <b-col cols="12" class="mt-2">
            <span class="font-bold text-gray h4">{{document.subjectMatter}}</span><br/>
            <div class="flex my-1">
              <b-badge class="bg-blue text-capitalize">
                {{cleanDocumentType(document.documentType.toLowerCase())}}
              </b-badge>
            </div>
            <span class="text-gray text-xs font-bold" >Registration Date: {{dateFormat(document.filingDate)}}</span><br/>
          </b-col>
        </b-row>

        <b-row class="pt-1">
          <b-col cols="12">
            <div class="flex h-full" style="cursor: pointer;">
              <div :class="[(currentRoute === 'details') ? 'menu-item-active' : 'menu-item']" @click="select('details')">
                <div class="flex mt-6">
                  <div style="font-size: 14px">Details</div>
                </div>
              </div>
              <div :class="[(currentRoute === 'attachment') ? 'menu-item-active' : 'menu-item']" @click="select('attachment')">
                <div class="flex mt-6">
                  <div style="font-size: 14px">Attachment</div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row class="py-4">
          <b-col cols="12">
            <LegalDocumentDetails :document="document" v-if="currentRoute === 'details'"/>
            <LegalDocumentRegistryAttachment :document-handle="document.edmsId" v-if="currentRoute === 'attachment'"/>
          </b-col>
        </b-row>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import LegalDocumentRegistryAttachment
  from "@/views/home/services/registry/legal-documents/tabs/LegalDocumentRegistryAttachment";
import LegalDocumentDetails from "@/views/home/services/registry/legal-documents/tabs/LegalDocumentDetails";

export default {
  name: "LegalDocumentFileWrapper",
  components: { LegalDocumentDetails, LegalDocumentRegistryAttachment},
  data(){
    return{
      instrumentNumber:null,
      loading: false,
      document:null,
      currentRoute:"details"
    }
  },
  mounted(){
    this.instrumentNumber = this.$route.params.id
    this.fetchLegalDocumentDetails(this.instrumentNumber)
  },
  methods:{
    ...mapActions(["fetchLegalDocumentDetails"]),
    select(route){
      this.currentRoute = route
    },
    cleanDocumentType(name){
      let n = name.replaceAll("_", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    back() {
      window.history.back()
    }
  },
  computed:{
    ...mapGetters(["getLegalDocumentDetails"])
  },
  watch:{
    getLegalDocumentDetails(data){
      this.document = data
    }
  }
};
</script>

<style scoped>

</style>