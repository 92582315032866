<template>
  <b-row>
    <b-col>
      <v-treeview
        hoverable
        :items="items"
        item-key="id"
        selected-color="indigo"
        open-on-click
        return-object
        expand-icon="mdi-chevron-down">
        <template v-slot:prepend="{ item}">
          <b-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <i v-if="!item.file" class="fa fa-folder"></i>
              <i v-else class="fa fa-file"></i>
              <span
                v-bind="attrs"
                v-on="on">
                  {{item.description}}
                </span>
            </template>
            <span>{{item.description}}</span>
          </b-tooltip>
        </template>
      </v-treeview>
    </b-col>
  </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  data: () => ({
    items: [],
  }),

  mounted() {
    this.fetchLevelOneIsic()
  },
  computed: {
    ...mapGetters(["getIsicFetchChildren"]),
  },
  methods: {
    ...mapActions(["fetchIsic", "fetchLevelOneIsic", "fetchIsicLevelChildren"]),
    fetch(levelId) {
      this.fetchIsicLevelChildren(levelId)
    }
  },
  watch: {
    getIsicFetchChildren(data) {
      this.items = data
    }
  }


}
</script>

<style scoped>

</style>