<template>
    <div>
        <b-row>
            <b-modal id="modal-add-word" hide-footer ref="addWord" title="Add Rejection/Query reason template">
                <b-col cols="12">
                    <b-form class="w-full">
                        <b-form-input
                                v-model="form.reason"
                                type="text"
                                placeholder="Reason"
                                class="mt-2 noFocus"
                                required>
                        </b-form-input>
                        <b-form-textarea
                                v-model="form.message"
                                type="text"
                                placeholder="Response message "
                                class="mt-3 noFocus"
                                required>
                        </b-form-textarea>


                        <b-form-select
                                v-model="form.appealStatus"
                                :options="positionList"
                                class="form-select noFocus mt-3">
                        </b-form-select>


                        <div style="text-align: end;">

                            <b-button
                                    @click="saveWord"
                                    class="bg-blue w-25 mt-4"
                                    variant="primary">
                                Save
                                <b-spinner small v-if="loading"></b-spinner>
                            </b-button>

                        </div>
                    </b-form>
                </b-col>
            </b-modal>
        </b-row>
        <b-row>
            <b-modal id="modal-edit-word" hide-footer ref="editWord" title="Edit Word">
                <b-col cols="12">
                    <b-form class="w-full">
                        <b-form-input
                                v-model="eform.reason"
                                type="text"
                                placeholder="Reason"
                                class="mt-2 noFocus"
                                required>
                        </b-form-input>
                        <b-form-textarea
                                v-model="eform.message"
                                type="text"
                                placeholder="Message "
                                class="mt-3 noFocus"
                                required>
                        </b-form-textarea>


                        <b-form-select
                                v-model="eform.appealStatus"
                                :options="positionList"
                                class="form-select noFocus mt-3">
                        </b-form-select>

                        <p class="text-sm mt-3 italic text-blue">
                            This action will be logged and your account flagged as one who took this action and also had
                            the
                            permission to do so.
                        </p>

                        <div style="text-align: end;">
                            <b-button
                                    @click="updateWord"
                                    class="bg-blue mt-3"
                                    variant="primary">
                                Save Changes
                                <b-spinner small v-if="loading"></b-spinner>
                            </b-button>
                        </div>
                    </b-form>
                </b-col>
            </b-modal>
        </b-row>
        <b-row>
            <b-modal id="modal-delete-word" hide-footer ref="deleteWord" title="Remove Word">
                <b-col cols="12">
                    <b-form class="w-full">
                        <b-row>
                            <b-col cols="12">
                                <p class="text-center">
                                    Are you sure you want to remove this response <b>{{ dform.word.toUpperCase() }}</b>
                                    from
                                    the database?<br/><br/>
                                    Removing this word means that it will no longer be used and considered as a
                                    response during query/response to the client.
                                </p>
                                <p class="text-sm mt-3 italic text-red">
                                    This action will be logged and your account flagged as one who took this action and
                                    also had the
                                    permission to do so.
                                </p>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="4"></b-col>
                            <b-col cols="4" class="text-center justify-content-center">
                                <b-button @click="removeWord" class="w-full mt-3" variant="danger">
                                    Yes
                                    <b-spinner small v-if="loading"></b-spinner>
                                </b-button>
                            </b-col>
                            <b-col cols="4"></b-col>
                        </b-row>
                    </b-form>
                </b-col>
            </b-modal>
        </b-row>
        <b-row>
            <b-col cols="12" class="mb-4">
                <b-row class="mx-1">
                    <b-col cols="2">
                        <button
                                v-b-modal.modal-add-word
                                class="bg-blue w-full text-white px-2 py-1 text-sm rounded-sm hover:bg-blue-dark">
                          <i class="fa fa-plus"></i>
                            Add Response
                        </button>
                    </b-col>
                    <b-col cols="2">

                    </b-col>
                    <b-col cols="6"></b-col>
                    <b-col cols="2">
                        <b-form-input
                                class="form-text w-full text-sm form-select-sm noFocus"
                                type="text"
                                placeholder="Search"
                                required
                                v-on:keyup.enter="searchDictionary(form.reason)">
                        </b-form-input>
                    </b-col>
                </b-row>
            </b-col>

        </b-row>

        <div class="mx-auto">
            <b-progress value="100" v-if="loading" variant="#007ac5" height="0.2rem" animated></b-progress>
        </div>

        <b-row class="mx-auto">
            <b-col cols="12">
                <table class="w-full leading-normal">
                    <thead>
                    <tr class="border-b border-t border-grey">
                        <th class="t-header">#</th>
                        <th class="t-header">Reason</th>
                        <th class="t-header">Message</th>
                        <th class="t-header">Allow Appeal</th>
                        <th v-if="canEditWord()" class="t-header">Edit</th>
                        <th v-if="canDeleteWord()" class="t-header">Remove</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(page, idx) in wordList"
                        :key="idx">
                        <td class="t-body">{{ idx + 1 }}</td>
                        <td class="t-body font-bold">{{ page.reason }}</td>
                        <td class="t-body">{{ page.message }}</td>
                        <td class="t-body">{{ page.appealStatus ? 'Yes' : 'No' }}</td>
                        <td v-if="canEditWord()" class="t-body">
                            <button @click="editWordPrompt(page)"
                                    class="bg-blue px-2 py-1 text-white text-sm shadow hover:bg-blue-dark">
                              <i class="fa fa-pencil mr-1"></i>Edit
                            </button>
                        </td>
                        <td v-if="canDeleteWord()" class="t-body">
                            <button @click="deleteWordPrompt(page)"
                                    class="bg-red px-2 py-1 rounded-sm text-white text-sm">
                              <i class="fa fa-ban mr-1"></i> De-activate
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td v-if="wordList.length === 0" colspan="8" class="bg-inertgrey text-center py-1">
                            <span class="text-sm">No results found</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "ResponsesTemplate",
    data() {
        return {
            loading: true,
            wordList: [],
            positionList: [
                {text: "--Allow appeal--", id: null},
                {value: true, text: 'Yes'},
                {value: false, text: 'No'},

            ],

            form: {
                reason: null,
                message: null,
                appealStatus: null,
            },
            eform: {
                reason: null,
                message: null,
                appealStatus: null,
            },
            dform: {
                id: null,
                word: ""
            },
            status: {
                approved: "px-3 py-1 bg-green rounded-md text-white text-xs w-10/12 text-center",
                rejected: "px-3 py-1 bg-red rounded-md text-white text-xs w-10/12 text-center"
            },
            rows: {
                odd: "border-t border-grey hover:bg-header_grey",
                even: "border-t border-grey bg-header_grey"
            },

            selectedId: null
        }
    },
    mounted() {
        this.fetchQueryRejectionWords()
    },
    methods: {
        ...mapActions(["fetchQueryRejectionWords", "addRejectionQueryWord", "editRejectionQueryWord", "deleteRejectionQueryWord"]),
        editWordPrompt(data) {
            this.selectedId = data.id
            this.eform = {
                message: data.message,
                appealStatus: data.appealStatus,
                reason: data.reason,

            }
            this.$refs.editWord.show();
        },
        deleteWordPrompt(data) {
            this.dform = {
                id: data.id,
                word: data.reason
            }
            this.$refs.deleteWord.show();
        },
        saveWord() {
            this.loading = true
            this.addRejectionQueryWord(this.form)
        },
        updateWord() {
            this.loading = true
            let data = this.eform
            this.editRejectionQueryWord(data)
        },
        removeWord() {
            this.loading = true
            this.deleteRejectionQueryWord(this.dform)
        },
        searchDictionary() {
            this.fetchQueryRejectionWords()
        },
        canEditWord() {
            return this.$can('can_edit_dictionary_entry', 'general')
        },
        canDeleteWord() {
            return this.$can('can_delete_dictionary_entry', 'general')
        }
    },
    computed: {
        ...mapGetters(
            [

                "geRejectionTransactionError",
                "getRejectionQueryWords",
                "getRejectionQueryTransactionSuccess"
            ]
        ),

    },
    watch: {
        getRejectionQueryWords(data) {
            this.loading = false
            this.wordList = []
            this.wordList = data
        },
        getRejectionQueryTransactionSuccess(data) {

            this.fetchQueryRejectionWords()
            this.$refs.addWord.hide();
            this.$refs.editWord.hide();
            this.$refs.deleteWord.hide();


            this.form = {
                reason: null,
                message: null,
                allow_appeal: null
            }

            this.successToast("Success", data.message)
        },
        geRejectionTransactionError(message) {
            this.loading = false
            this.errorToast("Error", message.data.message)
        },
    }
}
</script>

<style scoped>

</style>