<template>
  <b-row>
    <b-col cols="12">
      <b-row class="pt-1">
        <b-col cols="12">
          <div class="flex h-full" style="cursor: pointer;">
            <div :class="[(currentRoute === 'partners') ? 'menu-item-active' : 'menu-item']" @click="select('partners')">
              <div class="flex mt-6">
                <div style="font-size: 14px">Partners</div>
                <b-badge class="bg-blue mb-1 mx-1 ">
                  <span v-if="getBusinessPartners">
                    {{(getBusinessPartners.individualPartners.length + getBusinessPartners.corporatePartners.length)}}
                  </span>
                </b-badge>
              </div>
            </div>
            <div :class="[(currentRoute === 'details') ? 'menu-item-active' : 'menu-item']" @click="select('details')">
              <div class="flex mt-6">
                <div style="font-size: 14px">Address</div>
              </div>
            </div>
            <div :class="[(currentRoute === 'documents') ? 'menu-item-active' : 'menu-item']" @click="select('documents')">
              <div class="flex mt-6">
                <div style="font-size: 14px">Documents</div>
                <b-badge class="bg-blue mx-1 mb-1">
                  {{business.attachments.length}}
                </b-badge>
              </div>
            </div>
            <div :class="[(currentRoute === 'filing-history') ? 'menu-item-active' : 'menu-item']" @click="select('filing-history')">
              <div class="flex mt-6">
                <div style="font-size: 14px">Filing History</div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-row>
            <BusinessNamePartners v-if="currentRoute === 'partners'"/>
            <BusinessNameDetails :file="business" v-if="currentRoute === 'details'"/>
            <BusinessNameDocuments :brn="business.brn" :documents="business.attachments" v-if="currentRoute === 'documents'"/>
            <FilingHistory :brn="business.brn" v-if="currentRoute === 'filing-history'"/>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import BusinessNamePartners
  from "@/views/home/services/registry/business/files/tabs/business_name_data/BusinessNamePartners";
import BusinessNameDetails
  from "@/views/home/services/registry/business/files/tabs/business_name_data/BusinessNameDetails";
import BusinessNameDocuments
  from "@/views/home/services/registry/business/files/tabs/business_name_data/BusinessNameDocuments";
import FilingHistory from "@/views/home/services/registry/business/files/FilingHistory";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "BusinessNameFile",
  components: {FilingHistory, BusinessNameDocuments, BusinessNameDetails, BusinessNamePartners},
  props:{
    business:Object
  },
  data(){
    return{
      currentRoute:"partners",
    }
  },
  mounted() {
    this.fetchBusinessNamePartners(this.business.brn)
  },
  methods:{
    ...mapActions(["fetchBusinessNamePartners"]),
    select(route){
      this.currentRoute = route
    },
  },
  computed:{
    ...mapGetters(["getBusinessPartners"])
  },
}
</script>

<style scoped>

</style>