import Vue from 'vue'
import App from './App.vue'
import router from "@/router"
import store from "@/store"
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueNotification from "vue-notification";
import "./assets/styles/index.css"
import {abilitiesPlugin} from "@casl/vue";
import abilities from "@/abilities";
import axios from "axios";
import moment from "moment";


Vue.use(BootstrapVue)
Vue.use(VueNotification)
Vue.use(IconsPlugin)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false


const user = sessionStorage.getItem("userProfile");
if (user != null) {
  let usr = JSON.parse(sessionStorage.getItem("userProfile"));
  let token = sessionStorage.getItem("token");

  axios.defaults.headers.common = {Authorization: `Bearer ${token}`};

  Vue.prototype.$userProfile = usr;

  axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (!error.response) {
          /* alert(
             "Please check your internet connection. \n Try again or reload the page to continue"
           );*/
        }

        if (error.response.status === 401) {
          sessionStorage.clear();
          /* if (
             !alert("Your session has expired! \n reload the page to continue")
           ) {
             window.location.reload();
           }*/
          window.location.reload();
        }/* else if (error.response.status >= 600) {
          store.dispatch('notifySupportError', "That's our fault, kindly seek technical support")
          return error
        }*/
        return Promise.reject(error);
      }
  );
}

Vue.mixin({
  methods: {
    appendQueryParams(data) {
      return `?${Object.keys(data).map(k => `${k}=${data[k]}`).join("&")}`;
    },
    successToast: function (
        title = "Success",
        text = "Request was processed successfully"
    ) {
      this.$notify({
        group: "foo",
        type: "success",
        title: title,
        text: text,
      });
    },
    errorToast: function (
        title = "Error",
        text = "Something went wrong try again later"
    ) {
      this.$notify({
        group: "foo",
        type: "error",
        title: title,
        text: text,
      });
    },
    warningToast: function (
        title = "Warning",
        text = "It seems something is not right"
    ) {
      this.$notify({
        group: "foo",
        type: "warn",
        title: title,
        text: text,
      });
    },

    formatDate(date) {
      if(date){
        return moment(date).format("DD/MM/YYYY")
      }
      return ""
    },
    dateFormat(date){
      if(date){
        return moment(date).format("DD/MM/YYYY")
      }
      return ""
    },
    cleanUnderscoreString(name){
      let n = name.replaceAll("_", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    cleanEntityType(name) {
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    onlyNumberValidator(event) {
      if (!/[\d]/.test(event.key)) return event.preventDefault();
    },
    showConfirmDialog(message = "Are you sure of this action ?") {
      this.boxTwo = ''
      return this.$bvModal.msgBoxConfirm(message, {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: true,
        centered: true
      })

    },
    objectToQueryParams(objectToParse) {
      return Object.keys(objectToParse).map(key => key + '=' + objectToParse[key]).join('&');
    },
    getReference() {
      const randomDivisibleBy4 = this.generateRandomDivisibleBy4();
      return this.manipulateNumber(randomDivisibleBy4);
    }
    , generateRandomDivisibleBy4() {
      let resultString = "";

      for (let i = 0; i < 17; i++) {
        const randomNumber = Math.floor(Math.random() * 6) + 4;
        resultString += randomNumber.toString();
      }

      return resultString
    },

    getCenterThreeDigits(number) {
      const numString = number.toString();
      const startIndex = Math.floor((numString.length - 3) / 2);
      return numString.substr(startIndex, 3);
    },

    manipulateNumber(number) {
      const centerThreeDigits = this.getCenterThreeDigits(number);
      const multipliedResult = parseInt(centerThreeDigits) * 3;
      const firstTwoDigits = Math.floor(multipliedResult / 100);
      const lastTwoDigits = multipliedResult % 100;

      return firstTwoDigits.toString() +
          number.toString() +
          lastTwoDigits.toString();
    },
      filterUnitData(data) {
          let arrayData = [];
          let obj = {};
          data.forEach(list => {
              obj = {
                  value: list.id,
                  text: list.name ? list.name.toUpperCase() : "",
                  id: list.id,
                  name: list.name ? list.name.toUpperCase() : ""
              };
              arrayData.push(obj);
          });

          arrayData.sort(function (a, b) {
              if (a.text < b.text) return -1;
              if (a.text > b.text) return 1;
              return 0;
          });

          return arrayData;
      },
  },

});
Vue.use(abilitiesPlugin, abilities, {
  useGlobalProperties: true,
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')