<template>
  <b-row id="app">
    <b-col>
      <notifications group="foo" position="top right"/>
      <div class="app-holder">
        <router-view :key="$route.fullPath"></router-view>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "App",

  components: {},

  data: () => ({
  }),
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  height: 100%;
}

html,
body {
  height: 100%;
}

.app-holder {
  height: 100%;
}



*{
  scrollbar-width: thin;
  scrollbar-color: #cdcdcd #f0f0f0;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: #f0f0f0;
}

*::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
  /*background: linear-gradient(to top, #33ccff 0%, #cc00ff 100%)*/
}


.noFocus:focus {
  outline: none 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.noFocus {
  box-shadow: none !important
}

</style>
