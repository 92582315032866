import {api} from "@/app/services/api.service"

const state = {
    error: null,
    success: null,
    isic: [],
    isicChildren: []

};

const getters = {
    getIsicAdd: (state) => state.success,
    getIsicAddError: (state) => state.error,
    getIsicFetch: (state) => state.isic,
    getIsicFetchChildren: (state) => state.isicChildren,
};

const actions = {

    async fetchIsic({commit}, data) {
        api().get("/reservation/isic", data)
            .then(response => {
                commit("setIsicFetch", response);
            })
            .catch(error => {
                commit("setIsicFetchError", error);
            });
    }, async fetchLevelOneIsic({commit}, data) {
        api().get("/reservation/isic/parents", data)
            .then(response => {
                commit("setIsicChildrenFetch", response);
            })
            .catch(error => {
                commit("setIsicFetchError", error);
            });
    }, async fetchIsicLevelChildren({commit}, parentId) {
        api().get("/reservation/isic/?prentid=" + parentId)
            .then(response => {
                commit("setIsicChildrenFetch", response);
            })
            .catch(error => {
                commit("setIsicFetchError", error);
            });
    },

    async addIsicCategory({commit}, data) {

        api().post("/reservation/isic", data)
            .then(response => {
                commit("setIsic", response);
            })
            .catch(error => {
                commit("setIsicError", error);
            });
    },
};

const mutations = {
    setIsic: (state, response) => {
        state.success = response.data;
    },
    setIsicError: (state, error) => {
        state.error = error.response.data;
    }, setIsicFetch: (state, response) => {
        state.isic = response.data;
    }, setIsicChildrenFetch: (state, response) => {
        state.isicChildren = response.data;
    },
    setIsicFetchError: (state, error) => {
        state.error = error.response.data;
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};
