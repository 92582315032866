import {api} from "@/app/services/api.service";

const state = {
  message_success:null,
  error: null,
}

const getters = {
  getMessageSentSuccess: state => state.message_success,
  getError: state => state.error,
}

const actions = {

  async sendMessage({commit}, data) {
    api().post('configuration/system-messenger/send', data)
      .then(response => {
        commit('setMessageSuccess', response)
      })
      .catch(error => {
        commit('setError', error)
      })
  },
}

const mutations = {
  setMessageSuccess: (state, payload) => {
    state.message_success = payload.data;
  },
  setError: (state, error) => {
    state.error = error.response.data;
  },
}


export default {
  state,
  getters,
  actions,
  mutations

}
